import React, {ReactFragment} from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';
import {
  FactoryFloorTask,
  FloorTaskContentNote, FloorTaskContentQCCurtainV1, FloorTaskContentPackCurtainV1,
  FloorTaskContentRepair,
  MapOf, FloorTaskContentBlind, FloorTaskContentCurtain,
} from 'two-core';

interface Props {
  data: any;
}

class CUQcPack_v1 extends CutSheetTemplate {
  report: Report;
  contentPrio: MapOf<number>;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
    this.contentPrio = {
      repair: 3,
      curtain: 1,
    };
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      3,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">curtain std v1</td>
              <td className="text">
                <b>QC_Pack</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text">{this.props.data.reference}</td>
              <td className="label">shipping to</td>
              <td className="text" colSpan={3}>
                {this.props.data.ship_to}
              </td>
            </tr>
            <tr>
              <td className="label">product</td>
              <td className="text">CV Curtain</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    this.props.data.qc.items.sort((firstTask: FactoryFloorTask, secondTask: FactoryFloorTask) => {
      const task1CurtainIndex = firstTask.category === 'repair' ? (firstTask.content as FloorTaskContentRepair).blind_index : (firstTask.content as FloorTaskContentQCCurtainV1).curtain_index;
      const task2CurtainIndex = secondTask.category === 'repair' ? (secondTask.content as FloorTaskContentRepair).blind_index : (secondTask.content as FloorTaskContentQCCurtainV1).curtain_index;
      const weight1 =
        firstTask.order_item_index * 100 +
        (task1CurtainIndex ?? 1) * 10 +
        this.contentPrio[firstTask.category ?? 0];
      const weight2 =
        secondTask.order_item_index * 100 +
        (task2CurtainIndex ?? 1) * 10 +
        this.contentPrio[secondTask.category ?? 0];
      return weight1 - weight2;
    });

    let currentItemIndex = this.props.data.qc.items[0].order_item_index;
    let currentCurtainIndex = -1;
    let itemRows: any[] = [];
    let repair1: any, repair2: any;
    repair1 = repair2 = '';
    let isFirstRow = true;
    let currentLineCount = 1;
    let currentLineIndex = 0;

    {
      /* ITEMS */
    }
    this.props.data.qc.items.map((item: FactoryFloorTask) => {
      let indexColumn = <td className="no-top-border"/>;
      let row;
      let rowClass = 'inside-row';
      const curtainContent = item.content as FloorTaskContentCurtain;

      if (item.order_item_index !== currentItemIndex) {
        repair1 = repair2 = '';
        currentItemIndex = item.order_item_index;
        currentCurtainIndex = -1;
      }

      if (
        item.order_item_index !== currentItemIndex ||
        (curtainContent.curtain_index && curtainContent.curtain_index !== currentCurtainIndex)
      ) {
        this.report.addItem(
          item,
          currentLineCount,
          <React.Fragment>
            <table className="mt-10 odd-zebra three-cols content">
              {repair1}
              {repair2}
              {itemRows}
            </table>
          </React.Fragment>
        );
        currentCurtainIndex = curtainContent.curtain_index;
        currentLineCount = 0;
        currentLineIndex++;
        itemRows = [];
        isFirstRow = true;
      }

      if (isFirstRow) {
        isFirstRow = false;
        indexColumn = <td className="index">{currentLineIndex}</td>;
        rowClass = 'new-item';
      }

      if (item.category === 'repair') {
        const repairContent = item.content as FloorTaskContentRepair;
        repair1 = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">rework</td>
            <td className="text">{repairContent.repair_whole_item ? 'YES' : 'NO'}</td>
            <td className="label">type</td>
            <td className="text">{this.capitaliseFirst(repairContent.repair_type ?? 'Whole Item Rework')}</td>
            <td className="label">responsibility</td>
            <td className="text" colSpan={2}>
              {this.capitaliseFirst(repairContent.repair_responsibility)}
            </td>
          </tr>
        );
        repair2 = (
          <tr>
            <td/>
            <td className="label">repair</td>
            <td className="text" colSpan={5}>
              {repairContent.repair_details}
            </td>
            <td className="sign-off">check</td>
          </tr>
        );
        currentLineCount += 2;
      }

      if (item.category === 'curtain') {
        const qcContent = item.content as FloorTaskContentQCCurtainV1;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">label</td>
            <td className="text">{qcContent.label}</td>
            <td className="label">width</td>
            <td className="text">{qcContent.width}</td>
            <td className="label">drop</td>
            <td className="text">{qcContent.drop}</td>
            <td className="sign-off">check</td>
          </tr>
        );
        itemRows.push(row);
        currentLineCount++;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">fabric</td>
            <td className="text">{this.capitaliseFirst(qcContent.fabric)}</td>
            <td className="label">colour</td>
            <td className="text">{this.capitaliseFirst(qcContent.fabric_colour)}</td>
            <td className="label">lining</td>
            <td className="text">{qcContent.lining ? this.capitaliseFirst(qcContent.lining) : 'N/A'}</td>
            <td className="sign-off">check</td>
          </tr>
        );
        itemRows.push(row);
        currentLineCount++;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">heading</td>
            <td className="text">{this.capitaliseFirst(qcContent.heading)}</td>
            <td className="label">hem</td>
            <td className="text" colSpan={3}>{this.capitaliseFirst(qcContent.hem)}</td>
            <td className="sign-off">check</td>
          </tr>
        );
        itemRows.push(row);
        currentLineCount++;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">opening</td>
            <td className="text">{this.capitaliseFirst(qcContent.opening)}</td>
            <td className="label">option</td>
            <td className="text" colSpan={3}>{this.capitaliseFirst(qcContent.opening_options)}</td>
            <td className="sign-off">check</td>
          </tr>
        );
        itemRows.push(row);
        currentLineCount++;
        if (qcContent.track) {
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">track</td>
              <td className="text">{this.capitaliseFirst(qcContent.track)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(qcContent.track_colour ?? '')}</td>
              <td className="label">dual</td>
              <td className="text">{qcContent.dual ? (qcContent.curtain_index === 1 ? 'To Next' : 'To Previous') : 'No'}</td>
              <td className="sign-off">check</td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount++;
        }
        const packTask = this.props.data.pack.items.find((packItem: FactoryFloorTask) => {
          const packContent = packItem.content as FloorTaskContentPackCurtainV1;
          return packItem.order_item_index === item.order_item_index &&
            packItem.station === 'pack' &&
            packContent.curtain_index === curtainContent.curtain_index;
        });
        if (packTask) {
          const packContent = packTask.content as FloorTaskContentPackCurtainV1;
          if (packContent.components && packContent.components.length > 0) {
            for (const component of packContent.components) {
              if (component.colour) {
                row = (
                  <tr className={rowClass}>
                    {indexColumn}
                    <td className="label">component</td>
                    <td className="text">{this.capitaliseFirst(component.unit)}</td>
                    <td className="label">colour</td>
                    <td className="text">{this.capitaliseFirst(component.colour)}</td>
                    <td className="label">qty</td>
                    <td className="text">{component.qty}</td>
                    <td className="sign-off">pack</td>
                  </tr>
                );
              } else {
                row = (
                  <tr className={rowClass}>
                    {indexColumn}
                    <td className="label">component</td>
                    <td className="text" colSpan={3}>{this.capitaliseFirst(component.unit)}</td>
                    <td className="label">qty</td>
                    <td className="text">{component.qty}</td>
                    <td className="sign-off">pack</td>
                  </tr>
                );
              }
              itemRows.push(row);
              currentLineCount++;
            }
          }
        }
        if (qcContent.note) {
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">note</td>
              <td className="text" colSpan={5}>
                {`${qcContent.note}`.replace(/\r?\n|\r/g, ' ')}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount += Math.ceil(`${qcContent.note}`.length / 90);
        }
      }
    });

    //add all the rows necessary for the rows in this item blind combination
    this.report.addItem(
      this.props.data,
      currentLineCount,
      <React.Fragment>
        <table className="mt-10 odd-zebra three-cols content">
          {repair1}
          {repair2}
          {itemRows}
        </table>
      </React.Fragment>
    );
    currentLineCount = 0;
    currentLineIndex++;

    /* CHECK SUM */
    this.report.addItem(
      this.props.data,
      1,
      <React.Fragment>
        <table className="summary">
          <td className="label">total</td>
          <td className="text">{currentLineIndex - 1}</td>
        </table>
      </React.Fragment>
    );

    /* FOOTER */
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="colorvue-standard-saw-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default CUQcPack_v1;

interface Indexer {
  itemIndex: number;
  blindIndexes: number[];
}
