import React from 'react';
import {AppContext, TwoDialog} from 'two-app-ui';
import {Calendar} from 'primereact/calendar';
import {FactoryAlarm, FactoryAlarmPatch} from 'two-core';
import formats from '../../config/formats';
import {Toast} from 'primereact/toast';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  onSave: (updatedAlarms: FactoryAlarmPatch[]) => void;
  selectedAlarms: FactoryAlarm[];
  toast: React.RefObject<Toast>;
}

interface State {
  silenceUntil: Date | undefined;
}

class SilenceUntilDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      silenceUntil: undefined,
    };

    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
  }

  onSaveButtonClick() {
    const updatedAlarms: FactoryAlarmPatch[] = this.props.selectedAlarms.map(alarm => {
      return {id: alarm.id, silence_until: this.state.silenceUntil};
    });
    this.props.onSave(updatedAlarms);
    this.props.onHide();
  }

  render() {
    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label className="p-col-3" htmlFor="name">
            Notify me again on
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <Calendar
                value={this.state.silenceUntil}
                dateFormat={formats.calendarInputDate}
                onChange={e => {
                  this.setState({
                    silenceUntil: e.value as Date,
                  });
                }}
              />
            </span>
          </div>
        </div>
      </>
    );
    return (
      <TwoDialog
        headerTitle={'Set silence until'}
        showDialog={this.props.showDialog}
        width={50}
        onHide={this.props.onHide}
        onSave={this.onSaveButtonClick}
        loading={false}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default SilenceUntilDialog;
