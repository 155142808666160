import React, {Component} from 'react';
import {QueryParameter, StockTake} from 'two-core';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import StockTakesService from '../../services/StockTakesService';
import {AppColumnMenuBodyTemplate, AppContext, MessageService, TwoDataTable, TwoMessage, TwoToast} from 'two-app-ui';
import {Column} from 'primereact/column';
import formats from '../../config/formats';
import {DateTime} from 'luxon';
import {InputText} from 'primereact/inputtext';
import {MultiSelect} from 'primereact/multiselect';
import {MenuItem} from 'primereact/menuitem';
import {getStockTakeMenuItems, stockTakeStageOptions} from '../../utils/StockTakeUtil';
import StartStockTakeDialog from './StartStockTakeDialog';
import StopStockTakeDialog from './StopStockTakeDialog';
import {NavLink} from 'react-router-dom';
import NewStockTakeDialog from './NewStockTakeDialog';
import CloneStockTakeDialog from './CloneStockTakeDialog';
import EditStockTakeDialog from './EditStockTakeDialog';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';

interface State {
  loading: boolean;
  showStartStockTakeDialog?: boolean;
  showStopStockTakeDialog?: boolean;
  showNewStockTakeDialog?: boolean;
  showCloneStockTakeDialog?: boolean;
  showEditStockTakeDialog?: boolean;
  showReviewStockTakeDialog?: boolean;
  stockTakes: StockTake[];
  totalStockTakes: number;
  filter?: {
    [key: string]: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy?: {
    field: string;
    order: DataTableSortOrderType;
  };
  selectedStockTake?: StockTake;
}

export default class StockTakeList extends Component<{}, State> {
  static contextType = AppContext;
  subscription: Subscription = new Subscription();

  stockTakesService?: StockTakesService;
  twoToast?: TwoToast;

  constructor(props: {}) {
    super(props);

    this.state = {
      loading: false,
      stockTakes: [],
      totalStockTakes: 0,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: {
        field: 'started_at',
        order: -1,
      },
    };
    this.loadData = this.loadData.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.nameBody = this.nameBody.bind(this);
    this.onHideStartStockTakeDialog = this.onHideStartStockTakeDialog.bind(this);
    this.onHideStopStockTakeDialog = this.onHideStopStockTakeDialog.bind(this);
    this.onHideNewStockTakeDialog = this.onHideNewStockTakeDialog.bind(this);
    this.headerMenuItems = this.headerMenuItems.bind(this);
    this.onHideCloneStockTakeDialog = this.onHideCloneStockTakeDialog.bind(this);
    this.onHideEditStockTakeDialog = this.onHideEditStockTakeDialog.bind(this);
    this.onHideReviewStockTakeDialog = this.onHideReviewStockTakeDialog.bind(this);
  }

  componentDidMount() {
    this.stockTakesService = this.context.stockTakesService;
    this.twoToast = this.context.twoToast;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      const castedMessage = message as TwoMessage;
      if (castedMessage.name && castedMessage.name === messages.topSelectionChanged) {
        this.loadData();
      }
    });

    this.loadData();
  }

  async loadData() {
    const {filter, sortBy, pagination} = this.state;
    this.setState({loading: true});
    const {stockTakes, totalStockTakes} = await this.loadStockTakes(
      filter,
      sortBy,
      pagination.pageSize,
      pagination.offset
    );
    this.setState({loading: false, stockTakes, totalStockTakes});
  }

  async loadStockTakes(
    filter?: {[key: string]: string},
    sortBy?: {
      field: string;
      order: DataTableSortOrderType;
    },
    pageSize?: number,
    offset?: number
  ) {
    try {
      const filters = [];
      if (filter?.name) {
        filters.push(JSON.stringify({field: 'name', value: filter.name, condition: 'iLike'}));
      }
      if (filter?.stage) {
        filters.push(JSON.stringify({field: 'stage', value: filter.stage, condition: 'in'}));
      }

      let orderBys = undefined;
      if (sortBy) {
        orderBys = [JSON.stringify({field: sortBy.field, direction: sortBy.order === 1 ? 'asc' : 'desc'})];
      }
      const queryParams: QueryParameter = {
        page_size: pageSize,
        offset,
        orderBys,
        filters,
      };
      const response = await this.stockTakesService?.getStockTakes(queryParams);
      const stockTakes = (response?.records ?? []) as StockTake[];
      const totalStockTakes = response?.total_records ?? 0;
      return {stockTakes, totalStockTakes};
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading stock takes');
      return {stockTakes: [], totalStockTakes: 0};
    }
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(name: string, value: string) {
    await this.setState({filter: {...this.state.filter, [name]: value}});
    this.loadData();
  }

  onHideStartStockTakeDialog() {
    this.setState({showStartStockTakeDialog: false, selectedStockTake: undefined});
    this.loadData();
  }

  onHideNewStockTakeDialog() {
    this.setState({showNewStockTakeDialog: false});
    this.loadData();
  }

  onHideCloneStockTakeDialog() {
    this.setState({showCloneStockTakeDialog: false});
    this.loadData();
  }

  onHideEditStockTakeDialog() {
    this.setState({showEditStockTakeDialog: false});
    this.loadData();
  }
  onHideReviewStockTakeDialog() {
    this.setState({showReviewStockTakeDialog: false});
    this.loadData();
  }

  onHideStopStockTakeDialog() {
    this.setState({showStopStockTakeDialog: false, selectedStockTake: undefined});
    this.loadData();
  }

  headerMenuItems(): MenuItem[] {
    return getStockTakeMenuItems(undefined, {
      onAdd: () => this.setState({showNewStockTakeDialog: true}),
    });
  }

  rowMenuItems(stockTake: StockTake) {
    return getStockTakeMenuItems(stockTake, {
      onClone: stockTake => this.setState({showCloneStockTakeDialog: true, selectedStockTake: stockTake}),
      onEdit: stockTake => this.setState({showEditStockTakeDialog: true, selectedStockTake: stockTake}),
      onStart: stockTake => this.setState({showStartStockTakeDialog: true, selectedStockTake: stockTake}),
      onStop: stockTake => this.setState({showStopStockTakeDialog: true, selectedStockTake: stockTake}),
      onReview: stockTake => this.setState({showReviewStockTakeDialog: true, selectedStockTake: stockTake}),
    });
  }

  nameBody(stockTake: StockTake) {
    return (
      <AppColumnMenuBodyTemplate
        key={stockTake.id}
        rowItemIdentifier={stockTake?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.rowMenuItems(stockTake)}
        selectedItems={[]}
      >
        <NavLink to={'/stock-take/' + stockTake.id}>{stockTake.name}</NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  startedBody(stockTake: StockTake) {
    return (
      <>
        <span>
          {stockTake.started_at ? DateTime.fromISO(stockTake.started_at?.toString()).toFormat(formats.dateTime) : ''}
        </span>
        <span>{stockTake.started_by?.label}</span>
      </>
    );
  }

  approvedBody(stockTake: StockTake) {
    return (
      <>
        <span>
          {stockTake.approved_at ? DateTime.fromISO(stockTake.approved_at?.toString()).toFormat(formats.dateTime) : ''}
        </span>
        <span>{stockTake.approved_by?.label}</span>
      </>
    );
  }

  nameFilterTemplate() {
    return (
      <InputText
        name="name"
        className="form-filter"
        onBlur={e => {
          this.onFilterChange('name', e.target.value);
        }}
      />
    );
  }

  stageFilterTemplate() {
    const {filter} = this.state;
    return (
      <MultiSelect
        value={filter?.stage}
        options={stockTakeStageOptions}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange('stage', e.value);
        }}
        showClear
      />
    );
  }

  render() {
    const {
      loading,
      stockTakes,
      totalStockTakes,
      pagination,
      sortBy,
      selectedStockTake,
      showStartStockTakeDialog,
      showStopStockTakeDialog,
      showNewStockTakeDialog,
      showCloneStockTakeDialog,
      showEditStockTakeDialog,
      showReviewStockTakeDialog,
    } = this.state;
    return (
      <div className="stock-takes-table">
        <TwoDataTable
          loading={loading}
          value={stockTakes}
          activeFilters={{}}
          selectedItems={[]}
          rows={pagination.pageSize}
          first={pagination.offset}
          totalRecords={totalStockTakes}
          onSort={this.onSort}
          sortField={sortBy?.field}
          sortOrder={sortBy?.order}
          onPage={this.onPageChange}
          initMenuItems={this.headerMenuItems}
        >
          <Column
            header="Name"
            body={this.nameBody}
            sortField="name"
            sortable
            filter
            filterElement={this.nameFilterTemplate()}
            showFilterMenu={false}
          />
          <Column
            header="Stage"
            field="stage"
            filter
            filterElement={this.stageFilterTemplate()}
            showFilterMenu={false}
          />
          <Column header="Started" sortField="started_at" body={this.startedBody} sortable />
          <Column header="Approved" sortField="approved_at" body={this.approvedBody} sortable />
        </TwoDataTable>
        {selectedStockTake && showStartStockTakeDialog && (
          <StartStockTakeDialog
            showDialog={showStartStockTakeDialog}
            stockTake={selectedStockTake}
            onHide={this.onHideStartStockTakeDialog}
          />
        )}
        {selectedStockTake && showStopStockTakeDialog && (
          <StopStockTakeDialog
            showDialog={showStopStockTakeDialog}
            stockTakeId={selectedStockTake.id!}
            onHide={this.onHideStopStockTakeDialog}
          />
        )}
        {showNewStockTakeDialog && (
          <NewStockTakeDialog showDialog={showNewStockTakeDialog} onHide={this.onHideNewStockTakeDialog} />
        )}
        {selectedStockTake && showCloneStockTakeDialog && (
          <CloneStockTakeDialog
            showDialog={showCloneStockTakeDialog}
            originalStockTake={selectedStockTake}
            onHide={this.onHideCloneStockTakeDialog}
          />
        )}
        {selectedStockTake && showEditStockTakeDialog && (
          <EditStockTakeDialog
            showDialog={showEditStockTakeDialog}
            onHide={this.onHideEditStockTakeDialog}
            stockTakeId={selectedStockTake.id!}
            mode={'edit'}
          />
        )}
        {selectedStockTake && showReviewStockTakeDialog && (
          <EditStockTakeDialog
            showDialog={showReviewStockTakeDialog}
            onHide={this.onHideReviewStockTakeDialog}
            stockTakeId={selectedStockTake.id!}
            mode={'review'}
          />
        )}
      </div>
    );
  }
}
