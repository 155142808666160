import {InputTextarea} from 'primereact/inputtextarea';
import React from 'react';

interface Props {
  reason: string;
  handleOnHoldReasonChange: (reason: string) => void;
}
const ToOnHoldDialog = ({reason, handleOnHoldReasonChange}: Props) => {
  return (
    <>
      <div className="p-d-flex p-col-12 p-p-2 p-mb-2">
        <span>Please state a reason of the Hold.:</span>
      </div>
      <div className="p-d-flex p-col-12 p-p-2">
        <InputTextarea
          className="w-100"
          rows={5}
          cols={30}
          value={reason}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleOnHoldReasonChange(e.target.value)}
        />
      </div>
    </>
  );
};
export default ToOnHoldDialog;
