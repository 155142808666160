import React from 'react';
import {Button} from 'primereact/button';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column, ColumnProps} from 'primereact/column';
import {Toast} from 'primereact/toast';
import './Oos.scss';
import OosService from '../../services/OosService';
import {AppMenuItem, AppMenuItemTemplate, AppContext, MessageService, TwoMessage, ToastService} from 'two-app-ui';
import {Subscription} from 'rxjs';
import {library} from '@fortawesome/fontawesome-svg-core';
import OosDialog from './OosDialog';
import SetEtaDialog from './SetEtaDialog';
import {DateTime} from 'luxon';
import {formats} from '../../config/formats';
import {AppColumnMenuBodyTemplate, TwoDataTable} from 'two-app-ui';
import {Oos, OosStage, QueryParameter, Order, OosPatch, DropdownOption} from 'two-core';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import {InputText} from 'primereact/inputtext';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import values from '../../config/values';
import {oosStages} from './Constants/constants';
import {MenuItemOptions} from 'primereact/menuitem';
import {faPlusCircle, faArrowCircleRight} from '@fortawesome/pro-regular-svg-icons';
import {NavLink} from 'react-router-dom';

library.add(faPlusCircle, faArrowCircleRight);

interface State {
  loading: boolean;
  ooss: Oos[];
  totalOoss: number;
  selectedOoss: Oos[];
  pagination: {
    pageSize: number;
    offset: number;
  };
  showNewOosDialog: boolean;
  showEditOosDialog: boolean;
  showSetEtaDialog: boolean;
  filters: {
    description: string;
    stage: string;
    eta: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  currentTableMenuItemId: string | null;
  editOos: Oos | undefined;
}

class OosListComponent extends React.Component<{}, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;
  oosService: OosService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
      ooss: [],
      totalOoss: 0,
      selectedOoss: [],
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      showNewOosDialog: false,
      showEditOosDialog: false,
      showSetEtaDialog: false,
      filters: {
        description: '',
        stage: '',
        eta: {
          fromDate: null,
          toDate: null,
        },
      },
      sortBy: null,
      currentTableMenuItemId: null,
      editOos: undefined,
    };
    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.descriptionBodyTemplate = this.descriptionBodyTemplate.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
  }

  componentDidMount() {
    this.oosService = this.context.oosService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      const castedMessage = message as TwoMessage;
      if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
        await localStorage.setItem('current factory', castedMessage.value as string);
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  loadData() {
    this.setState({loading: true});
    //some columns have different name in database and map for server
    const sortBy = {
      field: this.state.sortBy?.field,
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    };
    switch (sortBy.field) {
      case 'description':
        sortBy.field = 'inventory_item.name';
        break;
    }

    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'factory_id',
        value: localStorage.getItem('current factory') ?? '',
      })
    );

    if (this.state.filters.description && this.state.filters.description.length > 0) {
      filters.push(
        JSON.stringify({
          field: 'inventory_item.name',
          value: this.state.filters.description,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.stage && this.state.filters.stage.length) {
      filters.push(
        JSON.stringify({
          field: 'stage',
          value: this.state.filters.stage,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.eta.fromDate) {
      const fromDate = this.state.filters.eta.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'eta',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.eta.toDate) {
      const toDate = this.state.filters.eta.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'eta',
          value: toDate,
          condition: '<=',
        })
      );
    }

    const orderBys = sortBy.field ? sortBy : {field: 'eta', direction: 'ASC'};

    const params: QueryParameter = {
      filters,
      orderBys: [JSON.stringify(orderBys)],
      aggregate: true,
    };

    this.oosService
      ?.getOos(params)
      .then(data => {
        const dataRecords = (data.records as Oos[]) ?? [];
        const ooss: Oos[] = dataRecords.map((oos: Oos) => {
          //convert date from response to Date object
          const eta: Date | undefined = oos.eta ? DateTime.fromISO(oos.eta as unknown as string).toJSDate() : undefined;
          return {...oos, eta};
        });
        this.handleSelectedItems(dataRecords);
        this.setState({
          ooss: ooss,
          totalOoss: data?.total_records ?? 0,
          loading: false,
          selectedOoss: [],
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedItems = this.state.selectedOoss;
    const selectedItemsCount = selectedItems.length;

    this.initNewMenuItems(menuItems);

    if (selectedItemsCount > 0) {
      this.initSeparatorMenuItems(menuItems);
      this.initStageMenuItems(menuItems);
    }
    return menuItems;
  }

  initSeparatorMenuItems(menuItems: AppMenuItem[]) {
    menuItems.push({
      separator: true,
    });
  }

  initNewMenuItems(menuItems: AppMenuItem[]) {
    menuItems.push({
      label: 'New',
      faIcon: faPlusCircle,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => this.setState({showNewOosDialog: true}),
    });
  }

  initStageMenuItems(menuItems: AppMenuItem[]) {
    menuItems.push({
      label: 'Available',
      faIcon: faArrowCircleRight,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => this.stageChange('Available'),
    });

    menuItems.push({
      label: 'Delayed',
      faIcon: faArrowCircleRight,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => this.stageChange('Delayed'),
    });
    menuItems.push({
      label: 'Eta confirmed',
      faIcon: faArrowCircleRight,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => this.setState({showSetEtaDialog: true}),
    });
  }

  processShowAddNewOosDialog() {
    this.setState({showNewOosDialog: true});
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  handleSelectedItems(allItems: Oos[]) {
    const selectedItems = [...this.state.selectedOoss];
    const items: Oos[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Oos[]) {
    this.setState({selectedOoss: items});
  }

  async setChangeSelectedItem(item: Oos) {
    const items = [...this.state.selectedOoss];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedOoss: items});
    }
  }

  descriptionBodyTemplate(rowData: Oos) {
    return (
      <AppColumnMenuBodyTemplate
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems()}
        selectedItems={this.state.selectedOoss}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <NavLink to={'oos-item/' + rowData.id}>
          {rowData.inventory_item?.name ?? ''} : {rowData.inventory_item?.sku ?? ''} :{' '}
          {rowData.inventory_item?.colour ?? ''}
        </NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  stageBodyTemplate(rowData: Order) {
    return (
      <span className={`oos-stage-badge oos-stage-${(rowData.stage ?? '').toLowerCase().replaceAll(' ', '-')}`}>
        {rowData.stage}
      </span>
    );
  }

  renderOosFooter() {
    return (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button
          label="cancel"
          className={'p-mr-2 p-button-text'}
          onClick={() => {
            this.setState({showNewOosDialog: false});
          }}
        />
        <Button label="save" className={'p-mr-2'} onClick={() => {}} autoFocus />
      </div>
    );
  }

  formatDateColumn(data: Oos, props: ColumnProps) {
    switch (props.field) {
      case 'eta':
        return data.eta ? DateTime.fromJSDate(data.eta).toFormat(formats.date) : '';
      default:
        return '';
    }
  }

  stageChange(stage: OosStage) {
    const oosItems = this.state.selectedOoss;
    const promises = oosItems.map(oos => {
      const oosPatch: OosPatch = {
        stage: stage,
      };
      return this.oosService?.updateOos(oos.id?.toString() ?? '', oosPatch);
    });

    Promise.all(promises)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Stage changed');
        this.loadData();
      })
      .catch(error => {
        console.error('error: ' + error);
        this.toastService?.showError(this.toast, 'Stage was not changed');
      });
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams | DateColumnFilterChangeEvent) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  render() {
    const descriptionFilter = (
      <InputText
        name="description"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const selectedStageFilterTemplate = (value: string) => {
      if (value) {
        return (
          <span
            className={`p-mr-1 oos-stage-badge oos-stage-badge-filter oos-stage-${value
              .toLowerCase()
              .replaceAll(' ', '-')}`}
          >
            {value}
          </span>
        );
      }

      return <></>;
    };

    const stageFilterTemplate = (option: DropdownOption) => {
      return (
        <span className={`oos-stage-badge oos-stage-${(option.value as string).toLowerCase().replaceAll(' ', '-')}`}>
          {option.value}
        </span>
      );
    };

    const stageFilter = (
      <MultiSelect
        selectedItemTemplate={selectedStageFilterTemplate}
        itemTemplate={stageFilterTemplate}
        value={this.state.filters.stage}
        options={oosStages}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const etaFilter = (
      <DateColumnFilter name="eta" value={this.state.filters.eta} onChange={e => this.onFilterChange(e)} />
    );

    return (
      <div id="oos_page_container" className="page-container">
        <TwoDataTable
          pageSizeIdentifier={'oos_page_container'}
          addNewItemEvent={() => this.processShowAddNewOosDialog()}
          selectedItems={this.state.selectedOoss}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as Oos[])}
          activeFilters={{}}
          selectionMode="multiple"
          loading={this.state.loading}
          totalRecords={this.state.totalOoss}
          first={this.state.pagination.offset}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={this.onSort}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          value={this.state.ooss}
          rows={this.state.pagination.pageSize}
          initMenuItems={this.initMenuItems}
        >
          <Column
            header="OOS Item"
            field="description"
            body={this.descriptionBodyTemplate}
            filter
            filterElement={descriptionFilter}
            sortable
            style={{width: '300px'}}
            showFilterMenu={false}
          />
          <Column
            header="Stage"
            field="stage"
            body={this.stageBodyTemplate}
            filter
            filterElement={stageFilter}
            sortable
            style={{width: '150px'}}
            showFilterMenu={false}
          />
          <Column
            header="ETA"
            field="eta"
            body={this.formatDateColumn}
            filter
            filterElement={etaFilter}
            sortable
            style={{width: '150px'}}
            showFilterMenu={false}
          />
        </TwoDataTable>
        {/* New oos dialog */}
        <OosDialog
          showDialog={this.state.showNewOosDialog}
          toast={this.toast}
          onHide={() => {
            this.setState({showNewOosDialog: false});
            this.loadData();
          }}
          isNew={true}
        />
        {/* Edit oos dialog */}
        <OosDialog
          showDialog={this.state.showEditOosDialog}
          toast={this.toast}
          onHide={() => {
            this.setState({showEditOosDialog: false});
            this.loadData();
          }}
          isEdit={true}
          oos={this.state.editOos}
        />
        <SetEtaDialog
          showDialog={this.state.showSetEtaDialog}
          onHide={() => {
            this.setState({showSetEtaDialog: false});
            this.loadData();
          }}
          oosRecords={this.state.selectedOoss}
          toast={this.toast}
        />
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default OosListComponent;
