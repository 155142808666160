import React from 'react';
import {History} from 'history';
import {AppContext} from 'two-app-ui';
import {PurchaseOrder} from 'two-core';
import {StageMenuOptions, OtherMenuOptions} from '../Order/Constants/constants';
import PurchaseOrderOrderListComponent from './PurchaseOrderOrderListComponent';

interface Props {
  purchaseOrder: PurchaseOrder;
  history: History;
}

class PurchaseOrderOrders extends React.Component<Props, {}> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="p-d-flex p-p-2" style={{height: '100%', width: '100%'}}>
        <PurchaseOrderOrderListComponent
          showFilterBox={false}
          stageMenuOptions={[StageMenuOptions.MaterialAvailable]}
          otherMenuOptions={[OtherMenuOptions.RemoveFromPO]}
          purchaseOrder={this.props.purchaseOrder}
          history={this.props.history}
        />
      </div>
    );
  }
}

export default PurchaseOrderOrders;
