import {DropdownOption} from 'two-core';

export const inventoryItemCategories: DropdownOption[] = [
  {label: 'Aluminium', value: 'Aluminium'},
  {label: 'Automation', value: 'Automation'},
  {label: 'Hardware', value: 'Hardware'},
  {label: 'Extras', value: 'Extras'},
  {label: 'Fabrics', value: 'Fabrics'},
  {label: 'Powder Coating', value: 'Powder Coating'},
];

export const inventoryItemUoms: DropdownOption[] = [
  {label: 'Each', value: 'Each'},
  {label: 'Linear Meter', value: 'Linear Meter'},
  {label: 'Pair', value: 'Pair'},
  {label: 'Piece', value: 'Piece'},
  {label: 'Set', value: 'Set'},
  {label: 'Square Meter', value: 'Square Meter'},
];

export const inventoryItemTypes: DropdownOption[] = [
  {label: 'Item', value: 'Item'},
  {label: 'Kit', value: 'Kit'},
];

export const inventoryItemProductLines: DropdownOption[] = [
  {label: 'COLOURVUE', value: 'COLOURVUE'},
  {label: 'SHADESOL', value: 'SHADESOL'},
  {label: 'CURTAINS', value: 'CURTAINS'},
];

export const supplyItemReorderUnits: DropdownOption[] = [
  {label: 'Each', value: 'Each'},
  {label: 'Length', value: 'Length'},
  {label: 'Linear Meter', value: 'Linear Meter'},
  {label: 'Pair', value: 'Pair'},
  {label: 'Piece', value: 'Piece'},
  {label: 'Roll', value: 'Roll'},
  {label: 'Set', value: 'Set'},
  {label: 'Square Meter', value: 'Square Meter'},
];

export const reisePoDialogTitle = 'Choose supplier(s) for the following items please';

export const reisePoDialogSubTitle =
  'Some of the items you have chose to create PO(s) for, can be purchased from multiple supplier. Please choose the one(s) you wisch to purchase from';
