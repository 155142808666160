import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';
import {
  FactoryFloorTask,
  FloorTaskContentCurtain,
  FloorTaskContentRepair,
  FloorTaskContentSawCurtainV1,
  MapOf,
} from 'two-core';

interface Props {
  data: any;
}

class CUSaw_v1 extends CutSheetTemplate {
  report: Report;
  contentPrio: MapOf<number>;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
    this.contentPrio = {
      repair: 3,
      track: 1,
    };
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      3,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">curtain v1</td>
              <td className="text">
                <b>SAW</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text">{this.props.data.reference}</td>
              <td className="label">shipping to</td>
              <td className="text" colSpan={3}>
                {this.props.data.ship_to}
              </td>
            </tr>
            <tr>
              <td className="label">product</td>
              <td className="text">CV Curtain</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      /* ITEMS */
    }
    {
      this.props.data.saw.items.sort((firstTask: FactoryFloorTask, secondTask: FactoryFloorTask) => {
        const task1CurtainIndex = firstTask.category === 'repair' ? (firstTask.content as FloorTaskContentRepair).blind_index : (firstTask.content as FloorTaskContentSawCurtainV1).curtain_index;
        const task2CurtainIndex = secondTask.category === 'repair' ? (secondTask.content as FloorTaskContentRepair).blind_index : (secondTask.content as FloorTaskContentSawCurtainV1).curtain_index;
        const weight1 =
          firstTask.order_item_index * 100 +
          (task1CurtainIndex ?? 1) * 10 +
          this.contentPrio[firstTask.category ?? 0];
        const weight2 =
          secondTask.order_item_index * 100 +
          (task2CurtainIndex ?? 1) * 10 +
          this.contentPrio[secondTask.category ?? 0];
        return weight1 - weight2;
      });

      let currentItemIndex = this.props.data.saw.items[0].order_item_index;
      let currentBlindIndex = -1;
      let itemRows: any[] = [];
      let repair1: any, repair2: any;
      repair1 = repair2 = '';
      let isFirstRow = true;
      let currentLineCount = 1;
      let currentLineIndex = 0;

      this.props.data.saw.items.map((item: FactoryFloorTask) => {
        let indexColumn = <td className="no-top-border" />;
        let row;
        let rowClass = 'inside-row';
        const curtainContent = item.content as FloorTaskContentCurtain;

        if (item.order_item_index !== currentItemIndex) {
          currentItemIndex = item.order_item_index;
          currentBlindIndex = -1;
        }

        if (
          item.order_item_index !== currentItemIndex ||
          (curtainContent.curtain_index && curtainContent.curtain_index !== currentBlindIndex)
        ) {
          this.report.addItem(
            item,
            currentLineCount,
            <React.Fragment>
              <table className="mt-10 odd-zebra three-cols content">
                {repair1}
                {repair2}
                {itemRows}
              </table>
            </React.Fragment>
          );
          repair1 = repair2 = '';
          currentItemIndex = item.order_item_index;
          currentBlindIndex = curtainContent.curtain_index;
          currentLineCount = 0;
          currentLineIndex++;
          itemRows = [];
          isFirstRow = true;
        }

        if (isFirstRow) {
          isFirstRow = false;
          indexColumn = <td className="index">{currentLineIndex}</td>;
          rowClass = 'new-item';
        }

        if (item.category === 'repair') {
          const repairContent = item.content as FloorTaskContentRepair;
          repair1 = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">rework</td>
              <td className="text">{repairContent.repair_whole_item ? 'YES' : 'NO'}</td>
              <td className="label">type</td>
              <td className="text">{this.capitaliseFirst(repairContent.repair_type ?? 'Whole Item Rework')}</td>
              <td className="label">responsibility</td>
              <td className="text" colSpan={2}>
                {this.capitaliseFirst(repairContent.repair_responsibility)}
              </td>
            </tr>
          );
          repair2 = (
            <tr>
              <td />
              <td className="label">repair</td>
              <td className="text" colSpan={5}>
                {repairContent.repair_details}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += 2;
        }

        if (item.category === 'track') {
          const trackContent = item.content as FloorTaskContentSawCurtainV1;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">label</td>
              <td className="text">{trackContent.label}</td>
              <td className="label">extrusion</td>
              <td className="text">{this.capitaliseFirst(trackContent.type +' '+trackContent.colour)}</td>
              <td className="label">length</td>
              <td className="number">{trackContent.length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
          itemRows.push(row);
          if (trackContent.note) {
            row = (
              <tr className={rowClass}>
                {indexColumn}
                <td className="label">note</td>
                <td className="text" colSpan={5}>
                  {`${trackContent.note}`.replace(/\r?\n|\r/g, ' ')}
                </td>
                <td className="sign-off">check</td>
              </tr>
            );
            itemRows.push(row);
            currentLineCount += Math.ceil(`${trackContent.note}`.length / 90);
          }
        }
      });
      this.report.addItem(
        this.props.data,
        currentLineCount,
        <React.Fragment>
          <table className="mt-10 odd-zebra three-cols content">
            {repair1}
            {repair2}
            {itemRows}
          </table>
        </React.Fragment>
      );

      {
        /* SUMMARY */
      }
      this.report.addItem(
        this.props.data,
        1,
        <React.Fragment>
          <table className="summary">
            <td className="label">total</td>
            <td className="text">{currentLineIndex}</td>
          </table>
        </React.Fragment>
      );
    }

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="colorvue-standard-saw-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default CUSaw_v1;
