import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';

interface Props {
  data: any;
}

//interface State {}

class CVSaw extends CutSheetTemplate {
  report: Report;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      2,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">cv std v2</td>
              <td className="text">
                <b>SAW</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text" colSpan={3}>
                {this.props.data.reference}
              </td>
              <td className="label">shipping to</td>
              <td className="text">{this.props.data.ship_to}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      /* ITEMS */
    }
    {
      this.props.data.saw.items.map((item: any) => {
        let currentLineCount = 1;
        let brail;
        let cover_front;
        let cover_back;
        let cover_legth;
        let channel;
        let mountingRail, note;
        let repair1, repair2, repair3;

        if (item.content.repair_respo) {
          repair1 = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">rework</td>
              <td className="text">{item.content.repair_whole_item ? 'YES' : 'NO'}</td>
              <td className="label">type</td>
              <td className="text">{this.capitaliseFirst(item.content.repair_type)}</td>
              <td className="label">responsibility</td>
              <td className="text" colSpan={2}>
                {this.capitaliseFirst(item.content.repair_respo)}
              </td>
            </tr>
          );
          if (item.content.repair_type === 'cutdown (standard)') {
            repair2 = (
              <tr>
                <td />
                <td className="label">new height</td>
                <td className="number">{item.content.new_height}</td>
                <td className="label">new width</td>
                <td className="number" colSpan={3}>
                  {item.content.new_width}
                </td>
                <td className="sign-off">check</td>
              </tr>
            );
          } else {
            repair2 = '';
          }
          repair3 = (
            <tr>
              <td />
              <td className="label">repair detail</td>
              <td className="text" colSpan={5}>
                {item.content.repair_details}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += 3;
        } else {
          repair1 = repair2 = repair3 = '';
        }

        if (item.content.brail_length) {
          brail = (
            <tr className="inside-row">
              <td className="no-top-border"> </td>
              <td className="label no-top-border">base rail</td>
              <td className="text">{this.capitaliseFirst(item.content.brail_type)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.brail_colour)}</td>
              <td className="label">length</td>
              <td className="number">{item.content.brail_length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        } else {
          brail = '';
        }

        if (item.content.cover_front) {
          cover_front = (
            <tr className="inside-row">
              <td className="no-top-border"> </td>
              <td className="label no-top-border">cover</td>
              <td className="text">{this.capitaliseFirst(item.content.cover_type)} Front</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.cover_colour)}</td>
              <td className="label">length</td>
              <td className="number">{item.content.cover_front}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        } else {
          cover_front = '';
        }

        if (item.content.cover_back) {
          cover_back = (
            <tr className="inside-row">
              <td className="no-top-border"> </td>
              <td className="label no-top-border">cover</td>
              <td className="text">{this.capitaliseFirst(item.content.cover_type)} Back</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.cover_colour)}</td>
              <td className="label">length</td>
              <td className="number">{item.content.cover_back}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        } else {
          cover_back = '';
        }

        if (item.content.cover_length) {
          cover_legth = (
            <tr className="inside-row">
              <td className="no-top-border"> </td>
              <td className="label no-top-border">cover</td>
              <td className="text">{this.capitaliseFirst(item.content.cover_type)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.cover_colour)}</td>
              <td className="label">length</td>
              <td className="number">{item.content.cover_length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        } else {
          cover_legth = '';
        }

        if (item.content.ch_l_length) {
          channel = (
            <tr className="inside-row">
              <td className="no-top-border"> </td>
              <td className="label no-top-border">channels</td>
              <td className="text">{this.capitaliseFirst(item.content.ch_colour)}</td>
              <td className="label">left</td>
              <td className="number">{item.content.ch_l_length}</td>
              <td className="label">right</td>
              <td className="number">{item.content.ch_r_length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        } else {
          channel = '';
        }

        if (item.content.head_mount_length) {
          mountingRail = (
            <tr className="inside-row">
              <td className="no-top-border"> </td>
              <td className="label no-top-border">mount rail</td>
              <td className="text">{this.capitaliseFirst(item.content.head_mount_colour)}</td>
              <td></td>
              <td></td>
              <td className="label">length</td>
              <td className="number">{item.content.head_mount_length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        } else {
          mountingRail = '';
        }

        if (item.content.notes) {
          note = (
            <tr>
              <td />
              <td className="label">note</td>
              <td className="text" colSpan={5}>
                {`${item.content.notes}`.replace(/\r?\n|\r/g, ' ')}
              </td>
            </tr>
          );
          currentLineCount += Math.ceil(`${item.content.notes}`.length / 90);
        } else {
          note = '';
        }

        this.report.addItem(
          item,
          currentLineCount,
          <React.Fragment>
            <table className="mt-10 odd-zebra three-cols content">
              {repair1}
              {repair2}
              {repair3}
              <tr className="new-item">
                <td className="index">{item.order_item_index}</td>
                <td className="label">tube</td>
                <td className="text">{this.capitaliseFirst(item.content.tube_type)}</td>
                <td> </td>
                <td> </td>
                <td className="label">length</td>
                <td className="number">{item.content.tube_length}</td>
                <td className="sign-off">cut</td>
              </tr>
              {brail}
              {cover_front}
              {cover_back}
              {cover_legth}
              {channel}
              {mountingRail}
              {note}
            </table>
          </React.Fragment>
        );
      });
      {
        /* CHECK SUM */
      }
      this.report.addItem(
        this.props.data,
        1,
        <React.Fragment>
          <table className="summary">
            <td className="label">total</td>
            <td className="text">{this.props.data.saw.items.length}</td>
          </table>
        </React.Fragment>
      );
    }

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="colorvue-standard-saw-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default CVSaw;
