import React from 'react';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import '../../scss/CustomTable.scss';
import AlarmsService from '../../services/AlarmsService';
import EditAlarm from './EditAlarm';
import update from 'immutability-helper';
import OrdersService from '../../services/OrdersService';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import {FactoryAlarm} from 'two-core';
import './Alarms.scss';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import {Toast} from 'primereact/toast';
import {CalendarChangeParams} from 'primereact/calendar';
import {InputSwitchChangeParams} from 'primereact/inputswitch';

interface Props {
  toast: React.RefObject<Toast>;
  selectedAlarm: FactoryAlarm | undefined;
  selectedAlarmReferencedData?: string;
  showDialog: boolean;
  onHide: () => void;
}

interface State {
  loadingDialog: boolean;
  currentAlarm: FactoryAlarm | undefined;
  alarmReferencedData: string;
}

class AlarmDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  purchaseOrdersService: PurchaseOrdersService | null = null;
  ordersService: OrdersService | null = null;
  alarmsService: AlarmsService | null = null;
  toastService: ToastService | null = null;
  subscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);

    this.state = {
      loadingDialog: false,
      currentAlarm: undefined,
      alarmReferencedData: '',
    };

    this.saveAlarm = this.saveAlarm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    this.alarmsService = this.context.alarmsService;
    this.ordersService = this.context.ordersService;
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.alarmEdit) {
        this.loadAlarm();
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  loadAlarm() {
    const alarm = this.props.selectedAlarm;
    if (alarm) {
      this.setState({currentAlarm: alarm});
      this.openEditDialog(alarm);
    }
  }

  saveAlarm() {
    const alarm = this.state.currentAlarm;

    if (alarm) {
      this.setState({loadingDialog: true});

      this.alarmsService
        ?.updateAlarm(alarm?.id?.toString() ?? '', alarm)
        .then(() => {
          this.toastService?.showSuccess(this.props.toast, 'Alarm updated successfully.');
          this.setState({loadingDialog: false});
          this.props.onHide();
          MessageService.sendMessage(messages.alarmUpdated);
        })
        .catch(error => {
          this.setState({loadingDialog: false});

          this.toastService?.showError(this.props.toast, 'Sorry, alarm update failed, please try again.');
          console.error('error: ' + error);
        });
    } else {
      this.toastService?.showError(this.props.toast, 'Sorry, alarm update failed, please try again.');
    }
  }

  handleInputChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const newState = update(this.state, {
      currentAlarm: {
        $merge: {
          [e.target.name]: e.target.value,
        },
      },
    });
    this.setState(newState);
  }

  handleCheckBoxChange(e: InputSwitchChangeParams) {
    const user = this.getCurrentUser();
    const value = e.target.value;
    const newState = update(this.state, {
      currentAlarm: {
        $merge: {
          done: value,
          closed_by: value ? user?.id : null,
          closed_at: value ? new Date() : null,
        },
      },
    });
    this.setState(newState);
  }

  handleDateChange(e: CalendarChangeParams) {
    const date = e.target.value as Date;

    const newState = update(this.state, {
      currentAlarm: {
        $merge: {
          silence_until: date,
        },
      },
    });
    this.setState(newState);
  }

  async openEditDialog(alarm: FactoryAlarm) {
    let referenceData = this.props.selectedAlarmReferencedData;
    this.setState({
      loadingDialog: true,
      currentAlarm: alarm,
    });

    if (alarm !== null && !referenceData) {
      await this.ordersService
        ?.getOrder(alarm.reference_id.trim())
        .then(order => {
          if (order !== null) {
            referenceData = `${order.id} ${order.reference}`;
          }

          this.setState({
            loadingDialog: false,
            alarmReferencedData: referenceData ?? '',
          });
        })
        .catch(() => {
          this.setState({
            loadingDialog: false,
            alarmReferencedData: '',
          });
        });
    } else {
      this.setState({
        loadingDialog: false,
        alarmReferencedData: referenceData ?? '',
      });
    }
  }

  getCurrentUser() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = unparsedUser ? JSON.parse(unparsedUser) : undefined;
    const currentUserUsername = currentUser?.username ?? '';
    const currentUserId = currentUser.uuid;

    return {
      username: currentUserUsername,
      id: currentUserId,
    };
  }

  render() {
    const dialogBody = this.state.currentAlarm && (
      <EditAlarm
        alarm={this.state.currentAlarm}
        alarmReferencedData={this.state.alarmReferencedData}
        handleInputChange={this.handleInputChange}
        handleCheckBoxChange={this.handleCheckBoxChange}
        handleDateChange={this.handleDateChange}
      />
    );
    return (
      <TwoDialog
        headerTitle={'Edit Alarm'}
        showDialog={this.props.showDialog}
        width={50}
        onHide={this.props.onHide}
        onSave={this.saveAlarm}
        loading={this.state.loadingDialog}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}

export default AlarmDialog;
