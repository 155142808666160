import React from 'react';
import '../../scss/CustomTable.scss';
import {Column} from 'primereact/column';
import OrdersService from '../../services/OrdersService';
import {TwoDataTable, AppContext} from 'two-app-ui';
import {DataTablePageParams} from 'primereact/datatable';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {Bom, Order, QueryParameter} from 'two-core';
import BomService from '../../services/BomService';
import {DateTime} from 'luxon';
import {formats} from '../../config/formats';
interface RouteProps {
  id: string;
}

interface State {
  loading: boolean;
  boms: Bom[];
  total_boms: number;
  pagination: {
    pageSize: number;
    offset: number;
  };
}

class BomListComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  ordersService: OrdersService | null;
  bomService: BomService | null;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);
    this.state = {
      loading: false,
      boms: [],
      total_boms: 0,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
    };

    this.ordersService = null;
    this.bomService = null;
    this.subtractedBodyTemplate = this.subtractedBodyTemplate.bind(this);
  }

  componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.bomService = this.context.bomService;
    this.loadData();
  }

  loadData() {
    this.setState({loading: true});
    const orderId = this.props.match.params.id;

    const bomFilters: string[] = [];

    bomFilters.push(
      JSON.stringify({
        field: 'order_id',
        value: orderId,
      })
    );

    const bomParams: QueryParameter = {
      filters: bomFilters,
      aggregate: true,
    };

    this.bomService?.getBoms(bomParams).then(boms => {
      this.setState({
        boms: boms ?? [],
        total_boms: boms?.length ?? 0,
        loading: false,
      });
    });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  subtractedBodyTemplate(rowData: Order) {
    const formated_substracted_on = rowData.factory_order?.ecd
      ? DateTime.fromISO(rowData.factory_order?.ecd.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_substracted_on}</span>;
  }

  render() {
    return (
      <div id="boms_page_container" className="page-container">
        <TwoDataTable
          pageSizeIdentifier={'boms_page_container'}
          selectedItems={[]}
          heightToScroll="500px"
          activeFilters={{}}
          loading={this.state.loading}
          value={this.state.boms}
          totalRecords={this.state.total_boms}
          //rowGroupMode="subheader"
          sortField="inventory_item_id"
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
        >
          <Column header="Inventory Item Name" field="inventory_item.name" style={{flex: '1 1'}} />
          <Column header="Colour" field="inventory_item.colour" style={{flex: '1 1'}} />
          <Column header="Sku" field="inventory_item.sku" style={{flex: '1 1'}} />
          <Column header="Quantity" field="quantity" style={{flex: '1 1'}} />
          <Column header="Uom" field="inventory_item.uom" style={{flex: '1 1'}} />
          <Column header="Extra Info" field="extra_info" style={{flex: '1 1'}} />
          <Column header="Subtracted" field="subtracted_on" body={this.subtractedBodyTemplate} style={{flex: '1 1'}} />
        </TwoDataTable>
      </div>
    );
  }
}

export default withRouter(BomListComponent);
