import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {QueryParameter, Oos, ApiListResponse, OosPatch} from 'two-core';

class OosService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.oos as string;
  }

  async getOos(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then(response => {
        return Promise.resolve(response as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createOos(params: Oos): Promise<Oos> {
    return this.post(this.endpoint, params)
      .then(response => {
        return Promise.resolve(response as Oos);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updateOos(id: string, params: OosPatch): Promise<Oos> {
    return this.patch(this.endpoint + '/' + id, params)
      .then(data => {
        return Promise.resolve(data as Oos);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default OosService;
