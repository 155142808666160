import React, {useContext, useEffect} from 'react';
import {TwoDataTable, getTwoDateFormat, AppContext, AppColumnMenuBodyTemplate} from 'two-app-ui';
import {Order, TleContentNote, User} from 'two-core';
import {Column} from 'primereact/column';
import {DateTime} from 'luxon';
import {ProgressSpinner} from 'primereact/progressspinner';
import {SchedulerFilterProps} from './SchedulerFilter';
import {OrderStageComponent} from '../Order/OrderStageComponent';
import {NavLink} from 'react-router-dom';
import {MenuItem} from 'primereact/menuitem';
import {Tooltip} from 'primereact/tooltip';
import {SchedulerMode} from './Scheduler';
interface TableRow {
  id: string;
  group: string;
  order: Order;
}

export interface SchedulerPaginationProps {
  pageSize?: number;
  offset?: number;
  lastLoadedEcdDate?: DateTime;
  lastEcdDate?: DateTime;
}
interface Props {
  loading: boolean;
  lazyLoading: boolean;
  userLoading: boolean;
  orders: Order[];
  selectedOrders: Order[];
  onOrdersLazyLoad: () => void;
  onOrderSelect: (order: Order) => void;
  onOrdersSelectionChange: (orders: Order[]) => void;
  pagination: SchedulerPaginationProps;
  totalOrders: number;
  filter: SchedulerFilterProps;
  initMenuItems: () => MenuItem[];
  onUserCheck: (userId: string) => void;
  usersMap: Map<string, User>;
  mode: SchedulerMode;
  collapsedRows: string[];
  setCollapsedRows: (collapsedRows: string[]) => void;
}
export const SchedulerTable = ({
  loading,
  orders,
  selectedOrders,
  onOrdersLazyLoad,
  onOrderSelect,
  pagination,
  totalOrders,
  lazyLoading,
  filter,
  initMenuItems,
  onOrdersSelectionChange,
  onUserCheck,
  usersMap,
  mode,
  userLoading,
  collapsedRows,
  setCollapsedRows,
}: Props) => {
  const context = useContext(AppContext);
  const patrolRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    //todo this is temporary if it will work fine for tables it should be moved to the two-app-ui in future
    if (loading || lazyLoading) return;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        onOrdersLazyLoad();
      }
    }, options);

    if (patrolRef.current) {
      observer.observe(patrolRef.current);
    }

    return () => {
      if (patrolRef.current) {
        observer.unobserve(patrolRef.current);
      }
    };
  }, [loading, lazyLoading]);

  const getGroupDate = (order: Order) => {
    if (mode === 'scheduler') {
      const ecd = order?.factory_order?.ecd;

      if (!ecd) {
        return 'No ECD';
      }

      const luxonEcd = DateTime.fromISO(`${ecd}`);

      if (luxonEcd.startOf('day') < DateTime.now().startOf('day')) {
        return 'Overdue';
      }

      const formattedEcd = luxonEcd.toFormat(getTwoDateFormat(context.usersService?.settings?.date_format, 'date'));
      const dayName = luxonEcd.toFormat('cccc');

      return `${formattedEcd} (${dayName})`;
    }
    if (mode === 'completed') {
      const completedAt = order?.factory_order?.completed_at;

      if (!completedAt) {
        return 'No Completed Date';
      }

      const luxonCompletedAt = DateTime.fromISO(`${completedAt}`);

      const formattedCompletedAt = luxonCompletedAt.toFormat(
        getTwoDateFormat(context.usersService?.settings?.date_format, 'date')
      );
      const dayName = luxonCompletedAt.toFormat('cccc');

      return `${formattedCompletedAt} (${dayName})`;
    }
    return '';
  };

  const lastNoteBody = (row: TableRow) => {
    const lastNote = row.order.last_note;
    const user = usersMap.get(lastNote?.recorded_by ?? '');
    if (!lastNote) {
      return '';
    }
    const content = lastNote.content as TleContentNote;
    let text = '';
    let recordedAt = '';
    if (lastNote.recorded_at) {
      recordedAt = DateTime.fromISO(`${lastNote.recorded_at}`).toFormat(
        getTwoDateFormat(context.usersService?.settings?.date_format, 'shortDate')
      );
      text = `${recordedAt}: ${content.text}`;
    } else {
      text = content.text;
    }
    const id = `last_note_${row.order.id}_${lastNote?.id}`;
    return (
      <div>
        <span id={id}>{text}</span>
        <Tooltip
          target={`#${id}`}
          position="left"
          showDelay={500}
          mouseTrack
          mouseTrackLeft={5}
          onShow={() => onUserCheck(lastNote?.recorded_by)}
        >
          <div className="p-d-flex p-jc-between p-mb-2">
            <div className="p-mr-2">{recordedAt}</div>
            <div>
              {userLoading ? (
                <ProgressSpinner style={{width: '1em', height: '1em'}} strokeWidth="8" />
              ) : (
                user?.full_name
              )}
            </div>
          </div>
          <div>{content.text}</div>
        </Tooltip>
      </div>
    );
  };

  const ecdBody = (row: TableRow) => {
    const ecd = row.order?.factory_order?.ecd;

    if (!ecd) {
      return '';
    }

    return DateTime.fromISO(`${ecd}`).toFormat(
      getTwoDateFormat(context.usersService?.settings?.date_format, 'shortDate')
    );
  };

  const stageBody = (row: TableRow) => {
    const currentFactoryId = localStorage.getItem('current factory') ?? '';
    return <OrderStageComponent order={row.order} currentFactoryId={currentFactoryId} />;
  };

  const approvedAtBody = (row: TableRow) => {
    const approvedAt = row.order?.approved_at;

    if (!approvedAt) {
      return '';
    }

    return DateTime.fromISO(`${approvedAt}`).toFormat(
      getTwoDateFormat(context.usersService?.settings?.date_format, 'shortDate')
    );
  };

  const customerBody = (row: TableRow) => {
    const owner = row.order.owner_company;
    const id = `customer_${row.order.id}_${owner?.account_number}`.replaceAll(' ', '');
    return (
      <div>
        <span id={id}>{owner?.account_number}</span>
        <Tooltip target={`#${id}`} position="right" showDelay={500} mouseTrack mouseTrackLeft={15}>
          <div className="p-d-flex p-flex-column">
            <span>{owner?.account_number}</span>
            <span>{owner?.name}</span>
            <span>{owner?.trading_as}</span>
          </div>
        </Tooltip>
      </div>
    );
  };

  const rowGroupHeaderTemplate = (row: TableRow, totalSize = 0) => {
    return (
      <div className="p-d-flex p-jc-between w-100">
        <div>{row.group}</div>
        <div>
          <label className="p-mr-1">Total</label>
          {totalSize}
        </div>
      </div>
    );
  };

  const referenceBody = (row: TableRow) => {
    const id = `reference_${row.order.id}`;
    return (
      <div>
        <span id={id}>{row.order.reference}</span>
        <Tooltip target={`#${id}`} position="right" showDelay={500} mouseTrack mouseTrackLeft={15}>
          <span>{row.order.reference}</span>
        </Tooltip>
      </div>
    );
  };

  const summaryBody = (row: TableRow) => {
    const id = `summary_${row.order.id}`;
    return (
      <div>
        <span id={id}>{row.order.factory_order?.summary}</span>
        <Tooltip target={`#${id}`} position="right" showDelay={500} mouseTrack mouseTrackLeft={15}>
          <span>{row.order.factory_order?.summary}</span>
        </Tooltip>
      </div>
    );
  };
  const orderCodeBody = (row: TableRow) => {
    return (
      <AppColumnMenuBodyTemplate
        rowItemIdentifier={row.order?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={mode !== 'completed' ? () => initMenuItems() : undefined}
        selectedItems={selectedOrders}
        handleChangeSelectedItems={async () => onOrderSelect(row.order)}
      >
        <NavLink to={'/order/' + row.order.id}>{row.order.id}</NavLink>
      </AppColumnMenuBodyTemplate>
    );
  };

  const getLazyLoadFooter = (mode: SchedulerMode, pagination: SchedulerPaginationProps, totalOrders?: number) => {
    if (mode === 'completed') {
      if (pagination.offset! <= totalOrders!) {
        return (
          <div ref={patrolRef}>
            {lazyLoading && (
              <>
                <ProgressSpinner style={{width: '1em', height: '1em'}} strokeWidth="8" /> Loading...
              </>
            )}
          </div>
        );
      }
    } else if (mode === 'scheduler') {
      if (pagination.lastEcdDate && pagination.lastEcdDate > pagination.lastLoadedEcdDate!) {
        return (
          <div ref={patrolRef}>
            {lazyLoading && (
              <>
                <ProgressSpinner style={{width: '1em', height: '1em'}} strokeWidth="8" /> Loading...
              </>
            )}
          </div>
        );
      }
    }
    return undefined;
  };
  const getTableRows = (orders: Order[]) => {
    const totalSizeMap = new Map<string, number>();
    const rows: TableRow[] = [];
    for (const order of orders) {
      const group = getGroupDate(order);
      totalSizeMap.set(group, (totalSizeMap.get(group) ?? 0) + Number(order.factory_order!.size));
      rows.push({
        id: order.id!,
        group: group,
        order: order,
      });
    }
    return {rows, totalSizeMap};
  };

  const onRowToggle = (e: {data: TableRow}) => {
    if (collapsedRows.some(row => row === e.data.group)) {
      setCollapsedRows(collapsedRows.filter(row => row !== e.data.group));
      return;
    }
    setCollapsedRows([...collapsedRows, e.data.group]);
  };

  const {rows, totalSizeMap} = getTableRows(orders);
  const expandedRows = rows.filter(row => !collapsedRows.includes(row.group));

  return (
    <div className="orders-scheduler-table">
      <TwoDataTable
        loading={loading}
        value={rows}
        rowGroupMode="subheader"
        groupRowsBy={'group'}
        rowGroupHeaderTemplate={row => rowGroupHeaderTemplate(row, totalSizeMap.get(row.group))}
        activeFilters={{}}
        selectedItems={selectedOrders}
        showPaging={false}
        rows={pagination?.pageSize}
        first={pagination?.offset}
        footer={() => getLazyLoadFooter(mode, pagination, totalOrders)}
        selectionMode={mode === 'scheduler' ? 'multiple' : undefined}
        showSelectAll={false}
        initMenuItems={mode !== 'completed' ? initMenuItems : () => []}
        handleChangeSelectedItems={rows =>
          onOrdersSelectionChange((rows as (TableRow | Order)[]).map(r => (r as TableRow)?.order ?? r) as Order[])
        }
        expandableRowGroups
        expandedRows={expandedRows}
        onRowExpand={onRowToggle}
        onRowCollapse={onRowToggle}
      >
        <Column header="Order Code" body={orderCodeBody} className="col-l" />
        <Column header="Date" body={approvedAtBody} className="col-max-s" />
        <Column header="ECD" body={ecdBody} className="col-max-s" />
        <Column header="Customer" body={customerBody} className="col-max-s" />
        <Column header="Reference" body={referenceBody} className="col-min-xl" />
        <Column header="Type" field="order.type" className="col-max-s" hidden={(filter.types?.length ?? 0) < 2} />
        <Column header="Bay" field="order.factory_order.production_bay" className="col-max-s" />
        <Column header="Prio" field="order.priority" className="col-max-xs" />
        <Column header="Stage" body={stageBody} className="col-max-l" />
        <Column header="Last Note" body={lastNoteBody} className="col-min-m" />
        <Column header="Summary" body={summaryBody} field="factory_order.summary" className="col-min-s col-max-xl" />
        <Column header="Size" field="order.factory_order.size" className="col-max-xs" />
      </TwoDataTable>
    </div>
  );
};
