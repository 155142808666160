import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {OtherMenuOptions, PrintMenuOptions, StageMenuOptions} from '../Order/Constants/constants';
import OrderListComponent from '../Orders/OrderListComponent';

class AllOrdersComponet extends React.Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <OrderListComponent
        showFilterBox={false}
        printMenuOptions={Object.values(PrintMenuOptions)}
        stageMenuOptions={Object.values(StageMenuOptions)}
        otherMenuOptions={Object.values(OtherMenuOptions)}
        history={this.props.history}
      />
    );
  }
}

export default withRouter(AllOrdersComponet);
