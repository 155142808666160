import React from 'react';
import {Button} from 'primereact/button';
import {ProgressSpinner} from 'primereact/progressspinner';
import {FileReference, Order, OrderAggregate, QueryParameter} from 'two-core';
import {PhotosContent} from './PhotosContent';
import './OrderPhotos.scss';
import {AppContext, MessageService, TwoToast} from 'two-app-ui';
import FactoryOrdersService from '../../../services/FactoryOrdersService';
import OrdersService from '../../../services/OrdersService';
import {messages} from '../../../config/messages';

interface TempFile {
  url: string;
  file: File;
}

interface Props {
  orderId: string;
}
interface State {
  loadingOrder: boolean;
  updatingOrder: boolean;
  order?: Order;
  tempFiles?: TempFile[];
  fileUrlsToDelete: string[];
}

export class OrderPhotos extends React.Component<Props, State> {
  static contextType = AppContext;

  ordersService?: OrdersService;
  factoryOrdersService?: FactoryOrdersService;

  twoToast?: TwoToast;
  constructor(props: Props) {
    super(props);
    this.state = {
      loadingOrder: false,
      updatingOrder: false,
      fileUrlsToDelete: [],
    };

    this.onFilesSelect = this.onFilesSelect.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onFileDelete = this.onFileDelete.bind(this);
  }

  componentDidMount() {
    this.twoToast = this.context.twoToast;
    this.ordersService = this.context.ordersService;
    this.factoryOrdersService = this.context.factoryOrdersService;

    this.loadData();
  }

  async loadData() {
    const orderId = this.props.orderId;
    const order = await this.loadOrder(orderId);
    if (!order) {
      return;
    }
    this.setState(() => ({order: order}));
  }

  async loadOrder(orderId: string) {
    this.setState({loadingOrder: true});
    const filters = [
      JSON.stringify({
        field: 'factory_order.id',
        value: orderId,
      }),
    ];
    const aggregate: OrderAggregate[] = ['factory_order'];
    const params: QueryParameter = {
      filters,
      aggregate,
    };
    return this.ordersService
      ?.getOrders(params)
      .then(data => {
        return (data?.records as Order[])[0];
      })
      .catch(e => {
        this.twoToast?.showError('Error loading order');
        return undefined;
      })
      .finally(() => {
        this.setState({loadingOrder: false});
      });
  }

  async uploadPhotos(factoryOrderId: string, tempFiles?: TempFile[]): Promise<Partial<FileReference>[] | undefined> {
    if (tempFiles?.length) {
      const promises = [];
      for (const tempFile of tempFiles) {
        promises.push(this.factoryOrdersService?.uploadPhoto(factoryOrderId, tempFile.file));
      }
      return Promise.all(promises)
        .then(responses => {
          this.twoToast?.showSuccess('Photos uploaded successfully.');
          return responses.map(response => {
            const url = new URL(response!.config.url!);
            // return `${url.origin}${url.pathname}`;
            return {
              index: -1,
              url: `${url.origin}${url.pathname}`,
              type: 'image',
            };
          });
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, photos upload failed, please try again.');
          console.error('error: ' + error);
          return undefined;
        });
    }
    return undefined;
  }

  async deleteFiles(factoryOrderId: string, fileUrlsToDelete: string[]) {
    if (fileUrlsToDelete?.length) {
      const promises = [];
      for (const urlToDelete of fileUrlsToDelete) {
        promises.push(this.factoryOrdersService?.deletePhoto(factoryOrderId, urlToDelete));
      }
      return Promise.all(promises)
        .then(() => {
          this.twoToast?.showSuccess('Photos deleted successfully.');
          return true;
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, photos delete failed, please try again.');
          console.error('error: ' + error);
          return undefined;
        });
    }
    return undefined;
  }

  onFilesSelect(selectedFiles: File[]) {
    this.setState(state => {
      const renamedFiles = selectedFiles.map((file, index) => {
        const fileExtension = file.name.split('.')[1].toLowerCase();
        const tempFileName = `${Date.now()}${index}.${fileExtension}`;
        const newTempFile = new File([file], tempFileName, {type: file.type});
        return {file: newTempFile, url: URL.createObjectURL(file)};
      });
      return {tempFiles: [...(state.tempFiles ?? []), ...renamedFiles]};
    });
  }

  onFileDelete(url: string) {
    if (url.includes('blob')) {
      this.setState(state => {
        const updatedFiles = state.tempFiles!.filter(tempFile => tempFile.url !== url);
        return {
          tempFiles: updatedFiles,
        };
      });
    } else {
      this.setState(state => {
        const updatedUrls = [...state.fileUrlsToDelete, url];
        return {
          fileUrlsToDelete: updatedUrls,
        };
      });
    }
  }

  async onSave() {
    const {order, tempFiles, fileUrlsToDelete} = this.state;
    if (!order?.factory_order || (!tempFiles?.length && !fileUrlsToDelete?.length)) {
      return;
    }
    this.setState({updatingOrder: true});
    let orderFiles: Partial<FileReference>[] = order.factory_order?.files ?? [];
    if (fileUrlsToDelete.length) {
      await this.deleteFiles(order.factory_order!.id!, fileUrlsToDelete);
      orderFiles = orderFiles.filter(file => !fileUrlsToDelete.includes(file.url!));
    }
    const uploadedFileRefs = await this.uploadPhotos(order.factory_order!.id!, tempFiles);
    if (uploadedFileRefs?.length) {
      orderFiles.push(...uploadedFileRefs);
    }

    //re-index files
    let index = 0;
    for (const orderFile of orderFiles) {
      orderFile.index = index++;
    }
    this.factoryOrdersService
      ?.updateFactoryOrder(order.factory_order!.id!, {
        files: orderFiles,
      })
      .then(() => {
        this.twoToast?.showSuccess('Order updated successfully.');
        MessageService.sendMessage(messages.ordersUpdated);
      })
      .catch(() => {
        this.twoToast?.showError('Error saving order.');
        this.setState({updatingOrder: false});
      });
  }

  render() {
    const {loadingOrder, updatingOrder, order, tempFiles, fileUrlsToDelete} = this.state;

    if (loadingOrder) {
      return (
        <div className={'p-d-flex p-jc-center p-ai-center h-100'}>
          <div className="align-self-center">
            <ProgressSpinner />
            <div>Loading order...</div>
          </div>
        </div>
      );
    }

    if (updatingOrder) {
      return (
        <div className={'p-d-flex p-jc-center p-ai-center h-100'}>
          <div className="align-self-center">
            <ProgressSpinner />
            <div>Updating...</div>
          </div>
        </div>
      );
    }

    if (!order) {
      return (
        <div className={'p-d-flex p-jc-center p-ai-center h-100'}>
          <div className="align-self-center">
            <div>Order not found.</div>
          </div>
        </div>
      );
    }

    //todo Allow upload files only for order in stage 'Sent to Floor' or 'In Production'. We should check both production_stage and factory_chain.stage.

    const newFileUrls = tempFiles?.map(tempFile => tempFile.url) ?? [];
    const savedPhotoUrls =
      order.factory_order?.files?.map(file => file.url).filter(url => !fileUrlsToDelete.includes(url)) ?? [];
    const mergedPhotoUrls = [...savedPhotoUrls, ...newFileUrls];
    return (
      <>
        <div className="p-d-flex p-jc-end p-mb-2">
          <Button
            label="Save Photos"
            className="p-button-success"
            onClick={this.onSave}
            disabled={!(tempFiles?.length || fileUrlsToDelete?.length)}
          />
        </div>
        <div className={'p-d-flex p-jc-center p-ai-center h-100'}>
          <div className="align-self-center" style={{minWidth: '80%'}}>
            <PhotosContent
              fileUrls={mergedPhotoUrls}
              onFilesSelect={this.onFilesSelect}
              onFileDelete={this.onFileDelete}
            />
          </div>
        </div>
      </>
    );
  }
}
