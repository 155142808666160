import React from 'react';
import {Scheduler} from '../Scheduler/Scheduler';

export const SchedulerPage = () => {
  return (
    <div id="scheduler_page" className="page-container">
      <Scheduler mode="scheduler" />
    </div>
  );
};
