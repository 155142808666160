import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';
import {
  FactoryFloorTask,
  FloorTaskContentAssBlind,
  FloorTaskContentAssGeneralDepre,
  FloorTaskContentAssHeadbox,
  MapOf,
} from 'two-core';

interface Props {
  data: any;
}

class CVAssembly_v2 extends CutSheetTemplate {
  report: Report;
  contentPrio: MapOf<number>;
  executableTasks = 0;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
    this.contentPrio = {
      repair: 1,
      note: 2,
      general: 3,
      headbox: 4,
      blind: 5,
    };
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      2,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">cv std v3</td>
              <td className="text">
                <b>ASSEMBLY</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text" colSpan={3}>
                {this.props.data.reference}
              </td>
              <td className="label">shipping to</td>
              <td className="text">{this.props.data.ship_to}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      /* ITEMS */
    }
    const sortedItems = this.props.data.assembly.items.sort((firstTask: any, secondTask: any) => {
      const weight1 =
        firstTask.order_item_index * 100 +
        this.contentPrio[firstTask.category] * 10 +
        (firstTask.content?.blindIndex ?? 0);
      const weight2 =
        secondTask.order_item_index * 100 +
        this.contentPrio[secondTask.category] * 10 +
        (secondTask.content.blindIndex ?? 0);
      return weight1 - weight2;
    });

    this.executableTasks = 0;

    const blindsOnItem: Map<number, number> = new Map<number, number>();
    this.props.data.assembly.items.map((task: FactoryFloorTask) => {
      if (task.category === 'blind') {
        this.executableTasks++;
        const currentBlindCount = blindsOnItem.get(task.order_item_index) ?? 0;
        blindsOnItem.set(task.order_item_index, currentBlindCount + 1);
      }
      if (task.category === 'headbox') {
        this.executableTasks++;
      }
    });

    let currentItemIndex = this.props.data.assembly.items[0].order_item_index;
    let itemRows: any[] = [];
    let repair1: any, repair2: any, repair3: any;
    repair1 = repair2 = repair3 = '';
    let isFirstRow = true;
    let currentLineCount = 1;

    sortedItems.map((item: any) => {
      let indexColumn = <td className="no-top-border" />;
      let row;
      let rowClass = 'inside-row';

      if (item.order_item_index !== currentItemIndex) {
        this.report.addItem(
          item,
          currentLineCount,
          <React.Fragment>
            <table className="mt-10 odd-zebra three-cols content">
              {repair1}
              {repair2}
              {repair3}
              {itemRows}
            </table>
          </React.Fragment>
        );
        currentItemIndex = item.order_item_index;
        currentLineCount = 0;
        itemRows = [];
        isFirstRow = true;
      }

      if (isFirstRow) {
        isFirstRow = false;
        indexColumn = <td className="index">{item.order_item_index}</td>;
        rowClass = 'new-item';
      }

      if (item.category === 'repair') {
        repair1 = (
          <tr>
            <td>{item.order_item_index}</td>
            <td className="label">rework</td>
            <td className="text">{item.content.repair_whole_item ? 'YES' : 'NO'}</td>
            <td className="label">type</td>
            <td className="text">{this.capitaliseFirst(item.content.repair_type ?? 'Whole Item Rework')}</td>
            <td className="label">responsibility</td>
            <td className="text" colSpan={2}>
              {this.capitaliseFirst(item.content.repair_responsibility)}
            </td>
          </tr>
        );
        if (item.content.repair_type === 'cutdown (standard)') {
          repair2 = (
            <tr>
              <td />
              <td className="label">new height</td>
              <td className="number">{item.content.new_height}</td>
              <td className="label">new width</td>
              <td className="number" colSpan={3}>
                {item.content.new_width}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += 1;
        }
        repair3 = (
          <tr>
            <td />
            <td className="label">repair</td>
            <td className="text" colSpan={5}>
              {item.content.repair_details}
            </td>
            <td className="sign-off">check</td>
          </tr>
        );
        currentLineCount += 2;
      }

      if (item.category === 'headbox') {
        const data = item.content;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">deco cover</td>
            <td className="text">{this.capitaliseFirst(data.type)}</td>
            <td className="label">colour</td>
            <td className="text">
              {this.capitaliseFirst(data.wrapFabric ? `${data.wrapFabric} ${data.wrapFabricColour}` : data.colour)}
            </td>
            <td className="label">length</td>
            <td className="number">{data.length}</td>
            <td className="sign-off">done</td>
          </tr>
        );
        itemRows.push(row);
        currentLineCount++;
      }

      if (item.category === 'general') {
        const data: FloorTaskContentAssGeneralDepre = item.content;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">product</td>
            <td className="text">{this.capitaliseFirst(data.product)}</td>
            <td className="label">config</td>
            <td className="text">{data.configuration.toUpperCase()}</td>
            <td className="label">dual</td>
            <td className="text">{data.dual ? 'Yes' : 'No'}</td>
            <td className="sign-off">check</td>
          </tr>
        );
        itemRows.push(row);
        currentLineCount++;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">mount</td>
            <td className="text" colSpan={6}>
              {this.capitaliseFirst(data.mount)}
            </td>
            {/*<td className="label">width</td>*/}
            {/*<td className="number">{data.width}</td>*/}
            {/*<td className="label">drop</td>*/}
            {/*<td className="number" colSpan={2}>*/}
            {/*  {data.drop}*/}
            {/*</td>*/}
          </tr>
        );
        itemRows.push(row);
        currentLineCount++;
        // row = (
        //   <tr className={rowClass}>
        //     {indexColumn}
        //     <td className="label">control</td>
        //     <td className="text">{this.capitaliseFirst(data.controlType)}</td>
        //     <td className="label">
        //       {data.controlType === 'chain' ? 'chain' : 'motor'}
        //     </td>
        //     <td className="text">
        //       {this.capitaliseFirst(
        //         data.controlType === 'chain' ? data.chain : data.motor
        //       )}
        //     </td>
        //     <td className="label">compo col</td>
        //     <td className="text" colSpan={2}>
        //       {this.capitaliseFirst(data.componentColour)}
        //     </td>
        //   </tr>
        // );
        // itemRows.push(row);
        // currentLineCount++;
        if (data.isWireGuide) {
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">special</td>
              <td className="text" colSpan={6}>
                This is a WIRE GUIDE.
              </td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount++;
        }
      }

      if (item.category === 'blind') {
        const data = item.content;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="text" colSpan={6}>
              blind ({data.blindIndex}/{blindsOnItem.get(item.order_item_index) ?? 0})
            </td>
            <td className="sign-off">done</td>
          </tr>
        );
        itemRows.push(row);
        currentLineCount++;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">tube</td>
            <td className="text">{this.capitaliseFirst(data.tube)}</td>
            <td className="label">b2b width</td>
            <td className="number">{data.b2bWidth}</td>
            <td className="label">drop</td>
            <td className="text" colSpan={2}>
              {data.drop}
            </td>
          </tr>
        );
        itemRows.push(row);
        currentLineCount++;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">fabric</td>
            <td className="text">{this.capitaliseFirst(data.fabric)}</td>
            <td className="label">colour</td>
            <td className="text">{this.capitaliseFirst(data.fabricColour)}</td>
            <td className="label">direction</td>
            <td className="text" colSpan={2}>
              {this.capitaliseFirst(data.direction)}
            </td>
          </tr>
        );
        itemRows.push(row);
        currentLineCount++;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">base rail</td>
            <td className="text">{this.capitaliseFirst(data.bottomRail)}</td>
            <td className="label">caps</td>
            <td className="number">{data.isWireGuide ? 'Wire Guide' : 'Standard'}</td>
            <td className="label">ctrl side</td>
            <td className="text" colSpan={2}>
              {data.controlSide}
            </td>
          </tr>
        );
        itemRows.push(row);
        currentLineCount++;
        if (data.controlSide) {
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">control</td>
              <td className="text">
                {this.capitaliseFirst(
                  !data.isControl
                    ? 'dependent'
                    : data.chainType
                    ? `chain ${data.chainType} ${data.chainLength}`
                    : 'motor'
                )}
              </td>
              <td className="label">ins L</td>
              <td className="number">
                {this.capitaliseFirst(
                  `${data.insertLeft}${
                    data.controlSide.toLowerCase() === 'l' && data.springAssist ? ' ' + data.springAssist : ''
                  }`
                )}
              </td>
              <td className="label">ins R</td>
              <td className="text" colSpan={2}>
                {this.capitaliseFirst(
                  `${data.insertRight}${
                    data.controlSide.toLowerCase() === 'r' && data.springAssist ? ' ' + data.springAssist : ''
                  }`
                )}
              </td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount++;
        }
      }

      if (item.category === 'note') {
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">note</td>
            <td className="text" colSpan={5}>
              {`${item.content}`.replace(/\r?\n|\r/g, ' ')}
            </td>
            <td className="sign-off">check</td>
          </tr>
        );
        itemRows.push(row);
        currentLineCount += Math.ceil(`${item.content}`.length / 90);
      }
    });

    this.report.addItem(
      this.props.data,
      currentLineCount,
      <React.Fragment>
        <table className="mt-10 odd-zebra three-cols content">
          {repair1}
          {repair2}
          {repair3}
          {itemRows}
        </table>
      </React.Fragment>
    );

    {
      /* CHECK SUM */
    }
    this.report.addItem(
      this.props.data,
      1,
      <React.Fragment>
        <table className="summary">
          <td className="label">total</td>
          <td className="text">{this.executableTasks}</td>
        </table>
      </React.Fragment>
    );

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="colorvue-standard-saw-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default CVAssembly_v2;
