const shippingLabel =
  ' CT~~CD,~CC^~CT~' +
  '^XA' +
  '^CI27' +
  '^XZ' +
  '^XA' +
  '^FT32,58^A0N,28,28^FH^CI28^FDcustomer^FS^CI27' +
  '^FT60,258^A0N,28,28^FH^CI28^FDorder^FS^CI27' +
  '^FT27,154^A0N,28,28^FH^CI28^FDreference^FS^CI27' +
  '^FT35,530^A0N,28,28^FH^CI28^FDcontent^FS^CI27' +
  '^FT29,370^A0N,28,28^FH^CI28^FDaddress^FS^CI27' +
  '^FT120,592^A0N,28,28^FB239,1,7,C^FH^CI28^FDpackage dimensions^FS^CI27' +
  '^FT49,672^A0N,28,28^FH^CI28^FDsize^FS^CI27' +
  '^FT15,752^A0N,28,28^FH^CI28^FDweight^FS^CI27' +
  '^FT226,752^A0N,28,28^FB27,1,7,C^FH^CI28^FDkg^FS^CI27' +
  '^FT216,672^A0N,28,28^FB16,1,7,C^FH^CI28^FDX^FS^CI27' +
  '^FT342,670^A0N,34,33^FB19,1,9,C^FH^CI28^FDX^FS^CI27' +
  '^FT647,258^A0N,28,28^FH^CI28^FDof^FS^CI27' +
  '^FO16,557^GB464,0,4^FS' +
  '^FO478,559^GB0,224,4^FS' +
  '^FO16,190^GB767,0,4^FS' +
  '^FO16,302^GB767,0,4^FS' +
  '^FO502,200^GB0,96,4^FS' +
  '^FO16,478^GB767,0,4^FS' +
  '^FO539,579^GFA,269,5600,28,:Z64:eJzdlFEOBCEIQ7n/pbuZoS06ewKqidE+fpAC8K56FnpbQQI71DfSSgqb98NOJYnBJc1k5SON0bsKtBLCWNJj/82lzcyrfXtrAYyZ+/AXpDCFcK7W0ZYJrI6E7WS2agTj1MFc+CcprFhTuD0xPbqeMQKKwd23+1kLjJN5WecYVrJuHXoKa7sq7U5dpl7PtK6cb7SafbNla2r27GdURr//YT/rzDGDRyiLzQzqmDg2lS7olcDGtfYtKofZrapo+ZbAfuK3Vjc=:B706' +
  '^FT152,73^A0N,68,68^FH^CI28^FD{customer}^FS^CI27' +
  '^FT136,262^A0N,39,41^FH^CI28^FD{order_code}^FS^CI27' +
  '^FT152,165^A0N,56,56^FH^CI28^FD{reference}^FS^CI27' +
  '^FT136,534^A0N,39,41^FH^CI28^FD{content}^FS^CI27' +
  '^FT136,377^A0N,45,46^FH^CI28^FD{address_1}^FS^CI27' +
  '^FT526,275^A0N,73,74^FB105,1,19,R^FH^CI28^FD{index}^FS^CI27' +
  '^FT679,277^A0N,79,79^FB114,1,20,C^FH^CI28^FD{count}^FS^CI27' +
  '^FT115,669^A0N,39,48^FB92,1,10,C^FH^CI28^FD{width}^FS^CI27' +
  '^FT238,669^A0N,39,51^FB96,1,10,C^FH^CI28^FD{height}^FS^CI27' +
  '^FT366,669^A0N,39,51^FB96,1,10,C^FH^CI28^FD{depth}^FS^CI27' +
  '^FT105,749^A0N,39,41^FB109,1,10,C^FH^CI28^FD{weight}^FS^CI27' +
  '^FT136,441^A0N,45,46^FH^CI28^FD{address_2}^FS^CI27' +
  '^FT708,441^A0N,45,46^FB75,1,12,R^FH^CI28^FD{state}^FS^CI27' +
  '^PQ1,,,Y' +
  '^XZ';

export default shippingLabel;
