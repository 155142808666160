import React from 'react';
import {Dropdown, DropdownChangeParams, DropdownProps} from 'primereact/dropdown';
import {Column, ColumnProps} from 'primereact/column';
import {InputSwitch} from 'primereact/inputswitch';
import {Calendar} from 'primereact/calendar';
import {DataTable, DataTableSelectionChangeParams} from 'primereact/datatable';
import {Divider} from 'primereact/divider';
import './Oos.scss';
import OosService from '../../services/OosService';
import {AppContext, ToastService, MessageService, TwoDialog} from 'two-app-ui';
import {oosStages} from './Constants/constants';
import {DateTime} from 'luxon';
import InventoryService from '../../services/InventoryService';
import OrdersService from '../../services/OrdersService';
import {formats} from '../../config/formats';
import BomService from '../../services/BomService';
import {Oos, Bom, Order, InventoryItem, QueryParameter, ProductionStageM2cAuShades} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  isNew?: boolean;
  selectedOrders?: Order[];
  isEdit?: boolean;
  oos?: Oos | undefined;
}

interface State {
  oos: Oos;
  oosRecords: Oos[];
  selectedInventoryItem: InventoryItem | undefined;
  inventoryItems: InventoryItem[];
  loadedBoms: Bom[]; //orders loaded after dialog was opened
  selectedBoms: Bom[]; //selected orders
  assignOrdersToggleInputValue: boolean;
  isNew: boolean;
  showNewOosDialog: boolean;
  isEdit: boolean;
  loading: boolean;
}

class OosDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  oosService: OosService | null;
  inventoryService: InventoryService | null;
  ordersService: OrdersService | null;
  bomService: BomService | null;
  toastService: ToastService | null = null;

  loadingCount: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      oos: {
        factory_id: '',
        stage: 'Eta Not Confirmed',
        inventory_item_id: '',
        updated_at: new Date(),
      },
      selectedInventoryItem: undefined,
      inventoryItems: [],
      oosRecords: [],
      assignOrdersToggleInputValue: false,
      loadedBoms: [],
      selectedBoms: [],
      isNew: props.isNew ?? false,
      isEdit: props.isEdit ?? false,
      showNewOosDialog: false,
      loading: false,
    };
    this.oosService = null;
    this.inventoryService = null;
    this.ordersService = null;
    this.bomService = null;
    this.loadData = this.loadData.bind(this);
    this.loadInventoryItemsData = this.loadInventoryItemsData.bind(this);
    this.loadBomsData = this.loadBomsData.bind(this);
    this.createOos = this.createOos.bind(this);
    this.onClickSaveAction = this.onClickSaveAction.bind(this);
    this.oosOptionTemplate = this.oosOptionTemplate.bind(this);
    this.oosSelectedOptionTemplate = this.oosSelectedOptionTemplate.bind(this);
    this.inventoryItemOptionTemplate = this.inventoryItemOptionTemplate.bind(this);
    this.inventoryItemSelectedOptionTemplate = this.inventoryItemSelectedOptionTemplate.bind(this);
    this.oosOptionChange = this.oosOptionChange.bind(this);
    this.formatDateColumn = this.formatDateColumn.bind(this);
    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
    this.priorityBodyTemplate = this.priorityBodyTemplate.bind(this);
    this.onHide = this.onHide.bind(this);

    this.loadingCount = 0;
  }
  componentDidMount() {
    this.inventoryService = this.context.inventoryService;
    this.oosService = this.context.oosService;
    this.ordersService = this.context.ordersService;
    this.bomService = this.context.bomService;
    this.toastService = this.context.toastService;
  }

  onHide() {
    this.setState({
      oos: {
        factory_id: '',
        stage: 'Eta Not Confirmed',
        inventory_item_id: '',
        updated_at: new Date(),
      },
      selectedInventoryItem: undefined,
      inventoryItems: [],
      oosRecords: [],
      assignOrdersToggleInputValue: false,
      loadedBoms: [],
      selectedBoms: [],
      showNewOosDialog: false,
      loading: false,
    });
    this.props.onHide();
  }

  loadInventoryItemsData() {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'factory_id',
        value: localStorage.getItem('current factory'),
      })
    );

    const params: QueryParameter = {
      filters: filters,
    };

    const loadingCount = ++this.loadingCount;
    this.inventoryService?.getInventoryItems(params).then(data => {
      this.setState({
        inventoryItems: data.records as InventoryItem[],
      });
      loadingCount === this.loadingCount && this.setState({loading: false});
    });
  }

  async loadBomsData(inventoryItemId: string) {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'inventory_item_id',
        value: inventoryItemId,
      })
    );
    const stageFilterValues: ProductionStageM2cAuShades[] = ['New', 'Waiting 4 Material'];
    filters.push(
      JSON.stringify({
        field: 'factory_order.production_stage',
        value: stageFilterValues,
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.setState({loading: true});
    const loadingCount = ++this.loadingCount;
    this.bomService?.getBoms(params).then(data => {
      const boms: Bom[] = data ?? [];
      const selectedOrdersIds: string[] = this.props.selectedOrders?.map((order: Order) => order.id ?? '') ?? [];
      this.setState({
        loadedBoms: boms,
        selectedBoms:
          this.state.oos?.assigned_orders_ids && this.state.oos?.assigned_orders_ids.length
            ? boms.filter((bom: Bom) => {
                return (
                  this.state.oos.assigned_orders_ids?.includes(bom.order?.id as string) ||
                  selectedOrdersIds?.includes(bom.order?.id as string)
                );
              })
            : [],
      });
      loadingCount === this.loadingCount && this.setState({loading: false});
    });
  }

  loadOosData(params: QueryParameter) {
    const loadingCount = ++this.loadingCount;

    this.oosService?.getOos(params).then(data => {
      this.setState({
        oosRecords: (data.records as Oos[]) ?? [],
      });
      loadingCount === this.loadingCount && this.setState({loading: false});
    });
  }

  async loadData() {
    this.setState({loading: true});
    if (this.state.isNew) {
      if (this.props.oos) {
        //set data for prepared new oos
        this.setState({
          oos: this.props.oos,
          inventoryItems: [this.props.oos.inventory_item as InventoryItem],
          selectedInventoryItem: this.props.oos.inventory_item,
          loading: false,
        });
        this.loadBomsData(this.props.oos.inventory_item_id);
      } else {
        // load data for new oos
        this.loadInventoryItemsData();
      }
    } else if (this.state.isEdit) {
      //load data for edit mode
      if (this.props.oos) {
        this.setState({
          oosRecords: [this.props.oos],
          oos: this.props.oos,
          assignOrdersToggleInputValue:
            (this.props.oos?.assigned_orders_ids && this.props.oos?.assigned_orders_ids.length > 0) ?? false,
        });
        this.loadBomsData(this.props.oos.inventory_item_id);
      }
    } else {
      //load data for assign mode
      //get boms with orders ids
      const ordersIds: string[] =
        this.props.selectedOrders?.map((order: Order) => {
          return order.id ?? '';
        }) ?? [];
      const bomsParams: QueryParameter = {
        filters: [
          JSON.stringify({
            field: 'order_id',
            value: ordersIds,
            condition: 'in',
          }),
        ],
      };
      this.bomService?.getBoms(bomsParams).then(boms => {
        if (boms.length > 0) {
          //all selected orders must contain same inventory id
          let inventoryItemsIds: string[] = [];
          for (let i = 0; i < ordersIds.length; i++) {
            const orderInventoryItemsIds: string[] = boms
              .filter((bom: Bom) => ordersIds[i] === bom.order_id)
              .map((filteredBom: Bom) => filteredBom.inventory_item_id);
            if (i === 0) {
              //get all inventory items ids of first order
              inventoryItemsIds = orderInventoryItemsIds;
            } else {
              if (inventoryItemsIds.length > 0) {
                //remove inventory id if it does not exist in other orders
                inventoryItemsIds = inventoryItemsIds.filter((id: string) => orderInventoryItemsIds.includes(id));
              } else {
                //break for because inventoryItemsIds is empty
                break;
              }
            }
          }

          const filters: string[] = [];
          filters.push(
            JSON.stringify({
              field: 'factory_id',
              value: localStorage.getItem('current factory') ?? '',
            })
          );
          filters.push(
            JSON.stringify({
              field: 'inventory_item_id',
              value: inventoryItemsIds,
              condition: 'in',
            })
          );

          const params: QueryParameter = {
            filters,
            aggregate: true,
          };
          this.loadOosData(params);
        }
      });
    }
  }

  createOos() {
    const oos: Oos = {
      factory_id: localStorage.getItem('current factory') ?? '',
      stage: this.state.oos.stage,
      inventory_item_id: this.state.oos.inventory_item_id ?? '',
      eta: this.state.oos.eta ?? undefined,
      assigned_orders_ids: this.state.selectedBoms.map((bom: Bom) => bom.order?.id as string),
      updated_at: new Date(),
    };
    const loadingCount = ++this.loadingCount;
    this.oosService
      ?.createOos(oos)
      .then(() => {
        MessageService.sendMessage(messages.oosUpdated);
        this.toastService?.showSuccess(this.props.toast, 'Oos record created');
        this.onHide();
      })
      .catch(error => {
        loadingCount === this.loadingCount && this.setState({loading: false});
        console.error('error: ' + error);
        this.toastService?.showError(this.props.toast, 'Oos record was not created');
      });
  }

  updateOos() {
    const oos: Oos = {
      id: this.state.oos.id,
      factory_id: localStorage.getItem('current factory') ?? '',
      stage: this.state.oos.stage,
      inventory_item_id: this.state.oos.inventory_item_id ?? '',
      eta: this.state.oos.eta ?? undefined,
      assigned_orders_ids: this.state.selectedBoms.map((bom: Bom) => bom.order?.id as string),
      updated_at: new Date(),
    };
    const loadingCount = ++this.loadingCount;
    this.oosService
      ?.updateOos(oos.id?.toString() ?? '', oos)
      .then(() => {
        MessageService.sendMessage(messages.oosUpdated);

        this.toastService?.showSuccess(this.props.toast, 'Oos record updated');
        this.onHide();
      })
      .catch(error => {
        loadingCount === this.loadingCount && this.setState({loading: false});
        console.error('error: ' + error);
        this.toastService?.showError(this.props.toast, 'Oos record was not updated');
      });
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  onClickSaveAction() {
    this.setState({loading: true});
    if (this.state.isNew) {
      //create new oos
      this.createOos();
    } else {
      //update new oos
      this.updateOos();
    }
  }

  oosOptionTemplate(option: Oos) {
    return (
      <div>
        {option.inventory_item?.name ?? ''} : {option.inventory_item?.sku ?? ''} : {option.inventory_item?.colour ?? ''}
      </div>
    );
  }

  oosSelectedOptionTemplate(option: Oos, props: DropdownProps) {
    if (option) {
      return (
        <div>
          {option.inventory_item?.name ?? ''} : {option.inventory_item?.sku ?? ''} :{' '}
          {option.inventory_item?.colour ?? ''}
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  }

  inventoryItemOptionTemplate(option: InventoryItem) {
    return (
      <div>
        {option?.name ?? ''}: {option?.sku ?? ''}: {option?.colour ?? ''}
      </div>
    );
  }

  inventoryItemSelectedOptionTemplate(option: InventoryItem, props: DropdownProps) {
    if (option) {
      return (
        <div>
          {option?.name ?? ''}: {option?.sku ?? ''}: {option?.colour ?? ''}
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  }

  oosOptionChange(e: DropdownChangeParams) {
    const oos: Oos = e.value;
    this.setState({
      oos: oos,
      assignOrdersToggleInputValue: true,
    });
    this.loadBomsData(oos.inventory_item_id);
  }

  formatDateColumn(data: Bom, props: ColumnProps) {
    switch (props.field) {
      case 'ecd':
        return data.factory_order?.ecd
          ? DateTime.fromISO(data.factory_order?.ecd as unknown as string).toFormat(formats.date)
          : '';
      default:
        return '';
    }
  }

  stageBodyTemplate(rowData: Bom) {
    return (
      <span
        className={`stage-badge stage-${(rowData.factory_order?.production_stage ?? '')
          .toLowerCase()
          .replace(' ', '-')}`}
      >
        {rowData?.factory_order?.production_stage}
      </span>
    );
  }

  priorityBodyTemplate(rowData: Bom) {
    let priorityText = '';
    switch (rowData.order?.priority) {
      case 1:
        priorityText = '!';
        break;
      case 2:
        priorityText = '!!';
        break;
      case 3:
        priorityText = '!!!';
        break;
    }
    return <span>{priorityText}</span>;
  }

  render() {
    const dialogBody = (
      <>
        {/*whats missing or OOS item*/}
        {this.state.isNew ? (
          <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
            <label htmlFor="name" className="p-col-2">
              What&apos;s missing
            </label>
            <div className="p-col-10 p-p-0">
              <span className="p-fluid">
                <Dropdown
                  value={this.state.selectedInventoryItem}
                  options={this.state.inventoryItems}
                  onChange={(e: DropdownChangeParams) => {
                    this.setState({
                      oos: {
                        ...this.state.oos,
                        inventory_item_id: e.value.id,
                      },
                      selectedInventoryItem: e.value,
                    });
                    this.loadBomsData(e.value.id);
                  }}
                  optionLabel="title"
                  filter
                  filterBy="name,sku,colour"
                  placeholder="Select inventory item"
                  itemTemplate={this.inventoryItemOptionTemplate}
                  valueTemplate={this.inventoryItemSelectedOptionTemplate}
                  disabled={this.props.oos?.inventory_item !== undefined}
                  virtualScrollerOptions={{
                    lazy: false,
                    itemSize: 15,
                    showLoader: true,
                    delay: 0,
                  }}
                />
              </span>
            </div>
          </div>
        ) : (
          <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
            <label htmlFor="name" className="p-col-2">
              OOS item
            </label>
            <div className="p-col-10 p-p-0">
              <span className="p-fluid">
                <Dropdown
                  value={this.state.oos}
                  options={this.props.isEdit ? [this.state.oos] : this.state.oosRecords}
                  onChange={this.oosOptionChange}
                  itemTemplate={this.oosOptionTemplate}
                  valueTemplate={this.oosSelectedOptionTemplate}
                  optionLabel="title"
                  placeholder="Select oos item"
                  disabled={this.state.isEdit}
                />
              </span>
            </div>
          </div>
        )}
        {/*stage and eta*/}
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="name" className="p-col-2">
            Stage
          </label>
          <div className="p-col-4 p-p-0">
            <span className="p-fluid">
              <Dropdown
                value={this.state.oos.stage}
                options={oosStages}
                onChange={e => {
                  this.setState({
                    oos: {...this.state.oos, stage: e.target.value},
                  });
                }}
                disabled={!this.state.isNew && !this.state.isEdit}
              />
            </span>
          </div>
          <label htmlFor="name" className="p-col-2">
            Eta
          </label>
          <div className="p-col-4 p-p-0">
            <span className="p-fluid">
              <Calendar
                value={this.state.oos.eta}
                dateFormat={formats.calendarInputDate}
                onChange={e => {
                  const eta = e.value as Date;
                  this.setState({
                    oos: {
                      ...this.state.oos,
                      eta: eta,
                    },
                  });
                }}
                disabled={!this.state.isNew && !this.state.isEdit}
              />
            </span>
          </div>
        </div>
        <Divider />
        {/*assign orders options*/}
        {this.state.isNew || this.state.isEdit ? (
          <div className="p-d-flex p-col-12 p-p-0">
            <div className="p-col-1">
              <InputSwitch
                checked={this.state.assignOrdersToggleInputValue}
                onChange={e => {
                  this.setState({
                    assignOrdersToggleInputValue: e.target.value,
                  });
                }}
              />
            </div>
            <div className="p-col-11">
              <label>Assign orders</label>
            </div>
          </div>
        ) : (
          <div className="p-col-12">
            {this.state.oos.id ? (
              <label>Assigned orders</label>
            ) : (
              <label>Select an oos item to see assigned orders</label>
            )}
          </div>
        )}
        {/*orders table*/}
        {this.state.assignOrdersToggleInputValue && (
          <DataTable
            className="oos-orders-table"
            value={this.state.loadedBoms}
            selection={this.state.selectedBoms}
            onSelectionChange={(e: DataTableSelectionChangeParams) => {
              if (this.state.isNew || this.state.isEdit) {
                //allow selection only if oos is new
                this.setState({selectedBoms: e.value});
              }
            }}
            selectionMode="multiple"
          >
            <Column selectionMode="multiple" headerStyle={{width: '3rem'}} />
            <Column header="Code" field="order_id" style={{width: '180px'}} />
            <Column header="Customer" field="owner_company.account_number" style={{width: '180px'}} />
            <Column header="Stage" field="stage" body={this.stageBodyTemplate} />
            <Column header="Prio" field="order.priority" body={this.priorityBodyTemplate} style={{width: '180px'}} />
            <Column header="ECD" field="ecd" body={this.formatDateColumn} style={{width: '180px'}} />
            <Column header="Qty" field="quantity" style={{width: '180px'}} />
          </DataTable>
        )}
      </>
    );
    return (
      <TwoDialog
        headerTitle={this.state.isNew ? 'Create OOS' : this.state.isEdit ? 'Edit OOS' : 'Assign OOS'}
        showDialog={this.props.showDialog}
        width={70}
        onHide={this.onHide}
        onShow={this.loadData}
        onSave={this.onClickSaveAction}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default OosDialog;
