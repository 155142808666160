import {CutSheetReport, FloorTaskContentFabric, FloorTaskContentSaw, MapOf, PrePowderItem, SawExtra} from 'two-core';
import {ProductionLabelMaker} from '../ProductionLabelMaker';
import {ProductionLabelData} from '../ProductionLabelData';

export function mapToColourvueLabel(cvCutSheet: CutSheetReport): ProductionLabelData[] {
  const result: ProductionLabelData[] = [];
  const labelMaker = new ProductionLabelMaker(
    'CV ROLLER BLIND',
    '???',
    cvCutSheet.production_bay?.toString() ?? '?',
    cvCutSheet.order_id,
    cvCutSheet.reference,
    '???',
    '???'
  );
  const contentPrio: MapOf<number> = {
    repair: 1,
    note: 2,
    tube: 3,
    rail: 4,
    deco_cover: 5,
    channel: 6,
    head_mount: 7,
  };

  let lastItemIndex = 0;
  let lastBlindIndex = 0;
  let blindIndex = 0;

  // ----- SAW
  let orderedItems = cvCutSheet.saw.items.sort(
    (a, b) =>
      a.order_item_index * 100 +
      (contentPrio[(a.content as any).category ?? ''] ?? 0) * 10 +
      (a.content as any).blind_index -
      (b.order_item_index * 100 +
        (contentPrio[(b.content as any).category ?? ''] ?? 0) * 10 +
        (b.content as any).blind_index)
  ) as any[];
  labelMaker.station = 'SAW';
  for (const item of orderedItems) {
    if (lastItemIndex !== item.order_item_index) {
      lastItemIndex = item.order_item_index;
      lastBlindIndex = 0;
    }
    labelMaker.product = item.product;
    if (item.category !== 'note' && item.category !== 'repair') {
      const sawContent = item.content as FloorTaskContentSaw;
      if (lastBlindIndex !== sawContent.blind_index) {
        lastBlindIndex = sawContent.blind_index;
        blindIndex++;
        labelMaker.index = `BLIND #${blindIndex}`;
      }
      result.push(
        labelMaker.makeLabelTwoLines(
          sawContent.extrusion,
          sawContent.colour,
          '',
          `${sawContent.length}`,
          item.id ?? 0,
          sawContent.type
        )
      );
    }
  }

  // ----- FABRIC
  lastItemIndex = 0;
  lastBlindIndex = 0;
  blindIndex = 0;
  orderedItems = cvCutSheet.fabric.items.sort(
    (a, b) =>
      a.order_item_index * 100 +
      (contentPrio[(a.content as any).category ?? ''] ?? 0) * 10 +
      (a.content as any).blind_index -
      (b.order_item_index * 100 +
        (contentPrio[(b.content as any).category ?? ''] ?? 0) * 10 +
        (b.content as any).blind_index)
  ) as any[];
  labelMaker.station = 'FABRIC';
  for (const item of orderedItems) {
    if (lastItemIndex !== item.order_item_index) {
      lastItemIndex = item.order_item_index;
      lastBlindIndex = 0;
    }
    labelMaker.product = item.product;
    if (item.category !== 'note' && item.category !== 'repair') {
      const fabricContent = item.content as FloorTaskContentFabric;
      if (lastBlindIndex !== fabricContent.blind_index) {
        lastBlindIndex = fabricContent.blind_index;
        blindIndex++;
        labelMaker.index = `BLIND #${blindIndex}`;
      }
      result.push(
        labelMaker.makeLabelTwoLines(
          fabricContent.fabric,
          fabricContent.colour,
          `WIDTH: ${fabricContent.width}`,
          `DROP: ${fabricContent.drop}`,
          item.id ?? 0,
          fabricContent.type
        )
      );
    }
  }

  result.push(labelMaker.makePhotoLabel());

  return result;
}
