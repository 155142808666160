import React from 'react';
import {InputText} from 'primereact/inputtext';
import {StocKeyModel} from './Models/StockModel';

interface InventoryItemStockProps {
  updateItems: StocKeyModel;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InventoryItemStock = ({updateItems, handleInputChange}: InventoryItemStockProps) => {
  return (
    <div className={'inventory-item-detail'}>
      {Object.keys(updateItems).map(key => {
        return (
          <div className="p-field p-grid p-mb-1" key={key}>
            <label className="p-col-3 p-mb-2 p-md-3 p-mb-md-2" htmlFor="name">
              {updateItems[key].item?.name}
            </label>
            <div className="p-col-6 p-md-6">
              <InputText
                name={key}
                type="number"
                value={updateItems[key].quantity}
                onChange={e => handleInputChange(e)}
              />
            </div>
            <label className="p-col-3 p-mb-2 p-md-3 p-mb-md-2 p-justify-start">{updateItems[key].item?.uom}</label>
          </div>
        );
      })}
    </div>
  );
};

export default InventoryItemStock;
