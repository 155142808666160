import React from 'react';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {InventoryItem, TleContentUpdate, TimeLineEvent} from 'two-core';
import {toInputUppercase} from '../Inventory/Constants/Utils';
import {
  inventoryItemCategories,
  inventoryItemProductLines,
  inventoryItemTypes,
  inventoryItemUoms,
} from '../Inventory/Constants/constants';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import InventoryService from '../../services/InventoryService';
import TleService from '../../services/TleService';
import {History} from 'history';

interface Props {
  inventoryItem: InventoryItem;
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  history: History;
}

interface State {
  inventoryItem: InventoryItem;
  loading: boolean;
}

class InventoryItemEdit extends React.Component<Props, State> {
  static contextType = AppContext;
  inventoryService: InventoryService | null = null;
  tleService: TleService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      inventoryItem: {
        category: '',
        factory_id: '',
        name: '',
        type: '',
        uom: '',
        updated_at: new Date(),
      },
    };

    this.closeInventoryItem = this.closeInventoryItem.bind(this);
    this.setInventoryItem = this.setInventoryItem.bind(this);
    this.saveInventoryItem = this.saveInventoryItem.bind(this);
  }

  componentDidMount() {
    this.inventoryService = this.context.inventoryService;
    this.tleService = this.context.tleService;
    this.toastService = this.context.toastService;
  }

  setInventoryItem() {
    const item = this.props.inventoryItem;
    this.setState({inventoryItem: item});
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) {
    const inventoryItem = this.state.inventoryItem;
    const newState = {...inventoryItem, [e.target.name]: e.target.value};
    this.setState({inventoryItem: newState});
  }

  emptyInventoryItem() {
    const inventoryItem: InventoryItem = {
      factory_id: localStorage.getItem('current factory') ?? '',
      category: '',
      name: '',
      type: '',
      uom: '',
      updated_at: new Date(),
    };
    return inventoryItem;
  }

  closeInventoryItem() {
    this.props.onHide();
    this.setState({
      loading: false,
      inventoryItem: this.emptyInventoryItem(),
    });
  }

  saveInventoryItem() {
    this.setState({loading: true});
    const inventoryItem = this.state.inventoryItem;

    if (inventoryItem.id) {
      this.inventoryService
        ?.updateInventoryItem(inventoryItem.id, inventoryItem)
        .then(() => {
          const content: TleContentUpdate = {
            reason: 'inventory item update',
            updates: [
              {field: 'name', value: inventoryItem.name.toString()},
              {
                field: 'colour',
                value: inventoryItem.colour ?? '',
              },
              {
                field: 'sku',
                value: inventoryItem.sku ?? '',
              },
              {
                field: 'category',
                value: inventoryItem.category ?? '',
              },
              {
                field: 'type',
                value: inventoryItem.type ?? '',
              },
              {
                field: 'uom',
                value: inventoryItem.uom ?? '',
              },
              {
                field: 'current stock level',
                value: inventoryItem.current_stock_level?.toString() ?? '',
              },
              {
                field: 'reorder limit',
                value: inventoryItem.reorder_limit?.toString() ?? '',
              },
              {
                field: 'product lines',
                value: (inventoryItem.product_lines ?? []).join(', '),
              },
            ],
          };

          const tle: TimeLineEvent = {
            event_type: 'update',
            entity_type: 'inventory_item',
            recorded_by: this.getCurrentUserId(),
            entity_id: inventoryItem.id?.toString() ?? '',
            content: content,
            recorded_at: new Date(),
          };
          this.createTle(tle);
          this.toastService?.showSuccess(this.props.toast, 'Inventory item updated successfully.');
          this.closeInventoryItem();
        })
        .catch(() => {
          this.toastService?.showError(this.props.toast, 'Sorry, Inventory item update failed, please try again.');
        });
    } else {
      this.inventoryService
        ?.createInventoryItem(inventoryItem)
        .then(data => {
          const newItem: InventoryItem = data;
          this.setState({
            inventoryItem: newItem,
          });
          this.toastService?.showSuccess(this.props.toast, 'Inventory item created successfully.');
          this.props.history.push(`/inventory-item/${newItem.id}`);
        })
        .catch(() => {
          this.toastService?.showError(this.props.toast, 'Sorry, Inventory item create failed, please try again.');
        });
    }
  }

  createTle(tle: TimeLineEvent) {
    return this.tleService
      ?.createTle(tle)
      .then(() => {
        MessageService.sendMessage(messages.inventoryItemUpdated);
      })
      .catch(error => {
        console.log(error);
      });
  }

  getCurrentUserId(): string {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  render() {
    const {inventoryItem} = this.state;

    const headerTitle = this.state.inventoryItem?.id ? 'Edit Inventory Item' : 'Add Inventory Item';

    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="name" className="p-col-1">
            name
          </label>
          <div className="p-col-3 p-p-0">
            <InputText
              className="w-100"
              id="name"
              name="name"
              value={inventoryItem.name}
              onChange={e => this.handleInputChange(e)}
              onInput={toInputUppercase}
            />
          </div>
          <label htmlFor="colour" className="p-col-1">
            colour
          </label>
          <div className="p-col-3 p-p-0">
            <InputText
              className="w-100"
              id="colour"
              name="colour"
              value={inventoryItem.colour}
              onChange={e => this.handleInputChange(e)}
              onInput={toInputUppercase}
            />
          </div>
          <label htmlFor="sku" className="p-col-1">
            sku
          </label>
          <div className="p-col-3 p-p-0">
            <InputText
              className="w-100"
              id="sku"
              name="sku"
              value={inventoryItem.sku}
              onChange={e => this.handleInputChange(e)}
              onInput={toInputUppercase}
            />
          </div>
        </div>

        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="productLine" className="p-col-1">
            product line
          </label>
          <div className="p-col-3 p-p-0">
            <MultiSelect
              className="w-100"
              optionLabel="label"
              optionValue="value"
              value={inventoryItem.product_lines}
              options={inventoryItemProductLines}
              name="product_lines"
              id="product_lines"
              onChange={e => this.handleInputChange(e)}
            />
          </div>
          <label htmlFor="category" className="p-col-1">
            category
          </label>
          <div className="p-col-3 p-p-0">
            <Dropdown
              className="w-100"
              optionLabel="label"
              optionValue="value"
              value={inventoryItem.category}
              options={inventoryItemCategories}
              name="category"
              id="category"
              onChange={e => this.handleInputChange(e)}
            />
          </div>
          <label htmlFor="type" className="p-col-1">
            type
          </label>
          <div className="p-col-3 p-p-0">
            <Dropdown
              className="w-100"
              optionLabel="label"
              optionValue="value"
              value={inventoryItem.type}
              options={inventoryItemTypes}
              name="type"
              id="type"
              onChange={e => this.handleInputChange(e)}
            />
          </div>
        </div>

        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0 p-pb-0">
          <label htmlFor="uom" className="p-col-1">
            uom
          </label>
          <div className="p-col-3 p-p-0">
            <Dropdown
              className="w-100"
              optionLabel="label"
              optionValue="value"
              value={inventoryItem.uom}
              options={inventoryItemUoms}
              name="uom"
              id="uom"
              onChange={e => this.handleInputChange(e)}
            />
          </div>
          <label htmlFor="current_stock_level" className="p-col-1">
            current stock
          </label>
          <div className="p-col-3 p-p-0">
            <InputText
              className="w-100"
              id="current_stock_level"
              name="current_stock_level"
              type="number"
              value={inventoryItem.current_stock_level}
              onChange={e => this.handleInputChange(e)}
            />
          </div>
          <label htmlFor="reorder_limit" className="p-col-1">
            re-order limit
          </label>
          <div className="p-col-3 p-p-0">
            <InputText
              className="w-100"
              id="reorder_limit"
              name="reorder_limit"
              type="number"
              value={inventoryItem.reorder_limit}
              onChange={e => this.handleInputChange(e)}
            />
          </div>
        </div>
      </>
    );
    return (
      <TwoDialog
        className="inventory-item-dialog"
        headerTitle={headerTitle}
        showDialog={this.props.showDialog}
        width={70}
        onHide={this.closeInventoryItem}
        onShow={this.setInventoryItem}
        onSave={this.saveInventoryItem}
        loading={false}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}

export default InventoryItemEdit;
