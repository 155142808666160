import React from 'react';
import {AppContext, TwoDialog, TwoToast} from 'two-app-ui';
import {CutSheetReport} from 'two-core';
import {ProductionLabelData} from './ProductionLabelData';
import {mapToShadesolLabel} from './Mappers/ShadesolLabelMapper';
import {mapToColourvueLabel} from './Mappers/ColourVueLabelMapper';
import productionLabelTemplateOneLine from './Templates/ProductionLabelTemplateOneLine';
import productionLabelTemplateTwoLines from './Templates/ProductionLabelTemplateTwoLines';
import {mapToCurtainLabel} from './Mappers/CurtainLabelMapper';
import productionLabelTemplateTwoLines_v2 from './Templates/ProductionLabelTemplateTwoLines_v2';
import productionLabelTemplatePhotos from './Templates/ProductionLabelPhotos';

interface State {
  orders: string[];
  labels: ProductionLabelData[] | null;
  mapping: boolean;
  printing: boolean;
}

interface ProductionLabelsProps {
  show: boolean;
  printerIp?: string;
  data: CutSheetReport[] | undefined;
  onHide: () => void;
}

class ProductionLabelsDialog extends React.Component<ProductionLabelsProps, State> {
  static contextType = AppContext;
  twoToast?: TwoToast;

  constructor(props: ProductionLabelsProps) {
    super(props);
    this.state = {
      orders: [],
      labels: null,
      mapping: false,
      printing: false,
    };
    this.onHide = this.onHide.bind(this);
    this.print = this.print.bind(this);
  }

  componentDidMount() {
    this.twoToast = this.context.twoToast;
    if (this.props.data) {
      this.mapLabels();
    }
  }

  componentDidUpdate(prevProps: Readonly<ProductionLabelsProps>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.data !== this.props.data) {
      this.mapLabels();
    }
  }

  async mapLabels() {
    this.setState({mapping: true});
    const labels: ProductionLabelData[] = [];
    const orders: string[] = [];
    for (const cutSheet of this.props.data ?? []) {
      if (cutSheet.type === 'Standard') {
        let newLabels: ProductionLabelData[] = [];
        if (cutSheet.production_line === 'Shadesol') {
          newLabels = mapToShadesolLabel(cutSheet);
        } else if (cutSheet.production_line === 'Colourvue') {
          newLabels = mapToColourvueLabel(cutSheet);
        } else if (cutSheet.production_line === 'Curtains') {
          newLabels = mapToCurtainLabel(cutSheet);
        }
        orders.push(`${cutSheet.order_id} (${newLabels.length})`);
        labels.push(...newLabels);
      }
    }
    this.setState({labels: labels, orders: orders, mapping: false});
  }

  async print() {
    if (this.state.labels && this.props.printerIp) {
      this.setState({printing: true});
      const printerIp = this.props.printerIp;
      if (printerIp === '') {
        throw new Error('no factory printer ip');
      }

      const url = 'http://' + printerIp + '/pstprnt';

      for (const label of this.state.labels?.reverse() ?? []) {
        const template =
          label.task_type !== 'Photos' ? productionLabelTemplateTwoLines_v2 : productionLabelTemplatePhotos;
        const pritableLabel = template.replace(/{(\w*)}/g, (m, key: keyof typeof label) => {
          return label[key]?.toString() ?? '';
        });
        await this.sleep(200);
        window.postMessage(
          {
            type: 'zebra_print_label',
            zpl: pritableLabel,
            url: url,
          },
          '*'
        );
      }
      const labelCount = this.state.labels.length;
      this.twoToast?.showInfo(`${labelCount} label${labelCount > 1 ? 's' : ''} sent to printer.`);
      this.setState({printing: false}, () => this.onHide());
    }
  }

  async sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
  }

  async onHide() {
    this.props.onHide();
  }

  render() {
    return (
      <TwoDialog
        showDialog={this.props.show}
        header={'Print Production Labels'}
        saveButtonTitle={'Print'}
        loading={this.state.mapping}
        saving={this.state.printing}
        onHide={this.onHide}
        onSave={() => this.print()}
      >
        {this.state.orders.length === 0 ? (
          <span>{'Do you wish to print the production labels?'}</span>
        ) : (
          <span>{`Do you wish to print production labels for: ${this.state.orders.join(', ')} ?`}</span>
        )}
      </TwoDialog>
    );
  }
}
export default ProductionLabelsDialog;
