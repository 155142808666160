import React from 'react';
import {StockTake, StockTakeInventoryItemStage, StockTakeStage} from 'two-core';
import {AppMenuItem, AppMenuItemTemplate, TwoAction} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faClipboardListCheck, faClone, faPencil, faPlay, faPlus, faStop} from '@fortawesome/pro-regular-svg-icons';

export interface StockTakeMenuFunctions {
  onAdd?: () => void;
  onClone?: (stockTake: StockTake) => void;
  onEdit?: (stockTake: StockTake) => void;
  onStart?: (stockTake: StockTake) => void;
  onStop?: (stockTake: StockTake) => void;
  onReview?: (stockTake: StockTake) => void;
}
interface ItemData {
  label?: string;
  icon?: IconDefinition;
  action?: () => void;
  separator?: boolean;
}

export const stockTakeItemStageOptions: {label: StockTakeInventoryItemStage; value: StockTakeInventoryItemStage}[] = [
  {label: 'New', value: 'New'},
  {label: 'Counted', value: 'Counted'},
  {label: 'Approved', value: 'Approved'},
  {label: 'Re-Count', value: 'Re-Count'},
];

export const stockTakeStageOptions: {label: StockTakeStage; value: StockTakeStage}[] = [
  {label: 'Draft', value: 'Draft'},
  {label: 'Started', value: 'Started'},
  {label: 'In Progress', value: 'In Progress'},
  {label: 'Review', value: 'Review'},
  {label: 'Recount', value: 'Recount'},
  {label: 'Recount In Progress', value: 'Recount In Progress'},
  {label: 'Completed', value: 'Completed'},
];

/**
 * Get button menu items for factory order
 * @param selectedStockTake
 * @param functions
 */
export function getStockTakeButtonMenuItems(
  selectedStockTake: StockTake | undefined,
  functions: StockTakeMenuFunctions
): TwoAction[] {
  const items = getStockTakeItems(selectedStockTake, functions);
  let mainItem = items.find(item => item.label === 'Edit');
  if (!mainItem) {
    mainItem = items[0];
  }
  return items.map(item => {
    if (item.separator) {
      return {separator: true};
    }
    return {
      label: item.label,
      icon: item.icon,
      action: item.action,
      main: item.label === mainItem?.label,
    };
  });
}

/**
 * Get table menu items for factory orders
 * @param selectedStockTake
 * @param functions
 */
export function getStockTakeMenuItems(
  selectedStockTake: StockTake | undefined,
  functions: StockTakeMenuFunctions
): AppMenuItem[] {
  const items = getStockTakeItems(selectedStockTake, functions);
  return items.map(item => {
    if (item.separator) {
      return {separator: true};
    }
    return {
      label: item.label,
      faIcon: item.icon,
      command: item.action,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
    };
  });
}

function getStockTakeItems(selectedStockTake: StockTake | undefined, functions: StockTakeMenuFunctions): ItemData[] {
  const items: ItemData[] = [];

  if (functions.onAdd) {
    items.push({
      label: 'Add New Stock Take',
      icon: faPlus,
      action: functions.onAdd,
    });
  }

  if (selectedStockTake) {
    const stage = selectedStockTake.stage;
    const stoppableStockTakeStages: StockTakeStage[] = ['Started', 'In Progress', 'Recount', 'Recount In Progress'];

    if (functions.onClone) {
      items.push({
        label: 'Clone',
        icon: faClone,
        action: () => functions.onClone!(selectedStockTake),
      });
    }
    if (stage !== 'Completed' && functions.onEdit) {
      items.push({
        label: 'Edit',
        icon: faPencil,
        action: () => functions.onEdit!(selectedStockTake),
      });
    }
    if (functions.onStart && stage === 'Draft') {
      items.push({
        label: 'Start Stock Take',
        icon: faPlay,
        action: () => functions.onStart!(selectedStockTake),
      });
    }
    if (functions.onStop && stoppableStockTakeStages.includes(selectedStockTake.stage)) {
      items.push({
        label: 'Counting Done',
        icon: faStop,
        action: () => functions.onStop!(selectedStockTake),
      });
    }
    if (functions.onReview && selectedStockTake.stage === 'Review') {
      items.push({
        label: 'Review',
        icon: faClipboardListCheck,
        action: () => functions.onReview!(selectedStockTake),
      });
    }
  }

  return items;
}
