import {DropdownOption} from 'two-core';

export const purchaseOrderStagesNew: string[] = ['Draft', 'Ordered', 'Eta Confirmed'];

export const purchaseOrderStagesNewOptions: DropdownOption[] = [
  {label: 'Draft', value: 'Draft'},
  {label: 'Ordered', value: 'Ordered'},
  {label: 'Eta Confirmed', value: 'Eta Confirmed'},
];

export const purchaseOrderStages: string[] = ['Draft', 'Ordered', 'Eta Confirmed', 'Delivered', 'Delayed', 'Cancelled'];

export const purchaseOrderStagesOptions: DropdownOption[] = [
  {label: 'Draft', value: 'Draft'},
  {label: 'Ordered', value: 'Ordered'},
  {label: 'ETA Confirmed', value: 'ETA Confirmed'},
  {label: 'Delayed', value: 'Delayed'},
  {label: 'Partially Delivered', value: 'Partially Delivered'},
  {label: 'Delivered', value: 'Delivered'},
  {label: 'Cancelled', value: 'Cancelled'},
];
