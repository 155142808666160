import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';
import {FactoryFloorTask, FloorTaskContentBlind, FloorTaskContentFabric, FloorTaskContentNote, MapOf} from 'two-core';

interface Props {
  data: any;
}

//interface State {}

class CVFabric_v3 extends CutSheetTemplate {
  report: Report;
  contentPrio: MapOf<number>;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
    this.contentPrio = {
      repair: 1,
      note: 2,
      skin: 3,
      wrap: 4,
    };
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      3,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">cv std v3</td>
              <td className="text">
                <b>FABRIC</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text">{this.props.data.reference}</td>
              <td className="label">shipping to</td>
              <td className="text" colSpan={3}>
                {this.props.data.ship_to}
              </td>
            </tr>
            <tr>
              <td className="label">product</td>
              <td className="text">CV Roller Blind</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      /* MIXED FABRICS */
    }
    const uniqueFabrics: string[] = [];
    for (const item of this.props.data.fabric.items) {
      if (
        item.content.fabric &&
        item.content.colour &&
        !uniqueFabrics.includes(item.content.fabric + item.content.colour)
      ) {
        uniqueFabrics.push(item.content.fabric + item.content.colour);
      }
    }

    if (uniqueFabrics.length > 1) {
      this.report.addItem(
        this.props.data,
        1,
        <React.Fragment>
          <div className="text">{`MIXED FABRICS !!! ${uniqueFabrics.length} different fabrics.`}</div>
        </React.Fragment>
      );
    }

    {
      /* ITEMS */
    }
    this.props.data.fabric.items.sort((firstTask: FactoryFloorTask, secondTask: FactoryFloorTask) => {
      const task1Content = firstTask.content as FloorTaskContentBlind;
      const task2Content = secondTask.content as FloorTaskContentBlind;
      const weight1 =
        firstTask.order_item_index * 100 +
        (task1Content.blind_index ?? 1) * 10 +
        this.contentPrio[firstTask.category ?? 0];
      const weight2 =
        secondTask.order_item_index * 100 +
        (task2Content.blind_index ?? 1) * 10 +
        this.contentPrio[secondTask.category ?? 0];
      return weight1 - weight2;
    });

    let currentItemIndex = this.props.data.fabric.items[0].order_item_index;
    let currentBlindIndex = -1;
    let itemRows: any[] = [];
    let repair1: any, repair2: any, repair3: any;
    repair1 = repair2 = repair3 = '';
    let isFirstRow = true;
    let currentLineCount = 1;
    let currentLineIndex = 0;

    this.props.data.fabric.items.map((item: any) => {
      let indexColumn = <td className="no-top-border" />;
      let row;
      let rowClass = 'inside-row';

      if (item.order_item_index !== currentItemIndex) {
        currentItemIndex = item.order_item_index;
        currentBlindIndex = -1;
      }

      if (
        item.order_item_index !== currentItemIndex ||
        (item.content.blind_index && item.content.blind_index !== currentBlindIndex)
      ) {
        this.report.addItem(
          item,
          currentLineCount,
          <React.Fragment>
            <table className="mt-10 odd-zebra three-cols content">
              {repair1}
              {repair2}
              {repair3}
              {itemRows}
            </table>
          </React.Fragment>
        );
        repair1 = repair2 = repair3 = '';
        currentItemIndex = item.order_item_index;
        currentBlindIndex = item.content.blind_index;
        currentLineCount = 0;
        currentLineIndex++;
        itemRows = [];
        isFirstRow = true;
      }

      if (isFirstRow) {
        isFirstRow = false;
        indexColumn = <td className="index">{currentLineIndex}</td>;
        rowClass = 'new-item';
      }

      if (item.category === 'repair') {
        repair1 = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">rework</td>
            <td className="text">{item.content.repair_whole_item ? 'YES' : 'NO'}</td>
            <td className="label">type</td>
            <td className="text">{this.capitaliseFirst(item.content.repair_type ?? 'Whole Item Rework')}</td>
            <td className="label">responsibility</td>
            <td className="text" colSpan={4}>
              {this.capitaliseFirst(item.content.repair_responsibility)}
            </td>
          </tr>
        );
        if (item.content.repair_type === 'cutdown (standard)') {
          repair2 = (
            <tr>
              <td />
              <td className="label">new height</td>
              <td className="number">{item.content.new_height}</td>
              <td className="label">new width</td>
              <td className="number" colSpan={5}>
                {item.content.new_width}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += 1;
        }
        repair3 = (
          <tr>
            <td />
            <td className="label">repair</td>
            <td className="text" colSpan={7}>
              {item.content.repair_details}
            </td>
            <td className="sign-off">check</td>
          </tr>
        );
        currentLineCount += 2;
      }

      if (item.category === 'wrap') {
        const data: FloorTaskContentFabric = item.content;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">wrap fabric</td>
            <td className="text">{this.capitaliseFabric(data.fabric)}</td>
            <td className="label">colour</td>
            <td className="text">{this.capitaliseFirst(data.colour)}</td>
            <td className="label">width</td>
            <td className="number">{data.width}</td>
            <td className="label">drop</td>
            <td className="number">{data.drop}</td>
            <td className="sign-off">cut</td>
          </tr>
        );
        currentLineCount++;
      }

      if (item.category === 'skin') {
        const data: FloorTaskContentFabric = item.content;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">fabric</td>
            <td className="text">{this.capitaliseFabric(data.fabric)}</td>
            <td className="label">colour</td>
            <td className="text">{this.capitaliseFirst(data.colour)}</td>
            <td className="label">width</td>
            <td className="number">{data.width}</td>
            <td className="label">drop</td>
            <td className="number">{data.drop}</td>
            <td className="sign-off">cut</td>
          </tr>
        );
        currentLineCount++;
      }

      if (item.category === 'note') {
        const taskContent = item.content as FloorTaskContentNote;
        row = (
          <tr className={rowClass}>
            {indexColumn}
            <td className="label">note</td>
            <td className="text" colSpan={7}>
              {taskContent.text.replace(/\r?\n|\r/g, ' ')}
            </td>
            <td className="sign-off">check</td>
          </tr>
        );
        currentLineCount += Math.ceil(taskContent.text.length / 90);
      }

      itemRows.push(row);
    });
    this.report.addItem(
      this.props.data,
      currentLineCount,
      <React.Fragment>
        <table className="mt-10 odd-zebra three-cols content">
          {repair1}
          {repair2}
          {repair3}
          {itemRows}
        </table>
      </React.Fragment>
    );
    this.report.addItem(
      this.props.data,
      1,
      <React.Fragment>
        <table className="summary">
          <td className="label">total</td>
          <td className="text">{currentLineIndex}</td>
        </table>
      </React.Fragment>
    );

    {
      /* CHECK SUM */
    }

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="colorvue-standard-fabric-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default CVFabric_v3;
