import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import React, {useContext, useState} from 'react';
import {AppContext, TwoDialog, getTwoDateFormat} from 'two-app-ui';
import {FactoryOrder, Order} from 'two-core';
import {DateTime} from 'luxon';
import {AppContextProps} from '../../App';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  orders: Order[];
}

export const EditEcdDialog = ({showDialog, onHide, orders}: Props) => {
  const context = useContext(AppContext) as AppContextProps;
  const calendarInputDate = getTwoDateFormat(context.usersService?.settings?.date_format, 'calendarInputDate');

  const [editedEcdsMap, setEditedEcdsMap] = useState(new Map<string, Date>());
  const [saving, setSaving] = useState(false);

  const onShow = () => {
    setEditedEcdsMap(new Map<string, Date>());
  };

  const onEcdChange = (e: CalendarChangeParams, orderId: string) => {
    const date = e.value as Date;
    setEditedEcdsMap(new Map(editedEcdsMap.set(orderId, date)));
  };

  const onSave = async () => {
    const promises: Promise<FactoryOrder>[] = [];
    setSaving(true);
    for (const [id, ecd] of Array.from(editedEcdsMap.entries())) {
      promises.push(
        context.factoryOrdersService!.updateFactoryOrder(id, {
          ecd: DateTime.fromJSDate(ecd).setZone('utc', {keepLocalTime: true}) as unknown as Date,
        })
      );
    }
    Promise.all(promises)
      .then(() => {
        context.twoToast.showSuccess('ECDs updated successfully');
        onHide();
      })
      .catch(e => {
        console.error(e);
        context.twoToast.showError('Failed to update ECDs');
      })
      .finally(() => {
        setSaving(false);
      });
  };
  const renderOrderEcd = (order: Order, editedEcdsMap: Map<string, Date>) => {
    let ecdValue = undefined;
    if (editedEcdsMap.has(order.id!)) {
      ecdValue = editedEcdsMap.get(order.id!);
    } else if (order.factory_order!.ecd) {
      ecdValue = DateTime.fromISO(order.factory_order!.ecd!.toString(), {zone: 'system'}).toJSDate();
    }
    return (
      <div key={order.id} className="p-grid w-100 p-fluid">
        <label htmlFor="number" className="p-col-6 p-as-center">
          {order.id}
        </label>
        <div className="p-col-6">
          <Calendar value={ecdValue} dateFormat={calendarInputDate} onChange={e => onEcdChange(e, order.id!)} />
        </div>
      </div>
    );
  };

  return (
    <>
      <TwoDialog
        header="Edit ECD"
        onHide={onHide}
        onSave={onSave}
        saving={saving}
        onShow={onShow}
        showDialog={showDialog}
        style={{width: '400px'}}
        breakpoints={{'576px': '90vw'}}
        draggable={false}
      >
        {orders.map((order: Order) => renderOrderEcd(order, editedEcdsMap))}
      </TwoDialog>
    </>
  );
};
