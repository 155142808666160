import React from 'react';
import {PurchaseOrder} from 'two-core';
import {DateTime} from 'luxon';
import formats from '../../config/formats';

interface Props {
  purchaseOrder: PurchaseOrder;
}

class PurchaseOrderDetail extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setPurchaseOrder(this.props.purchaseOrder);
  }

  setPurchaseOrder(purchaseOrder: PurchaseOrder) {
    this.setState({
      purchaseOrder: purchaseOrder,
    });
  }

  render() {
    const {purchaseOrder} = this.props;

    return (
      <>
        <div className="p-d-flex">
          <label htmlFor="vendor" className="p-col-12 p-md-3">
            vendor
          </label>
          <div className="p-col-12 p-md-9">
            <span>{purchaseOrder.supplier?.company_name ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex">
          <label htmlFor="stage" className="p-col-12 p-md-3">
            stage
          </label>
          <div className="p-col-12 p-md-9">
            <span>{purchaseOrder.stage}</span>
          </div>
        </div>

        {purchaseOrder.sent_at && (
          <div className="p-d-flex">
            <label htmlFor="delivery_date" className="p-col-12 p-md-3">
              sent at
            </label>
            <div className="p-col-12 p-md-9">
              <span>{DateTime.fromISO(purchaseOrder.sent_at.toString()).toFormat(formats.date)}</span>
            </div>
          </div>
        )}

        {purchaseOrder.eta && (
          <div className="p-d-flex">
            <label htmlFor="eta" className="p-col-12 p-md-3">
              eta
            </label>
            <div className="p-col-12 p-md-9">
              <span>{DateTime.fromISO(purchaseOrder.eta.toString()).toFormat(formats.date)}</span>
            </div>
          </div>
        )}
        {purchaseOrder.delivered_at && (
          <div className="p-d-flex">
            <label htmlFor="delivery_date" className="p-col-12 p-md-3">
              delivered on
            </label>
            <div className="p-col-12 p-md-9">
              <span>{DateTime.fromISO(purchaseOrder.delivered_at.toString()).toFormat(formats.date)}</span>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default PurchaseOrderDetail;
