import React from 'react';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {AppContext, MessageService, TwoDialog} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import OosService from '../../services/OosService';
import {Oos, OosPatch} from 'two-core';
import {Calendar} from 'primereact/calendar';
import {messages} from '../../config/messages';
import formats from '../../config/formats';

export const oosStages = ['Eta Not Confirmed', 'Eta Confirmed', 'Delayed', 'Available'];

interface Props {
  oos: Oos;
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
}

interface State {
  oos: Oos;
  loading: boolean;
}

class OosEdit extends React.Component<Props, State> {
  static contextType = AppContext;
  oosService: OosService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      oos: {
        factory_id: '',
        inventory_item_id: '',
        stage: 'Available',
        updated_at: new Date(),
      },
    };

    this.closeOOs = this.closeOOs.bind(this);
    this.setOos = this.setOos.bind(this);
    this.saveOos = this.saveOos.bind(this);
  }

  componentDidMount() {
    this.oosService = this.context.oosService;
  }

  setOos() {
    const item = this.props.oos;
    this.setState({oos: item});
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const oos = this.state.oos;
    const newState = {...oos, [e.target.name]: e.target.value};
    this.setState({oos: newState});
  }

  emptyOos() {
    const inventoryItem: Oos = {
      factory_id: localStorage.getItem('current factory') ?? '',
      inventory_item_id: '',
      stage: 'Available',
      updated_at: new Date(),
    };
    return inventoryItem;
  }

  closeOOs() {
    this.props.onHide();
    this.setState({
      loading: false,
      oos: this.emptyOos(),
    });
  }

  saveOos() {
    this.setState({loading: true});
    const oos = this.state.oos;

    const oosPatch: OosPatch = {
      stage: oos.stage,
      eta: oos.eta,
    };

    if (oos.id) {
      this.oosService
        ?.updateOos(oos.id?.toString() ?? '', oosPatch)
        .then(() => {
          MessageService.sendMessage(messages.oosUpdated);
          this.props.toast.current?.show({
            contentClassName: '',
            severity: 'success',
            summary: 'Success',
            detail: 'Oos updated successfully.',
            life: 3000,
          });
          this.closeOOs();
        })
        .catch(() => {
          this.setState({loading: false});
          this.props.toast.current?.show({
            contentClassName: '',
            severity: 'error',
            summary: 'Error',
            detail: 'Sorry, Oos update failed, please try again.',
            life: 3000,
          });
        });
    } else {
      this.setState({loading: false});
      this.props.toast.current?.show({
        contentClassName: '',
        severity: 'error',
        summary: 'Error',
        detail: 'Sorry, Oos update failed, please try again.',
        life: 3000,
      });
    }
  }

  render() {
    const {oos} = this.state;

    const dialogBody = (
      <>
        <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
          <label htmlFor="stage" className="p-col-1">
            stage
          </label>
          <div className="p-col-5 p-p-0">
            <Dropdown
              className="w-100"
              value={oos.stage}
              options={oosStages}
              name="stage"
              id="stage"
              onChange={e => this.handleInputChange(e)}
            />
          </div>

          <label htmlFor="eta" className="p-col-1">
            eta
          </label>
          <div className="p-col-5 p-p-0">
            <span className="p-fluid">
              <Calendar
                value={oos.eta ? new Date(oos.eta) : undefined}
                onChange={e => this.handleInputChange(e)}
                name={'eta'}
                dateFormat={formats.calendarInputDate}
              />
            </span>
          </div>
        </div>
      </>
    );
    return (
      <TwoDialog
        headerTitle={'Edit Oos'}
        showDialog={this.props.showDialog}
        width={50}
        onHide={this.closeOOs}
        onShow={this.setOos}
        onSave={this.saveOos}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}

export default OosEdit;
