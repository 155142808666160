import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';

interface Props {
  data: any;
}

//interface State {}

class SSSaw extends CutSheetTemplate {
  report: Report;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      2,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">ss std v2</td>
              <td className="text">
                <b>SAW</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text" colSpan={3}>
                {this.props.data.reference}
              </td>
              <td className="label">shipping to</td>
              <td className="text">{this.props.data.ship_to}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      this.props.data.saw.items.map((item: any) => {
        let currentLineCount = 1;

        let repair1, repair2;
        let bottomRail;
        let coverBack;
        let coverTop;
        let coverFront;
        let channelsOuter;
        let channelsInner;
        let channelsFace;
        let arms;
        let steelWeight;
        let extras;
        let note;

        if (item.content.repair_respo) {
          repair1 = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">rework</td>
              <td className="text">{item.content.repair_whole_item ? 'YES' : 'NO'}</td>
              <td className="label">responsibility</td>
              <td className="text" colSpan={4}>
                {this.capitaliseFirst(item.content.repair_respo)}
              </td>
            </tr>
          );
          repair2 = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">repair detail</td>
              <td className="text" colSpan={5}>
                {item.content.repair_details}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += 2;
        } else {
          repair1 = repair2 = '';
        }

        if (item.content.wb_type) {
          bottomRail = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">weight bar</td>
              <td className="text">{this.capitaliseFirst(item.content.wb_type)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.wb_colour)}</td>
              <td className="label">length</td>
              <td className="number">{item.content.wb_length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        } else {
          bottomRail = '';
        }

        if (item.content.box_back_type) {
          coverBack = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">cover</td>
              <td className="text">{this.capitaliseFirst(item.content.box_back_type)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.box_back_colour)}</td>
              <td className="label">length</td>
              <td className="number">{item.content.box_back_length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        } else {
          coverBack = '';
        }

        if (item.content.box_front_type) {
          coverFront = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">cover</td>
              <td className="text">{this.capitaliseFirst(item.content.box_front_type)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.box_front_colour)}</td>
              <td className="label">length</td>
              <td className="number">{item.content.box_front_length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        } else {
          coverFront = '';
        }

        if (item.content.box_top_type) {
          coverTop = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">box back</td>
              <td className="text">{this.capitaliseFirst(item.content.box_top_type)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.box_top_colour)}</td>
              <td className="label">length</td>
              <td className="number">{item.content.box_top_length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        } else {
          coverTop = '';
        }

        if (item.content.ch_out_left_length || item.content.urail_left_length) {
          channelsOuter = (
            <React.Fragment>
              <tr>
                <td>{item.order_item_index}</td>
                <td className="label">channel</td>
                <td className="text">{item.content.urail_left_length ? 'Face Fit U Rail Left' : 'Outer Rail Left'}</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(item.content.ch_left_colour)}</td>
                <td className="label">length</td>
                <td className="number">
                  {item.content.urail_left_length ? item.content.urail_left_length : item.content.ch_out_left_length}
                </td>
                <td className="sign-off">cut</td>
              </tr>
              <tr>
                <td />
                <td className="label">channel</td>
                <td className="text">
                  {item.content.urail_left_length ? 'Face Fit U Rail Right' : 'Outer Rail Right'}
                </td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(item.content.ch_right_colour)}</td>
                <td className="label">length</td>
                <td className="number">
                  {item.content.urail_right_length ? item.content.urail_right_length : item.content.ch_out_right_length}
                </td>
                <td className="sign-off">cut</td>
              </tr>
            </React.Fragment>
          );
          currentLineCount += 2;
        } else {
          channelsOuter = '';
        }

        if (item.content.ch_in_left_length) {
          channelsInner = (
            <React.Fragment>
              <tr>
                <td>{item.order_item_index}</td>
                <td className="label">channel</td>
                <td className="text">Inner Left</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(item.content.ch_left_colour)}</td>
                <td className="label w-10mm">length</td>
                <td className="text">{item.content.ch_in_left_length}</td>
                <td className="sign-off">cut</td>
              </tr>
              <tr>
                <td />
                <td className="label">channel</td>
                <td className="text">Inner Right</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(item.content.ch_right_colour)}</td>
                <td className="label">length</td>
                <td className="text">{item.content.ch_in_right_length}</td>
                <td className="sign-off">cut</td>
              </tr>
            </React.Fragment>
          );
          currentLineCount += 2;
        } else {
          channelsInner = '';
        }

        if (item.content.ch_ff_left_length) {
          channelsFace = (
            <React.Fragment>
              <tr>
                <td />
                <td className="label">channel</td>
                <td className="text">L Face Fit Left</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(item.content.ch_left_colour)}</td>
                <td className="label">length</td>
                <td className="number">{item.content.ch_ff_left_length}</td>
                <td className="sign-off">cut</td>
              </tr>
              <tr>
                <td />
                <td className="label">channel</td>
                <td className="text">L Face Fit Right</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(item.content.ch_right_colour)}</td>
                <td className="label">length</td>
                <td className="number">{item.content.ch_ff_right_length}</td>
                <td className="sign-off">cut</td>
              </tr>
            </React.Fragment>
          );
          currentLineCount += 2;
        } else {
          channelsFace = '';
        }

        if (item.content.arm_length) {
          arms = (
            <React.Fragment>
              <tr>
                <td />
                <td className="label">arm</td>
                <td className="text">Left</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(item.content.arm_colour)}</td>
                <td className="label">length</td>
                <td className="number">{item.content.arm_length}</td>
                <td className="sign-off">cut</td>
              </tr>
              <tr>
                <td />
                <td className="label">arm</td>
                <td className="text">Right</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(item.content.arm_colour)}</td>
                <td className="label">length</td>
                <td className="number">{item.content.arm_length}</td>
                <td className="sign-off">cut</td>
              </tr>
            </React.Fragment>
          );
          currentLineCount += 2;
        } else {
          arms = '';
        }

        if (item.content.steel_count) {
          steelWeight = (
            <tr>
              <td />
              <td className="label">weight</td>
              <td className="text">{item.content.steel_count}</td>
              <td />
              <td />
              <td className="label">length</td>
              <td className="number">{item.content.steel_length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        } else {
          steelWeight = '';
        }

        if (item.content.extras && item.content.extras.length > 0) {
          extras = item.content.extras.map((extra: any) => {
            currentLineCount++;
            return (
              <tr>
                <td>{item.order_item_index}</td>
                <td className="label">extra</td>
                <td className="text">{this.capitaliseFirst(extra.material)}</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(extra.colour)}</td>
                <td className="label">length</td>
                <td className="number">{extra.length}</td>
                <td className="sign-off">cut</td>
              </tr>
            );
          });
        } else {
          extras = '';
        }

        if (item.content.notes) {
          note = (
            <tr>
              <td />
              <td className="label">note</td>
              <td className="text" colSpan={5}>
                {`${item.content.notes}`.replace(/\r?\n|\r/g, ' ')}
              </td>
            </tr>
          );
          currentLineCount += Math.ceil(`${item.content.notes}`.length / 90);
        } else {
          note = '';
        }
        <div style={{pageBreakAfter: 'always'}}>
          {this.report.addItem(
            item,
            currentLineCount,
            <table className="mt-10 odd-zebra three-cols content">
              {repair1}
              {repair2}
              {coverBack}
              {coverTop}
              {coverFront}
              <tr>
                <td>{item.order_item_index}</td>
                <td className="label">tube</td>
                <td className="text">{this.capitaliseFirst(item.content.tube_type)}</td>
                <td />
                <td />
                <td className="label">length</td>
                <td className="number">{item.content.tube_length}</td>
                <td className="sign-off">cut</td>
              </tr>
              {bottomRail}
              {channelsOuter}
              {channelsInner}
              {channelsFace}
              {arms}
              {steelWeight}
              {extras}
              {note}
            </table>
          )}
          ;
        </div>;
      });
    }

    {
      /* CHECK SUM */
    }
    {
      this.report.addItem(
        this.props.data,
        1,
        <React.Fragment>
          <table className="summary">
            <td className="label">total</td>
            <td className="text">{this.props.data.fabric.items.length}</td>
          </table>
        </React.Fragment>
      );
    }
    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="shadesol-standard-fabric-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default SSSaw;
