import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import React from 'react';
import {AppContext, TwoDialog} from 'two-app-ui';
import {SupplyItem} from 'two-core';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  onSave: (selectedSupplyItem: SupplyItem) => void;
  unselectedSupplyItems: SupplyItem[];
}

interface State {
  selectedSupplyItem?: SupplyItem;
}

export class PurchaseOrderItemAddSupplyItemDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {};

    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSupplyItemChange = this.onSupplyItemChange.bind(this);
  }

  onHide() {
    this.props.onHide();
  }

  onShow() {
    this.setState({selectedSupplyItem: this.props.unselectedSupplyItems[0]});
  }

  onSupplyItemChange(e: DropdownChangeParams) {
    return this.setState({selectedSupplyItem: e.value});
  }

  onSave() {
    const {onSave} = this.props;
    const {selectedSupplyItem} = this.state;
    if (selectedSupplyItem) {
      onSave(selectedSupplyItem);
    }
  }

  render() {
    const {showDialog, onSave, unselectedSupplyItems} = this.props;
    const {selectedSupplyItem} = this.state;

    const selectedSiTemplate = (option: SupplyItem) => {
      if (option) {
        return (
          <div className="flex align-items-center" id={`selected-${option.id}`}>
            {`${option.name}  |  ${option.package_size_label}  |  ${option.unit_price}/${option.reorder_unit}`}
            {/*<div>{option.name}</div>*/}
          </div>
        );
      }

      return <span></span>;
    };

    const siOptionTemplate = (option: SupplyItem) => {
      return (
        <div className="flex align-items-center" id={`option-${option.id}`}>
          {`${option.name}  |  ${option.package_size_label}   |  ${option.unit_price}/${option.reorder_unit}`}
          {/*<div>{option.name}</div>*/}
        </div>
      );
    };

    return (
      <>
        <TwoDialog
          header="Select Supply Item"
          loading={false}
          onHide={this.onHide}
          onShow={this.onShow}
          onSave={this.onSave}
          showDialog={showDialog}
          draggable={false}
        >
          <div className="p-fluid">
            <div className="p-field">Choose which supply item you want to add, please.</div>
            <div className="p-field">
              <Dropdown
                value={selectedSupplyItem}
                options={unselectedSupplyItems}
                onChange={this.onSupplyItemChange}
                dataKey="id"
                valueTemplate={selectedSiTemplate}
                itemTemplate={siOptionTemplate}
                optionLabel="sku"
              />
            </div>
          </div>
        </TwoDialog>
      </>
    );
  }
}
