import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import React from 'react';
import {MessageService, AppContext, TwoDialog, PurchaseOrderPrinterComponent} from 'two-app-ui';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import {
  Bom,
  PurchaseOrder,
  PurchaseOrderAggregate,
  PurchaseOrderItem,
  PurchaseOrderItemPatch,
  PurchaseOrderPatch,
  QueryParameter,
  Supplier,
} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import formats from '../../config/formats';
import {DateTime} from 'luxon';
import PurchaseOrderItems, {InvalidInventoryItem} from './PurchaseOrderItems';
import './PurchaseOrderEditDialog.scss';
import {InputText} from 'primereact/inputtext';
import InventoryService from '../../services/InventoryService';
import {PurchaseOrderEditConfirmationDialog} from './PurchaseOrderEditConfirmationDialog';
import {Button} from 'primereact/button';

interface Props {
  purchaseOrder: PurchaseOrder;
  supplier: Supplier;
  poItems: PurchaseOrderItem[];
  showDialog: boolean;
  onHide: () => void;
}

interface State {
  loading: boolean;
  purchaseOrderPatch: PurchaseOrderPatch;
  formPoItems?: PurchaseOrderItem[];
  showApprovalDialog?: boolean;
  itemsValid: boolean;
  invalidItems?: InvalidInventoryItem[];
  shouldPrint?: boolean;
  poToPrint?: PurchaseOrder;
}

class PurchaseOrderEditDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  purchaseOrdersService: PurchaseOrdersService | null = null;
  inventoryService?: InventoryService;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      purchaseOrderPatch: {},
      itemsValid: true,
    };

    this.onSaveClick = this.onSaveClick.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onEtaChange = this.onEtaChange.bind(this);
    this.onSentAtChange = this.onSentAtChange.bind(this);
    this.onDeliveredAtChange = this.onDeliveredAtChange.bind(this);
    this.setFormPurchaseOrders = this.setFormPurchaseOrders.bind(this);
    this.onItemsValidated = this.onItemsValidated.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
  }

  componentDidMount() {
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.inventoryService = this.context.inventoryService;
  }

  async loadPurchaseOrder(id: string) {
    try {
      const filters: string[] = [JSON.stringify({field: 'id', value: id})];
      const aggregate: PurchaseOrderAggregate[] = ['items', 'supplier'];
      const params: QueryParameter = {
        filters: filters,
        aggregate: aggregate,
      };
      const result = await this.purchaseOrdersService!.getPurchaseOrders(params);
      return ((result?.records ?? []) as PurchaseOrder[])[0];
    } catch (error) {
      console.error('Failed to load purchase order', error);
      this.context.twoToast?.showError('Failed to load purchase order');
      return undefined;
    }
  }

  async onSaveClick(shouldPrint?: boolean) {
    if (!this.state.itemsValid) {
      this.setState(() => ({
        showApprovalDialog: true,
        invalidItems: this.state.invalidItems,
        shouldPrint: shouldPrint,
      }));
      return;
    }
    this.setState(
      () => ({shouldPrint: shouldPrint}),
      () => this.onSave(shouldPrint)
    );
  }

  async onSave(shouldPrint?: boolean) {
    const {purchaseOrderPatch, formPoItems} = this.state;
    const {purchaseOrder, poItems} = this.props;
    this.setState({loading: true});
    const promises = [];
    if (Object.keys(purchaseOrderPatch).length) {
      //save the PO
      promises.push(this.purchaseOrdersService?.updatePurchaseOrder(purchaseOrder.id!, purchaseOrderPatch));
    }

    const poItems2Create: PurchaseOrderItem[] = [];
    const poItems2Delete: PurchaseOrderItem[] = [];
    const poItems2Update: PurchaseOrderItem[] = [];
    for (const formPoItem of formPoItems ?? []) {
      if (formPoItem.id! > 0) {
        const poItem = poItems.find(poItem => formPoItem.id === poItem.id)!;
        if (formPoItem.supply_item_id !== poItem.supply_item_id || formPoItem.quantity !== poItem.quantity) {
          poItems2Update.push(formPoItem);
        }
      } else {
        poItems2Create.push(formPoItem);
      }
    }
    for (const poItem of poItems ?? []) {
      const formPoItem = formPoItems?.find(formPoItem => formPoItem.id === poItem.id);
      if (!formPoItem) {
        poItems2Delete.push(poItem);
      }
    }
    promises.push(
      ...poItems2Create.map(poItem => this.purchaseOrdersService?.createPurchaseOrderItem(purchaseOrder.id!, poItem))
    );
    promises.push(
      ...poItems2Delete.map(poItem =>
        this.purchaseOrdersService?.deletePurchaseOrderItem(purchaseOrder.id!, poItem.id!)
      )
    );
    promises.push(
      ...poItems2Update.map(poItem => {
        const poItemPatch: PurchaseOrderItemPatch = {
          supply_item_id: poItem.supply_item_id,
          quantity: poItem.quantity,
          qty_in_uom: poItem.qty_in_uom,
        };
        return this.purchaseOrdersService?.updatePurchaseOrderItem(purchaseOrder.id!, poItem.id!, poItemPatch);
      })
    );

    Promise.all(promises)
      .then(async () => {
        this.context.twoToast?.showSuccess(`Purchase Order ${purchaseOrder.name} updated successfully.`);
        if (shouldPrint) {
          const poToPrint = (await this.loadPurchaseOrder(purchaseOrder.id!)) as PurchaseOrder | undefined;
          if (poToPrint) {
            this.setState({poToPrint});
            //give some time for the re-render component to show the print dialog
            await setTimeout(() => {
              this.onPrint();
              this.onHide();
            }, 100);
          } else {
            this.onHide();
          }
        } else {
          MessageService.sendMessage(messages.purchaseOrderUpdated);
          this.onHide();
        }
      })
      .catch(() => {
        this.context.twoToast?.showError(
          `Sorry, Purchase Order ${purchaseOrder.name} update failed, please try again.`
        );
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  onPrint() {
    MessageService.sendMessage(messages.printUpdatedPurchaseOrder);
  }

  onHide() {
    this.props.onHide();
    this.setState({
      loading: false,
      purchaseOrderPatch: {},
      shouldPrint: false,
      poToPrint: undefined,
    });
  }

  async onShow() {
    const {poItems, purchaseOrder} = this.props;
    const formItems = poItems.map(poItem => ({...poItem}));
    this.setState({poToPrint: undefined});
  }

  getUncoveredBoms(relatedBoms: Bom[], poItems: PurchaseOrderItem[]) {
    return relatedBoms.filter(relatedBom => {
      return !poItems.some(poItem => poItem.inventory_item_id === relatedBom.inventory_item_id);
    });
  }

  onEtaChange(e: CalendarChangeParams) {
    this.setState({
      purchaseOrderPatch: {
        ...this.state.purchaseOrderPatch,
        eta: e.value as Date,
      },
    });
  }

  onSentAtChange(e: CalendarChangeParams) {
    this.setState({
      purchaseOrderPatch: {
        ...this.state.purchaseOrderPatch,
        sent_at: e.value as Date,
      },
    });
  }

  onDeliveredAtChange(e: CalendarChangeParams) {
    this.setState({
      purchaseOrderPatch: {
        ...this.state.purchaseOrderPatch,
        delivered_at: e.value as Date,
      },
    });
  }

  setFormPurchaseOrders(formPoItems: PurchaseOrderItem[]) {
    this.setState({formPoItems: formPoItems});
  }

  onItemsValidated(isValid: boolean, invalidItems: InvalidInventoryItem[]) {
    this.setState({itemsValid: isValid, invalidItems: invalidItems});
  }

  onNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      purchaseOrderPatch: {
        ...this.state.purchaseOrderPatch,
        name: e.target.value,
      },
    });
  }

  renderFooter() {
    return (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={this.onHide} />
        <Button label="Save" className={'p-mr-2'} onClick={() => this.onSaveClick()} autoFocus />
        <Button label={'Save & Print'} className={'p-mr-2'} onClick={() => this.onSaveClick(true)} />
      </div>
    );
  }

  render() {
    const {purchaseOrder, poItems, supplier} = this.props;
    const {purchaseOrderPatch, formPoItems, showApprovalDialog, invalidItems, poToPrint, shouldPrint} = this.state;
    const sentAt = purchaseOrderPatch.sent_at ?? (purchaseOrder.sent_at ? new Date(purchaseOrder.sent_at!) : undefined);
    const eta = purchaseOrderPatch.eta ?? (purchaseOrder.eta ? new Date(purchaseOrder.eta!) : undefined);
    const dialogBody = (
      <>
        <div className="p-grid p-ai-center w-100 p-mb-2">
          <label htmlFor="name" className="p-col-1">
            name
          </label>
          <div className="p-col-3 p-p-0 p-fluid">
            <InputText value={purchaseOrderPatch.name ?? purchaseOrder.name} onChange={this.onNameChange} />
          </div>
          <label htmlFor="vendor" className="p-col-1">
            vendor
          </label>
          <div className="p-col-3 p-p-0">
            <span className="p-col-3">{purchaseOrder.supplier?.company_name}</span>
          </div>
          <label htmlFor="stage" className="p-col-1">
            stage
          </label>
          <div className="p-col-3 p-p-0">
            <span className="p-fluid">{purchaseOrder.stage ?? 'Draft'}</span>
          </div>
        </div>
        <div className="p-grid p-ai-center w-100 p-mb-2">
          <label htmlFor="sent_at" className="p-col-1">
            order on
          </label>
          <div className="p-col-3 p-p-0">
            {purchaseOrder.stage === 'Ordered' ? (
              <Calendar
                className={'w-100'}
                value={sentAt}
                dateFormat={formats.calendarInputDate}
                name="sent_at"
                onChange={this.onSentAtChange}
              />
            ) : (
              <span className="p-fluid">
                {sentAt ? DateTime.fromJSDate(new Date(sentAt)).toFormat(formats.date) : ''}
              </span>
            )}
          </div>

          <label htmlFor="eta" className="p-col-1">
            eta
          </label>
          <div className="p-col-3 p-p-0">
            {purchaseOrder.stage === 'Ordered' ||
            purchaseOrder.stage === 'Eta Confirmed' ||
            purchaseOrder.stage === 'Delayed' ||
            purchaseOrder.stage === 'Partially Delivered' ? (
              <Calendar
                className={'w-100'}
                value={eta}
                dateFormat={formats.calendarInputDate}
                name="eta"
                onChange={this.onEtaChange}
              />
            ) : (
              <span className="p-fluid">{eta ? DateTime.fromJSDate(new Date(eta)).toFormat(formats.date) : ''}</span>
            )}
          </div>

          <label htmlFor="delivered_at" className="p-col-1">
            delivered
          </label>
          <div className="p-col-3 p-p-0">
            {purchaseOrder.stage === 'Delivered' ? (
              <Calendar
                className={'w-100'}
                value={purchaseOrder.delivered_at ? new Date(purchaseOrder.delivered_at) : undefined}
                dateFormat={formats.calendarInputDate}
                name="delivered_at"
                onChange={this.onDeliveredAtChange}
              />
            ) : (
              <span className="p-fluid">
                {purchaseOrder.delivered_at
                  ? DateTime.fromJSDate(new Date(purchaseOrder.delivered_at)).toFormat(formats.date)
                  : ''}
              </span>
            )}
          </div>
        </div>
        <div className="purchase-order-items-grid w-100">
          <PurchaseOrderItems
            purchaseOrder={purchaseOrder}
            supplier={supplier}
            onItemsChange={this.setFormPurchaseOrders}
            onValidated={this.onItemsValidated}
            editable={true}
            itemsTableId="purchase_order_edit_dialog_items_id"
          />
        </div>
        <PurchaseOrderEditConfirmationDialog
          showDialog={showApprovalDialog ?? false}
          onContinueAnyway={() => this.onSave(shouldPrint)}
          onHide={() => this.setState({showApprovalDialog: false})}
          invalidItems={invalidItems ?? []}
        />
        <PurchaseOrderPrinterComponent
          purchaseOrders={poToPrint ? [poToPrint] : []}
          triggerMessage={messages.printUpdatedPurchaseOrder}
          printAreaId={'print-area-for-updated-purchase-order'}
        />
      </>
    );

    return (
      <>
        <TwoDialog
          header={'Edit Purchase Order'}
          showDialog={this.props.showDialog}
          visible={this.props.showDialog}
          style={{width: '70vw'}}
          breakpoints={{'1920px': '90vw'}}
          onHide={this.onHide}
          onShow={this.onShow}
          loading={this.state.loading}
          className={'purchase-order-edit-dialog'}
          footer={this.renderFooter()}
        >
          {dialogBody}
        </TwoDialog>
      </>
    );
  }
}

export default PurchaseOrderEditDialog;
