import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import OrderListComponent from '../Orders/OrderListComponent';

class NewOrdersPage extends React.Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {};
  }

  render() {
    return <OrderListComponent showFilterBox={false} orderStages={['New', 'Init']} history={this.props.history} />;
  }
}

export default withRouter(NewOrdersPage);
