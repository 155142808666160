import React from 'react';
import {AppContext, TwoDialog, TwoToast} from 'two-app-ui';
import StockTakesService from '../../services/StockTakesService';
import {QueryParameter, StockTake, StockTakeInventoryItem} from 'two-core';
import {Button} from 'primereact/button';
import {StockTakeItemList, StockTakeItemListMode} from './StockTakeItemList/StockTakeItemList';
import {confirmDialog} from 'primereact/confirmdialog';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  stockTakeId: number;
  mode: StockTakeItemListMode;
}

interface State {
  loading?: boolean;
  saving?: boolean;
  stockTake?: StockTake;
  itemsPatch?: StockTakeInventoryItem[];
}
export default class EditStockTakeDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  stockTakesService?: StockTakesService;

  twoToast?: TwoToast;
  constructor(props: Props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onSave = this.onSave.bind(this);
    this.saveStockTake = this.saveStockTake.bind(this);

    this.state = {loading: false};
  }

  componentDidMount() {
    this.stockTakesService = this.context.stockTakesService;
    this.twoToast = this.context.twoToast;
  }

  async loadData() {
    const {stockTakeId} = this.props;
    this.setState({loading: true});
    const stockTake = await this.loadStockTake(stockTakeId);
    this.setState({loading: false, stockTake});
  }
  async loadStockTake(stockTakeId: number) {
    try {
      const queryParams: QueryParameter = {
        filters: [JSON.stringify({field: 'id', value: stockTakeId})],
        aggregate: ['document'],
      };
      const response = await this.stockTakesService!.getStockTakes(queryParams);
      const record = ((response?.records ?? []) as StockTake[])[0];
      if (!record) {
        this.twoToast?.showError('Error loading stock take');
        return undefined;
      }
      return record;
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading stock take');
      return undefined;
    }
  }

  onHide() {
    this.setState({
      loading: false,
      saving: false,
      stockTake: undefined,
      itemsPatch: undefined,
    });
    this.props.onHide();
  }

  onShow() {
    this.loadData();
  }

  onSave() {
    const {stockTake, itemsPatch} = this.state;
    if (itemsPatch?.length) {
      const recountItems = itemsPatch.filter(item => {
        return !item.approved && item.counts?.some(count => count.counted_qty === undefined);
      });
      if (stockTake?.stage === 'Review' && recountItems.length) {
        this.onShowStartRecountRecountDialog(recountItems.length, stockTake!.id, itemsPatch);
      } else {
        this.saveStockTake(stockTake!.id, {document: {items: itemsPatch}} as Partial<StockTake>);
      }
    } else {
      const recountItems = stockTake?.document?.items.filter(item => {
        return !item.approved && item.counts?.some(count => count.counted_qty === undefined);
      });
      if (stockTake?.stage === 'Review' && recountItems?.length) {
        this.onShowStartRecountRecountDialog(recountItems.length, stockTake!.id, stockTake!.document!.items!);
      } else {
        this.twoToast?.showInfo('No changes to save');
        this.onHide();
      }
    }
  }

  onShowStartRecountRecountDialog(
    recountItemsLength: number,
    stockTakeId: number,
    itemsPatch: StockTakeInventoryItem[]
  ) {
    const message = `There are ${recountItemsLength} items marked for recount. Do you want to start the re-count now?`;
    confirmDialog({
      message: message,
      header: 'Re-count Stock Take',
      accept: () => {
        this.saveStockTake(stockTakeId, {document: {items: itemsPatch}, stage: 'Recount'} as Partial<StockTake>);
      },
      reject: () => {
        this.saveStockTake(stockTakeId, {document: {items: itemsPatch}} as Partial<StockTake>);
      },
    });
  }

  async saveStockTake(stockTakeId: number, stockTakePatch: Partial<StockTake>) {
    this.setState({saving: true});
    try {
      await this.stockTakesService?.updateStockTake(stockTakeId, stockTakePatch);
      this.twoToast?.showSuccess('Stock take updated');
      this.onHide();
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error updating stock take');
      this.setState({saving: false});
    }
  }

  render() {
    const {showDialog, mode} = this.props;
    const {stockTake, loading, saving, itemsPatch} = this.state;

    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={this.onHide} disabled={saving || loading} />
        <Button label="Save" onClick={this.onSave} loading={saving} disabled={loading || !stockTake} />
      </div>
    );

    let content = <></>;
    if (stockTake) {
      content = (
        <div style={{minHeight: '60vh'}} className="w-100">
          <StockTakeItemList
            mode={mode}
            items={itemsPatch ?? stockTake.document?.items ?? []}
            setItems={items => this.setState(() => ({itemsPatch: items}))}
            stockTake={stockTake}
          />
        </div>
      );
    }

    const header = `${mode === 'edit' ? 'Edit' : 'Review'} Stock Take Record ${stockTake?.name ?? ''}`;

    return (
      <>
        <TwoDialog
          onHide={this.onHide}
          onShow={this.onShow}
          header={header}
          loading={loading}
          showDialog={showDialog}
          style={{width: '75vw'}}
          breakpoints={{'768px': '80vw', '576px': '90vw'}}
          draggable={false}
          footer={footer}
        >
          {content}
        </TwoDialog>
      </>
    );
  }
}
