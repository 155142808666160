export class CsvReport {
  saveCsv(fileName: string, lines: string[]) {
    const finishedLines = lines.map(line => {
      return line.endsWith('\n') ? line : line + '\n';
    });
    const element = document.createElement('a');
    const file = new Blob(finishedLines, {
      type: 'text/csv',
    });
    element.href = window.URL.createObjectURL(file);
    element.download = `${fileName}.csv`;
    document.body.appendChild(element);
    element.click();
  }
}
