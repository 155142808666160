import React from 'react';
import {InputText} from 'primereact/inputtext';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';

import {Column} from 'primereact/column';
import {DataTableSortOrderType} from 'primereact/datatable';
import {Toast} from 'primereact/toast';
import {AppContext, TwoDataTable, ToastService, TwoDialog} from 'two-app-ui';
import SuppliersService from '../../services/SuppliersService';
import {Supplier, InventoryItem, SupplyItem, DropdownOption, MapOf, QueryParameter} from 'two-core';
import InventoryService from '../../services/InventoryService';
import {toInputUppercase} from '../Inventory/Constants/Utils';
import values from '../../config/values';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import {inventoryItemCategories, inventoryItemProductLines} from '../Inventory/Constants/constants';

interface Props {
  showDialog: boolean;
  onHide: () => void;
}

interface State {
  loading: boolean;
  inventoryItems: InventoryItem[];
  increaseOption: string;
  price: number;
  unit: string;
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  totalSupplyItems: number;
  selectedSupplyItems: SupplyItem[];
  supplyItems: SupplyItem[];
  suppliers: MapOf<Supplier>;
  filters: {
    supplier: string;
    sku: string;
    product_lines: string[];
    name: string;
    category: string;
    reorder_unit: string;
    package: string;
    unit_price: string;
  };
}

class InventoryPriceChangeDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  supplierService: SuppliersService | null = null;
  inventoryService: InventoryService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      inventoryItems: [],
      selectedSupplyItems: [],
      totalSupplyItems: 0,
      increaseOption: 'increase',
      price: 0,
      unit: 'percent',
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      supplyItems: [],
      suppliers: {},
      filters: {
        supplier: '',
        sku: '',
        product_lines: [],
        name: '',
        category: '',
        reorder_unit: '',
        package: '',
        unit_price: '',
      },
    };
    this.toast = React.createRef();

    this.productLineTemplate = this.productLineTemplate.bind(this);
    this.categoryTemplate = this.categoryTemplate.bind(this);
    this.supplierTemplate = this.supplierTemplate.bind(this);
    this.loadInventoryItems = this.loadInventoryItems.bind(this);
    this.getNewSupplyItem = this.getNewSupplyItem.bind(this);
    this.save = this.save.bind(this);
    this.getSuppliersDropDownOptions = this.getSuppliersDropDownOptions.bind(this);
  }

  componentDidMount() {
    this.supplierService = this.context.supplierService;
    this.toastService = this.context.toastService;
    this.inventoryService = this.context.inventoryService;
    this.loadData();
  }

  loadData() {
    this.loadInventoryItems();
    this.loadSuppliers();
    this.setState({
      selectedSupplyItems: [],
    });
  }

  loadInventoryItems() {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'factory_id',
        value: localStorage.getItem('current factory'),
      })
    );

    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          field: 'supply_item.name',
          value: this.state.filters.name,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.supplier) {
      filters.push(
        JSON.stringify({
          field: 'supply_item.supplier_id',
          value: this.state.filters.supplier,
        })
      );
    }
    if (this.state.filters.sku) {
      filters.push(
        JSON.stringify({
          field: 'supply_item.sku',
          value: this.state.filters.sku,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.product_lines && this.state.filters.product_lines.length > 0) {
      filters.push(
        JSON.stringify({
          field: 'product_lines',
          value: this.state.filters.product_lines,
          condition: 'anyOfAny',
        })
      );
    }
    if (this.state.filters.category && this.state.filters.category.length > 0) {
      filters.push(
        JSON.stringify({
          field: 'category',
          value: this.state.filters.category,
          condition: 'in',
        })
      );
    }
    if (this.state.filters.package) {
      filters.push(
        JSON.stringify({
          field: 'supply_item.package_size_label',
          value: this.state.filters.package,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.reorder_unit) {
      filters.push(
        JSON.stringify({
          field: 'supply_item.reorder_unit',
          value: this.state.filters.reorder_unit,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.unit_price) {
      filters.push(
        JSON.stringify({
          field: 'supply_item.unit_price',
          value: this.state.filters.unit_price,
        })
      );
    }

    const sortBy = {
      field: this.state.sortBy?.field,
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    };

    const sortByStringyfied = JSON.stringify(sortBy);

    const params: QueryParameter = {
      filters: filters,
      orderBys: this.state.sortBy ? [sortByStringyfied] : [],
      aggregate: true,
    };

    this.inventoryService
      ?.getInventoryItems(params)
      .then(async data => {
        const dataRecords = (data.records as InventoryItem[]) ?? [];

        const supplyItems: SupplyItem[] = this.loadSupplyItems(dataRecords);
        this.setState({
          inventoryItems: dataRecords,
          loading: false,
          supplyItems: supplyItems,
          totalSupplyItems: supplyItems.length,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  loadSupplyItems(inventoryItems: InventoryItem[]) {
    const supplyItems: SupplyItem[] = [];
    inventoryItems.forEach((inventoryItem: InventoryItem) => {
      inventoryItem.supply_items?.forEach((supplyItem: SupplyItem) => {
        if (supplyItem) {
          supplyItems.push(supplyItem);
        }
      });
    });

    return supplyItems;
  }

  async loadSuppliers() {
    const filters = [];

    filters.push(
      JSON.stringify({
        field: 'factory_id',
        value: localStorage.getItem('current factory'),
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: false,
    };
    this.supplierService?.getSuppliers(params).then(data => {
      const suppliers: Supplier[] = (data?.records as Supplier[]) ?? [];
      const mappedSuppliers: MapOf<Supplier> = {};
      for (const supplier of suppliers) {
        mappedSuppliers[supplier.id] = supplier;
      }

      this.setState({
        suppliers: mappedSuppliers,
      });
    });
  }

  supplierTemplate(rowData: SupplyItem) {
    return Object.keys(this.state.suppliers).length !== 0 ? this.state.suppliers[rowData.supplier_id].company_name : '';
  }

  productLineTemplate(rowData: SupplyItem) {
    const invItem: InventoryItem = this.state.inventoryItems.filter(
      inventoryItem => inventoryItem.id === rowData.inventory_item_id
    )[0];
    return invItem.product_lines ? invItem.product_lines.toString() : '';
  }

  categoryTemplate(rowData: SupplyItem) {
    const invItem: InventoryItem = this.state.inventoryItems.filter(
      inventoryItem => inventoryItem.id === rowData.inventory_item_id
    )[0];
    return invItem.category ? invItem.category : '';
  }

  setChangeSelectedItems(items: SupplyItem[]) {
    this.setState({selectedSupplyItems: items});
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams | DropdownChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadInventoryItems();
  }

  getSuppliersDropDownOptions() {
    const options: DropdownOption[] = [];
    if (this.state.suppliers) {
      const suppliers = Object.values(this.state.suppliers);
      suppliers.forEach(supplier => {
        const option = {
          label: supplier.company_name,
          value: supplier.id,
        };
        options.push(option);
      });
    }
    return options;
  }

  save() {
    const {selectedSupplyItems} = this.state;
    const promises: Promise<SupplyItem>[] = [];
    if (selectedSupplyItems) {
      selectedSupplyItems.forEach(supplyItem => {
        if (supplyItem.unit_price !== undefined && supplyItem.id) {
          const newSupplyItem: SupplyItem = this.getNewSupplyItem(supplyItem);
          console.log(newSupplyItem);
          const promise = this.inventoryService?.updateSupplyItem(
            supplyItem.inventory_item_id,
            supplyItem.id,
            newSupplyItem
          );
          if (promise) {
            promises.push(promise);
          }
        }
      });
    }
    if (promises) {
      Promise.all(promises)
        .then(() => {
          this.loadData();
          this.props.onHide();
        })
        .catch(error => {
          this.toastService?.showError(this.toast, 'Sorry, Price change failed, please try again.');
          this.setState({loading: false});
          console.error('error: ' + error);
        });
    }
  }

  getNewSupplyItem(item: SupplyItem) {
    const {price, unit, increaseOption} = this.state;
    const setPrice: number = Number(price) as number;
    let itemPrice: number = Number(item.unit_price) as number;
    if (itemPrice !== undefined) {
      if (increaseOption === 'increase') {
        itemPrice = unit === 'dollars' ? itemPrice + setPrice : itemPrice + (itemPrice * setPrice) / 100;
      } else {
        itemPrice = unit === 'dollars' ? itemPrice - setPrice : itemPrice - (itemPrice * setPrice) / 100;
      }
    }
    return {...item, unit_price: itemPrice};
  }

  render() {
    const dropdownIncreaseOptions: DropdownOption[] = [
      {label: 'increase', value: 'increase'},
      {label: 'decrease', value: 'decrease'},
    ];

    const dropdownUnitOptions: DropdownOption[] = [
      {label: 'percent', value: 'percent'},
      {label: 'dollars', value: 'dollars'},
    ];

    const nameFilter = (
      <InputText
        name="name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );

    const reorderUnitFilter = (
      <InputText
        name="reorder_unit"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const packageFilter = (
      <InputText
        name="package_size_label"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const unitPriceFilter = (
      <InputText
        name="unit_price"
        className="form-filter"
        type={'number'}
        min={0}
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const prodLinesFilter = (
      <MultiSelect
        optionLabel="label"
        optionValue="value"
        options={inventoryItemProductLines}
        value={this.state.filters.product_lines}
        name="product_lines"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
      />
    );

    const categoryFilter = (
      <MultiSelect
        optionLabel="label"
        optionValue="value"
        options={inventoryItemCategories}
        value={this.state.filters.category}
        name="category"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
      />
    );

    const skuFilter = (
      <InputText
        name="sku"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );

    const supplierFilter = (
      <Dropdown
        name="supplier"
        className="form-filter"
        options={this.getSuppliersDropDownOptions()}
        optionLabel="label"
        optionValue="value"
        value={this.state.filters.supplier}
        onChange={(e: DropdownChangeParams) => {
          this.handleFilterChange(e);
        }}
      />
    );

    const {price, increaseOption, unit} = this.state;
    return (
      <TwoDialog
        className="inventory-item-dialog"
        headerTitle={'Price Change'}
        showDialog={this.props.showDialog}
        onHide={this.props.onHide}
        onSave={this.save}
        loading={this.state.loading}
        width={90}
      >
        <div className={'inventory-item-detail'}>
          <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pb-0">
            <label htmlFor="increase" className="p-col-1">
              I want to
            </label>
            <div className="p-col-2 p-p-0">
              <Dropdown
                name="increase"
                style={{width: 150}}
                options={dropdownIncreaseOptions}
                optionLabel="label"
                optionValue="value"
                value={increaseOption}
                onChange={(e: DropdownChangeParams) => this.setState({increaseOption: e.value})}
              />
            </div>
            <label htmlFor="price" className="p-col-5">
              the Unit Price of the selected Supply Items below by
            </label>
            <div className="p-col-3 p-p-0">
              <InputText
                id="price"
                name="price"
                value={price}
                type="number"
                onChange={e =>
                  this.setState({
                    price: e.target.value as unknown as number,
                  })
                }
                min={0}
              />
            </div>
            <div className="p-col-2 p-p-0">
              <Dropdown
                name="unit"
                style={{width: 150}}
                options={dropdownUnitOptions}
                optionLabel="label"
                optionValue="value"
                value={unit}
                onChange={(e: DropdownChangeParams) => this.setState({unit: e.value})}
              />
            </div>
          </div>
        </div>
        <div id="inventory_page_container" className="page-container p-mt-4">
          <TwoDataTable
            selectedItems={this.state.selectedSupplyItems}
            pageSizeIdentifier={'inventory_page_container'}
            activeFilters={{}}
            value={this.state.supplyItems}
            loading={this.state.loading}
            totalRecords={this.state.totalSupplyItems}
            rows={this.state.pagination.pageSize}
            first={this.state.pagination.offset}
            sortField={this.state.sortBy?.field}
            sortOrder={this.state.sortBy?.order}
            selectionMode="multiple"
            handleChangeSelectedItems={items => this.setChangeSelectedItems(items as SupplyItem[])}
          >
            <Column
              header="Supplier"
              field="supplier"
              filter
              sortable
              body={this.supplierTemplate}
              style={{width: '200px'}}
              showFilterMenu={false}
              filterElement={supplierFilter}
            />
            <Column
              header="Product line"
              field="product_line"
              filter
              sortable
              body={this.productLineTemplate}
              style={{width: '200px'}}
              showFilterMenu={false}
              filterElement={prodLinesFilter}
            />
            <Column
              header="Category"
              field="category"
              filter
              sortable
              body={this.categoryTemplate}
              style={{width: '200px'}}
              showFilterMenu={false}
              filterElement={categoryFilter}
            />
            <Column
              header="SKU"
              field="sku"
              filter
              sortable
              style={{width: '200px'}}
              showFilterMenu={false}
              filterElement={skuFilter}
            />
            <Column
              header="Name"
              field="name"
              filter
              sortable
              style={{width: '200px'}}
              showFilterMenu={false}
              filterElement={nameFilter}
            />
            <Column
              header="Reorder Unit"
              field="reorder_unit"
              filter
              sortable
              style={{width: '200px'}}
              showFilterMenu={false}
              filterElement={reorderUnitFilter}
            />
            <Column
              header="Package"
              field="package_size_label"
              filter
              sortable
              style={{width: '200px'}}
              showFilterMenu={false}
              filterElement={packageFilter}
            />
            <Column
              header="Unit Price"
              field="unit_price"
              filter
              sortable
              style={{width: '200px'}}
              showFilterMenu={false}
              filterElement={unitPriceFilter}
            />
          </TwoDataTable>
        </div>
      </TwoDialog>
    );
  }
}

export default InventoryPriceChangeDialog;
