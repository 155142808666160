import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiResponse, ApiListResponse, QueryParameter} from 'two-core';

class SupplyItemsService extends ApiService {
  protected endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.supplyItems ?? '';
  }

  async getSupplyItems(params: QueryParameter): Promise<ApiListResponse> {
    if (!params.filters) {
      params.filters = [];
    }

    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
}

export default SupplyItemsService;
