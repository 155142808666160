export const config = () => {
  const userService = process.env.REACT_APP_USER_SERVICE;
  const factoryService = process.env.REACT_APP_FACTORY_SERVICE;
  const orderService = process.env.REACT_APP_ORDER_SERVICE;
  const tleService = process.env.REACT_APP_TLE_SERVICE;
  const integrationService = process.env.REACT_APP_INTEGRATION_SERVICE;
  const companyService = process.env.REACT_APP_COMPANY_SERVICE;
  return {
    system: {
      badgeInterval: 60000,
      label_printer: 'http://192.168.128.184/pstprnt',
    },
    endpoints: {
      users: userService + 'users',
      orders: orderService + 'orders',
      factoryOrders: factoryService + 'factory-orders',
      factoryOrdersSizeSum: orderService + 'factory-orders-size-sum',
      groupOrders: orderService + 'groupOrders',
      alarms: factoryService + 'alarms',
      tles: tleService + 'tles',
      factoryTles: factoryService + 'factory-tles',
      factories: factoryService + 'factories',
      oos: factoryService + 'oos',
      //      parts: baseEndpoint + 'parts',
      inventory: factoryService + 'inventory',
      cuttingSheets: factoryService + 'cutsheets',
      shippingLabels: factoryService + 'shippinglabels',
      purchaseOrders: factoryService + 'purchase-orders',
      suppliers: factoryService + 'suppliers',
      supplyItems: factoryService + 'supply-items',
      inventoryItems: factoryService + 'inventory-items',
      inventoryItemsUpdateStock: factoryService + 'inventory-update-stock',
      inventoryItemsUpdateStockMulti: factoryService + 'inventory-items-stock',
      inventoryItemsUpdateSupplyPoMulti: factoryService + 'inventory-update-po-multi',
      boms: factoryService + 'boms',
      inventoryMove: factoryService + 'inventory-move',
      shipmentItems: factoryService + 'shipments',
      integration: integrationService + 'integrations',
      integrationOrders: integrationService + 'orders',
      companies: companyService + 'companies',
      stockTakes: factoryService + 'stock-takes',
    },
    aws: {
      proofBucket: process.env.REACT_APP_FILES_PROOF_BUCKET_NAME,
    },
  };
};
export default config;
