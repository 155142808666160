import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {AppContext, TwoAction, TwoEntityComponent, TwoEntityPanel, TwoTimeline, TwoTimelineItem} from 'two-app-ui';
import {FactoryOrderPatch, Oos, OosPatch, OosStage, Order, QueryParameter, TimeLineEvent} from 'two-core';
import TleService from '../../services/TleService';
import {Subscription} from 'rxjs';
import {MessageService} from 'two-app-ui';
import {messages} from '../../config/messages';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCalendarAlt, faPencil, faList, faArrowCircleRight} from '@fortawesome/pro-regular-svg-icons';
import {Toast} from 'primereact/toast';
import OosService from '../../services/OosService';
import OosDetail from './OosDetail';
import {ProgressSpinner} from 'primereact/progressspinner';
import OosOrders from './OosOrders';
import OosEdit from './OosEdit';
import SetEtaDialog from '../Oos/SetEtaDialog';
import FactoryOrdersService from '../../services/FactoryOrdersService';
import OrdersService from '../../services/OrdersService';

library.add(faCalendarAlt, faPencil, faList, faArrowCircleRight);

interface RouteProps {
  id: string;
}

interface State {
  loadingOOs: boolean;
  loadingSecondaryView: boolean;
  oos: Oos | undefined;
  events: TimeLineEvent[];
  items: TwoTimelineItem[];
  showEditDialog: boolean;
  showSetEtaDialog: boolean;
}

class OosComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  tleService: TleService | null = null;
  oosService: OosService | null = null;
  ordersService: OrdersService | null = null;
  factoryOrdersService: FactoryOrdersService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingOOs: false,
      loadingSecondaryView: false,
      oos: undefined,
      events: [],
      items: [],
      showEditDialog: false,
      showSetEtaDialog: false,
    };

    this.toast = React.createRef();
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    this.tleService = this.context.tleService;
    this.oosService = this.context.oosService;
    this.factoryOrdersService = this.context.factoryOrdersService;
    this.ordersService = this.context.ordersService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.oosUpdated) {
        this.loadOos(id);
      }
    });

    this.loadOos(id);
    this.loadEvents(id);
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.messageSendSubscription.unsubscribe();
  }

  loadOos(id: string) {
    this.setState({loadingOOs: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.oosService
      ?.getOos(params)
      .then(data => {
        const oos = (data.records as Oos[])[0];
        this.setState({
          oos: oos,
          loadingOOs: false,
        });
      })
      .catch(error => {
        this.toast.current?.show({
          contentClassName: '',
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, records load failed, please try again.',
          life: 3000,
        });
        console.error(error);
        this.setState({
          loadingOOs: false,
        });
      });
  }

  loadEvents(id: string) {
    this.setState({loadingSecondaryView: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'out_of_stock',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    this.tleService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];

        const items = events.map(event => {
          const item: TwoTimelineItem = {event: event};
          return item;
        });

        this.setState({
          events: events,
          loadingSecondaryView: false,
          items: items,
        });
      })
      .catch(() => {
        this.toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, order events load failed, please try again.',
          life: 3000,
          contentClassName: '',
        });
        this.setState({loadingSecondaryView: false});
      });
  }

  async stageChange(stage: OosStage) {
    const oos = this.state.oos;
    if (oos) {
      const oosPatch: OosPatch = {
        stage: stage,
      };
      return this.oosService
        ?.updateOos(oos.id?.toString() ?? '', oosPatch)
        .then(() => {
          this.toast.current?.show({
            contentClassName: '',
            severity: 'success',
            summary: 'Success',
            detail: 'Stage changed',
            life: 3000,
          });
          MessageService.sendMessage(messages.oosUpdated);

          if (stage === 'Available') {
            this.loadAndMoveOrders();
          }
        })
        .catch(error => {
          console.error('error: ' + error);
          this.toast.current?.show({
            contentClassName: '',
            severity: 'error',
            summary: 'Error',
            detail: 'Stage was not changed',
            life: 3000,
          });
        });
    } else {
      this.toast.current?.show({
        contentClassName: '',
        severity: 'error',
        summary: 'Error',
        detail: 'Stage was not changed',
        life: 3000,
      });
    }
  }

  loadAndMoveOrders() {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'oos.id',
        value: this.state.oos?.id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.ordersService
      ?.getOrders(params)
      .then(data => {
        const orders = data.records as Order[];
        this.moveOrdersAfterAvailableStage(orders);
      })
      .catch(error => {
        console.error(error);
      });
  }

  moveOrdersAfterAvailableStage(orders: Order[]) {
    const updateOrders: Order[] = [];
    orders.map(order => {
      const pos = order.purchase_orders?.filter(po => po !== null && po.stage !== 'Delivered') ?? [];
      const oos = order.out_of_stocks?.filter(oos => oos !== null && oos.stage !== 'Available') ?? [];

      if (pos.length === 0 && oos.length === 0) {
        updateOrders.push(order);
      }
    });

    const newStage = 'Ready';
    const factoryOrderPatch: FactoryOrderPatch = {
      production_stage: newStage,
    };

    const promises = updateOrders.map(order => {
      return this.factoryOrdersService?.updateFactoryOrder(order.id ?? '', factoryOrderPatch);
    });

    Promise.all(promises)
      .then(() => {
        MessageService.sendMessage(messages.oosOrdersReassigned);
        this.toast.current?.show({
          contentClassName: '',
          severity: 'success',
          summary: 'Success',
          detail: `Order Stage to ${newStage} updated successfully.`,
          life: 3000,
        });
      })
      .catch(error => {
        this.toast.current?.show({
          contentClassName: '',
          severity: 'error',
          summary: 'Error',
          detail: `Sorry, Order Stage update to ${newStage} failed.`,
          life: 3000,
        });
        console.error(error);
      });
  }

  getActions(oos: Oos): TwoAction[] {
    const actions: TwoAction[] = [];
    const stage = oos.stage;

    const editAction = {
      icon: faPencil,
      label: 'Edit',
      main: true,
      action: () => {
        this.setState({showEditDialog: true});
      },
    };

    const availableStageAction = {
      label: 'Available',
      icon: faArrowCircleRight,
      action: () => {
        this.stageChange('Available');
      },
    };

    const delayedStageAction = {
      label: 'Delayed',
      icon: faArrowCircleRight,
      action: () => {
        this.stageChange('Delayed');
      },
    };

    const confimedStageAction = {
      label: 'Eta Confirmed',
      icon: faArrowCircleRight,
      action: () => {
        this.setState({showSetEtaDialog: true});
      },
    };

    actions.push(editAction);
    if (stage === 'Delayed' || stage === 'Eta Not Confirmed' || stage === 'Eta Confirmed') {
      actions.push(availableStageAction);
    }

    if (stage === 'Eta Confirmed' || stage === 'Eta Not Confirmed') {
      actions.push(delayedStageAction);
    }

    if (stage === 'Eta Not Confirmed') {
      actions.push(confimedStageAction);
    }

    return actions;
  }

  render() {
    const {oos, items} = this.state;

    return oos ? (
      <>
        <TwoEntityComponent title={oos.inventory_item?.name ?? ''} actions={this.getActions(oos)}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loadingOOs ? <OosDetail oos={oos} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Orders" icon={faList} tooltip="Orders">
            {!this.state.loadingSecondaryView ? <OosOrders toast={this.toast} oos={oos} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            {!this.state.loadingSecondaryView ? <TwoTimeline key={oos.id} items={items} /> : <ProgressSpinner />}
          </TwoEntityPanel>
        </TwoEntityComponent>
        <Toast ref={this.toast} />
        <OosEdit
          toast={this.toast}
          oos={oos}
          showDialog={this.state.showEditDialog}
          onHide={() => this.setState({showEditDialog: false})}
        />
        <SetEtaDialog
          showDialog={this.state.showSetEtaDialog}
          onHide={() => {
            this.setState({showSetEtaDialog: false});
          }}
          oosRecords={[oos]}
          toast={this.toast}
        />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(OosComponent);
