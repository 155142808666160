import React from 'react';
import CVAssembly from './Colourvue/CVAssembly';
import CVFabric from './Colourvue/CVFabric';
import CVSaw from './Colourvue/CVSaw';
import CVQualityCheck from './Colourvue/CVQualityCheck';
import SSAssembly from './Shadesol/SSAssembly';
import SSFabric from './Shadesol/SSFabric';
import SSSaw from './Shadesol/SSSaw';
import SSPack from './Shadesol/SSPack';
import SSPrePowder from './Shadesol/SSPrePowder';
import {Subscription} from 'rxjs';
import {MessageService} from 'two-app-ui';
import './cutting-sheets.scss';
import CVSaw_v2 from './Colourvue/CVSaw_v2';
import CVFabric_v2 from './Colourvue/CVFabric_v2';
import CVAssembly_v2 from './Colourvue/CVAssembly_v2';
import CVQualityCheck_v2 from './Colourvue/CVQualityCheck_v2';
import CVPack_v2 from './Colourvue/CVPack_v2';
import CVSaw_v3 from './Colourvue/CVSaw_v3';
import CVFabric_v3 from './Colourvue/CVFabric_v3';
import CVAssQcPack_v3 from './Colourvue/CVAssQcPack_v3';
import {CutSheetReport, FactoryCapabilitiesCurtains, FactoryChainItem} from 'two-core';
import CUSaw_v1 from './Curtains/CUSaw_v1';
import CUAssembly_v1 from './Curtains/CUAssembly_v1';
import CUQcPack_v1 from './Curtains/CUQcPack_v1';
import CUFabric_v1 from './Curtains/CUFabric_v1';

interface State {
  printableData: any[] | null;
}

interface CuttingSheetProps {
  type?: string;
  data: CutSheetReport[] |undefined;
  prePowderOnly?: boolean;
}

class CuttingSheets extends React.Component<CuttingSheetProps, State> {
  subscription: Subscription = new Subscription();
  printFrame: any;
  constructor(props: CuttingSheetProps) {
    super(props);
    this.state = {
      printableData: null,
    };
    this.printFrame = React.createRef();
  }
  async componentDidMount() {
    this.subscription = MessageService.getMessage().subscribe(message => {
      if ('print-cutting-sheets' === message) {
        this.print();
      }
    });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  print() {
    const content = document.getElementById('print-area');
    const pri = this.printFrame.current;

    pri.contentDocument.open();
    pri.contentDocument.write(content?.innerHTML);
    pri.contentDocument.close();

    //const style = document.createElement("style");

    const headElements = document.querySelectorAll("style, link[rel='stylesheet']");

    for (let i = 0, headElementsLen = headElements.length; i < headElementsLen; ++i) {
      const node = headElements[i];
      if (node.tagName === 'STYLE' || node.tagName === 'LINK') {
        const newHeadEl = pri.contentDocument.createElement('style');
        const sheet = (node as HTMLStyleElement).sheet as CSSStyleSheet;

        if (sheet) {
          let styleCSS = '';

          for (let j = 0, cssLen = sheet.cssRules.length; j < cssLen; ++j) {
            const rule = sheet.cssRules[j] as CSSStyleRule;
            if (typeof rule.cssText === 'string') {
              if (rule.selectorText && rule.selectorText.indexOf('cut-sheet') >= 0) {
                styleCSS += `${sheet.cssRules[j].cssText} \r\n`;
              }
            }
          }
          newHeadEl.setAttribute('id', `react - to - print - ${i} `);
          newHeadEl.appendChild(pri.contentDocument.createTextNode(styleCSS));
          pri.contentDocument.head.appendChild(newHeadEl);
        }
      }
    }

    pri.focus();
    pri.contentWindow.print();
    //this.setState({ printableData: null });
  }

  render() {
    const sheets: JSX.Element[] = [];

    this.props.data?.map((data) => {
      if (data.production_line === 'Shadesol') {
        if (this.props.prePowderOnly) {
          sheets.push(
            <React.Fragment>{data.prepowder.items.length > 0 ? <SSPrePowder data={data} /> : ''}</React.Fragment>
          );
        } else {
          sheets.push(
            <React.Fragment>
              {data.prepowder.items.length > 0 ? <SSPrePowder data={data} /> : ''}
              <SSSaw data={data} />
              <SSFabric data={data} />
              <SSAssembly data={data} />
              <SSPack data={data} />
            </React.Fragment>
          );
        }
      } else if (data.production_line === 'Colourvue') {
        switch (data.version) {
          case 1:
            sheets.push(
              <React.Fragment>
                <CVSaw data={data} />
                <CVFabric data={data} />
                <CVAssembly data={data} />
                <CVQualityCheck data={data} />
              </React.Fragment>
            );
            break;
          case 2:
            sheets.push(
              <React.Fragment>
                <CVSaw_v2 data={data} />
                <CVFabric_v2 data={data} />
                <CVAssembly_v2 data={data} />
                <CVQualityCheck_v2 data={data} />
                <CVPack_v2 data={data} />
              </React.Fragment>
            );
            break;
          case 3:
            sheets.push(
              <React.Fragment>
                <CVSaw_v3 data={data} />
                <CVFabric_v3 data={data} />
                <CVAssQcPack_v3 data={data} />
                {/*<CVAssembly_v3 data={data} />*/}
                {/*<CVQualityCheck_v3 data={data} />*/}
                {/*<CVPack_v3 data={data} />*/}
              </React.Fragment>
            );
            break;
        }
      } else if (data.production_line === 'Curtains') {
        let currentFactoryChainItem: FactoryChainItem | undefined;
        if (data.factory_chain) {
          const currentFactoryId = localStorage.getItem('current factory') ?? '';
          currentFactoryChainItem = data.factory_chain[currentFactoryId];
        }
        let toDo: FactoryCapabilitiesCurtains = 'all';
        if (currentFactoryChainItem && currentFactoryChainItem.to_perform) {
          toDo = currentFactoryChainItem.to_perform;
        }
        sheets.push(
          <React.Fragment>
            {(toDo === 'all' || toDo === 'track-only') && data.saw?.items?.length ? <CUSaw_v1 data={data} /> : ''}
            {(toDo === 'all' || toDo === 'fabric-only') && data.fabric?.items?.length ? <CUFabric_v1 data={data} /> : ''}
            {(toDo === 'all' || toDo === 'track-only') && data.assembly?.items?.length ? <CUAssembly_v1 data={data} /> : ''}
            {(toDo === 'all' || toDo === 'track-only') && data.qc?.items?.length ? <CUQcPack_v1 data={data} /> : ''}
          </React.Fragment>
        );
      }
    });

    return (
      <React.Fragment>
        <div id="print-area">
          {sheets?.map((sheet: JSX.Element) => {
            return sheet;
          })}
        </div>
        <iframe title="print" id="ifmcontentstoprint" ref={this.printFrame} />
      </React.Fragment>
    );
  }
}
export default CuttingSheets;
