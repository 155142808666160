import {
  CutSheetReport,
  FloorTaskContentFabric,
  FloorTaskContentSaw,
  FloorTaskContentSawCurtainV1,
  MapOf,
  PrePowderItem,
  SawExtra,
} from 'two-core';
import {ProductionLabelMaker} from '../ProductionLabelMaker';
import {ProductionLabelData} from '../ProductionLabelData';

export function mapToCurtainLabel(cuCutSheet: CutSheetReport): ProductionLabelData[] {
  const result: ProductionLabelData[] = [];
  const labelMaker = new ProductionLabelMaker(
    'CV CURTAIN',
    '???',
    cuCutSheet.production_bay?.toString() ?? '?',
    cuCutSheet.order_id,
    cuCutSheet.reference,
    '???',
    '???'
  );
  let lastItemIndex = 0;
  let lastCurtainIndex = 0;
  let curtainIndex = 0;

  // ----- SAW
  const orderedItems = cuCutSheet.saw.items.sort(
    (a, b) =>
      a.order_item_index * 100 +
      (a.content as FloorTaskContentSawCurtainV1).curtain_index -
      (b.order_item_index * 100 + (b.content as FloorTaskContentSawCurtainV1).curtain_index)
  ) as any[];
  for (const item of orderedItems) {
    if (lastItemIndex !== item.order_item_index) {
      lastItemIndex = item.order_item_index;
      lastCurtainIndex = 0;
    }
    if (item.category !== 'note' && item.category !== 'repair') {
      const sawContent = item.content as FloorTaskContentSawCurtainV1;
      if (lastCurtainIndex !== sawContent.curtain_index) {
        lastCurtainIndex = sawContent.curtain_index;
        curtainIndex++;
        labelMaker.index = `CURTAIN #${curtainIndex}`;
      }
      result.push(
        labelMaker.makeLabelTwoLines(
          sawContent.type,
          sawContent.colour,
          '',
          `${sawContent.length}`,
          item.id ?? 0,
          'TRACK'
        )
      );
    }
  }

  result.push(labelMaker.makePhotoLabel());

  return result;
}
