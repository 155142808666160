import React, {useContext, useState} from 'react';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  TwoDataTable,
  getTwoDateFormat,
} from 'two-app-ui';
import {InventoryItem, StockTake, StockTakeCount, StockTakeInventoryItem, StockTakeInventoryItemStage} from 'two-core';
import {DateTime} from 'luxon';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {DataTableSortParams} from 'primereact/datatable';
import {MultiSelect} from 'primereact/multiselect';
import {MenuItemOptions} from 'primereact/menuitem';
import {
  faArrowRotateRight,
  faCheck,
  faPencil,
  faPlus,
  faToggleOff,
  faToggleOn,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import {confirmDialog} from 'primereact/confirmdialog';
import './StockTakeItemList.scss';
import {toInputUppercase} from '../../Inventory/Constants/Utils';
import {inventoryItemCategories} from '../../Inventory/Constants/constants';
import {stockTakeItemStageOptions} from '../../../utils/StockTakeUtil';
import AddStockTakeItemsDialog from '../AddStockTakeItemsDialog/AddStockTakeItemsDialog';
import EditStockTakeItemDialog from '../EditStockTakeItemDialog';

export type StockTakeItemListMode = 'readonly' | 'edit' | 'review';
interface Props {
  stockTake?: StockTake;
  items: StockTakeInventoryItem[];
  mode: StockTakeItemListMode;
  setItems?: (items: StockTakeInventoryItem[]) => void;
}

export const StockTakeItemList = ({items, mode, setItems, stockTake}: Props) => {
  const context = useContext(AppContext);

  const [expandedRows, setExpandedRows] = useState<StockTakeInventoryItem[]>([]);
  const [rows, setRows] = useState<number>(10);
  const [first, setFirst] = useState<number>(0);
  const [filters, setFilters] = useState<{[key: string]: string | number | string[]}>({});
  const [sort, setSort] = useState<DataTableSortParams | undefined>(undefined);
  const [selectedItems, setSelectedItems] = useState<StockTakeInventoryItem[]>([]);
  const [showAddItemsDialog, setShowAddItemsDialog] = useState(false);
  const [showEditStockTakeItemDialog, setShowEditStockTakeItemDialog] = useState(false);
  const [showApprovedItems, setShowApprovedItems] = useState(false);
  const onFilterChange = (name: string, value: string | number | string[]) => {
    setFilters({
      ...filters,
      [name]: value,
    });
    setSelectedItems([]);
  };

  const onAddStockTakeItems = (invItems: InventoryItem[]) => {
    const newItems: StockTakeInventoryItem[] = invItems.map(item => {
      return {
        id: item.id!,
        name: item.name,
        stage: 'New',
        colour: item.colour ?? '',
        category: item.category,
        uom: item.uom,
        sku: item.sku ?? '',
        approved: false,
        counts: [{index: 0}],
      };
    });
    setItems?.([...items, ...newItems]);
  };

  const onHideEditStockTakeItemDialog = () => {
    setShowEditStockTakeItemDialog(false);
    setSelectedItems([]);
  };

  const onSaveEditStockTakeItemDialog = (newCount: number, stockTakeInventoryItem: StockTakeInventoryItem) => {
    const updatedItems: StockTakeInventoryItem[] = items.map(item => {
      if (item.id === stockTakeInventoryItem.id) {
        const maxIndex = item.counts?.reduce((max, count) => (count.index > max ? count.index : max), 0) ?? 0;
        const lastCount = item.counts?.find(count => count.index === maxIndex);
        if (lastCount) {
          lastCount.counted_qty = newCount;
          item.stage = 'Counted';
        }
      }
      return item;
    });
    setItems?.(updatedItems);
  };

  const initMenuItems = (isHeader: boolean): AppMenuItem[] => {
    const menuItems: AppMenuItem[] = [];

    if (mode !== 'readonly' && isHeader) {
      menuItems.push({
        label: 'Add New',
        faIcon: faPlus,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          setShowAddItemsDialog(true);
        },
      });
    }

    if (selectedItems.length) {
      if (mode === 'review') {
        if (selectedItems.every(item => item.stage === 'Counted')) {
          menuItems.push({
            label: `Approve (${selectedItems.length})`,
            faIcon: faCheck,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              const selectedItemsIds = selectedItems.map(item => item.id);
              const updatedItems: StockTakeInventoryItem[] = items.map(item => {
                if (selectedItemsIds.includes(item.id)) {
                  item.approved = true;
                  item.stage = 'Approved';
                }
                return item;
              });
              setItems?.(updatedItems);
              setSelectedItems([]);
            },
          });
          menuItems.push({
            label: `Re-count (${selectedItems.length})`,
            faIcon: faArrowRotateRight,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => {
              const selectedItemsIds = selectedItems.map(item => item.id);
              const updatedItems: StockTakeInventoryItem[] = items.map(item => {
                if (selectedItemsIds.includes(item.id)) {
                  const maxIndex = item.counts?.reduce((max, count) => (count.index > max ? count.index : max), 0) ?? 0;
                  item.counts?.push({index: maxIndex + 1});
                  item.approved = false;
                  item.stage = 'Re-Count';
                }
                return item;
              });
              setItems?.(updatedItems);
              setSelectedItems([]);
            },
          });
        }
        if (selectedItems.length === 1) {
          menuItems.push({
            label: 'Manual Count',
            faIcon: faPencil,
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
            command: () => setShowEditStockTakeItemDialog(true),
          });
        }
      }
      menuItems.push({
        label: 'Remove',
        faIcon: faTrashAlt,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          const header = `Remove Items from Stock Take ${stockTake?.name ?? ''}`;
          const messages = [];
          if (selectedItems.length === 1) {
            messages.push(
              `You have selected to remove ‘${selectedItems[0].name} ${selectedItems[0].colour}’ from the stock take ${stockTake?.name}.`
            );
            if (selectedItems[0].counts?.at(-1)?.counted_qty) {
              messages.push('It has already been counted.');
            }
            if (selectedItems[0].approved) {
              messages.push('It has already been approved.');
            }
          } else {
            messages.push(
              `You have selected to remove ${selectedItems.length} items from the stock take ${stockTake?.name}.`
            );
            const countedItems = selectedItems.filter(item => item.counts?.at(-1)?.counted_qty);
            if (countedItems.length) {
              messages.push(`${countedItems.length} of them have already been counted.`);
            }
            const approvedItems = selectedItems.filter(item => item.approved);
            if (approvedItems.length) {
              messages.push(`${approvedItems.length} of them have already been approved.`);
            }
          }
          messages.push('Are you sure you want to continue?');
          confirmDialog({
            message: (
              <div>
                {messages.map((message, index) => (
                  <div key={index}>{message}</div>
                ))}
              </div>
            ),
            header: header,
            accept: () => {
              setItems?.([...items.filter(item => !selectedItems.includes(item))]);
              setSelectedItems([]);
            },
            className: 'remove-confirmation-dialog',
          });
        },
      });
    }
    if (isHeader && stockTake?.stage === 'Review') {
      if (showApprovedItems) {
        menuItems.push({
          label: 'Hide Approved',
          faIcon: faToggleOff,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => setShowApprovedItems(false),
        });
      } else {
        menuItems.push({
          label: 'Show Approved',
          faIcon: faToggleOn,
          template: (item: AppMenuItem, options: MenuItemOptions) => {
            return <AppMenuItemTemplate item={item} options={options} />;
          },
          command: () => setShowApprovedItems(true),
        });
      }
    }

    return menuItems;
  };

  const positionBody = (rowData: StockTakeInventoryItem) => {
    const position = rowData.position;
    if (!position) return '';
    return `${position.section}/${position.rack}/${position.shelve}/${position.bay}`;
  };

  const stockLevelBody = (rowData: StockTakeInventoryItem) => {
    const lastCount = rowData.counts?.at(-1);
    return lastCount?.system_stock_level ?? '';
  };

  const countedBody = (rowData: StockTakeInventoryItem) => {
    const lastCount = rowData.counts?.at(-1);
    return lastCount?.counted_qty ?? '';
  };

  const diffBody = (rowData: StockTakeInventoryItem) => {
    const lastCount = rowData.counts?.at(-1);
    if (!lastCount?.counted_qty || !lastCount?.system_stock_level) return '';
    return (lastCount?.counted_qty ?? 0) - (lastCount?.system_stock_level ?? 0);
  };

  const diffPercentageBody = (rowData: StockTakeInventoryItem) => {
    const lastCount = rowData.counts?.at(-1);
    if (!lastCount?.counted_qty || !lastCount?.system_stock_level) return '';
    const diff = (lastCount?.counted_qty ?? 0) - (lastCount?.system_stock_level ?? 0);
    return Math.round((diff / (lastCount?.system_stock_level ?? 0)) * 100) + '%';
  };
  const stageBody = (rowData: StockTakeInventoryItem) => {
    return (
      <span
        className={`stock-take-item-stage-badge stock-take-item-stage-${(rowData?.stage ?? '').toLowerCase().replaceAll(' ', '-')}`}
      >
        {rowData?.stage}
      </span>
    );
  };

  const countedAtBody = (rowData: StockTakeCount) => {
    const dateFormat = context.usersService?.settings?.date_format;
    return rowData.counted_at
      ? DateTime.fromISO(rowData.counted_at.toString()).toFormat(getTwoDateFormat(dateFormat, 'dateTime'))
      : '';
  };
  const rowExpansionTemplate = (rowData: StockTakeInventoryItem) => {
    return (
      <div className="w-100">
        <TwoDataTable selectedItems={[]} activeFilters={[]} value={rowData.counts} showPaging={false} hideFilter>
          <Column header="Count" field="index" className="col-s" />
          <Column header="Counted" field="counted_qty" className="col-s" />
          <Column header="Counted At" body={countedAtBody} className="col-l" />
          <Column header="By" field="counted_by.label" />
        </TwoDataTable>
      </div>
    );
  };

  const nameBody = (rowData: StockTakeInventoryItem) => {
    return (
      <AppColumnMenuBodyTemplate
        key={rowData.id}
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={mode !== 'readonly' ? () => initMenuItems(false) : undefined}
        selectedItems={selectedItems}
        handleChangeSelectedItems={async () =>
          setSelectedItems(
            !selectedItems.some(item => item.id === rowData.id) ? [...selectedItems, rowData] : selectedItems
          )
        }
      >
        {rowData.name}
      </AppColumnMenuBodyTemplate>
    );
  };

  const nameFilter = (
    <InputText
      className="form-filter"
      onChange={e => onFilterChange('name', e.target.value)}
      onInput={toInputUppercase}
    />
  );
  const colourFilter = (
    <InputText
      className="form-filter"
      onChange={e => onFilterChange('colour', e.target.value)}
      onInput={toInputUppercase}
    />
  );
  const categoryFilter = (
    <MultiSelect
      optionLabel="label"
      optionValue="value"
      options={inventoryItemCategories}
      value={filters.category}
      className="form-filter"
      onChange={e => onFilterChange('category', e.target.value)}
    />
  );
  const stageFilter = (
    <MultiSelect
      optionLabel="label"
      optionValue="value"
      options={stockTakeItemStageOptions}
      value={filters.stage}
      className="form-filter"
      onChange={e => onFilterChange('stage', e.target.value)}
    />
  );

  const hideHeaderActions = !['edit', 'review'].includes(mode);

  const assignedInvItemsIds = items.map(item => item.id);

  const filteredItems = items.filter(item => {
    if (stockTake?.stage === 'Review' && !showApprovedItems && item.approved) {
      return false;
    }
    for (const [name, value] of Array.from(Object.entries(filters))) {
      if (name === 'name' && (value as string)?.length) {
        if (!item.name.toUpperCase().includes((value as string).toUpperCase())) {
          return false;
        }
      }
      if (name === 'colour' && (value as string)?.length) {
        if (!item.colour.toUpperCase().includes((value as string).toUpperCase())) {
          return false;
        }
      }
      if (name === 'category' && (value as string[])?.length) {
        if (!(value as string[]).includes(item.category)) {
          return false;
        }
      }
      if (name === 'stage' && (value as string[])?.length) {
        if (!(value as StockTakeInventoryItemStage[]).includes(item.stage)) {
          return false;
        }
      }
    }
    return true;
  });
  filteredItems.sort((a, b) => {
    if (sort?.sortField === 'name') {
      return a.name.localeCompare(b.name) * (sort.sortOrder ?? 1);
    }
    if (sort?.sortField === 'colour') {
      return a.colour.localeCompare(b.colour) * (sort.sortOrder ?? 1);
    }
    if (sort?.sortField === 'category') {
      return a.category.localeCompare(b.category) * (sort.sortOrder ?? 1);
    }
    return 0;
  });
  const rowsData = filteredItems.slice(first, first + rows);

  return (
    <div id="stock_take_item_list_container" className="w-100 h-100">
      <TwoDataTable
        pageSizeIdentifier="stock_take_item_list_container"
        selectedItems={selectedItems}
        activeFilters={[]}
        value={rowsData}
        // showPaging={false}
        rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRows}
        onRowToggle={e => {
          setExpandedRows(e.data);
        }}
        hideFilter={hideHeaderActions}
        rows={rows}
        first={first}
        totalRecords={filteredItems.length}
        onPage={(e: {first: number; rows: number}) => {
          setFirst(e.first);
          setRows(e.rows);
        }}
        onSort={e => {
          setSort(e);
          setSelectedItems([]);
        }}
        sortField={sort?.sortField}
        sortOrder={sort?.sortOrder}
        selectionMode={!hideHeaderActions ? 'multiple' : undefined}
        handleChangeSelectedItems={items => setSelectedItems(items as StockTakeInventoryItem[])}
        initMenuItems={() => initMenuItems(true)}
        isMenuDynamic
      >
        <Column expander className={'table-expander'} bodyClassName={'table-expander'} />
        <Column
          header="Name"
          field="name"
          body={nameBody}
          filter
          filterElement={nameFilter}
          sortable={!hideHeaderActions}
          showFilterMenu={false}
          className="col-min-xxl"
        />
        <Column
          header="Colour"
          field="colour"
          filter
          filterElement={colourFilter}
          sortable={!hideHeaderActions}
          showFilterMenu={false}
          className="col-min-l"
        />
        <Column
          header="Category"
          field="category"
          filter
          filterElement={categoryFilter}
          sortable={!hideHeaderActions}
          showFilterMenu={false}
          className="col-l"
        />
        <Column header="Position [s/r/s/b]" body={positionBody} className={'col-min-l col-max-xl'} />
        <Column header="Units" field="uom" className="col-m" />
        <Column header="Stock Level" body={stockLevelBody} className="col-m" />
        <Column header="Counted" body={countedBody} className="col-min-s col-max-m" />
        <Column header="Diff" body={diffBody} className="col-s" />
        <Column header="Diff [%]" body={diffPercentageBody} className="col-s" />
        <Column
          header="Stage"
          body={stageBody}
          filter
          filterElement={stageFilter}
          showFilterMenu={false}
          className="col-m"
        />
      </TwoDataTable>
      <AddStockTakeItemsDialog
        showDialog={showAddItemsDialog}
        onHide={() => setShowAddItemsDialog(false)}
        addStockTakeItems={onAddStockTakeItems}
        assignedInvItemsIds={assignedInvItemsIds}
      />
      {showEditStockTakeItemDialog && selectedItems[0] && (
        <EditStockTakeItemDialog
          showDialog={showEditStockTakeItemDialog}
          onHide={onHideEditStockTakeItemDialog}
          onSave={onSaveEditStockTakeItemDialog}
          item={selectedItems[0]}
        />
      )}
    </div>
  );
};
