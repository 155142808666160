import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';

interface Props {
  data: any;
}

//interface State {}

class CVQualityCheck extends CutSheetTemplate {
  report: Report;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      2,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">cv std v2</td>
              <td className="text">
                <b>QC & PACK</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text" colSpan={3}>
                {this.props.data.reference}
              </td>
              <td className="label">shipping to</td>
              <td className="text">{this.props.data.ship_to}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      /* DOUBLE BRACKET WARNING */
    }
    const doubleBracketItems: string[] = [];
    for (const item of this.props.data.qc.items) {
      if (item.content.brackets && `${item.content.brackets}`.includes('db')) {
        doubleBracketItems.push(item.order_item_index);
      }
    }
    if (doubleBracketItems.length > 0) {
      this.report.addItem(
        this.props.data,
        1,
        <React.Fragment>
          <div className="text">{`DOUBLE BRACKETS !!! On items: ${doubleBracketItems.join(', ')}`}</div>
        </React.Fragment>
      );
    }

    {
      /* ITEMS */
    }
    {
      this.props.data.qc.items.map((item: any) => {
        let currentLineCount = 5;
        let easyLink;
        let cover;
        let channel;
        let wire, wires;
        let note;
        let dual;
        let brackets;
        let repair1, repair2, repair3;

        if (item.content.repair_respo) {
          repair1 = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">rework</td>
              <td className="text">{item.content.repair_whole_item ? 'YES' : 'NO'}</td>
              <td className="label">type</td>
              <td className="text">{this.capitaliseFirst(item.content.repair_type)}</td>
              <td className="label">responsibility</td>
              <td className="text" colSpan={2}>
                {this.capitaliseFirst(item.content.repair_respo)}
              </td>
            </tr>
          );
          if (item.content.repair_type === 'cutdown (standard)') {
            repair2 = (
              <tr>
                <td />
                <td className="label">new height</td>
                <td className="number">{item.content.new_height}</td>
                <td className="label">new width</td>
                <td className="number" colSpan={3}>
                  {item.content.new_width}
                </td>
                <td className="sign-off">check</td>
              </tr>
            );
          } else {
            repair2 = '';
          }
          repair3 = (
            <tr>
              <td />
              <td className="label">repair detail</td>
              <td className="text" colSpan={5}>
                {item.content.repair_details}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += 3;
        } else {
          repair1 = repair2 = repair3 = '';
        }

        if (item.content.el) {
          easyLink = (
            <tr className="inside-row">
              <td className="no-top-border" />
              <td className="label no-top-border">easy link</td>
              <td className="text">YES</td>
              <td className="label">type</td>
              <td className="text">{this.capitaliseFirst(item.content.el_type)}</td>
              <td className="label">linked to</td>
              <td className="text">{this.capitaliseFirst(item.content.linked_to)}</td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount++;
        } else {
          easyLink = '';
        }

        if (item.content.dual_blind) {
          dual = (
            <tr className="inside-row">
              <td className="no-top-border" />
              <td className="label no-top-border">dual blind</td>
              <td className="text" colSpan={3}>
                YES
              </td>
              <td className="label">pairs with</td>
              <td className="text">{item.content.dual_blind_pair}</td>
              {/*<td className="label">bracket</td>*/}
              {/*<td className="text">{item.content.dual_blind_bracket}</td>*/}
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount++;
        } else {
          dual = '';
        }

        if (item.content.brackets) {
          brackets = (
            <tr className="inside-row">
              <td className="no-top-border" />
              <td className="label no-top-border">brackets</td>
              <td className="text" colSpan={5}>
                {this.capitaliseFirst(item.content.brackets)}
              </td>
              <td className="sign-off">packed</td>
            </tr>
          );
          currentLineCount++;
        } else {
          brackets = '';
        }

        if (item.content.cover_type) {
          cover = (
            <tr className="inside-row">
              <td className="no-top-border" />
              <td className="label no-top-border">cover</td>
              <td className="text">{this.capitaliseFirst(item.content.cover_type)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.cover_colour)}</td>
              <td className="label">length</td>
              <td className="text">{item.content.cover_length}</td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount++;
        } else {
          cover = '';
        }

        if (item.content.channel_l) {
          channel = (
            <tr className="inside-row">
              <td className="no-top-border" />
              <td className="label no-top-border">channels</td>
              <td className="text">{this.capitaliseFirst(item.content.channels_colour)}</td>
              <td className="label">left</td>
              <td className="text">{item.content.channel_l}</td>
              <td className="label">right</td>
              <td className="text">{item.content.channel_r}</td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount++;
        } else {
          channel = '';
        }

        if (item.content.wire_guide) {
          wire = (
            <tr className="inside-row">
              <td className="no-top-border" />
              <td className="label">wire guide</td>
              <td colSpan={5} className="text">
                check base rail caps to suit WIRE GUIDE
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount++;
        } else {
          wire = '';
        }

        if (item.content.wire_qty) {
          wires = (
            <tr className="inside-row">
              <td className="no-top-border" />
              <td className="label no-top-border">wire</td>
              <td className="text">{item.content.wire_qty}</td>
              <td className="label">type</td>
              <td className="text">{item.content.wire_type}</td>
              <td className="label">length</td>
              <td className="number">{item.content.wire_length}</td>
              <td className="sign-off">packed</td>
            </tr>
          );
          currentLineCount++;
        } else {
          wires = '';
        }

        const components = item.content.components?.map((component: any) => {
          currentLineCount++;
          return (
            <tr className="inside-row">
              <td className="no-top-border" />
              <td className="label no-top-border">component</td>
              <td className="text" colSpan={3}>
                {this.capitaliseFirst(component.label)}
              </td>
              <td className="label">qty</td>
              <td className="number">{component.qty}</td>
              <td className="sign-off">packed</td>
            </tr>
          );
        });

        if (item.content.notes) {
          note = (
            <tr>
              <td />
              <td className="label">note</td>
              <td className="text" colSpan={5}>
                {`${item.content.notes}`.replace(/\r?\n|\r/g, ' ')}
              </td>
            </tr>
          );
          currentLineCount += Math.ceil(`${item.content.notes}`.length / 90);
        } else {
          note = '';
        }

        this.report.addItem(
          item,
          currentLineCount,
          <React.Fragment>
            <table className="mt-10 odd-zebra three-cols content">
              {repair1}
              {repair2}
              {repair3}
              <tr className="new-item">
                <td className="index">{item.order_item_index}</td>
                <td className="label">product</td>
                <td className="text">{item.content.product}</td>
                <td className="label">location</td>
                {/* todo correct string for location will be added when there
                 will be a table of locations. */}
                <td className="text">{item.content?.location?.substring(0, 30)}</td>
                <td className="label">fabric</td>
                <td className="text">
                  {this.capitaliseFabric(item.content.fabric)} {this.capitaliseFirst(item.content.fab_colour)}
                </td>
                <td className="sign-off">check</td>
              </tr>
              <tr className="inside-row">
                <td className="no-top-border" />
                <td className="label no-top-border">ctrl type</td>
                <td className="text">{this.capitaliseFirst(item.content.control_type)}</td>
                <td className="label">ctrl side</td>
                <td className="text">{item.content.control_side.toUpperCase()}</td>
                <td className="label">direction</td>
                <td className="text">{this.capitaliseFirst(item.content.roll_direction)}</td>
                <td className="sign-off">check</td>
              </tr>
              <tr className="inside-row">
                <td className="no-top-border" />
                <td className="label no-top-border">mount</td>
                <td className="text">{this.capitaliseFirst(item.content.mount)}</td>
                <td className="label">b2b width</td>
                <td className="text">{item.content.width}</td>
                <td className="label">drop</td>
                <td className="text">{item.content.drop}</td>
                <td className="sign-off">check</td>
              </tr>
              {easyLink}
              <tr className="inside-row">
                <td className="no-top-border" />
                <td className="label no-top-border">base Rail</td>
                <td className="text" colSpan={3}>
                  {this.capitaliseFirst(item.content.br_type)}
                </td>
                <td className="label">rail colour</td>
                <td className="text">{this.capitaliseFirst(item.content.br_colour)}</td>
                <td className="sign-off">check</td>
              </tr>
              <tr className="inside-row">
                <td className="no-top-border" />
                <td className="label no-top-border">chain</td>
                <td className="text">{this.capitaliseFirst(item.content.chain)}</td>
                <td className="label">ch length</td>
                <td className="number">{item.content.chain_length}</td>
                <td className="label">motor</td>
                <td className="number">{this.capitaliseFirst(item.content.motor)}</td>
                <td className="sign-off">check</td>
              </tr>
              {dual}
              {brackets}
              {cover}
              {channel}
              {wire}
              {wires}
              {components}
              {note}
            </table>
          </React.Fragment>
        );
      });
    }

    {
      /* CHECK SUM */
    }
    this.report.addItem(
      this.props.data,
      1,
      <React.Fragment>
        <table className="summary">
          <td className="label">total</td>
          <td className="text">{this.props.data.qc.items.length}</td>
        </table>
      </React.Fragment>
    );

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="colorvue-standard-assembly-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default CVQualityCheck;
