import {DropdownOption} from 'two-core';

export const productLines: DropdownOption[] = [
  {label: 'Colourvue', value: 'Colourvue'},
  {label: 'Shadesol', value: 'Shadesol'},
  {label: 'Curtains', value: 'Curtains'},
];

export const colourvueExtLabels = ['blind', 'cassette', 'channels', 'tube'];
export const shadesolExtLabels = ['box', 'channels', 'tube'];
export const curtainsExtLabels = ['fabric', 'track', 'box'];
