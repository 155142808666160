import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiResponse, ApiListResponse, QueryParameter, Supplier} from 'two-core';

class SuppliersService extends ApiService {
  protected endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.suppliers ?? '';
  }

  async getSuppliers(params: QueryParameter): Promise<ApiListResponse> {
    if (!params.filters) {
      params.filters = [];
    }

    params.filters.push(
      JSON.stringify({
        field: 'factory_id',
        value: localStorage.getItem('current factory'),
      })
    );

    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async getSupplier(uuid: string): Promise<Supplier> {
    return this.get(this.endpoint + '/' + uuid)
      .then((response: ApiResponse | ApiListResponse) => {
        return Promise.resolve(response as unknown as Supplier);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
}

export default SuppliersService;
