import React from 'react';

interface Props {
  data: any;
}

export class CutSheetTemplate extends React.Component<Props> {
  protected capitaliseAll(text: string): string {
    if (!text) {
      return text;
    }
    return text.trim().toUpperCase();
  }

  protected capitaliseFirst(text: string): string {
    if (!text) {
      return text;
    }
    let result = '';
    const words = text.trim().toLowerCase().split(' ');
    for (const word of words) {
      if (word && word.length > 0) {
        result += `${word[0].toUpperCase()}${word.substring(1)} `;
      }
    }
    return result.trim();
  }

  protected capitaliseFabric(fabricName: string): string {
    if (!fabricName) {
      return fabricName;
    }
    let result = fabricName;
    const capitalised = this.capitaliseFirst(fabricName);
    result = capitalised;
    const startBracket = capitalised.indexOf('(');
    const stopBracket = capitalised.indexOf(')');
    if (startBracket > 0 && stopBracket > startBracket + 1) {
      const toReplaceString = capitalised.substring(startBracket, stopBracket + 1);
      if (toReplaceString) {
        result = capitalised.replace(toReplaceString, toReplaceString.toUpperCase());
      }
    }
    return result;
  }
}
