import React from 'react';
import {AppContext, TwoDialog, TwoToast} from 'two-app-ui';
import StockTakesService from '../../services/StockTakesService';
import {CreateStockTakeRequest, StockTake} from 'two-core';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';

interface Props {
  showDialog: boolean;
  originalStockTake: StockTake;
  onHide: () => void;
}

interface State {
  saving?: boolean;
  name?: string;
}

export default class CloneStockTakeDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  stockTakesService?: StockTakesService;

  twoToast?: TwoToast;
  constructor(props: Props) {
    super(props);

    this.state = {};

    this.onHide = this.onHide.bind(this);
    this.createStockTake = this.createStockTake.bind(this);
    this.onCreate = this.onCreate.bind(this);
  }

  componentDidMount() {
    this.stockTakesService = this.context.stockTakesService;
    this.twoToast = this.context.twoToast;
  }

  onHide() {
    this.setState({
      saving: false,
      name: undefined,
    });
    this.props.onHide();
  }

  onCreate() {
    const {originalStockTake} = this.props;
    const {name} = this.state;
    if (!name || name.trim().length === 0) {
      this.twoToast?.showError('Name is required');
      return;
    }

    const currentFactoryId = localStorage.getItem('current factory')!;
    const requestData: CreateStockTakeRequest = {
      name: name.trim(),
      factoryId: currentFactoryId,
    };
    this.createStockTake(requestData, originalStockTake.id!);
  }

  async createStockTake(requestData: CreateStockTakeRequest, originalId: number) {
    this.setState({saving: true});
    try {
      const resultPromise = this.stockTakesService?.cloneStockTake(requestData, originalId);
      const timerPromise = new Promise(resolve => {
        setTimeout(() => {
          resolve('timeout');
        }, 30000);
      });
      Promise.any([resultPromise, timerPromise]).then(result => {
        if (result === 'timeout') {
          this.twoToast?.showWarn(
            'The operation is taking longer than expected, please refresh in few moments. Should the new Stock Take not appear in the list in 5 minutes, please contact IT.'
          );
        } else {
          this.twoToast?.showSuccess('Stock take created');
        }
        this.onHide();
      });
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error creating stock take');
      this.setState({saving: false});
    }
  }

  render() {
    const {showDialog, originalStockTake} = this.props;
    const {saving, name} = this.state;

    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={this.onHide} disabled={saving} />
        <Button label="Create" onClick={this.onCreate} loading={saving} />
      </div>
    );

    return (
      <>
        <TwoDialog
          onHide={this.onHide}
          header={`Clone Stock Take ${originalStockTake.name}`}
          loading={false}
          showDialog={showDialog}
          style={{width: '75vw'}}
          breakpoints={{'768px': '80vw', '576px': '90vw'}}
          draggable={false}
          footer={footer}
        >
          <div className=" w-100">
            <div className="p-field p-grid p-fluid">
              <label className="p-col-2">name</label>
              <div className="p-col-10">
                <InputText onChange={e => this.setState({name: e.target.value})} value={name} disabled={saving} />
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-10 p-offset-2">
                Please note, you are about to clone the stock take {originalStockTake.name}. This will populate the new
                stock take record with all the items from the original stock take, disregarding any changes to the
                inventory like new items or deletions of existing ones.
              </div>
            </div>
          </div>
        </TwoDialog>
      </>
    );
  }
}
