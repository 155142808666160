import React from 'react';
import {AppContext, TwoDialog, TwoToast} from 'two-app-ui';
import StockTakesService from '../../services/StockTakesService';
import {QueryParameter, StockTake, StockTakeStage} from 'two-core';
import {Button} from 'primereact/button';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  stockTake: StockTake;
}

interface State {
  loading?: boolean;
  saving?: boolean;
  runningStockTakes?: StockTake[];
}

export default class StartStockTakeDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  stockTakesService?: StockTakesService;

  twoToast?: TwoToast;
  constructor(props: Props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onStart = this.onStart.bind(this);
    this.saveStockTake = this.saveStockTake.bind(this);

    this.state = {loading: false};
  }

  componentDidMount() {
    this.stockTakesService = this.context.stockTakesService;
    this.twoToast = this.context.twoToast;
  }

  async loadData() {
    this.setState({loading: true});
    const runningStockTakes = await this.loadRunningStockTakes();
    this.setState({loading: false, runningStockTakes: runningStockTakes});
  }
  async loadRunningStockTakes() {
    try {
      const runningStockTakeStages: StockTakeStage[] = [
        'Started',
        'In Progress',
        'Review',
        'Recount',
        'Recount In Progress',
      ];
      const queryParams: QueryParameter = {
        filters: [JSON.stringify({field: 'stage', value: runningStockTakeStages, condition: 'in'})],
      };
      const response = await this.stockTakesService!.getStockTakes(queryParams);
      return (response?.records ?? []) as StockTake[];
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading stock takes');
      return undefined;
    }
  }

  onHide() {
    this.setState({
      loading: false,
      saving: false,
      runningStockTakes: undefined,
    });
    this.props.onHide();
  }

  onShow() {
    this.loadData();
  }

  onStart() {
    const {stockTake} = this.props;
    const stockTakePatch: Partial<StockTake> = {stage: 'Started'};
    this.saveStockTake(stockTake.id, stockTakePatch);
  }

  async saveStockTake(stockTakeId: number, stockTakePatch: Partial<StockTake>) {
    this.setState({saving: true});
    try {
      await this.stockTakesService?.updateStockTake(stockTakeId, stockTakePatch);
      this.twoToast?.showSuccess('Stock take started');
      this.onHide();
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error starting stock take');
      this.setState({saving: false});
    }
  }

  render() {
    const {showDialog} = this.props;
    const {runningStockTakes, loading, saving} = this.state;

    let dialogContent = <></>;
    let footer = <></>;
    if (runningStockTakes?.length) {
      const runningStockTake = runningStockTakes[0];
      dialogContent = (
        <div>
          Sorry, but there is already a Stock Take in progress (started: {runningStockTake.started_at} by{' '}
          {runningStockTake.started_by?.label}). You need to finish the stock take in progress to start another one.
        </div>
      );
      footer = (
        <div className={'p-d-flex p-justify-end'}>
          <Button label="Acknowledged" className={'p-mr-2 p-button-text'} onClick={this.onHide} />
        </div>
      );
    } else if (runningStockTakes !== undefined) {
      const factoryKey = localStorage.getItem('current factory key') ?? '';
      dialogContent = <div>You are about to start a stock take for the {factoryKey} factory.</div>;
      footer = (
        <div className={'p-d-flex p-justify-end'}>
          <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={this.onHide} disabled={saving} />
          <Button label="Yes, Start" onClick={this.onStart} loading={saving} />
        </div>
      );
    }

    return (
      <>
        <TwoDialog
          onHide={this.onHide}
          onShow={this.onShow}
          header="Start Stock Take"
          loading={loading}
          showDialog={showDialog}
          style={{width: '75vw'}}
          breakpoints={{'768px': '80vw', '576px': '90vw'}}
          draggable={false}
          footer={footer}
        >
          {dialogContent}
        </TwoDialog>
      </>
    );
  }
}
