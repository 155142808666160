import React from 'react';
import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import {ScrollPanel} from 'primereact/scrollpanel';
import {PurchaseOrder} from 'two-core';
import formats from '../../config/formats';

interface Props {
  purchaseOrders: PurchaseOrder[];
  handleChange: (item: PurchaseOrder, index: number) => void;
}
const PurchaseOrderDate = ({purchaseOrders, handleChange}: Props) => {
  return (
    <ScrollPanel style={{width: '100%', height: '100%'}}>
      <div className={'purchasing-order-date-detail'}>
        {purchaseOrders &&
          purchaseOrders.map((purchaseOrder: PurchaseOrder, index: number) => {
            return (
              <div key={index} className="p-fluid p-grid p-flex-row p-mt-2">
                <div className="p-d-flex p-ai-center p-col-12 p-lg-4 p-jc-center">
                  <label htmlFor="number" className="p-mr-1">
                    PO
                  </label>
                  <span className="">{purchaseOrder.name}</span>
                </div>
                <div className="p-ai-center p-col-12 p-lg-4 p-field p-grid p-mb-0">
                  <label htmlFor="sent_at" className="p-mr-1 p-col-fixed" style={{width: '5em'}}>
                    sent on
                  </label>
                  <Calendar
                    className={'p-col'}
                    value={purchaseOrder.sent_at}
                    dateFormat={formats.calendarInputDate}
                    name="sent_at"
                    onChange={(e: CalendarChangeParams) => {
                      const date = e.value as Date;
                      const poupdated: PurchaseOrder = {
                        ...purchaseOrder,
                        sent_at: date,
                      };
                      handleChange(poupdated, index);
                    }}
                  />
                </div>
                <div className="p-ai-center p-col-12 p-lg-4 p-field p-grid p-mb-0">
                  <label htmlFor="eta" className="p-mr-1 p-col-fixed" style={{width: '5em'}}>
                    eta
                  </label>
                  <Calendar
                    className={'p-col'}
                    value={purchaseOrder.eta}
                    dateFormat={formats.calendarInputDate}
                    name="eta"
                    onChange={(e: CalendarChangeParams) => {
                      const date = e.value as Date;
                      const poupdated: PurchaseOrder = {
                        ...purchaseOrder,
                        eta: date,
                      };
                      handleChange(poupdated, index);
                    }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </ScrollPanel>
  );
};

export default PurchaseOrderDate;
