import React from 'react';
import {IconProp, library} from '@fortawesome/fontawesome-svg-core';
import {faFileInvoiceDollar} from '@fortawesome/pro-regular-svg-icons';
import {formats} from '../../config/formats';
import {DateTime} from 'luxon';
import SuppliersService from '../../services/SuppliersService';
import {AppContext, ReferenceComponent, ReferenceType} from 'two-app-ui';
import {PurchaseOrder, Supplier} from 'two-core';

library.add(faFileInvoiceDollar);
interface Props {
  identifier: string;
  purchaseOrders: PurchaseOrder[];
  suppliers: Supplier[];
  handleChangePoSupplier?: (suppliers: Supplier[]) => void;
  handlePoReferenceClick?: (purchaseOrder: PurchaseOrder) => void;
}

class PoReferenceComponent extends React.Component<Props> {
  static contextType = AppContext;
  supplierService: SuppliersService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.supplierService = this.context.supplierService;
  }

  async loadSupplier(supplierId: string) {
    if (supplierId) {
      const existingSuppliers = [...this.props.suppliers];
      await this.supplierService?.getSupplier(supplierId).then((supplier: Supplier) => {
        if (supplier) {
          existingSuppliers.push(supplier);
          if (this.props.handleChangePoSupplier) {
            this.props.handleChangePoSupplier(existingSuppliers);
          }
        }
      });
    }
  }

  resolveType(stage: string, etaDate: DateTime | undefined) {
    let poType = ReferenceType.DEFAULT;
    switch (stage.toLocaleLowerCase()) {
      case 'draft':
        poType = ReferenceType.INFO;
        break;
      case 'delivered':
        poType = ReferenceType.SUCCESS;
        break;
      case 'ordered':
      case 'eta confirmed':
        poType = etaDate
          ? etaDate > DateTime.now()
            ? ReferenceType.DEFAULT
            : ReferenceType.DANGER
          : ReferenceType.WARNING;
        break;
      case 'delayed':
        poType = etaDate && etaDate > DateTime.now() ? ReferenceType.WARNING : ReferenceType.DANGER;
        break;
    }
    return poType;
  }

  onTooltipShow = async () => {
    const suppliersIds = this.props.suppliers.map(supplier => supplier.id);
    for (const purchaseOrder of this.props.purchaseOrders) {
      if (!suppliersIds.includes(purchaseOrder.supplier_id)) {
        this.loadSupplier(purchaseOrder.supplier_id);
      }
    }
  };

  tooltipElement = (purchaseOrder: PurchaseOrder, supplier: Supplier | undefined) => {
    return (
      <div className={'p-d-flex p-flex-column'}>
        <div className="p-grid">
          <label className="p-col-12 p-md-5">name</label>
          <div className="p-col-12 p-md-7">
            <span>{purchaseOrder?.name ?? ''} </span>
          </div>
        </div>

        <div className="p-grid">
          <label className="p-col-12 p-md-5">supplier</label>
          <div className="p-col-12 p-md-7">{<span>{supplier?.company_name ?? ''}</span>}</div>
        </div>

        <div className="p-grid">
          <label htmlFor="type" className="p-col-12 p-md-5">
            stage
          </label>
          <div className="p-col-12 p-md-7">
            <span>{purchaseOrder?.stage ?? ''}</span>
          </div>
        </div>

        <div className="p-grid">
          <label htmlFor="size" className="p-col-12 p-md-5 ">
            sent
          </label>
          <div className="p-col-12 p-md-7">
            <span>
              {purchaseOrder.sent_at
                ? DateTime.fromISO(purchaseOrder.sent_at.toString()).toFormat(formats.date)
                : 'N/A'}
            </span>
          </div>
        </div>
        <div className="p-grid">
          <label className="p-col-12 p-md-5">eta</label>
          <div className="p-col-12 p-md-7">
            <span>{purchaseOrder.eta ? DateTime.fromISO(purchaseOrder.eta.toString()).toLocaleString() : 'N/A'}</span>
          </div>
        </div>
        <div className="p-grid">
          <label className="p-col-12 p-md-5">delivery date</label>
          <div className="p-col-12 p-md-7">
            <span>
              {purchaseOrder.delivered_at
                ? DateTime.fromISO(purchaseOrder.delivered_at.toString()).toLocaleString()
                : 'N/A'}
            </span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const icon: IconProp = ['far', 'file-invoice-dollar'];

    return (
      this.props.purchaseOrders &&
      this.props.purchaseOrders.map((purchaseOrder: PurchaseOrder, index: number) => {
        if (purchaseOrder) {
          const dateToFormat = purchaseOrder.delivered_at ? purchaseOrder.delivered_at : purchaseOrder.eta;
          const dateLabel = dateToFormat
            ? DateTime.fromISO(dateToFormat.toString()).toFormat(formats.shortDate)
            : 'No ETA';

          const typeValue = this.resolveType(
            purchaseOrder.stage,
            purchaseOrder.eta ? DateTime.fromISO(purchaseOrder.eta.toString()) : undefined
          );
          const id = this.props.identifier + purchaseOrder.id + '-' + index;
          const supplier = this.props.suppliers.find(supplier => supplier.id === purchaseOrder.supplier_id);

          const tooltipElement = this.tooltipElement(purchaseOrder, supplier);

          return (
            <ReferenceComponent
              key={index}
              label={dateLabel}
              index={'po-reference-' + id}
              faIcon={icon}
              type={typeValue}
              tooltipElement={tooltipElement}
              onTooltipShow={this.onTooltipShow}
              onReferenceClick={() =>
                this.props.handlePoReferenceClick ? this.props.handlePoReferenceClick(purchaseOrder) : null
              }
            />
          );
        } else {
          return <React.Fragment key={index}></React.Fragment>;
        }
      })
    );
  }
}

export default PoReferenceComponent;
