import React, {ReactFragment} from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';
import {
  FactoryFloorTask,
  FloorTaskContentAssBlind,
  FloorTaskContentAssHeadbox,
  FloorTaskContentBlind,
  FloorTaskContentNote,
  FloorTaskContentPack,
  FloorTaskContentRepair,
  MapOf,
} from 'two-core';

interface Props {
  data: any;
}

class CVAssQcPack_v3 extends CutSheetTemplate {
  report: Report;
  contentPrio: MapOf<number>;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
    this.contentPrio = {
      repair: 1,
      note: 2,
      blind: 3,
      headbox: 4,
    };
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      3,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">cv std v3</td>
              <td className="text">
                <b>A_Q_P</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text">{this.props.data.reference}</td>
              <td className="label">shipping to</td>
              <td className="text" colSpan={3}>
                {this.props.data.ship_to}
              </td>
            </tr>
            <tr>
              <td className="label">product</td>
              <td className="text">CV Roller Blind</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      /* ITEMS */
    }
    this.props.data.assembly.items.sort((firstTask: FactoryFloorTask, secondTask: FactoryFloorTask) => {
      const task1Content = firstTask.content as FloorTaskContentBlind;
      const task2Content = secondTask.content as FloorTaskContentBlind;
      const weight1 =
        firstTask.order_item_index * 100 +
        (task1Content.blind_index ?? 1) * 10 +
        this.contentPrio[firstTask.category ?? 0];
      const weight2 =
        secondTask.order_item_index * 100 +
        (task2Content.blind_index ?? 1) * 10 +
        this.contentPrio[secondTask.category ?? 0];
      return weight1 - weight2;
    });
    this.props.data.qc.items.sort((firstTask: FactoryFloorTask, secondTask: FactoryFloorTask) => {
      const task1Content = firstTask.content as FloorTaskContentBlind;
      const task2Content = secondTask.content as FloorTaskContentBlind;
      const weight1 =
        firstTask.order_item_index * 100 +
        (task1Content.blind_index ?? 1) * 10 +
        this.contentPrio[firstTask.category ?? 0];
      const weight2 =
        secondTask.order_item_index * 100 +
        (task2Content.blind_index ?? 1) * 10 +
        this.contentPrio[secondTask.category ?? 0];
      return weight1 - weight2;
    });
    this.props.data.pack.items.sort((firstTask: FactoryFloorTask, secondTask: FactoryFloorTask) => {
      const task1Content = firstTask.content as FloorTaskContentBlind;
      const task2Content = secondTask.content as FloorTaskContentBlind;
      const weight1 =
        firstTask.order_item_index * 100 +
        (task1Content.blind_index ?? 1) * 10 +
        this.contentPrio[firstTask.category ?? 0];
      const weight2 =
        secondTask.order_item_index * 100 +
        (task2Content.blind_index ?? 1) * 10 +
        this.contentPrio[secondTask.category ?? 0];
      return weight1 - weight2;
    });

    //find all item blind index combinations
    const itemBlindIndexes: Indexer[] = [];
    this.props.data.qc.items.map((task: FactoryFloorTask) => {
      const taskContent = task.content as FloorTaskContentBlind;
      let indexer4item = itemBlindIndexes.find(indexer => {
        return indexer.itemIndex === task.order_item_index;
      });
      if (!indexer4item) {
        indexer4item = {
          itemIndex: task.order_item_index,
          blindIndexes: [],
        };
        itemBlindIndexes.push(indexer4item);
      }
      if (!indexer4item.blindIndexes.includes(taskContent.blind_index)) {
        indexer4item.blindIndexes.push(taskContent.blind_index);
      }
    });

    let currentLineIndex = 1;
    let indexColumn: ReactFragment;
    let row: ReactFragment;
    let rowClass: string;

    //cycle through all the items
    for (const indexer of itemBlindIndexes) {
      //in each item go over each blind
      for (const blindIndex of indexer.blindIndexes) {
        //filter the assembly, qc and pack tasks for the specific item and blind
        const assemblyTasks: FactoryFloorTask[] = this.props.data.assembly.items.filter((task: FactoryFloorTask) => {
          const taskContent = task.content as FloorTaskContentBlind;
          return task.order_item_index === indexer.itemIndex && taskContent.blind_index === blindIndex;
        });
        const qcTasks: FactoryFloorTask[] = this.props.data.qc.items.filter((task: FactoryFloorTask) => {
          const taskContent = task.content as FloorTaskContentBlind;
          return task.order_item_index === indexer.itemIndex && taskContent.blind_index === blindIndex;
        });
        const packTasks: FactoryFloorTask[] = this.props.data.pack.items.filter((task: FactoryFloorTask) => {
          const taskContent = task.content as FloorTaskContentBlind;
          return task.order_item_index === indexer.itemIndex && taskContent.blind_index === blindIndex;
        });

        //each blind has its own rows, repair and notes
        const itemRows: any[] = [];
        let repair1: any, repair2: any, repair3: any;
        repair1 = repair2 = repair3 = '';
        let currentLineCount = 1; //per report item size => how many rows does the inserted table / report item have
        let isFirstRow = true; //used to determine whether to show the index or not and top line CSS

        //map each task => only assembly shows repair and note as it is first
        assemblyTasks.map((assemblyTask: FactoryFloorTask) => {
          if (isFirstRow) {
            isFirstRow = false;
            indexColumn = <td className="no-top-border">{currentLineIndex}</td>;
            rowClass = 'internal-split';
          } else {
            indexColumn = <td className="no-top-border" />;
            rowClass = 'inside-row';
          }

          if (assemblyTask.category === 'repair') {
            const content = assemblyTask.content as FloorTaskContentRepair;
            repair1 = (
              <tr className={rowClass}>
                {indexColumn}
                <td className="label">rework</td>
                <td className="text">{content.repair_whole_item ? 'YES' : 'NO'}</td>
                <td className="label">type</td>
                <td className="text">{this.capitaliseFirst(content.repair_type ?? 'Whole Item Rework')}</td>
                <td className="label">responsibility</td>
                <td className="text">{this.capitaliseFirst(content.repair_responsibility)}</td>
              </tr>
            );
            if (content.repair_type === 'cutdown (standard)') {
              repair2 = (
                <tr>
                  <td />
                  <td className="label">new height</td>
                  <td className="number">{content.new_height}</td>
                  <td className="label">new width</td>
                  <td className="number" colSpan={3}>
                    {content.new_width}
                  </td>
                  <td className="sign-off">check</td>
                </tr>
              );
              currentLineCount += 1;
            }
            repair3 = (
              <tr>
                <td />
                <td className="label">repair</td>
                <td className="text" colSpan={5}>
                  {content.repair_details}
                </td>
                <td className="sign-off">check</td>
              </tr>
            );
            currentLineCount += 2;
          }

          if (assemblyTask.category === 'note') {
            const content = assemblyTask.content as FloorTaskContentNote;
            row = (
              <tr className={rowClass}>
                {indexColumn}
                <td className="label">note</td>
                <td className="text" colSpan={5}>
                  {`${content.text}`.replace(/\r?\n|\r/g, ' ')}
                </td>
                <td className="sign-off">check</td>
              </tr>
            );
            itemRows.push(row);
            currentLineCount += Math.ceil(content.text.length / 90);
          }

          if (assemblyTask.category === 'headbox') {
            const data = assemblyTask.content as FloorTaskContentAssHeadbox;
            row = (
              <tr className={rowClass}>
                {indexColumn}
                <td className="label">deco cover</td>
                <td className="text">{this.capitaliseFirst(data.type)}</td>
                <td className="label">colour</td>
                <td className="text">
                  {this.capitaliseFirst(
                    data.wrap_fabric ? `${data.wrap_fabric} ${data.wrap_fabric_colour}` : data.colour
                  )}
                </td>
                <td className="label">length</td>
                <td className="number">{data.length}</td>
                <td className="sign-off">done</td>
              </tr>
            );
            itemRows.push(row);
            currentLineCount++;
          }

          if (assemblyTask.category === 'blind') {
            const data = assemblyTask.content as FloorTaskContentAssBlind;
            row = (
              <tr className={rowClass}>
                {indexColumn}
                <td className="label">label</td>
                <td className="text">{data.label}</td>
                <td className="label">mount</td>
                <td className="text">{this.capitaliseFirst(data.mount)}</td>
                <td className="label">dual</td>
                <td className="text">{data.dual ? 'Yes' : 'No'}</td>
                <td className="sign-off">done</td>
              </tr>
            );
            itemRows.push(row);
            currentLineCount++;
            row = (
              <tr className={rowClass}>
                {indexColumn}
                <td className="label">tube</td>
                <td className="text">{this.capitaliseFirst(data.tube)}</td>
                <td className="label">b2b width</td>
                <td className="number">{data.b2b_width}</td>
                <td className="label">drop</td>
                <td className="text" colSpan={2}>
                  {data.drop}
                </td>
              </tr>
            );
            itemRows.push(row);
            currentLineCount++;
            row = (
              <tr className={rowClass}>
                {indexColumn}
                <td className="label">fabric</td>
                <td className="text">{this.capitaliseFirst(data.fabric)}</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(data.fabric_colour)}</td>
                <td className="label">direction</td>
                <td className="text" colSpan={2}>
                  {this.capitaliseFirst(data.direction)}
                </td>
              </tr>
            );
            itemRows.push(row);
            currentLineCount++;

            if (data.additional_deduction && data.additional_deduction === true) {
              currentLineCount++;
              const fabricDeduction = `Fab Ded... ${data.additional_deduction_amount}mm`;
              const alignAmount =
                data.additional_deduction_alignment?.toLowerCase() === 'left'
                  ? 0
                  : data.additional_deduction_alignment?.toLowerCase() === 'right'
                    ? data.additional_deduction_amount
                    : Math.ceil((data.additional_deduction_amount ?? 0) / 2);
              const align = `${data.additional_deduction_alignment} (${alignAmount}mm of Left)`;
              row = (
                <tr className={rowClass}>
                  {indexColumn}
                  <td className="label">extra</td>
                  <td className="text">{fabricDeduction}</td>
                  <td className="label">final width</td>
                  <td className="text">{data.fabric_width}</td>
                  <td className="label">align</td>
                  <td className="text">{this.capitaliseFirst(align)}</td>
                </tr>
              );
              itemRows.push(row);
              currentLineCount++;
            }

            row = (
              <tr className={rowClass}>
                {indexColumn}
                <td className="label">base rail</td>
                <td className="text">{this.capitaliseFirst(data.bottom_rail)}</td>
                <td className="label">b rail colour</td>
                <td className="number">{this.capitaliseFirst(data.bottom_rail_colour)}</td>
                <td className="label">ctrl side</td>
                <td className="text" colSpan={2}>
                  {data.control_side}
                </td>
              </tr>
            );
            itemRows.push(row);
            currentLineCount++;
            if (data.control_side) {
              row = (
                <tr className={rowClass}>
                  {indexColumn}
                  <td className="label">control</td>
                  <td className="text">
                    {this.capitaliseFirst(
                      !data.is_control
                        ? 'dependent'
                        : data.chain_type
                          ? `chain ${data.chain_type} ${data.chain_length}`
                          : data.spring_loaded
                            ? `spring ${data.spring_setting_1}/${data.spring_setting_2}`
                            : 'motor'
                    )}
                  </td>
                  <td className="label">ins L</td>
                  <td className="number">
                    {this.capitaliseFirst(
                      `${data.insert_left}${
                        data.control_side.toLowerCase() === 'l' && data.spring_assist ? ' ' + data.spring_assist : ''
                      }`
                    )}
                  </td>
                  <td className="label">ins R</td>
                  <td className="text" colSpan={2}>
                    {this.capitaliseFirst(
                      `${data.insert_right}${
                        data.control_side.toLowerCase() === 'r' && data.spring_assist ? ' ' + data.spring_assist : ''
                      }`
                    )}
                  </td>
                </tr>
              );
              itemRows.push(row);
              currentLineCount++;
            }
            if (data.is_wireguide) {
              row = (
                <tr className={rowClass}>
                  {indexColumn}
                  <td className="label">special</td>
                  <td className="text" colSpan={6}>
                    This is a WIRE GUIDE.
                  </td>
                </tr>
              );
              itemRows.push(row);
              currentLineCount++;
            }
          }
        });

        // qcTasks.map((qcTask: FactoryFloorTask) => {
        //   if (qcTask.category === 'blind') {
        //     const data = qcTask.content as FloorTaskContentQC;
        //     row = (
        //       <tr>
        //         {indexColumn}
        //         <td className="label internal-split">label</td>
        //         <td className="text internal-split">{data.label}</td>
        //         <td className="label internal-split">mount</td>
        //         <td className="text internal-split">
        //           {this.capitaliseFirst(data.mount)}
        //         </td>
        //         <td className="label internal-split">dual</td>
        //         <td className="text internal-split">
        //           {data.is_dual ? 'Yes' : 'No'}
        //         </td>
        //         <td className="sign-off internal-split">check</td>
        //       </tr>
        //     );
        //     itemRows.push(row);
        //     currentLineCount++;
        //
        //     //specials: headbox, channels, head mount rail, wireguide Kat said not required, skype 14/3/22
        //
        //     row = (
        //       <tr className={rowClass}>
        //         {indexColumn}
        //         <td className="label">b2b width</td>
        //         <td className="number">{data.width}</td>
        //         <td className="label">drop</td>
        //         <td className="number">{data.drop}</td>
        //         <td className="label">ctrl side</td>
        //         <td className="text" colSpan={2}>
        //           {data.control_side}
        //         </td>
        //       </tr>
        //     );
        //     itemRows.push(row);
        //     currentLineCount++;
        //
        //     row = (
        //       <tr className={rowClass}>
        //         {indexColumn}
        //         <td className="label">fabric</td>
        //         <td className="text">{this.capitaliseFirst(data.fabric)}</td>
        //         <td className="label">colour</td>
        //         <td className="text">
        //           {this.capitaliseFirst(data.fabric_colour)}
        //         </td>
        //         <td className="label">direction</td>
        //         <td className="text" colSpan={2}>
        //           {this.capitaliseFirst(data.direction)}
        //         </td>
        //       </tr>
        //     );
        //     itemRows.push(row);
        //     currentLineCount++;
        //
        //     row = (
        //       <tr className={rowClass}>
        //         {indexColumn}
        //         <td className="label">base rail</td>
        //         <td className="text">
        //           {this.capitaliseFirst(data.bottom_rail)}
        //         </td>
        //         <td className="label">colour</td>
        //         <td className="text">{data.bottom_rail_colour ?? ''}</td>
        //         <td className="label">caps</td>
        //         <td className="text" colSpan={2}>
        //           {data.is_wireguide ? 'Wire Guide' : 'Standard'}
        //         </td>
        //       </tr>
        //     );
        //     itemRows.push(row);
        //     currentLineCount++;
        //
        //     row = (
        //       <tr className={rowClass}>
        //         {indexColumn}
        //         <td className="label">control</td>
        //         <td className="text">
        //           {this.capitaliseFirst(
        //             !data.is_control
        //               ? 'dependent'
        //               : data.chain_type
        //               ? `chain ${data.chain_type} ${data.chain_length}`
        //               : 'motor'
        //           )}
        //         </td>
        //         <td className="label">ins L</td>
        //         <td className="number">
        //           {this.capitaliseFirst(data.insert_left)}
        //         </td>
        //         <td className="label">ins R</td>
        //         <td className="text" colSpan={2}>
        //           {this.capitaliseFirst(data.insert_right)}
        //         </td>
        //       </tr>
        //     );
        //     itemRows.push(row);
        //     currentLineCount++;
        //   }
        // });

        packTasks.map((packTask: FactoryFloorTask) => {
          if (packTask.category === 'blind') {
            const data = packTask.content as FloorTaskContentPack;

            // row = (
            //   <tr className="first-inside internal-split">
            //     {indexColumn}
            //     <td className="label internal-split">label</td>
            //     <td className="text internal-split" colSpan={5}>
            //       {data.label}
            //     </td>
            //     <td className="sign-off internal-split">done</td>
            //   </tr>
            // );
            // itemRows.push(row);
            // currentLineCount++;

            // row = (
            //   <tr className={rowClass}>
            //     {indexColumn}
            //     <td className="label">W x D</td>
            //     <td className="number">
            //       {data.blind_length ?? 0} x {data.drop ?? 0}
            //     </td>
            //     <td className="label">fabric</td>
            //     <td className="number">{data.fabric}</td>
            //     <td className="label">colour</td>
            //     <td className="text">{data.fabric_colour}</td>
            //     <td className="sign-off">pack</td>
            //   </tr>
            // );
            // itemRows.push(row);
            // currentLineCount++;

            // if (data.deco_cover) {
            //   const colourColumns: any[] = [];
            //   if (!data.deco_cover_fabric) {
            //     colourColumns.push(<td className="label">colour</td>);
            //     colourColumns.push(
            //       <td className="text" colSpan={3}>
            //         {this.capitaliseFirst(data.deco_cover_colour)}
            //       </td>
            //     );
            //   } else {
            //     colourColumns.push(<td className="label">farbic</td>);
            //     colourColumns.push(
            //       <td className="text">
            //         {this.capitaliseFirst(data.deco_cover_fabric)}
            //       </td>
            //     );
            //     colourColumns.push(<td className="label">fab col</td>);
            //     colourColumns.push(
            //       <td className="text">
            //         {this.capitaliseFirst(data.deco_cover_fabric_colour)}
            //       </td>
            //     );
            //   }
            //   row = (
            //     <tr className={rowClass}>
            //       {indexColumn}
            //       <td className="label">deco cover</td>
            //       <td className="text">
            //         {this.capitaliseFirst(data.deco_cover)}
            //       </td>
            //
            //       {colourColumns}
            //       <td className="sign-off">pack</td>
            //     </tr>
            //   );
            //   itemRows.push(row);
            //   currentLineCount++;
            // }

            if (data.channel_colour) {
              row = (
                <tr className={rowClass}>
                  {indexColumn}
                  <td className="label">channels</td>
                  <td className="text">2</td>
                  <td className="label">colour</td>
                  <td className="text">{this.capitaliseFirst(data.channel_colour)}</td>
                  <td className="label">length</td>
                  <td className="number">{data.channel_length}</td>
                  <td className="sign-off">pack</td>
                </tr>
              );
              itemRows.push(row);
              currentLineCount++;
            }

            if (data.wire_qty) {
              row = (
                <tr className={rowClass}>
                  {indexColumn}
                  <td className="label">wires</td>
                  <td className="number">{data.wire_qty}</td>
                  <td className="label">type</td>
                  <td className="text">{data.wire_type}</td>
                  <td className="label">length</td>
                  <td className="number">{data.wire_length}</td>
                  <td className="sign-off">pack</td>
                </tr>
              );
              itemRows.push(row);
              currentLineCount++;
            }

            if (data.brackets && data.brackets.length > 0) {
              for (const bracket of data.brackets) {
                row = (
                  <tr className={rowClass}>
                    {indexColumn}
                    <td className="label">bracket</td>
                    <td className="text">{bracket.unit}</td>
                    <td className="label">colour</td>
                    <td className="text">{bracket.colour}</td>
                    <td className="label">qty</td>
                    <td className="number">{bracket.qty}</td>
                    <td className="sign-off">pack</td>
                  </tr>
                );
                itemRows.push(row);
                currentLineCount++;
              }
            }

            if (data.components) {
              for (const component of data.components) {
                const colourColumns: any[] = [];
                const unitColspan = '';
                if (!component.colour) {
                  colourColumns.push(
                    <td className="text" colSpan={3}>
                      {component.unit}
                    </td>
                  );
                } else {
                  colourColumns.push(<td className="text">{component.unit}</td>);
                  colourColumns.push(<td className="label">colour</td>);
                  colourColumns.push(<td className="text">{component.colour}</td>);
                }
                row = (
                  <tr className={rowClass}>
                    {indexColumn}
                    <td className="label">component</td>
                    {colourColumns}
                    <td className="label">qty</td>
                    <td className="number">{component.qty}</td>
                    <td className="sign-off">pack</td>
                  </tr>
                );
                itemRows.push(row);
                currentLineCount++;
              }
            }
          }
        });

        //add all the rows necessary for the rows in this item blind combination
        this.report.addItem(
          this.props.data,
          currentLineCount,
          <React.Fragment>
            <table className="mt-10 odd-zebra three-cols content">
              {repair1}
              {repair2}
              {repair3}
              {itemRows}
            </table>
          </React.Fragment>
        );
        currentLineCount = 0;
        currentLineIndex++;
      }
    }
    {
      /* CHECK SUM */
    }
    this.report.addItem(
      this.props.data,
      1,
      <React.Fragment>
        <table className="summary">
          <td className="label">total</td>
          <td className="text">{currentLineIndex - 1}</td>
        </table>
      </React.Fragment>
    );

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="colorvue-standard-saw-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default CVAssQcPack_v3;

interface Indexer {
  itemIndex: number;
  blindIndexes: number[];
}
