import React from 'react';
import {AppContext, TwoDialog, TwoToast} from 'two-app-ui';
import OrdersService from '../../services/OrdersService';
import {Order, OrderPatch, TimeLineEvent, TleContentNote} from 'two-core';
import {InputTextarea} from 'primereact/inputtextarea';
import {MessageService, ToastService} from 'two-app-ui';
import {messages} from '../../config/messages';
import {Toast} from 'primereact/toast';
import TleService from '../../services/TleService';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  selectedOrders: Order[];
  injectLastNotes?: (createdTles: TimeLineEvent[]) => void;
}

interface State {
  note: string;
  loading: boolean;
}

class OrderNoteDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  tleService: TleService | null = null;
  twoToast?: TwoToast;

  constructor(props: Props) {
    super(props);
    this.state = {
      note: '',
      loading: false,
    };

    this.onHide = this.onHide.bind(this);
    this.createNote = this.createNote.bind(this);
  }

  componentDidMount() {
    this.tleService = this.context.tleService;
    this.twoToast = this.context.twoToast;
  }

  onHide() {
    this.setState({
      note: '',
      loading: false,
    });
    this.props.onHide();
  }

  async createNote() {
    const {selectedOrders, injectLastNotes} = this.props;
    if (!(selectedOrders && selectedOrders.length > 0)) {
      throw new Error('Invalid orders');
    }

    this.setState({loading: true});
    Promise.all(
      selectedOrders.map((order: Order) => {
        const note = this.state.note;
        const content: TleContentNote = {
          text: note,
          title: 'note added',
        };

        const tle: TimeLineEvent = {
          event_type: 'note',
          entity_type: 'order',
          recorded_by: this.getCurrentUserId(),
          entity_id: order.id ?? '',
          content: content,
          recorded_at: new Date(),
        };

        return this.createTle(order, tle);
      })
    )
      .then(createdTles => {
        this.twoToast?.showSuccess('Note for order created successfully.');
        MessageService.sendMessage(messages.orderNoteCreated);
        injectLastNotes?.(createdTles.filter(tle => tle) as TimeLineEvent[]);
      })
      .catch(error => {
        console.error(error);
        this.twoToast?.showError('Note failed to save for one of the orders.');
      });
    this.onHide();
  }

  createTle(order: Order, tle: TimeLineEvent) {
    return this.tleService?.createTle(tle).then(data => {
      return data;
    });
  }

  getCurrentUserId(): string {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  render() {
    const dialogBody = (
      <>
        <div className="p-d-flex p-col-12 p-p-0 p-mb-2">
          <span>Note will be added to these orders:</span>
          {this.props.selectedOrders.map((order: Order, index: number) => (
            <span key={index} className={'p-ml-1'}>
              {order.id}
            </span>
          ))}
        </div>
        <div className="p-d-flex p-col-12 p-p-0">
          <InputTextarea
            className="w-100"
            rows={5}
            cols={30}
            value={this.state.note}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({note: e.target.value})}
          />
        </div>
      </>
    );

    return (
      <TwoDialog
        headerTitle={'Add a Note'}
        showDialog={this.props.showDialog}
        width={50}
        onHide={this.onHide}
        onSave={this.createNote}
        loading={false}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default OrderNoteDialog;
