import React from 'react';
import {AppContext, MessageService, TwoDialog} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import {InventoryItem, Stock, TimeLineEvent, TleContentUnknown} from 'two-core';
import InventoryItemStock from '../Inventory/InventoryItemStock';
import InventoryService from '../../services/InventoryService';
import TleService from '../../services/TleService';
import {StocKeyModel, StockModel} from '../Inventory/Models/StockModel';
import {messages} from '../../config/messages';

interface Props {
  inventoryItem: InventoryItem;
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
}

interface State {
  inventoryItem: InventoryItem;
  updateStockItems: StocKeyModel;
  loading: boolean;
}

class InventoryItemStockDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  inventoryService: InventoryService | null = null;
  tleService: TleService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      inventoryItem: {
        category: '',
        factory_id: '',
        name: '',
        type: '',
        uom: '',
        updated_at: new Date(),
      },
      updateStockItems: {},
      loading: false,
    };

    this.closeDialog = this.closeDialog.bind(this);
    this.setInventoryItem = this.setInventoryItem.bind(this);
    this.saveStockItems = this.saveStockItems.bind(this);
    this.handleInventoryItemStockInputChange = this.handleInventoryItemStockInputChange.bind(this);
  }

  componentDidMount() {
    this.inventoryService = this.context.inventoryService;
    this.tleService = this.context.tleService;
  }

  setInventoryItem() {
    const inventoryItem = this.props.inventoryItem;
    const updateStockItems: StocKeyModel = {};
    updateStockItems[inventoryItem.id ?? ''] = {
      quantity: inventoryItem.current_stock_level,
      item: inventoryItem,
    };
    this.setState({
      inventoryItem: inventoryItem,
      updateStockItems: updateStockItems,
    });
  }

  closeDialog() {
    this.setState({loading: false});
    this.props.onHide();
  }

  handleInventoryItemStockInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const updateStockItems = this.state.updateStockItems;

    const stockModel = updateStockItems[e.target.name];
    const newStockModel: StockModel = {
      ...stockModel,
      quantity: e.target.value as unknown as number,
    };

    const newState: StocKeyModel = {
      ...updateStockItems,
      [e.target.name]: newStockModel,
    };

    this.setState({updateStockItems: newState});
  }

  saveStockItems() {
    this.setState({loading: true});
    const inventoryItem = this.state.inventoryItem;

    if (this.state.updateStockItems !== null && inventoryItem) {
      const factory_id: string = localStorage.getItem('current factory') ?? '';

      const stockModel = this.state.updateStockItems[inventoryItem.id ?? ''];
      const stock: Stock = {
        quantity: stockModel.quantity ?? 0,
        item: stockModel.item,
      };

      this.inventoryService
        ?.updateStocks(factory_id, [stock])
        .then(() => {
          this.addTlesOfInventoryItemsStock(stock);
          MessageService.sendMessage(messages.inventoryItemUpdated);
          this.props.toast.current?.show({
            contentClassName: '',
            severity: 'success',
            summary: 'Success',
            detail: 'Stock item updated successfully.',
            life: 3000,
          });
          this.closeDialog();
        })
        .catch(() => {
          this.setState({loading: false});

          this.props.toast.current?.show({
            contentClassName: '',
            severity: 'error',
            summary: 'Error',
            detail: 'Sorry, Stock item update failed, please try again.',
            life: 3000,
          });
        });
    }
  }

  addTlesOfInventoryItemsStock(stock: Stock) {
    const inventoryItem = stock.item;
    const content: TleContentUnknown = {
      content: `old_value: ${inventoryItem.current_stock_level}, new_value: ${stock.quantity}`,
    };
    const tle: TimeLineEvent = {
      event_type: 'back_in_stock',
      entity_type: 'inventory_item',
      recorded_by: this.getCurrentUserId(),
      entity_id: inventoryItem.id?.toString() ?? '',
      content: content,
      recorded_at: new Date(),
    };

    this.createTle(tle);
  }

  createTle(tle: TimeLineEvent): Promise<void> | undefined {
    return this.tleService
      ?.createTle(tle)
      .then(() => {})
      .catch(error => {
        console.log(error);
      });
  }

  getCurrentUserId(): string {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  render() {
    const dialogBody = (
      <InventoryItemStock
        updateItems={this.state.updateStockItems}
        handleInputChange={this.handleInventoryItemStockInputChange}
      />
    );
    return (
      <TwoDialog
        className="inventory-item-dialog"
        headerTitle={'Update stock'}
        showDialog={this.props.showDialog}
        width={50}
        onHide={this.closeDialog}
        onShow={this.setInventoryItem}
        onSave={this.saveStockItems}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default InventoryItemStockDialog;
