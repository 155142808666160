import {DateTime} from 'luxon';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import React from 'react';
import {AppContext, ToastService, TwoDataTable} from 'two-app-ui';
import {Bom, Order, PurchaseOrder, QueryParameter, Supplier} from 'two-core';
import formats from '../../config/formats';
import BomService from '../../services/BomService';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import PoItemReferenceComponent from '../Reference/PoItemReferenceComponent';
import {Tooltip} from 'primereact/tooltip';

interface Props {
  order: Order;
}

interface State {
  loading: boolean;
  boms: Bom[];
  total_boms: number;
  purchaseOrders: PurchaseOrder[];
  poSuppliers: Supplier[];
}

class OrderBoms extends React.Component<Props, State> {
  static contextType = AppContext;
  bomService: BomService | null = null;
  toastService: ToastService | null = null;
  purchaseOrdersService?: PurchaseOrdersService;
  toast: React.RefObject<Toast>;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      boms: [],
      total_boms: 0,
      purchaseOrders: [],
      poSuppliers: [],
    };

    this.toast = React.createRef();

    this.subtractedBodyTemplate = this.subtractedBodyTemplate.bind(this);
    this.iiNameBodyTemplate = this.iiNameBodyTemplate.bind(this);
    this.iiColourBodyTemplate = this.iiColourBodyTemplate.bind(this);
    this.iiSkuBodyTemplate = this.iiSkuBodyTemplate.bind(this);
    this.iiExtraInfoBodyTemplate = this.iiExtraInfoBodyTemplate.bind(this);
    this.reservedBodyTemplate = this.reservedBodyTemplate.bind(this);
  }

  componentDidMount() {
    this.bomService = this.context.bomService;
    this.toastService = this.context.toastService;
    this.purchaseOrdersService = this.context.purchaseOrdersService;

    this.loadData();
  }

  loadData() {
    this.setState({loading: true});
    const orderId = this.props.order.id ?? '';

    const bomFilters: string[] = [];

    bomFilters.push(
      JSON.stringify({
        field: 'order_id',
        value: orderId,
      })
    );

    const bomParams: QueryParameter = {
      filters: bomFilters,
      aggregate: true,
    };

    this.bomService
      ?.getBoms(bomParams)
      .then(boms => {
        const itemsIds = boms?.map(bom => bom.inventory_item_id) ?? [];
        const poParams: QueryParameter = {
          aggregate: true,
          filters: [
            JSON.stringify({
              field: 'bom.order_id',
              value: orderId,
            }),
          ],
        };
        for (const bom of boms) {
          bom.purchase_orders = [];
        }

        this.purchaseOrdersService?.getPurchaseOrders(poParams).then(data => {
          const dataRecords = (data.records as PurchaseOrder[]) ?? [];

          const suppliers: Supplier[] = [];

          for (const po of dataRecords) {
            if (po.supplier && !suppliers.includes(po.supplier)) {
              suppliers.push(po.supplier);
            }

            po.items = po.items?.filter(item => itemsIds.includes(item.inventory_item_id)) ?? [];
            for (const bom of boms) {
              const poItem = po.items?.find(item => item.inventory_item_id === bom.inventory_item_id);
              if (poItem) {
                bom.purchase_orders?.push(po);
              }
            }
          }

          this.setState({
            boms: boms ?? [],
            total_boms: boms?.length ?? 0,
            loading: false,
            purchaseOrders: dataRecords ?? [],
            poSuppliers: suppliers ?? [],
          });
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, boms records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  iiNameBodyTemplate(rowData: Bom) {
    const id = 'name' + rowData.id ?? '';
    return (
      <div>
        <span id={id}>{rowData.inventory_item?.name}</span>
        <Tooltip target={`#${id}`} position="right" showDelay={500} mouseTrack mouseTrackLeft={15}>
          <span>{rowData.inventory_item?.name}</span>
        </Tooltip>
      </div>
    );
  }

  iiColourBodyTemplate(rowData: Bom) {
    const id = 'colour' + rowData.id ?? '';
    return (
      <div>
        <span id={id}>{rowData.inventory_item?.colour}</span>
        <Tooltip target={`#${id}`} position="right" showDelay={500} mouseTrack mouseTrackLeft={15}>
          <span>{rowData.inventory_item?.colour}</span>
        </Tooltip>
      </div>
    );
  }

  iiSkuBodyTemplate(rowData: Bom) {
    const id = 'sku' + rowData.id ?? '';
    return (
      <div>
        <span id={id}>{rowData.inventory_item?.sku}</span>
        <Tooltip target={`#${id}`} position="right" showDelay={500} mouseTrack mouseTrackLeft={15}>
          <span>{rowData.inventory_item?.sku}</span>
        </Tooltip>
      </div>
    );
  }

  iiExtraInfoBodyTemplate(rowData: Bom) {
    const id = 'extra' + rowData.id ?? '';
    return (
      <div>
        <span id={id}>{rowData.extra_info}</span>
        <Tooltip target={`#${id}`} position="right" showDelay={500} mouseTrack mouseTrackLeft={15}>
          <span>{rowData.extra_info}</span>
        </Tooltip>
      </div>
    );
  }

  subtractedBodyTemplate(rowData: Bom) {
    const formated_substracted_on = rowData.subtracted_at
      ? DateTime.fromISO(rowData.subtracted_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_substracted_on}</span>;
  }

  reservedBodyTemplate(rowData: Bom) {
    const formated_substracted_on = rowData.reserved_at
      ? DateTime.fromISO(rowData.reserved_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_substracted_on}</span>;
  }

  poReferenceTemplate = (rowData: Bom) => {
    return <PoItemReferenceComponent key={'PO-' + rowData.id} suppliers={this.state.poSuppliers} bom={rowData} />;
  };

  render() {
    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="boms_list_page" className="page-container">
          <TwoDataTable
            pageSizeIdentifier={'boms_list_page'}
            selectedItems={[]}
            heightToScroll="500px"
            activeFilters={{}}
            loading={this.state.loading}
            value={this.state.boms}
            totalRecords={this.state.total_boms}
            sortField="inventory_item_id"
            showPaging={false}
          >
            <Column
              header="Inventory Item Name"
              field="inventory_item.name"
              body={this.iiNameBodyTemplate}
              className="col-min-xl"
            />
            <Column
              header="Colour"
              field="inventory_item.colour"
              body={this.iiColourBodyTemplate}
              className="col-min-s col-max-m"
            />
            <Column
              header="Sku"
              field="inventory_item.sku"
              body={this.iiSkuBodyTemplate}
              className="col-min-s col-max-xl"
            />
            <Column header="Qty" field="quantity" className="col-min-s col-max-s" />
            <Column header="UoM" field="inventory_item.uom" className="col-min-s col-max-m" />
            {/*<Column header="Extra Info" field="extra_info" body={this.iiExtraInfoBodyTemplate} className="col-max-m" />*/}
            <Column
              header="Reserved"
              field="reserved_at"
              body={this.reservedBodyTemplate}
              className="col-min-m col-max-m"
            />
            <Column header="PO" field="reserved_at" body={this.poReferenceTemplate} className="col-min-m col-max-m" />
            <Column
              header="Subtracted"
              field="subtracted_at"
              body={this.subtractedBodyTemplate}
              className="col-min-m col-max-m"
            />
          </TwoDataTable>
          <Toast ref={this.toast} />
        </div>
      </div>
    );
  }
}

export default OrderBoms;
