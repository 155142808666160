import React from 'react';
import {AppContext, MessageService, TwoDataTable, TwoDialog} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import OosService from '../../services/OosService';
import {Bom, Oos, OosPatch, Order, ProductionStageM2cAuShades, QueryParameter} from 'two-core';
import {Column} from 'primereact/column';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import BomService from '../../services/BomService';
import {DataTablePageParams} from 'primereact/datatable';
import {messages} from '../../config/messages';

interface Props {
  oos: Oos;
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
}

interface State {
  oos: Oos;
  loading: boolean;
  loadingBoms: boolean;
  items: Bom[];
  selectedItems: Bom[];
  totalItems: number;
  pagination: {
    pageSize: number;
    offset: number;
  };
}

class OosAssignOrdersDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  oosService: OosService | null = null;
  bomService: BomService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      loadingBoms: false,
      totalItems: 0,
      oos: {
        factory_id: '',
        inventory_item_id: '',
        stage: 'Available',
        updated_at: new Date(),
      },
      items: [],
      selectedItems: [],
      pagination: {
        pageSize: 25,
        offset: 0,
      },
    };

    this.closeDialog = this.closeDialog.bind(this);
    this.setOos = this.setOos.bind(this);
    this.saveOos = this.saveOos.bind(this);
    this.ecdBodyTemplate = this.ecdBodyTemplate.bind(this);
    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
    this.priorityBodyTemplate = this.priorityBodyTemplate.bind(this);
  }

  componentDidMount() {
    this.oosService = this.context.oosService;
    this.bomService = this.context.bomService;
  }

  setOos() {
    const item = this.props.oos;
    this.setState({oos: item});
    this.loadData(item);
  }

  async loadData(oos: Oos) {
    this.setState({loadingBoms: true});

    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'inventory_item_id',
        value: oos.inventory_item_id,
      })
    );

    const stageFilterValues: ProductionStageM2cAuShades[] = ['New', 'Waiting 4 Material'];
    filters.push(
      JSON.stringify({
        field: 'factory_order.production_stage',
        value: stageFilterValues,
        condition: 'in',
      })
    );

    filters.push(
      JSON.stringify({
        field: 'order_id',
        value: oos.assigned_orders_ids?.filter(id => id) ?? [],
        condition: 'notIn',
      })
    );

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      aggregate: true,
    };

    this.bomService
      ?.getBoms(params)
      .then(boms => {
        this.setState({
          loadingBoms: false,
          items: boms,
          totalItems: boms.length,
          selectedItems: [],
        });
      })
      .catch(error => {
        this.setState({loadingBoms: false});

        this.props.toast.current?.show({
          contentClassName: '',
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, records load failed, please try again.',
          life: 3000,
        });
        console.error(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData(this.state.oos);
  }

  emptyOos() {
    const inventoryItem: Oos = {
      factory_id: localStorage.getItem('current factory') ?? '',
      inventory_item_id: '',
      stage: 'Available',
      updated_at: new Date(),
    };
    return inventoryItem;
  }

  closeDialog() {
    this.props.onHide();
    this.setState({
      loading: false,
      oos: this.emptyOos(),
      items: [],
      selectedItems: [],
    });
  }

  saveOos() {
    const orders: Order[] = [];
    const selectedItems = this.state.selectedItems;
    selectedItems.forEach(i => {
      if (i.order) {
        orders.push(i.order);
      }
    });

    this.assignOrder(orders);
  }

  async assignOrder(orders: Order[]) {
    this.setState({loading: true});
    const oos = this.state.oos;
    const allOrdersIds = oos.assigned_orders_ids?.filter(o => o !== null) ?? [];
    const addOrdersIds: string[] = orders.map((order: Order) => order.id ?? '');

    allOrdersIds.push(...addOrdersIds);
    const oosPatch: OosPatch = {
      stage: oos.stage,
      assigned_orders_ids: allOrdersIds,
    };

    return this.oosService
      ?.updateOos(oos.id?.toString() ?? '', oosPatch)
      .then(() => {
        MessageService.sendMessage(messages.oosOrdersReassigned);
        this.props.toast.current?.show({
          contentClassName: '',
          severity: 'success',
          summary: 'Success',
          detail: 'Oos assigned successfully.',
          life: 3000,
        });
        this.closeDialog();
      })
      .catch(error => {
        this.setState({loading: false});
        this.props.toast.current?.show({
          contentClassName: '',
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, oos assign failed, please try again.',
          life: 3000,
        });
        console.error(error);
      });
  }

  handleSelectedItems(allItems: Bom[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: Bom[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Bom[]) {
    this.setState({selectedItems: items});
  }

  ecdBodyTemplate(rowData: Bom) {
    return rowData.factory_order?.ecd
      ? DateTime.fromISO(rowData.factory_order?.ecd as unknown as string).toFormat(formats.date)
      : '';
  }

  stageBodyTemplate(rowData: Bom) {
    return (
      <span
        className={`stage-badge stage-${rowData.factory_order?.production_stage ?? ''.toLowerCase().replace(' ', '-')}`}
      >
        {rowData.factory_order?.production_stage}
      </span>
    );
  }

  priorityBodyTemplate(rowData: Bom) {
    let priorityText = '';
    const priority = rowData.order?.priority ?? 0;
    switch (Number(priority)) {
      case 1:
        priorityText = '!';
        break;
      case 2:
        priorityText = '!!';
        break;
      case 3:
        priorityText = '!!!';
        break;
    }
    return <span>{priorityText}</span>;
  }

  render() {
    const dialogBody = (
      <div className="p-d-flex" style={{height: '100%', width: '100%'}}>
        <div id="assign_orders-page_container" className="page-container">
          <TwoDataTable
            style={{height: '100%'}}
            pageSizeIdentifier={'page_container'}
            heightToScroll={undefined}
            selectionMode="multiple"
            selectedItems={this.state.selectedItems}
            handleChangeSelectedItems={items => this.setChangeSelectedItems(items as Bom[])}
            loading={this.state.loadingBoms}
            value={this.state.items}
            activeFilters={{}}
            totalRecords={this.state.totalItems}
            rows={this.state.pagination.pageSize}
            first={this.state.pagination.offset}
            onPage={e => this.onPageChange(e as DataTablePageParams)}
          >
            <Column header="Order Code" field="order_id" style={{width: '180px'}} />
            <Column header="Customer" field="owner_company.account_number" style={{width: '180px'}} />
            <Column header="Stage" field="stage" body={this.stageBodyTemplate} />
            <Column header="Prio" field="order.priority" body={this.priorityBodyTemplate} style={{width: '180px'}} />
            <Column header="ECD" field="ecd" body={this.ecdBodyTemplate} style={{width: '180px'}} />
            <Column header="Qty" field="quantity" style={{width: '180px'}} />
          </TwoDataTable>
        </div>
      </div>
    );
    return (
      <TwoDialog
        className="oos-item-dialog"
        headerTitle={'Assign orders to Oos'}
        showDialog={this.props.showDialog}
        width={80}
        onHide={this.closeDialog}
        onShow={this.setOos}
        onSave={this.saveOos}
        loading={this.state.loading}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}

export default OosAssignOrdersDialog;
