import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, ShipmentItem} from 'two-core';

class ShipmentsService extends ApiService {
  shipmentItemEndpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.shipmentItemEndpoint = config().endpoints.shipmentItems ?? '';
  }

  async getShippingLabelsByOrders(orders: string[]): Promise<ApiListResponse> {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'order_id',
        value: orders,
        condition: 'in',
      })
    );
    const params = {
      filters: filters,
    };
    return this.getShippingLabels(params);
  }

  async getShippingLabels(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.shipmentItemEndpoint, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async saveItems(order_id: string, items: ShipmentItem[]): Promise<void> {
    return this.put(this.shipmentItemEndpoint + '/' + order_id + '/items', {
      items: items,
    })
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteItem(orderId: string, id: number): Promise<void> {
    return this.delete(this.shipmentItemEndpoint + '/' + orderId + '/items/' + id)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default ShipmentsService;
