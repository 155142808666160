import React from 'react';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  TwoDataTable,
  TwoDialog,
} from 'two-app-ui';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {InventoryItem, MapOf, QueryParameter, Supplier, SupplyItem} from 'two-core';
import {Column} from 'primereact/column';
import {DataTablePageParams} from 'primereact/datatable';
import {MenuItemOptions} from 'primereact/menuitem';
import {faCopy, faTrashAlt, faPlusCircle, faCheck, faPencil} from '@fortawesome/pro-regular-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faEllipsisH} from '@fortawesome/free-solid-svg-icons';
import InventoryService from '../../services/InventoryService';
import SuppliersService from '../../services/SuppliersService';
import {InputSwitchChangeParams} from 'primereact/inputswitch';
import {DropdownChangeParams} from 'primereact/dropdown';
import {Toast} from 'primereact/toast';
import SupplyItemForm from '../Inventory/SupplyItemForm';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import {Tooltip} from 'primereact/tooltip';

library.add(faEllipsisH, faCopy, faTrashAlt, faPlusCircle, faCheck, faPencil);

interface Props {
  inventoryItem: InventoryItem;
  toast: React.RefObject<Toast>;
}

interface State {
  loading: boolean;
  loadingForm: boolean;
  showSupplyItemDialog: boolean;
  currentSupplyItem: SupplyItem;
  suppliers: Supplier[];
  items: SupplyItem[];
  total_items: number;
  pagination: {
    pageSize: number;
    offset: number;
  };
}

class InventoryItemSuppliers extends React.Component<Props, State> {
  static contextType = AppContext;
  inventoryService: InventoryService | null = null;
  supplierService: SuppliersService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      loadingForm: false,
      showSupplyItemDialog: false,
      suppliers: [],
      currentSupplyItem: {
        inventory_item_id: this.props.inventoryItem.id ?? '',
        supplier_id: '',
        name: '',
        reorder_unit: '',
        order_minimum: 0,
        reorder_qty_in_uom: 0,
        default_option: false,
        updated_at: new Date(),
      },
      items: [],
      total_items: 0,
      pagination: {
        pageSize: 10,
        offset: 0,
      },
    };

    this.closeSupplyItem = this.closeSupplyItem.bind(this);
    this.saveSupplyItem = this.saveSupplyItem.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.supplierBodyTemplate = this.supplierBodyTemplate.bind(this);
    this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
    this.unitPriceBodyTemplate = this.unitPriceBodyTemplate.bind(this);
    this.defaultOptionBodyTemplate = this.defaultOptionBodyTemplate.bind(this);
    this.processShowSupplyItemAddDialog = this.processShowSupplyItemAddDialog.bind(this);
    this.processShowSupplyItemEditDialog = this.processShowSupplyItemEditDialog.bind(this);
  }

  componentDidMount() {
    this.inventoryService = this.context.inventoryService;
    this.supplierService = this.context.supplierService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.supplyItemUpdated) {
        this.loadSupplyItems();
      }
    });
    this.loadSupppliers();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.messageSendSubscription.unsubscribe();
  }

  async loadSupplyItems() {
    this.setState({loading: true});
    const params: QueryParameter = {
      aggregate: true,
    };
    this.inventoryService
      ?.getSupplyItems(this.props.inventoryItem.id ?? '', params)
      .then(data => {
        const dataRecords = (data as SupplyItem[]) ?? [];
        const suppliersMap: MapOf<Supplier> = {};
        for (const supplier of this.state.suppliers) {
          suppliersMap[supplier.id] = supplier;
        }
        const supplyItems = dataRecords.map(supplyItem => ({
          ...supplyItem,
          supplier: suppliersMap[supplyItem.supplier_id],
        }));

        this.setState({
          items: supplyItems ?? [],
          total_items: supplyItems.length,
          loading: false,
        });
      })
      .catch(error => {
        this.props.toast.current?.show({
          contentClassName: '',
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, records load failed, please try again.',
          life: 3000,
        });
        console.log(error);
        this.setState({loading: false});
      });
  }

  async loadSupppliers() {
    const filters: string[] = [];

    const params: QueryParameter = {
      filters: filters,
    };

    this.supplierService
      ?.getSuppliers(params)
      .then(data => {
        const suppliers = (data.records as Supplier[]) ?? [];

        this.setState(
          {
            suppliers: suppliers,
          },
          () => this.loadSupplyItems()
        );
      })
      .catch(error => {
        this.props.toast.current?.show({
          contentClassName: '',
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, suppliers records load failed, please try again.',
          life: 3000,
        });
        console.log(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadSupplyItems();
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | InputSwitchChangeParams) {
    const supplyItem = this.state.currentSupplyItem;
    const newState = {...supplyItem, [e.target.name]: e.target.value};
    this.setState({currentSupplyItem: newState});
  }

  initMenuItems(rowData: SupplyItem): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedItems = this.state.items;
    const selectedItemsCount = selectedItems.length;

    const newMenuItems = this.initNewMenuItems();
    menuItems.push(...newMenuItems);

    if (selectedItemsCount > 0) {
      menuItems.push({
        separator: true,
      });
      const otherMenuItems = this.initOtherMenuItems(rowData);
      menuItems.push(...otherMenuItems);
    }
    return menuItems;
  }

  initNewMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    menuItems.push({
      label: 'New',
      faIcon: faPlusCircle,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.processShowSupplyItemAddDialog();
      },
    });

    return menuItems;
  }

  initOtherMenuItems(rowData: SupplyItem): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    menuItems.push({
      label: 'Edit',
      faIcon: faPencil,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.processShowSupplyItemEditDialog(rowData);
      },
    });

    menuItems.push({
      label: 'Copy',
      faIcon: faCopy,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.copySupplyItem(rowData);
      },
    });

    menuItems.push({
      label: 'Delete',
      faIcon: faTrashAlt,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.deleteSupplyItem(rowData);
      },
    });
    return menuItems;
  }

  closeSupplyItem() {
    this.setState({
      showSupplyItemDialog: false,
      loadingForm: false,
      currentSupplyItem: {
        inventory_item_id: this.props.inventoryItem.id ?? '',
        supplier_id: '',
        name: '',
        reorder_unit: '',
        order_minimum: 0,
        reorder_qty_in_uom: 0,
        default_option: false,
        updated_at: new Date(),
      },
    });
  }

  saveSupplyItem() {
    this.setState({loadingForm: true});

    if (this.state.currentSupplyItem.id) {
      this.inventoryService
        ?.updateSupplyItem(
          this.props.inventoryItem.id?.toString() ?? '',
          this.state.currentSupplyItem.id ?? 0,
          this.state.currentSupplyItem
        )
        .then(() => {
          this.updateAndSaveDefaultOptionOfSupplyItems();
          this.closeSupplyItem();
          this.props.toast.current?.show({
            contentClassName: '',
            severity: 'success',
            summary: 'Success',
            detail: 'Supply item updated successfully.',
            life: 3000,
          });
        })
        .catch(() => {
          this.setState({loadingForm: false});
          this.props.toast.current?.show({
            contentClassName: '',
            severity: 'error',
            summary: 'Error',
            detail: 'Sorry, supply item update failed, please try again.',
            life: 3000,
          });
        });
    } else {
      this.inventoryService
        ?.createSupplyItem(this.props.inventoryItem.id?.toString() ?? '', this.state.currentSupplyItem)
        .then(() => {
          this.updateAndSaveDefaultOptionOfSupplyItems();
          this.closeSupplyItem();
          this.props.toast.current?.show({
            contentClassName: '',
            severity: 'success',
            summary: 'Success',
            detail: 'Supply item created successfully.',
            life: 3000,
          });
        })
        .catch(() => {
          this.setState({loadingForm: false});
          this.props.toast.current?.show({
            contentClassName: '',
            severity: 'error',
            summary: 'Error',
            detail: 'Sorry, supply item create failed, please try again.',
            life: 3000,
          });
        });
    }
  }

  async updateAndSaveDefaultOptionOfSupplyItems(): Promise<void> {
    const currentSupplyItem = this.state.currentSupplyItem;
    if (currentSupplyItem.default_option) {
      const supplyItems = this.state.items.filter(si => si.default_option === true && si.id !== currentSupplyItem.id);
      return Promise.all(
        supplyItems.map(supplyItem => {
          supplyItem.default_option = false;
          this.inventoryService?.updateSupplyItem(
            this.props.inventoryItem.id?.toString() ?? '',
            supplyItem.id ?? 0,
            supplyItem
          );
        })
      )
        .then(() => {
          MessageService.sendMessage(messages.supplyItemUpdated);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      MessageService.sendMessage(messages.supplyItemUpdated);
      return Promise.resolve();
    }
  }

  async processShowSupplyItemAddDialog() {
    this.setState({
      showSupplyItemDialog: true,
      currentSupplyItem: {
        inventory_item_id: this.props.inventoryItem.id ?? '',
        supplier_id: '',
        name: '',
        reorder_unit: '',
        reorder_qty_in_uom: 0,
        default_option: false,
        updated_at: new Date(),
      },
    });
  }

  async copySupplyItem(currentSupplyItem: SupplyItem) {
    if (currentSupplyItem) {
      this.setState({
        showSupplyItemDialog: true,
        currentSupplyItem: {
          inventory_item_id: currentSupplyItem.inventory_item_id,
          supplier_id: currentSupplyItem.supplier_id,
          sku: currentSupplyItem.sku,
          name: currentSupplyItem.name,
          reorder_unit: currentSupplyItem.reorder_unit,
          reorder_qty_in_uom: currentSupplyItem.reorder_qty_in_uom,
          package_size: currentSupplyItem.package_size,
          package_size_label: currentSupplyItem.package_size_label,
          default_option: currentSupplyItem.default_option,
          updated_at: new Date(),
        },
      });
    } else {
      this.props.toast.current?.show({
        contentClassName: '',
        severity: 'error',
        summary: 'Error',
        detail: 'Sorry, supply item copy failed, please try again.',
        life: 3000,
      });
    }
  }

  deleteSupplyItem(currentSupplyItem: SupplyItem) {
    this.setState({loading: true});
    if (currentSupplyItem && currentSupplyItem.inventory_item_id && currentSupplyItem.id) {
      this.inventoryService
        ?.deleteSupplyItem(currentSupplyItem.inventory_item_id, currentSupplyItem.id)
        .then(() => {
          MessageService.sendMessage(messages.supplyItemUpdated);
          this.setState({loading: false});
          this.props.toast.current?.show({
            contentClassName: '',
            severity: 'success',
            summary: 'Success',
            detail: 'Supply item deleted successfully.',
            life: 3000,
          });
        })
        .catch(error => {
          this.setState({loading: false});
          this.props.toast.current?.show({
            contentClassName: '',
            severity: 'error',
            summary: 'Error',
            detail: 'Sorry, supply item delete failed, please try again.',
            life: 3000,
          });
          console.log(error);
        });
    } else {
      this.setState({loading: false});
      this.props.toast.current?.show({
        contentClassName: '',
        severity: 'error',
        summary: 'Error',
        detail: 'Sorry, supply item delete failed, please try again.',
        life: 3000,
      });
    }
  }

  async processShowSupplyItemEditDialog(item: SupplyItem) {
    this.setState({
      currentSupplyItem: item,
      showSupplyItemDialog: true,
    });
  }

  unitPriceBodyTemplate(supplyItem: SupplyItem): string {
    return `$${supplyItem.unit_price ?? 0}`;
  }

  defaultOptionBodyTemplate(supplyItem: SupplyItem): JSX.Element {
    return supplyItem.default_option ? <FontAwesomeIcon icon={['far', 'check']}></FontAwesomeIcon> : <></>;
  }

  supplierBodyTemplate(supplyItem: SupplyItem): JSX.Element {
    return (
      <>
        <AppColumnMenuBodyTemplate
          isDynamicMenuItems={true}
          initMenuItems={() => this.initMenuItems(supplyItem)}
          rowItemIdentifier={supplyItem.id?.toString() ?? ''}
          selectedItems={[]}
        >
          <a
            id={`supplier-name-${supplyItem.id}`}
            href={'#/'}
            onClick={() => this.processShowSupplyItemEditDialog(supplyItem)}
          >
            {supplyItem.supplier?.company_name}
          </a>
        </AppColumnMenuBodyTemplate>
        <Tooltip target={`#supplier-name-${supplyItem.id}`} showDelay={500} mouseTrack mouseTrackLeft={15}>
          {supplyItem.supplier?.company_name}
        </Tooltip>
      </>
    );
  }

  nameBodyTemplate(supplyItem: SupplyItem): JSX.Element {
    return (
      <>
        <span id={`supply_item-name-${supplyItem.id}`}>{supplyItem.name}</span>
        <Tooltip target={`#supply_item-name-${supplyItem.id}`} showDelay={500} mouseTrack mouseTrackLeft={15}>
          {supplyItem.name}
        </Tooltip>
      </>
    );
  }

  render() {
    const inventoryItem = this.props.inventoryItem;

    const dialogBody = (
      <SupplyItemForm
        suppliers={this.state.suppliers}
        invItem={this.props.inventoryItem}
        item={this.state.currentSupplyItem}
        handleInputChange={this.handleInputChange}
      />
    );

    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="supplier_list_page" className="page-container" style={{width: '100%'}}>
          <TwoDataTable
            style={{height: '100%'}}
            pageSizeIdentifier={'supplier_list_page'}
            customEmptyMessage={'No vendors found.'}
            addNewItemEvent={inventoryItem.id ? this.processShowSupplyItemAddDialog : undefined}
            selectedItems={[]}
            rows={this.state.pagination.pageSize}
            first={this.state.pagination.offset}
            onPage={e => this.onPageChange(e as DataTablePageParams)}
            handleChangeSelectedItems={() => {}}
            loading={this.state.loading}
            activeFilters={{}}
            value={this.state.items}
            totalRecords={this.state.total_items}
          >
            <Column
              header="Supplier"
              field="supplier.company_name"
              body={item => this.supplierBodyTemplate(item)}
              className={'col-min-s col-max-xl'}
            />
            {/*<Column header="Phone" field="supplier.phone" style={{width: '170px'}} />*/}
            <Column header="Name" field="name" body={item => this.nameBodyTemplate(item)} className={'col-min-m'} />
            <Column header="SKU" field="sku" className={'col-min-m col-max-xl'} />
            <Column header="Unit" field="reorder_unit" className={'col-m'} />
            <Column
              header="Unit Price"
              field="unit_price"
              body={item => this.unitPriceBodyTemplate(item)}
              className={'col-m'}
            />
            <Column header="Package" field="package_size_label" className={'col-m'} />
            <Column header="Ord. Min." field="order_minimum" className={'col-s'} />
            <Column header="In UOM" field="reorder_qty_in_uom" className={'col-s'} />
            <Column
              header="Default"
              field="default_option"
              body={item => this.defaultOptionBodyTemplate(item)}
              className={'col-s'}
            />
          </TwoDataTable>
          <TwoDialog
            className="inventory-item-dialog"
            headerTitle={this.state.currentSupplyItem?.id ? 'Edit Supply Item' : 'Add Supply Item'}
            showDialog={this.state.showSupplyItemDialog}
            width={90}
            onHide={this.closeSupplyItem}
            onSave={this.saveSupplyItem}
            loading={this.state.loadingForm}
          >
            {dialogBody}
          </TwoDialog>
        </div>
      </div>
    );
  }
}

export default InventoryItemSuppliers;
