import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import OrderListComponent from '../Orders/OrderListComponent';

class ReturnsPage extends React.Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <OrderListComponent
        showFilterBox={false}
        orderStages={['Waiting For Return', 'Post Fix Return']}
        history={this.props.history}
      />
    );
  }
}

export default withRouter(ReturnsPage);
