import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {OtherMenuOptions, PrintMenuOptions, StageMenuOptions} from '../Order/Constants/constants';
import OrderListComponent from '../Orders/OrderListComponent';

class PowderListComponent extends React.Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <OrderListComponent
        showFilterBox={false}
        orderStages={['Powder Required', 'Powder Ordered']}
        orderTypes={['Repair', 'Standard']}
        printMenuOptions={[PrintMenuOptions.PowderPO]}
        stageMenuOptions={[StageMenuOptions.CoatedAndDelivered, StageMenuOptions.PowderOrdered]}
        otherMenuOptions={[OtherMenuOptions.RecordNote]}
        history={this.props.history}
      />
    );
  }
}

export default withRouter(PowderListComponent);
