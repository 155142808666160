import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {IntlProvider} from 'react-intl';
import packageData from '../package.json';

// @TODO: remove this or properly implement a version system.
console.log('app version: ' + packageData.version);
console.log('app-ui version: ' + packageData.dependencies['two-app-ui']);
console.log('core version: ' + packageData.dependencies['two-core']);

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={navigator.language}>
      <App />
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
