import {DateTime} from 'luxon';
import {ProgressSpinner} from 'primereact/progressspinner';
import React from 'react';
import {PurchaseOrder} from 'two-core';
import formats from '../../config/formats';

interface Props {
  loading?: boolean;
  purchaseOrders?: PurchaseOrder[];
}
export const OrderStagePurchaseOrdersTooltipContent = ({loading, purchaseOrders}: Props) => {
  const renderPurchaseOrder = (purchaseOrder: PurchaseOrder) => {
    return (
      <div style={{width: '250px'}}>
        <div className="p-grid">
          <label className="p-col-12 p-md-5">name</label>
          <div className="p-col-12 p-md-7">
            <span>{purchaseOrder?.name ?? ''}</span>
          </div>
        </div>

        <div className="p-grid">
          <label className="p-col-12 p-md-5">supplier</label>
          <div className="p-col-12 p-md-7">{<span>{purchaseOrder?.supplier?.company_name ?? ''}</span>}</div>
        </div>

        <div className="p-grid">
          <label htmlFor="type" className="p-col-12 p-md-5">
            stage
          </label>
          <div className="p-col-12 p-md-7">
            <span>{purchaseOrder?.stage ?? ''}</span>
          </div>
        </div>

        <div className="p-grid">
          <label htmlFor="size" className="p-col-12 p-md-5 ">
            sent
          </label>
          <div className="p-col-12 p-md-7">
            <span>
              {purchaseOrder.sent_at
                ? DateTime.fromISO(purchaseOrder.sent_at.toString()).toFormat(formats.date)
                : 'N/A'}
            </span>
          </div>
        </div>
        <div className="p-grid">
          <label className="p-col-12 p-md-5">eta</label>
          <div className="p-col-12 p-md-7">
            <span>{purchaseOrder.eta ? DateTime.fromISO(purchaseOrder.eta.toString()).toLocaleString() : 'N/A'}</span>
          </div>
        </div>
        <div className="p-grid">
          <label className="p-col-12 p-md-5">delivery date</label>
          <div className="p-col-12 p-md-7">
            <span>
              {purchaseOrder.delivered_at
                ? DateTime.fromISO(purchaseOrder.delivered_at.toString()).toLocaleString()
                : 'N/A'}
            </span>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="p-m-2 p-d-flex p-jc-center p-ai-center">
        <ProgressSpinner style={{width: '1em', height: '1em'}} strokeWidth="8" />
      </div>
    );
  }

  if (!purchaseOrders?.length) {
    return <div className="p-m-2">No purchase orders loaded.</div>;
  }

  return (
    <div className="p-grid p-m-0">
      {purchaseOrders?.map(purchaseOrder => (
        <div className="p-col-6 p-py-0 p-px-2 p-my-3" key={purchaseOrder.id}>
          {renderPurchaseOrder(purchaseOrder)}
        </div>
      ))}
    </div>
  );
};
