import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {OtherMenuOptions, PrintMenuOptions, StageMenuOptions} from '../Order/Constants/constants';
import OrderListComponent from '../Orders/OrderListComponent';

class AluListComponent extends React.Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <OrderListComponent
        showFilterBox={false}
        orderStages={['Alu Required', 'Alu Ordered', 'Alu Needs Cutting', 'Alu Cutting']}
        orderTypes={['Repair', 'Standard']}
        printMenuOptions={[PrintMenuOptions.AluCutSheets, PrintMenuOptions.AluPo]}
        stageMenuOptions={[
          StageMenuOptions.AluCut,
          StageMenuOptions.AluCutRequested,
          StageMenuOptions.AluOrdered,
          StageMenuOptions.AluDelivered,
        ]}
        otherMenuOptions={[OtherMenuOptions.RecordNote]}
        history={this.props.history}
      />
    );
  }
}

export default withRouter(AluListComponent);
