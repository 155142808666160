import React from 'react';
import {Card} from 'primereact/card';
import {AppContext} from 'two-app-ui';
import {Oos} from 'two-core';
import OosService from '../../services/OosService';
import {DateTime} from 'luxon';
import formats from '../../config/formats';

interface Props {
  oos: Oos;
}

class OosDetail extends React.Component<Props, {}> {
  static contextType = AppContext;
  oosService: OosService | null = null;

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.oosService = this.context.oosService;
  }

  render() {
    const oos = this.props.oos;

    return (
      <Card className={'p-d-flex order-detail-item'}>
        <div className="p-d-flex">
          <label htmlFor="stage" className="p-col-12 p-md-3">
            stage
          </label>
          <div className="p-col-12 p-md-9">
            <span>{oos.stage}</span>
          </div>
        </div>
        {oos.eta && (
          <div className="p-d-flex">
            <label htmlFor="eta" className="p-col-12 p-md-3">
              eta
            </label>
            <div className="p-col-12 p-md-9">
              <span>{DateTime.fromJSDate(new Date(oos.eta)).toFormat(formats.date)}</span>
            </div>
          </div>
        )}
      </Card>
    );
  }
}

export default OosDetail;
