import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, FactoryAlarm, QueryParameter, FactoryAlarmPatch} from 'two-core';

class AlarmsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.alarms ?? '';
  }

  async getAlarms(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then(data => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async getAlarm(id: string): Promise<FactoryAlarm> {
    return this.get(this.endpoint + '/' + id)
      .then(data => {
        return Promise.resolve((data as ApiResponse).body as FactoryAlarm);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async updateAlarm(id: string, data: FactoryAlarmPatch): Promise<FactoryAlarm> {
    return this.patch(this.endpoint + '/' + id, data)
      .then(data => {
        return Promise.resolve(data as FactoryAlarm);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }
}
export default AlarmsService;
