import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';

interface Props {
  data: any;
}

//interface State {}

class SSFabric extends CutSheetTemplate {
  report: Report;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
  }

  render() {
    this.report = new Report();

    this.report.addHeader(
      2,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">ss std v2</td>
              <td className="text">
                <b>FABRIC</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text" colSpan={3}>
                {this.props.data.reference}
              </td>
              <td className="label">shipping to</td>
              <td className="text">{this.props.data.ship_to}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      /* MIXED FABRICS */
    }
    const uniqueFabrics: string[] = [];
    for (const item of this.props.data.fabric.items) {
      if (!uniqueFabrics.includes(item.content.fabric + item.content.colour)) {
        uniqueFabrics.push(item.content.fabric + item.content.colour);
      }
    }
    if (uniqueFabrics.length > 1) {
      this.report.addItem(
        this.props.data,
        1,
        <React.Fragment>
          <div className="text">{`MIXED FABRICS !!! ${uniqueFabrics.length} different fabrics.`}</div>
        </React.Fragment>
      );
    }

    {
      this.props.data.fabric.items.map((item: any) => {
        let currentLineCount = 2;

        const zipRequired = item.content.zip_required ? 'zip' : <span>X</span>;
        let repair1, repair2, note;

        if (item.content.repair_respo) {
          repair1 = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">rework</td>
              <td className="text">{item.content.repair_whole_item ? 'YES' : 'NO'}</td>
              <td className="label">responsibility</td>
              <td className="text" colSpan={6}>
                {this.capitaliseFirst(item.content.repair_respo)}
              </td>
            </tr>
          );
          repair2 = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">repair Detail</td>
              <td className="text" colSpan={7}>
                {item.content.repair_details}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += 2;
        } else {
          repair1 = repair2 = '';
        }

        if (item.content.notes) {
          note = (
            <tr>
              <td />
              <td className="label">note</td>
              <td className="text" colSpan={5}>
                {`${item.content.notes}`.replace(/\r?\n|\r/g, ' ')}
              </td>
            </tr>
          );
          currentLineCount += Math.ceil(`${item.content.notes}`.length / 90);
        } else {
          note = '';
        }

        this.report.addItem(
          item,
          currentLineCount,
          <table className="mt-10 odd-zebra three-cols content">
            {repair1}
            {repair2}
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">product</td>
              <td className="text">{item.content.product}</td>
              <td className="label">fabric</td>
              <td className="text">{this.capitaliseFabric(item.content.fabric)}</td>
              <td className="label">width</td>
              <td className="number">{item.content.cloth_width}</td>
              <td className="sign-off">cut</td>
              <td className="sign-off">spline</td>
              <td className="sign-off title">{zipRequired}</td>
            </tr>
            <tr>
              <td />
              <td />
              <td />
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.colour)}</td>
              <td className="label">drop</td>
              <td className="number">{item.content.cloth_drop}</td>
              <td />
              <td />
              <td />
            </tr>

            {note}
          </table>
        );
      });
    }

    {
      /* CHECK SUM */
    }
    this.report.addItem(
      this.props.data,
      1,
      <React.Fragment>
        <table className="summary">
          <td className="label">total</td>
          <td className="text">{this.props.data.fabric.items.length}</td>
        </table>
      </React.Fragment>
    );

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="shadesol-standard-fabric-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default SSFabric;
