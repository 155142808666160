const productionLabelTemplatePhotos =
  ' CT~~CD,~CC^~CT~' +
  '^XA' +
  '~TA000' +
  '~JSN' +
  '^LT0' +
  '^MTT' +
  '^PON' +
  '^PMN' +
  '^LH0,0' +
  '^JMA' +
  '^PR8,8' +
  '~SD15' +
  '^JUS' +
  '^LRN' +
  '^CI27' +
  '^PA0,1,1,0' +
  '^XZ' +
  '^XA' +
  '^MMT' +
  '^PW831' +
  '^LL384' +
  '^LS0' +
  '^FT24,32^A0N,23,23^FH^CI28^FDQC & Pack^FS^CI27' +
  '^FO24,47^GB783,0,2^FS' +
  '^FT714,156^AFN,26,13^FB96,1,0,R^FH^FDPhotos^FS' +
  '^FO24,163^GB783,0,2^FS' +
  '^FT477,32^A0N,23,23^FB330,1,6,R^FH^CI28^FD{h_product}^FS^CI27' +
  '^FT24,95^A0N,28,28^FH^CI28^FD{order_code}^FS^CI27' +
  '^FT247,84^A0N,34,33^FH^CI28^FD{order_reference_1}^FS^CI27' +
  '^FT247,112^A0N,34,33^FH^CI28^FD{order_reference_2}^FS^CI27' +
  '^FT340,380^BQN,2,8' +
  '^FH^FDQA,{order_code}^FS' +
  '^PQ1,,,Y' +
  '^XZ';

export default productionLabelTemplatePhotos;
