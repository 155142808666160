import React from 'react';
import {EntityDetailField} from 'two-app-ui';
import {StockTake} from 'two-core';
import formats from '../../config/formats';
import {DateTime} from 'luxon';
interface Props {
  stockTake: StockTake;
}
export const StockTakeDetail = ({stockTake}: Props) => {
  return (
    <>
      <div className="p-mb-3">
        <EntityDetailField label="stage">{stockTake.stage}</EntityDetailField>
      </div>
      {stockTake.started_at && (
        <div>
          <EntityDetailField label="started">
            {stockTake.started_at ? DateTime.fromISO(stockTake.started_at?.toString()).toFormat(formats.dateTime) : ''}
          </EntityDetailField>
          <EntityDetailField label="by">{stockTake.started_by?.label}</EntityDetailField>
        </div>
      )}
      {stockTake.approved_at && (
        <div>
          <EntityDetailField label="approved">
            {stockTake.started_at ? DateTime.fromISO(stockTake.approved_at?.toString()).toFormat(formats.dateTime) : ''}
          </EntityDetailField>
          <EntityDetailField label="by">{stockTake.started_by?.label}</EntityDetailField>
        </div>
      )}
    </>
  );
};
