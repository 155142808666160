import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';
import {
  FactoryFloorTask,
  FloorTaskContentBlind,
  FloorTaskContentNote,
  FloorTaskContentRepair,
  FloorTaskContentSaw,
  MapOf,
} from 'two-core';

interface Props {
  data: any;
}

//interface State {}

class CVSaw_v3 extends CutSheetTemplate {
  report: Report;
  contentPrio: MapOf<number>;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
    this.contentPrio = {
      repair: 1,
      note: 2,
      tube: 3,
      rail: 4,
      deco_cover: 5,
      channel: 6,
      head_mount: 7,
    };
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      3,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">cv std v3</td>
              <td className="text">
                <b>SAW</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text">{this.props.data.reference}</td>
              <td className="label">shipping to</td>
              <td className="text" colSpan={3}>
                {this.props.data.ship_to}
              </td>
            </tr>
            <tr>
              <td className="label">product</td>
              <td className="text">CV Roller Blind</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      /* ITEMS */
    }
    {
      this.props.data.saw.items.sort((firstTask: FactoryFloorTask, secondTask: FactoryFloorTask) => {
        const task1Content = firstTask.content as FloorTaskContentBlind;
        const task2Content = secondTask.content as FloorTaskContentBlind;
        const weight1 =
          firstTask.order_item_index * 100 +
          (task1Content.blind_index ?? 1) * 10 +
          this.contentPrio[firstTask.category ?? 0];
        const weight2 =
          secondTask.order_item_index * 100 +
          (task2Content.blind_index ?? 1) * 10 +
          this.contentPrio[secondTask.category ?? 0];
        return weight1 - weight2;
      });

      let currentItemIndex = this.props.data.saw.items[0].order_item_index;
      let currentBlindIndex = -1;
      let itemRows: any[] = [];
      let repair1: any, repair2: any, repair3: any;
      repair1 = repair2 = repair3 = '';
      let isFirstRow = true;
      let currentLineCount = 1;
      let currentLineIndex = 0;

      this.props.data.saw.items.map((item: any) => {
        let indexColumn = <td className="no-top-border" />;
        let row;
        let rowClass = 'inside-row';
        const blindContent: FloorTaskContentBlind = item.content;

        if (item.order_item_index !== currentItemIndex) {
          currentItemIndex = item.order_item_index;
          currentBlindIndex = -1;
        }

        if (
          item.order_item_index !== currentItemIndex ||
          (blindContent.blind_index && blindContent.blind_index !== currentBlindIndex)
        ) {
          this.report.addItem(
            item,
            currentLineCount,
            <React.Fragment>
              <table className="mt-10 odd-zebra three-cols content">
                {repair1}
                {repair2}
                {repair3}
                {itemRows}
              </table>
            </React.Fragment>
          );
          repair1 = repair2 = repair3 = '';
          currentItemIndex = item.order_item_index;
          currentBlindIndex = blindContent.blind_index;
          currentLineCount = 0;
          currentLineIndex++;
          itemRows = [];
          isFirstRow = true;
        }

        if (isFirstRow) {
          isFirstRow = false;
          indexColumn = <td className="index">{currentLineIndex}</td>;
          rowClass = 'new-item';
        }

        if (item.category === 'note') {
          const taskContent: FloorTaskContentNote = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">note</td>
              <td className="text" colSpan={5}>
                {`${taskContent.text}`.replace(/\r?\n|\r/g, ' ')}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += Math.ceil(taskContent.text.length / 90);
        }

        if (item.category === 'repair') {
          const repairContent: FloorTaskContentRepair = item.content;
          repair1 = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">rework</td>
              <td className="text">{repairContent.repair_whole_item ? 'YES' : 'NO'}</td>
              <td className="label">type</td>
              <td className="text">{this.capitaliseFirst(repairContent.repair_type ?? 'Whole Item Rework')}</td>
              <td className="label">responsibility</td>
              <td className="text" colSpan={2}>
                {this.capitaliseFirst(repairContent.repair_responsibility)}
              </td>
            </tr>
          );
          if (repairContent.repair_type === 'cutdown (standard)') {
            repair2 = (
              <tr>
                <td />
                <td className="label">new height</td>
                <td className="number">{repairContent.new_height}</td>
                <td className="label">new width</td>
                <td className="number" colSpan={3}>
                  {repairContent.new_width}
                </td>
                <td className="sign-off">check</td>
              </tr>
            );
            currentLineCount += 1;
          }
          repair3 = (
            <tr>
              <td />
              <td className="label">repair</td>
              <td className="text" colSpan={5}>
                {repairContent.repair_details}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += 2;
        }

        if (item.category === 'tube') {
          const data: FloorTaskContentSaw = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">tube</td>
              <td className="text">{this.capitaliseFirst(data.extrusion)}</td>
              <td className="label">colour</td>
              <td className="text">MILL</td>
              <td className="label">length</td>
              <td className="number">{data.length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        }

        if (item.category === 'rail') {
          const data: FloorTaskContentSaw = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">base rail</td>
              <td className="text">{this.capitaliseFirst(data.extrusion)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(data.colour)}</td>
              <td className="label">length</td>
              <td className="number">{data.length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        }

        if (item.category === 'deco_cover') {
          const data: FloorTaskContentSaw = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">cover</td>
              <td className="text">{this.capitaliseFirst(data.extrusion)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(data.colour)}</td>
              <td className="label">length</td>
              <td className="number">{data.length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        }

        if (item.category === 'channel') {
          const data: FloorTaskContentSaw = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">channel</td>
              <td className="text">{this.capitaliseFirst(data.extrusion)}</td>
              <td className="label">colour</td>
              <td className="number">{data.colour}</td>
              <td className="label">length</td>
              <td className="number">{data.length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        }

        if (item.category === 'head_mount') {
          const data: FloorTaskContentSaw = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label no-top-border">mount rail</td>
              <td className="text">{this.capitaliseFirst(data.extrusion)}</td>
              <td className="label">colour</td>
              <td className="number">{data.colour}</td>
              <td className="label">length</td>
              <td className="number">{data.length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        }

        itemRows.push(row);
      });
      this.report.addItem(
        this.props.data,
        currentLineCount,
        <React.Fragment>
          <table className="mt-10 odd-zebra three-cols content">
            {repair1}
            {repair2}
            {repair3}
            {itemRows}
          </table>
        </React.Fragment>
      );

      this.report.addItem(
        this.props.data,
        1,
        <React.Fragment>
          <table className="summary">
            <td className="label">total</td>
            <td className="text">{currentLineIndex}</td>
          </table>
        </React.Fragment>
      );
    }

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="colorvue-standard-saw-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default CVSaw_v3;
