import React from 'react';
import {Fieldset} from 'primereact/fieldset';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {InputSwitch, InputSwitchChangeParams} from 'primereact/inputswitch';
import './FilterBoxComponent.scss';
import {productLines} from '../../config/factoryConstants';
interface Props {
  leadTime: number;
  totalActive: number;
  totalActiveFiltered: number;
  selectedProductLine: string;
  showDoneOrders: boolean;
  handleFilterChange: (e: InputSwitchChangeParams | DropdownChangeParams) => void;
}

const FilterboxComponent = ({
  leadTime,
  totalActive,
  totalActiveFiltered,
  selectedProductLine,
  showDoneOrders,
  handleFilterChange,
}: Props) => {
  return (
    <Fieldset id="filter-box" className="line-filter p-mb-3 p-p-1 w-100">
      <div className="p-d-flex p-flex-row p-flex-wrap">
        <div className="p-d-flex p-col-12 p-xs-12 p-sm-12 p-md-6 p-lg-3 p-as-center p-jc-center p-p-1">
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1 filter-box-span">Product Line</span>
          <div className="p-col-12 p-md-6 p-lg-6 p-p-0 p-as-center">
            <Dropdown
              name="productLine"
              className="dropdown-line"
              value={selectedProductLine}
              options={productLines}
              onChange={(e: DropdownChangeParams) => handleFilterChange(e)}
              optionLabel="label"
              optionValue="value"
              placeholder="Select option"
            />
          </div>
        </div>
        <div className="p-d-flex p-col-12 p-xs-12 p-sm-12 p-md-6 p-lg-3 p-as-center p-jc-center p-p-1">
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1 filter-box-span">Active Total</span>
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-0">
            {`${totalActive} (filtered to: ${totalActiveFiltered})`}
          </span>
        </div>
        <div className="p-d-flex p-col-12 p-xs-12 p-sm-12 p-md-6 p-lg-3 p-as-center p-jc-center p-p-1">
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1 filter-box-span">Current Lead Time</span>
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-0">{`${leadTime} days`}</span>
        </div>
        <div className="p-d-flex p-xs-12 p-sm-12 p-col-12 p-md-6 p-lg-3 p-as-center p-jc-center p-p-1">
          <span className="p-col-12 p-md-6 p-lg-6 p-as-center p-p-1 filter-box-span">Show Completed Orders</span>
          <div className="p-col-12 p-md-6 p-lg-6 p-p-0 p-as-center">
            <InputSwitch
              name="showDone"
              className={'p-as-center'}
              checked={showDoneOrders}
              onChange={e => handleFilterChange(e)}
            />
          </div>
        </div>
      </div>
    </Fieldset>
  );
};

export default FilterboxComponent;
