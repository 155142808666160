import React from 'react';
import {AppContext, TwoDialog, TwoToast} from 'two-app-ui';
import {InventoryItem, QueryParameter} from 'two-core';
import InventoryService from '../../../services/InventoryService';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';
import {AddStockTakeInventoryItemList, AddStockTakeInventoryItemListFilterProps} from './AddStockTakeInventoryItemList';
import {Skeleton} from 'primereact/skeleton';
import './AddStockTakeItemsDialog.scss';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  addStockTakeItems: (inventoryItems: InventoryItem[]) => void;
  assignedInvItemsIds?: string[];
}

interface State {
  loading: boolean;
  lazyLoading: boolean;
  newlyAssignedItems: InventoryItem[];
  loadedInventoryItems?: InventoryItem[];
  totalLoadedInventoryItems?: number;
  pagination: {
    pageSize: number;
    offset: number;
  };
  filters: AddStockTakeInventoryItemListFilterProps;
}

class AddStockTakeItemsDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  inventoryService?: InventoryService;
  twoToast?: TwoToast;
  observer?: IntersectionObserver;
  patrolRef = React.createRef<HTMLDivElement>();
  typingTimer?: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      lazyLoading: false,
      newlyAssignedItems: [],
      pagination: {
        pageSize: 50,
        offset: 0,
      },
      filters: {},
    };

    this.onHide = this.onHide.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onInventoryItemsLazyLoad = this.onInventoryItemsLazyLoad.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.inventoryService = this.context.inventoryService;
    this.twoToast = this.context.twoToast;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.onInventoryItemsLazyLoad();
      }
    }, options);
  }
  componentDidUpdate(prevProps: Props, prevState: State) {
    const {loading, lazyLoading} = this.state;
    if ((prevState.loading && !loading) || (prevState.lazyLoading && !lazyLoading)) {
      if (this.patrolRef?.current) {
        this.observer?.observe(this.patrolRef.current);
      }
    } else if (!prevState.lazyLoading && lazyLoading) {
      if (this.patrolRef?.current) {
        this.observer?.unobserve(this.patrolRef.current);
      }
    }
  }

  componentWillUnmount() {
    if (this.patrolRef?.current) {
      this.observer?.unobserve(this.patrolRef.current);
    }
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    const {pagination, filters} = this.state;
    const currentFactoryId = localStorage.getItem('current factory');
    this.setState({loading: true});
    // const excludedIds = [...(assignedInvItemsIds ?? []), ...newlyAssignedItems.map(item => item.id!)];
    const result = await this.loadInventoryItems(currentFactoryId!, pagination.pageSize, 0, filters);
    this.setState({
      loading: false,
      loadedInventoryItems: result?.records,
      totalLoadedInventoryItems: result?.total,
      pagination: {...pagination, offset: pagination.pageSize},
    });
  }

  async loadInventoryItems(
    currentFactoryId: string,
    pageSize: number,
    offset: number,
    filters: AddStockTakeInventoryItemListFilterProps
  ) {
    try {
      const queryFilters = [JSON.stringify({field: 'factory_id', value: currentFactoryId})];
      if (filters?.name?.length) {
        queryFilters.push(JSON.stringify({field: 'name', value: filters.name, condition: 'iLike'}));
      }
      if (filters?.colour?.length) {
        queryFilters.push(JSON.stringify({field: 'colour', value: filters.colour, condition: 'iLike'}));
      }
      if (filters?.category?.length) {
        queryFilters.push(JSON.stringify({field: 'category', value: filters.category, condition: 'in'}));
      }

      const queryParams: QueryParameter = {
        filters: queryFilters,
        offset,
        page_size: pageSize,
      };
      const response = await this.inventoryService!.getInventoryItems(queryParams);
      const records = (response?.records ?? []) as InventoryItem[];
      return {records, total: response?.total_records ?? 0};
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading inventory items');
      return undefined;
    }
  }

  async onInventoryItemsLazyLoad() {
    const {pagination, loadedInventoryItems, filters} = this.state;
    const currentFactoryId = localStorage.getItem('current factory')!;
    this.setState({lazyLoading: true});
    const result = await this.loadInventoryItems(currentFactoryId, pagination.pageSize, pagination.offset, filters);
    this.setState(
      {
        loadedInventoryItems: [...(loadedInventoryItems ?? []), ...(result?.records ?? [])],
        pagination: {...pagination, offset: pagination.offset + pagination.pageSize},
        lazyLoading: false,
      },
      () => {
        if (this.patrolRef?.current) {
          this.observer?.observe(this.patrolRef.current);
        }
      }
    );
  }

  onHide() {
    this.props.onHide();
  }

  onShow() {
    this.setState({
      loading: false,
      newlyAssignedItems: [],
    });
    this.loadData();
  }

  onAdd() {
    const {addStockTakeItems} = this.props;
    const {newlyAssignedItems} = this.state;
    if (!newlyAssignedItems.length) {
      this.twoToast?.showInfo('No items selected');
    } else {
      addStockTakeItems(newlyAssignedItems);
    }
    this.onHide();
  }

  onDragEnd(result: DropResult) {
    const {loadedInventoryItems} = this.state;
    const {source, destination} = result;
    if (source?.droppableId === destination?.droppableId) {
      return;
    }
    const destinationId = destination?.droppableId;
    const draggedInvItemId = result.draggableId.split('_')[0];
    const invItem = loadedInventoryItems!.find(item => item.id === draggedInvItemId)!;
    if (destinationId === 'newly-assigned-inventory-item-list') {
      this.setState(state => ({
        newlyAssignedItems: [...state.newlyAssignedItems, invItem],
      }));
    } else if (destinationId === 'loaded-inventory-item-list') {
      this.setState(state => ({
        newlyAssignedItems: state.newlyAssignedItems.filter(item => item.id !== draggedInvItemId),
      }));
    }
  }

  onFilterChange = (filters: Partial<AddStockTakeInventoryItemListFilterProps>) => {
    this.setState(
      state => ({filters: {...state.filters, ...filters}}),
      () => {
        if (this.typingTimer) {
          clearTimeout(this.typingTimer);
        }
        this.typingTimer = setTimeout(this.loadData, 2000);
      }
    );
  };

  render() {
    const {assignedInvItemsIds, showDialog} = this.props;
    const {newlyAssignedItems, loading, totalLoadedInventoryItems, loadedInventoryItems, pagination, filters} =
      this.state;

    const newlyAssignedInvItemsIds = newlyAssignedItems.map(item => item.id);
    const assignableInvItems = (loadedInventoryItems ?? []).filter(
      item => !newlyAssignedInvItemsIds?.includes(item.id!) && !assignedInvItemsIds?.includes(item.id!)
    );
    let patrolElement = undefined;
    if (pagination.offset < (totalLoadedInventoryItems ?? 0)) {
      //create patrol item at the end of the list
      const patrolItem: InventoryItem = {
        id: 'patrol',
        name: '',
        colour: '',
        sku: '',
        category: '',
        uom: '',
        factory_id: '',
        type: '',
        updated_at: new Date(),
      };
      assignableInvItems.push(patrolItem);
      patrolElement = (
        <div ref={this.patrolRef} className="w-100">
          <Skeleton />
        </div>
      );
    }

    return (
      <TwoDialog
        headerTitle={'Add Stock Take Inventory Items'}
        showDialog={showDialog}
        visible={showDialog}
        style={{width: '75vw', height: '90vh'}}
        breakpoints={{'768px': '80vw', '576px': '90vw'}}
        onHide={this.onHide}
        onSave={this.onAdd}
        onShow={this.onShow}
        saveButtonTitle="Add"
        className={'add-stock-take-items-dialog'}
      >
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className="p-d-flex p-flex-column w-100 h-100">
            <div className="p-mb-2">Search for the item(s) you want to add to your Stock take here.</div>
            <div id="loaded_inventory_item_list_container">
              <AddStockTakeInventoryItemList
                items={assignableInvItems}
                droppableId={'loaded-inventory-item-list'}
                pageSizeIdentifier={'loaded_inventory_item_list_container'}
                patrolElement={patrolElement}
                onFilterChange={this.onFilterChange}
                filters={filters}
                loading={loading}
              />
            </div>
            <div className="p-mb-2">Drag and drop the chosen items into the table below.</div>
            <div id="newly_assigned_inventory_item_list_container">
              <AddStockTakeInventoryItemList
                items={newlyAssignedItems ?? []}
                droppableId={'newly-assigned-inventory-item-list'}
                pageSizeIdentifier={'newly_assigned_inventory_item_list_container'}
              />
            </div>
          </div>
        </DragDropContext>
      </TwoDialog>
    );
  }
}

export default AddStockTakeItemsDialog;
