import React from 'react';

class ReportFooter {
  pageNumber: string;
  constructor() {
    this.pageNumber = '0/0';
  }
  setPageNumber(pageNumber: string) {
    this.pageNumber = pageNumber;
  }
  getFooter(): JSX.Element {
    return <React.Fragment>Missing footer definition!!</React.Fragment>;
  }
}

export default ReportFooter;
