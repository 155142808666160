import React from 'react';
import {AppContext, MessageService, ToastService, TwoDataTable, TwoDialog} from 'two-app-ui';
import {Order, PurchaseOrder} from 'two-core';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import {messages} from '../../config/messages';

interface Props {
  purchaseOrder: PurchaseOrder;
  orders: Order[];
  toast: React.RefObject<Toast>;
  showDialog: boolean;
  onHide: () => void;
}

interface State {
  loading: boolean;
}

class PurchaseOrderOrderRemoveDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  purchaseOrdersService: PurchaseOrdersService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };

    this.hideDialog = this.hideDialog.bind(this);
  }

  componentDidMount() {
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.toastService = this.context.toastService;
  }

  hideDialog() {
    this.props.onHide();
    this.setState({
      loading: false,
    });
  }

  remove(moveStageToReady: boolean) {
    this.setState({loading: true});
    const purchaseOrder = this.props.purchaseOrder;
    const poOrders = purchaseOrder.related_order_ids?.filter(o => o !== null) ?? [];
    const ordersIds = this.props.orders.map(o => o.id ?? '');

    const updatePurchaseOrders = poOrders.filter(po => !ordersIds.includes(po));
    const po = {
      ...purchaseOrder,
      related_order_ids: updatePurchaseOrders,
    };
    this.purchaseOrdersService
      ?.updatePurchaseOrder(po.id ?? '', po)
      .then(() => {
        if (moveStageToReady) {
          MessageService.sendMessage(messages.orderStageChangeReady);
        } else {
          MessageService.sendMessage(messages.purchaseOrderUpdated);
        }
        this.hideDialog();
        this.toastService?.showSuccess(this.props.toast, `Purchase Order ${purchaseOrder.id} updated successfully.`);
      })
      .catch(error => {
        this.toastService?.showError(
          this.props.toast,
          `Sorry, Purchase Order ${purchaseOrder.id} updated failed, please try again.`
        );
        this.setState({loading: false});
        console.error('error: ' + error);
      });
  }

  render() {
    const {purchaseOrder} = this.props;

    const footer = (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={() => this.hideDialog()} />
        <Button
          label={'Yes'}
          className={'p-mr-2'}
          onClick={() => {
            this.remove(true);
          }}
        />
        <Button
          label={'No'}
          className={'p-mr-2'}
          onClick={() => {
            this.remove(false);
          }}
          autoFocus
        />
      </div>
    );

    const dialogBody = (
      <>
        <div className="p-d-flex p-flex-column" style={{height: '100%', width: '100%'}}>
          <span className="p-d-flex p-pb-2 p-pt-2">
            Do you also want to change the stage of the order(s) below to change to Ready ?
          </span>
          <div id="order_list_page" className="page-container">
            <TwoDataTable
              style={{height: '100%'}}
              heightToScroll={undefined}
              selectedItems={[]}
              loading={this.state.loading}
              value={this.props.orders}
              activeFilters={{}}
              showPaging={false}
            >
              <Column header="Order Code" field="id" style={{width: '180px'}} showFilterMenu={false} />
              <Column header="Reference" field="reference" style={{width: '360px'}} showFilterMenu={false} />
              <Column
                header="Customer"
                field="owner_company.account_number"
                style={{width: '150px'}}
                showFilterMenu={false}
              />
              <Column
                header="Prod Line"
                field="factory_order.product_line"
                style={{width: '120px'}}
                showFilterMenu={false}
              />
              <Column header="Type" field="type" style={{width: '120px'}} showFilterMenu={false} />
              <Column
                header="Stage"
                field="factory_order.production_stage"
                style={{width: '180px'}}
                showFilterMenu={false}
              />
              <Column header="Size" field="factory_order.size" style={{width: '80px'}} showFilterMenu={false} />
              <Column header="Summary" field="factory_order.summary" style={{width: '180px'}} showFilterMenu={false} />
            </TwoDataTable>
          </div>
        </div>
      </>
    );
    return (
      <TwoDialog
        headerTitle={`Remove Order(s) from PO ${purchaseOrder.id ?? ''}`}
        showDialog={this.props.showDialog}
        visible={this.props.showDialog}
        width={80}
        onHide={this.hideDialog}
        loading={this.state.loading}
        footer={footer}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}

export default PurchaseOrderOrderRemoveDialog;
