import React from 'react';
import {IconProp, library} from '@fortawesome/fontawesome-svg-core';
import {faCalendarExclamation} from '@fortawesome/pro-regular-svg-icons';
import {formats} from '../../config/formats';
import {DateTime} from 'luxon';
import InventoryService from '../../services/InventoryService';
import {AppContext, ReferenceComponent, ReferenceType} from 'two-app-ui';
import {InventoryItem, Oos} from 'two-core';

library.add(faCalendarExclamation);

interface Props {
  identifier: string;
  oosRecords: Oos[];
  oosInventoryItems: InventoryItem[];
  handleChangeOosInventoryItems?: (inventoryItems: InventoryItem[]) => void;
  handleOosReferenceClick?: (oos: Oos) => void;
}

class OosReferenceComponent extends React.Component<Props> {
  static contextType = AppContext;

  inventoryItemService: InventoryService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.inventoryItemService = this.context.inventoryService;
  }

  async loadInventoryItem(inventoryItemId: string) {
    if (inventoryItemId) {
      const existingInventoryItems = [...this.props.oosInventoryItems];
      await this.inventoryItemService?.getInventoryItem(inventoryItemId).then((data: InventoryItem) => {
        const inventoryItem = data;
        if (inventoryItem) {
          existingInventoryItems.push(inventoryItem);
          if (this.props.handleChangeOosInventoryItems) {
            this.props.handleChangeOosInventoryItems(existingInventoryItems);
          }
        }
      });
    }
  }

  resolveType(stage: string, etaDate: DateTime | undefined) {
    let poType = ReferenceType.DEFAULT;
    switch (stage.toLocaleLowerCase()) {
      case 'eta not confirmed':
        poType = ReferenceType.INFO;
        break;
      case 'available':
        poType = ReferenceType.SUCCESS;
        break;
      case 'eta confirmed':
        poType = etaDate
          ? etaDate > DateTime.now()
            ? ReferenceType.DEFAULT
            : ReferenceType.DANGER
          : ReferenceType.WARNING;
        break;
      case 'delayed':
        poType = etaDate && etaDate > DateTime.now() ? ReferenceType.WARNING : ReferenceType.DANGER;
        break;
    }
    return poType;
  }

  onTooltipShow = async () => {
    const suppliersIds = this.props.oosInventoryItems.map(inventoryItem => inventoryItem.id);
    for (const oosRecord of this.props.oosRecords) {
      if (!suppliersIds.includes(oosRecord.inventory_item_id)) {
        this.loadInventoryItem(oosRecord.inventory_item_id);
      }
    }
  };

  tooltipElement = (oos: Oos, inventoryItem: InventoryItem | undefined) => {
    const tooltipElement = (
      <div className={'p-d-flex p-flex-column'}>
        <div className="p-grid">
          <label className="p-col-12 p-md-5">OOS item</label>
          <div className="p-col-12 p-md-7">
            <span>
              {inventoryItem?.name ?? ''}
              {inventoryItem?.colour ? ' - ' + inventoryItem.colour : ''}
            </span>
          </div>
        </div>

        <div className="p-grid">
          <label htmlFor="type" className="p-col-12 p-md-5">
            stage
          </label>
          <div className="p-col-12 p-md-7">
            <span>{oos?.stage ?? ''}</span>
          </div>
        </div>

        <div className="p-grid">
          <label htmlFor="size" className="p-col-12 p-md-5 ">
            eta
          </label>
          <div className="p-col-12 p-md-7">
            <span>{oos.eta ? DateTime.fromISO(oos.eta.toString()).toLocaleString() : 'N/A'}</span>
          </div>
        </div>
      </div>
    );

    return tooltipElement;
  };

  render() {
    const icon: IconProp = ['far', 'calendar-exclamation'];

    return (
      this.props.oosRecords &&
      this.props.oosRecords.map((oos: Oos, index: number) => {
        if (oos) {
          const stage = oos.stage;
          const eta = oos.eta;
          const etaDate = eta ? DateTime.fromISO(eta.toString()) : undefined;

          const etaValue = etaDate ? etaDate.toFormat(formats.shortDate) : 'No ETA';
          const typeValue = this.resolveType(stage, etaDate);
          const inventoryItem = this.props.oosInventoryItems.find(
            inventoryItem => inventoryItem.id === oos.inventory_item_id
          );
          const tooltipElement = this.tooltipElement(oos, inventoryItem);

          const id = this.props.identifier + oos.id + '-' + index;
          return (
            <ReferenceComponent
              key={'oos-reference-component-' + id}
              label={etaValue}
              index={'oos-reference-' + id}
              faIcon={icon}
              type={typeValue}
              tooltipElement={tooltipElement}
              onTooltipShow={this.onTooltipShow}
              onReferenceClick={() =>
                this.props.handleOosReferenceClick ? this.props.handleOosReferenceClick(oos) : null
              }
            />
          );
        } else {
          return <React.Fragment key={index}></React.Fragment>;
        }
      })
    );
  }
}

export default OosReferenceComponent;
