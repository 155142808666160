import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Subscription} from 'rxjs';
import {
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  UsersService,
  TwoDataTable,
  AppColumnMenuBodyTemplate,
  TwoMessage,
  ToastService,
  AppMenuItemSeparator,
} from 'two-app-ui';
import '../../scss/CustomTable.scss';
import {MenuItemOptions} from 'primereact/menuitem';
import AlarmsService from '../../services/AlarmsService';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCheck, faUndo, faPen, faVolumeSlash, faToggleOn, faToggleOff} from '@fortawesome/pro-regular-svg-icons';
import {Toast} from 'primereact/toast';
import OrdersService from '../../services/OrdersService';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import formats from '../../config/formats';
import {FactoryAlarm, QueryParameter, User, TwoType, FactoryAlarmPatch} from 'two-core';
import values from '../../config/values';
import './Alarms.scss';
import './AlarmListComponent.scss';
import {messages} from '../../config/messages';
import SilenceUntilDialog from './SilenceUntilDialog';
import AlarmDialog from './AlarmDialog';
import {Link} from 'react-router-dom';
import {Tooltip} from 'primereact/tooltip';
import {Dropdown} from 'primereact/dropdown';

library.add(faCheck);
library.add(faUndo);
library.add(faVolumeSlash);
library.add(faPen);

interface State {
  loading: boolean;
  users: User[];
  alarms: FactoryAlarm[];
  selectedAlarm: FactoryAlarm | undefined;
  totalAlarms: number;
  showEditDialog: boolean;
  showSilenceUntilDialog: boolean;
  activeFilters: {};
  filters: {
    detail: string;
    reference: string;
    closed_by: string;
    done: Boolean | undefined;
    created_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    closed_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    silence_until: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  showDone: boolean;
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  selectedAlarms: FactoryAlarm[];
}

class AlarmListComponent extends React.Component<{}, State> {
  static contextType = AppContext;
  purchaseOrdersService: PurchaseOrdersService | null;
  ordersService: OrdersService | null;
  alarmsService: AlarmsService | null;
  usersService: UsersService | null;
  subscription: Subscription = new Subscription();
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: {}) {
    super(props);
    this.alarmsService = null;
    this.ordersService = null;
    this.purchaseOrdersService = null;
    this.usersService = null;

    this.state = {
      users: [],
      alarms: [],
      selectedAlarm: undefined,
      totalAlarms: 0,
      loading: false,
      showEditDialog: false,
      showSilenceUntilDialog: false,
      activeFilters: {},
      filters: {
        closed_by: '',
        reference: '',
        detail: '',
        closed_at: {
          fromDate: null,
          toDate: null,
        },
        created_at: {
          fromDate: null,
          toDate: null,
        },
        done: undefined,
        silence_until: {
          fromDate: null,
          toDate: null,
        },
      },
      showDone: false,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      selectedAlarms: [],
    };

    this.detailBodyTemplate = this.detailBodyTemplate.bind(this);
    this.doneBodyTemplate = this.doneBodyTemplate.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.bulkUpdateAlarms = this.bulkUpdateAlarms.bind(this);
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.alarmsService = this.context.alarmsService;
    this.ordersService = this.context.ordersService;
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.usersService = this.context.usersService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.alarmUpdated) {
        this.loadData();
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
          await localStorage.setItem('current factory', castedMessage.value as string);
          this.loadData();
        }
      }
    });

    this.loadData();
    this.loadUsers();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedAlarms = this.state.selectedAlarms;
    const selectedAlarmsCount = selectedAlarms.length;

    if (selectedAlarmsCount > 0) {
      this.initOtherMenuItems(menuItems, selectedAlarms);
    }
    menuItems.push({
      separator: true,
      template: () => {
        <AppMenuItemSeparator />;
      },
    });
    menuItems.push({
      label: `${this.state.showDone ? 'Hide Done' : 'Show Done'}`,
      faIcon: this.state.showDone ? faToggleOn : faToggleOff,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: async () => {
        await this.setState({showDone: !this.state.showDone});
        MessageService.sendMessage(messages.initTableMenuItems);
        this.loadData();
      },
    });
    return menuItems;
  }

  initOtherMenuItems(menuItems: AppMenuItem[], selectedAlarms: FactoryAlarm[]) {
    // "Silence Until" menu item
    menuItems.push({
      label: 'Silence Until',
      faIcon: faVolumeSlash,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.setState({showSilenceUntilDialog: true});
      },
    });
    // "Done" menu item
    if (selectedAlarms.every(alarm => !alarm.done)) {
      menuItems.push({
        label: 'Mark as Done',
        faIcon: faCheck,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          const user = this.getCurrentUser();
          const updatedAlarms: FactoryAlarmPatch[] = selectedAlarms.map(alarm => {
            return {
              id: alarm.id,
              done: true,
              closed_by: user?.id,
              closed_at: new Date(),
            };
          });
          this.bulkUpdateAlarms(updatedAlarms);
        },
      });
    }
    // "Undone" menu item
    if (selectedAlarms.every(alarm => alarm.done)) {
      menuItems.push({
        label: 'Undone',
        faIcon: faUndo,
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          const updatedAlarms: FactoryAlarmPatch[] = selectedAlarms.map(alarm => {
            return {
              id: alarm.id,
              done: false,
              closed_by: null,
              closed_at: null,
            };
          });
          this.bulkUpdateAlarms(updatedAlarms);
        },
      });
    }
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    const currentFactoryId = localStorage.getItem('current factory') ?? '';

    //set current factory filter
    filters.push(
      JSON.stringify({
        field: 'factory_id',
        value: currentFactoryId,
      })
    );

    //set filters from table
    if (this.state.filters.detail) {
      filters.push(
        JSON.stringify({
          field: 'detail',
          value: this.state.filters.detail,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.reference) {
      filters.push(
        JSON.stringify({
          field: 'reference_id',
          value: this.state.filters.reference,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.closed_by) {
      filters.push(
        JSON.stringify({
          field: 'user.username',
          value: this.state.filters.closed_by,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.closed_at.fromDate) {
      const fromDate = this.state.filters.closed_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'closed_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.closed_at.toDate) {
      const toDate = this.state.filters.closed_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'closed_at',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.filters.created_at.fromDate) {
      const fromDate = this.state.filters.created_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.created_at.toDate) {
      const toDate = this.state.filters.created_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.filters.silence_until.fromDate) {
      const fromDate = this.state.filters.silence_until.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'silence_until',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.silence_until.toDate) {
      const toDate = this.state.filters.silence_until.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'silence_until',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.filters.done !== undefined) {
      filters.push(
        JSON.stringify({
          field: 'done',
          value: this.state.filters.done,
          condition: '=',
        })
      );
    }
    if (!this.state.showDone) {
      filters.push(
        JSON.stringify({
          field: 'done',
          value: false,
          condition: '=',
        })
      );
    }

    //some columns have different name in database and map for server

    const sortByStringyfied = JSON.stringify({
      field: this.state.sortBy?.field ?? 'created_at',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyfied],
      aggregate: true,
    };

    this.setState({activeFilters: {...filters}});

    this.alarmsService
      ?.getAlarms(params)
      .then(data => {
        const alarms = data.records as FactoryAlarm[];

        this.setState({
          alarms: alarms,
          totalAlarms: data.total_records ?? 0,
          loading: false,
          selectedAlarms: [],
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  loadUsers() {
    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };

    this.usersService
      ?.getUsers(params)
      .then(data => {
        const users: User[] = (data?.records as User[]) ?? [];

        this.setState({
          users: users,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DateColumnFilterChangeEvent) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  async setChangeSelectedItem(item: FactoryAlarm) {
    const items = [...this.state.selectedAlarms];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedAlarms: items});
    }
  }

  referenceBodyTemplate(rowData: FactoryAlarm) {
    return (
      <Link target={'_blank'} to={`/order/${rowData.reference_id}`}>
        {rowData.reference_id}
      </Link>
    );
  }

  detailBodyTemplate(rowData: FactoryAlarm) {
    return (
      <AppColumnMenuBodyTemplate
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems()}
        selectedItems={this.state.selectedAlarms}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <a onClick={() => this.openEditDialog(rowData)} className={'detail-collapse'} id={`chip-detail-${rowData.id}`}>
          {rowData.detail}
        </a>
        <Tooltip className={'chip-tooltip'} target={`#chip-detail-${rowData.id}`} position="right">
          {rowData.detail}
        </Tooltip>
      </AppColumnMenuBodyTemplate>
    );
  }

  doneBodyTemplate(rowData: FactoryAlarm) {
    return (
      <React.Fragment>
        <div className={''} data-id={rowData.id?.toString()}>
          {rowData.done ? <FontAwesomeIcon icon={['far', 'check']}></FontAwesomeIcon> : ''}
        </div>
      </React.Fragment>
    );
  }

  createdOnBodyTemplate(rowData: FactoryAlarm) {
    return this.dateBodyTemplate(rowData.created_at);
  }

  closedOnBodyTemplate(rowData: FactoryAlarm) {
    return this.dateBodyTemplate(rowData.closed_at);
  }

  silenceUntilBodyTemplate(rowData: FactoryAlarm) {
    return (
      <span>
        {rowData.silence_until ? DateTime.fromISO(rowData.silence_until?.toString()).toFormat(formats.date) : ''}
      </span>
    );
  }

  dateBodyTemplate(date: Date | undefined | null) {
    return <span>{date ? DateTime.fromISO(date?.toString()).toFormat(formats.dateTime) : ''}</span>;
  }

  closedByBodyTemplate(rowData: FactoryAlarm) {
    const userName = rowData?.closed_by ? this.state.users?.find(u => u.id === rowData.closed_by)?.username : '';
    return <span>{userName}</span>;
  }

  async openEditDialog(alarm: FactoryAlarm) {
    await this.setState({
      selectedAlarm: alarm,
      showEditDialog: true,
    });

    MessageService.sendMessage(messages.alarmEdit);
  }

  getCurrentUser() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = unparsedUser ? JSON.parse(unparsedUser) : undefined;
    const currentUserUsername = currentUser?.username ?? '';
    let currentUserId = currentUser.uuid;
    if (currentUserUsername && !currentUserId) {
      const users = this.state.users;
      const user = users.find(u => u.username === currentUserUsername);
      currentUserId = user?.id;
    }

    return {
      username: currentUserUsername,
      id: currentUserId,
    };
  }

  bulkUpdateAlarms(updatedAlarms: FactoryAlarmPatch[]) {
    this.setState({loading: true});
    for (const updatedAlarm of updatedAlarms) {
      this.alarmsService
        ?.updateAlarm(updatedAlarm.id?.toString() ?? '', updatedAlarm)
        .then(() => {
          this.toastService?.showSuccess(this.toast, 'Alarm updated successfully.');

          this.loadData();
        })
        .catch(() => {
          this.toastService?.showError(this.toast, 'Sorry, alarm update failed, please try again.');
        })
        .finally(() => {
          this.setState({loading: false});
        });
    }
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement> | DateColumnFilterChangeEvent) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  render() {
    const detailFilter = (
      <InputText
        name="detail"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const closedByFilter = (
      <InputText
        name="closed_by"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const referenceFilter = (
      <InputText
        name="reference"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const createdDateFilter = (
      <DateColumnFilter
        name="created_at"
        value={this.state.filters.created_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const closedOnFilter = (
      <DateColumnFilter name="closed_at" value={this.state.filters.closed_at} onChange={e => this.onFilterChange(e)} />
    );

    const silencedFilter = (
      <DateColumnFilter
        name="silence_until"
        value={this.state.filters.silence_until}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const doneOptions = [
      {name: 'All', code: 'all'},
      {name: 'True', code: true},
      {name: 'False', code: false},
    ];

    const doneFilter = (
      <Dropdown
        name="done"
        value={this.state.filters.done ?? 'all'}
        options={doneOptions}
        onChange={e => {
          if (e.target.value === 'all') {
            e.target.value = undefined;
          }
          this.onFilterChange(e);
        }}
        optionLabel="name"
        optionValue="code"
        className="form-filter"
      />
    );

    return (
      <div id="alarms_page_container" className="page-container">
        <TwoDataTable
          id={'alarms-table'}
          customEmptyMessage={'All systems working smoothly.'}
          selectedItems={this.state.selectedAlarms}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          handleChangeSelectedItems={(items: TwoType[]) => this.setState({selectedAlarms: items as FactoryAlarm[]})}
          selectionMode="multiple"
          loading={this.state.loading}
          activeFilters={this.state.activeFilters}
          value={this.state.alarms}
          totalRecords={this.state.totalAlarms}
          pageSizeIdentifier={'alarms_page_container'}
          initMenuItems={this.initMenuItems}
        >
          <Column
            header="Detail"
            field="detail"
            body={item => this.detailBodyTemplate(item)}
            filter
            filterElement={detailFilter}
            style={{width: '300px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Date Created"
            field="created_at"
            body={item => this.createdOnBodyTemplate(item)}
            filter
            filterElement={createdDateFilter}
            style={{width: '150px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Silenced Until"
            field="silence_until"
            body={item => this.silenceUntilBodyTemplate(item)}
            filter
            filterElement={silencedFilter}
            style={{width: '150px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Reference"
            field="reference_id"
            body={item => this.referenceBodyTemplate(item)}
            filter
            filterElement={referenceFilter}
            style={{width: '100px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Done"
            field="done"
            body={item => this.doneBodyTemplate(item)}
            filter
            filterElement={doneFilter}
            style={{width: '50px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Closed on"
            field="closed_at"
            body={item => this.closedOnBodyTemplate(item)}
            filter
            filterElement={closedOnFilter}
            style={{width: '50px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Closed by"
            field="closed_by"
            body={item => this.closedByBodyTemplate(item)}
            filter
            filterElement={closedByFilter}
            style={{width: '50px'}}
            showFilterMenu={false}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <SilenceUntilDialog
          showDialog={this.state.showSilenceUntilDialog}
          toast={this.toast}
          selectedAlarms={this.state.selectedAlarms}
          onHide={() => {
            this.setState({showSilenceUntilDialog: false});
          }}
          onSave={this.bulkUpdateAlarms}
        />
        <AlarmDialog
          toast={this.toast}
          selectedAlarm={this.state.selectedAlarm}
          showDialog={this.state.showEditDialog}
          onHide={() => {
            this.setState({showEditDialog: false});
          }}
        />
      </div>
    );
  }
}

export default AlarmListComponent;
