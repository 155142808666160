import React from 'react';
import {AppContext, TwoDataTable, TwoToast} from 'two-app-ui';
import {Bom, BomAggregate} from 'two-core';
import {Column} from 'primereact/column';
import BomService from '../../services/BomService';

interface Props {
  purchaseOrderId: string;
  orderId: string;
}

interface State {
  boms: Bom[];
  loading: boolean;
}

class PurchaseOrderOrderBoms extends React.Component<Props, State> {
  static contextType = AppContext;
  bomService?: BomService;
  twoToast?: TwoToast;
  constructor(props: Props) {
    super(props);
    this.state = {
      boms: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.bomService = this.context.bomService;
    this.twoToast = this.context.twoToast;
    this.loadData();
  }

  async loadData() {
    this.setState({loading: true});
    const {purchaseOrderId, orderId} = this.props;
    this.setState(() => ({loading: true}));
    const boms = (await this.loadBoms(orderId, purchaseOrderId)) ?? [];

    this.setState(() => ({loading: false, boms}));
  }

  async loadBoms(orderId: string, purchaseOrderId: string) {
    try {
      const filters = [
        JSON.stringify({
          field: 'order_id',
          value: orderId,
        }),
        JSON.stringify({
          field: 'ordered_on_po_id',
          value: purchaseOrderId,
        }),
      ];
      const aggregate: BomAggregate[] = ['inventory_item'];
      return await this.bomService!.getBoms({filters, aggregate});
    } catch (e) {
      this.twoToast?.showError('Error loading BOMs');
      return [];
    }
  }

  render() {
    const {boms, loading} = this.state;
    const {orderId} = this.props;

    return (
      <div id={`${orderId}-table`} className="page-container">
        <TwoDataTable
          pageSizeIdentifier={'boms-page-size'}
          selectedItems={[]}
          value={boms}
          activeFilters={{}}
          showPaging={false}
          dataKey="supply_item_id"
          loading={loading}
          hideFilter
        >
          <Column header="Name" field="inventory_item.name" />
          <Column header="Colour" field="inventory_item.colour" />
          <Column header="SKU" field="inventory_item.sku" />
          <Column header="Quantity" field="quantity" />
        </TwoDataTable>
      </div>
    );
  }
}

export default PurchaseOrderOrderBoms;
