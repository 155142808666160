const productionLabelTemplateTwoLines_v2 =
  ' CT~~CD,~CC^~CT~' +
  '^XA' +
  '~TA000' +
  '~JSN' +
  '^LT0' +
  '^MTT' +
  '^PON' +
  '^PMN' +
  '^LH0,0' +
  '^JMA' +
  '^PR8,8' +
  '~SD15' +
  '^JUS' +
  '^LRN' +
  '^CI27' +
  '^PA0,1,1,0' +
  '^XZ' +
  '^XA' +
  '^MMT' +
  '^PW831' +
  '^LL384' +
  '^LS0' +
  '^FO24,47^GB783,0,2^FS' +
  '^FT369,34^A0N,28,28^FH^CI28^FDBAY^FS^CI27' +
  '^FO24,163^GB783,0,2^FS' +
  '^FT477,32^A0N,23,23^FB330,1,6,R^FH^CI28^FD{h_product}^FS^CI27' +
  '^FT24,32^A0N,23,23^FH^CI28^FD{h_station}^FS^CI27' +
  '^FT24,95^A0N,28,28^FH^CI28^FD{order_code}^FS^CI27' +
  '^FT247,84^A0N,34,33^FH^CI28^FD{order_reference_1}^FS^CI27' +
  '^FT247,112^A0N,34,33^FH^CI28^FD{order_reference_2}^FS^CI27' +
  '^FT266,156^AFN,26,13^FB544,1,0,R^FH^FD{task_type}^FS' +
  '^FT24,154^A0N,31,30^FH^CI28^FD{item_index}^FS^CI27' +
  '^FT424,35^A0N,31,30^FH^CI28^FD{bay}^FS^CI27' +
  '^FT24,212^A0N,34,33^FH^CI28^FD{data_1_1_A}^FS^CI27' +
  '^FT24,240^A0N,34,33^FH^CI28^FD{data_1_1_B}^FS^CI27' +
  '^FT423,212^A0N,34,33^FH^CI28^FD{data_1_2_A}^FS^CI27' +
  '^FT424,240^A0N,34,33^FH^CI28^FD{data_1_2_B}^FS^CI27' +
  '^FT24,288^A0N,34,33^FH^CI28^FD{data_2_1_A}^FS^CI27' +
  '^FT24,316^A0N,34,33^FH^CI28^FD{data_2_1_B}^FS^CI27' +
  '^FT423,288^A0N,34,33^FH^CI28^FD{data_2_2_A}^FS^CI27' +
  '^FT423,316^A0N,34,33^FH^CI28^FD{data_2_2_B}^FS^CI27' +
  '^BY3,3,40^FT322,376^BCN,,N,N,,D' +
  '^FDt{ask_id}^FS' +
  '^PQ1,,,Y' +
  '^XZ';

export default productionLabelTemplateTwoLines_v2;
