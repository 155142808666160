import React from 'react';
import {AppContext, TwoDialog, TwoToast} from 'two-app-ui';
import StockTakesService from '../../services/StockTakesService';
import {StockTakeInventoryItem} from 'two-core';
import {Button} from 'primereact/button';
import {InputNumber} from 'primereact/inputnumber';

interface Props {
  showDialog: boolean;
  item: StockTakeInventoryItem;
  onHide: () => void;
  onSave: (newCount: number, stockTakeInventoryItem: StockTakeInventoryItem) => void;
}

interface State {
  newCountValue?: number;
}

export default class EditStockTakeItemDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  stockTakesService?: StockTakesService;

  twoToast?: TwoToast;
  constructor(props: Props) {
    super(props);

    this.state = {};

    this.onHide = this.onHide.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.stockTakesService = this.context.stockTakesService;
    this.twoToast = this.context.twoToast;
  }

  onHide() {
    this.setState({
      newCountValue: undefined,
    });
    this.props.onHide();
  }

  onSave() {
    const {newCountValue} = this.state;
    const {item} = this.props;
    if (newCountValue !== undefined) {
      this.props.onSave(newCountValue, item);
    } else {
      this.twoToast?.showInfo('Item not changed');
    }
    this.onHide();
  }

  render() {
    const {showDialog, item} = this.props;
    const {newCountValue} = this.state;

    const maxIndex = item.counts?.reduce((max, count) => (count.index > max ? count.index : max), 0) ?? 0;
    const currentCount = item.counts?.find(count => count.index === maxIndex);

    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={this.onHide} />
        <Button label="Save" onClick={this.onSave} />
      </div>
    );

    return (
      <>
        <TwoDialog
          onHide={this.onHide}
          header={'Manual Count Entry'}
          loading={false}
          showDialog={showDialog}
          style={{width: '50vw'}}
          breakpoints={{
            '1920px': '70vw',
            '992px': '80vw',
            '768px': '95vw',
          }}
          draggable={false}
          footer={footer}
        >
          <div className="p-d-flex p-flex-column h-100 p-px-3 p-py-5 w-100">
            <div className="p-grid">
              <label className="p-col-2">name</label>
              <span className="p-col-10">{item.name}</span>
            </div>

            <div className="p-grid">
              <label className="p-col-2">colour</label>
              <span className="p-col-10">{item?.colour ?? 'n/a'}</span>
            </div>

            <div className="p-grid">
              <label className="p-col-2">sku</label>
              <span className="p-col-10">{item.sku}</span>
            </div>
            <div className="p-grid">
              <label className="p-col-2">section</label>
              <span className="p-col-4">{item.position?.section}</span>
              <label className="p-col-2">rack</label>
              <span className="p-col-4">{item.position?.rack}</span>
            </div>
            <div className="p-grid">
              <label className="p-col-2">shelve</label>
              <span className="p-col-4">{item.position?.shelve}</span>
              <label className="p-col-2">bay</label>
              <span className="p-col-4">{item.position?.bay}</span>
            </div>
            <div className="p-grid p-mt-4">
              <label className="p-col-2 p-mr-2">count</label>
              <InputNumber
                style={{flexGrow: 2}}
                value={newCountValue ?? currentCount?.counted_qty}
                onValueChange={e => this.setState({newCountValue: e.value ?? 0})}
                mode="decimal"
                inputMode="decimal"
                minFractionDigits={0}
                maxFractionDigits={4}
                min={0}
                locale="en-AU"
                showButtons
                buttonLayout="horizontal"
                step={1}
                decrementButtonClassName="p-button-danger"
                incrementButtonClassName="p-button-success"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
              />
              <Button
                className="p-button p-ml-2 p-button-warning"
                label="Reset"
                onClick={() => this.setState({newCountValue: undefined})}
              />
            </div>
          </div>
        </TwoDialog>
      </>
    );
  }
}
