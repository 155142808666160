import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';

interface Props {
  data: any;
}

//interface State {}

class CVFabric extends CutSheetTemplate {
  report: Report;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      2,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">cv std v2</td>
              <td className="text">
                <b>FABRIC</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text" colSpan={3}>
                {this.props.data.reference}
              </td>
              <td className="label">shipping to</td>
              <td className="text">{this.props.data.ship_to}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      /* MIXED FABRICS */
    }
    const uniqueFabrics: string[] = [];
    for (const item of this.props.data.fabric.items) {
      if (!uniqueFabrics.includes(item.content.fabric + item.content.colour)) {
        uniqueFabrics.push(item.content.fabric + item.content.colour);
      }
      if (item.content.wrap_fabric && !uniqueFabrics.includes(item.content.wrap_fabric + item.content.wrap_colour)) {
        uniqueFabrics.push(item.content.wrap_fabric + item.content.wrap_colour);
      }
    }

    if (uniqueFabrics.length > 1) {
      this.report.addItem(
        this.props.data,
        1,
        <React.Fragment>
          <div className="text">{`MIXED FABRICS !!! ${uniqueFabrics.length} different fabrics.`}</div>
        </React.Fragment>
      );
    }

    {
      /* ITEMS */
    }
    this.props.data.fabric.items.map((item: any) => {
      let currentLineCount = 1;
      let wrapFabric;
      let repair1, repair2, repair3, note;

      if (item.content.repair_respo) {
        repair1 = (
          <tr>
            <td>{item.order_item_index}</td>
            <td className="label">rework</td>
            <td className="text">{item.content.repair_whole_item ? 'YES' : 'NO'}</td>
            <td className="label">type</td>
            <td className="text">{this.capitaliseFirst(item.content.repair_type)}</td>
            <td className="label">responsibility</td>
            <td className="text" colSpan={4}>
              {this.capitaliseFirst(item.content.repair_respo)}
            </td>
          </tr>
        );
        if (item.content.repair_type === 'cutdown (standard)') {
          repair2 = (
            <tr>
              <td />
              <td className="label">new height</td>
              <td className="number">{item.content.new_height}</td>
              <td className="label">new width</td>
              <td className="number" colSpan={5}>
                {item.content.new_width}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
        } else {
          repair2 = '';
        }
        repair3 = (
          <tr>
            <td />
            <td className="label">repair detail</td>
            <td className="text" colSpan={7}>
              {item.content.repair_details}
            </td>
            <td className="sign-off">check</td>
          </tr>
        );
        currentLineCount += 3;
      } else {
        repair1 = repair2 = repair3 = '';
      }

      if (item.content.wrap_fabric) {
        wrapFabric = (
          <tr>
            <td />
            <td className="label">wrap fabric</td>
            <td className="text">{this.capitaliseFabric(item.content.wrap_fabric)}</td>
            <td className="label">colour</td>
            <td className="text">{this.capitaliseFirst(item.content.wrap_colour)}</td>
            <td className="label">width</td>
            <td className="number">{item.content.wrap_width}</td>
            <td className="label">drop</td>
            <td className="number">{item.content.wrap_drop}</td>
            <td className="sign-off">cut</td>
          </tr>
        );
        currentLineCount++;
      } else {
        wrapFabric = '';
      }

      if (item.content.notes) {
        note = (
          <tr>
            <td />
            <td className="label">note</td>
            <td className="text" colSpan={7}>
              {`${item.content.notes}`.replace(/\r?\n|\r/g, ' ')}
            </td>
          </tr>
        );
        currentLineCount += Math.ceil(`${item.content.notes}`.length / 90);
      } else {
        note = '';
      }

      this.report.addItem(
        item,
        currentLineCount,
        <React.Fragment>
          <table className="mt-10 odd-zebra four-cols content">
            {repair1}
            {repair2}
            {repair3}
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">fabric</td>
              <td className="text">{this.capitaliseFabric(item.content.fabric)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.colour)}</td>
              <td className="label">width</td>
              <td className="number">{item.content.width}</td>
              <td className="label">drop</td>
              <td className="number">{item.content.drop}</td>
              <td className="sign-off">cut</td>
            </tr>
            {wrapFabric}
            {note}
          </table>
        </React.Fragment>
      );
    });
    {
      /* CHECK SUM */
    }
    this.report.addItem(
      this.props.data,
      1,
      <React.Fragment>
        <table className="summary">
          <td className="label">total</td>
          <td className="text">{this.props.data.fabric.items.length}</td>
        </table>
      </React.Fragment>
    );

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="colorvue-standard-fabric-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default CVFabric;
