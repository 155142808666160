import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {
  AppContext,
  TwoAction,
  TwoEntityComponent,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
  TwoToast,
} from 'two-app-ui';
import {QueryParameter, StockTake, TimeLineEvent} from 'two-core';
import StockTakesService from '../../services/StockTakesService';
import {ProgressSpinner} from 'primereact/progressspinner';
import {faCalendarAlt, faClipboardList} from '@fortawesome/pro-regular-svg-icons';
import {StockTakeDetail} from './StockTakeDetail';
import {getStockTakeButtonMenuItems} from '../../utils/StockTakeUtil';
import StartStockTakeDialog from './StartStockTakeDialog';
import StopStockTakeDialog from './StopStockTakeDialog';
import {StockTakeItemList} from './StockTakeItemList/StockTakeItemList';
import TleService from '../../services/TleService';
import EditStockTakeDialog from './EditStockTakeDialog';

interface RouteProps {
  id: string;
}

interface State {
  stockTake?: StockTake;
  events?: TwoTimelineItem[];
  loading?: boolean;
  loadingEvents?: boolean;
  showEditStockTakeDialog?: boolean;
  showReviewStockTakeDialog?: boolean;
  showStartStockTakeDialog?: boolean;
  showStopStockTakeDialog?: boolean;
}

class StockTakePage extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;

  stockTakesService?: StockTakesService;
  tleService?: TleService;
  twoToast?: TwoToast;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.onHideStartStockTakeDialog = this.onHideStartStockTakeDialog.bind(this);
    this.onHideStopStockTakeDialog = this.onHideStopStockTakeDialog.bind(this);
    this.onHideEditStockTakeDialog = this.onHideEditStockTakeDialog.bind(this);
    this.onHideReviewStockTakeDialog = this.onHideReviewStockTakeDialog.bind(this);

    this.state = {};
  }

  componentDidMount() {
    this.stockTakesService = this.context.stockTakesService;
    this.tleService = this.context.tleService;
    this.twoToast = this.context.twoToast;

    this.loadData();
  }

  async loadData() {
    this.setState({loading: true});
    const stockTakeId = parseInt(this.props.match.params.id);
    const stockTake = await this.loadStockTake(stockTakeId);
    if (!stockTake) {
      this.setState({loading: false});
      return;
    }
    const events = await this.loadEvents(stockTakeId);
    this.setState({loading: false, stockTake, events});
  }

  async loadStockTake(stockTakeId: number) {
    try {
      const filters = [JSON.stringify({field: 'id', value: stockTakeId})];
      const queryParams: QueryParameter = {
        filters,
        aggregate: ['document'],
      };
      const response = await this.stockTakesService?.getStockTakes(queryParams);
      const stockTakes = (response?.records ?? []) as StockTake[];
      const stockTake = stockTakes[0];
      if (!stockTake) {
        return undefined;
      }
      for (const item of stockTake.document?.items ?? []) {
        item.counts?.sort((a, b) => a.index - b.index);
      }
      return stockTake;
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading stock take');
      return undefined;
    }
  }

  async loadEvents(id: number) {
    this.setState(() => ({loadingEvents: true}));

    try {
      const filters: string[] = [
        JSON.stringify({
          field: 'entity_type',
          value: 'stock_take',
        }),
        JSON.stringify({
          field: 'entity_id',
          value: id,
        }),
      ];
      const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
      const params: QueryParameter = {
        filters: filters,
        orderBys: [orderBys],
        aggregate: true,
      };
      const response = await this.tleService?.getTimeLineEvents(params);
      this.setState(() => ({loadingEvents: false}));
      const records = (response?.records ?? []) as TimeLineEvent[];
      return records.map(record => ({event: record}));
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading timeline events');
      this.setState({loadingEvents: false});
      return undefined;
    }
  }
  getActions(): TwoAction[] {
    return getStockTakeButtonMenuItems(this.state.stockTake, {
      onEdit: () => this.setState({showEditStockTakeDialog: true}),
      onStart: () => this.setState({showStartStockTakeDialog: true}),
      onStop: () => this.setState({showStopStockTakeDialog: true}),
      onReview: () => this.setState({showReviewStockTakeDialog: true}),
    });
  }

  onHideStartStockTakeDialog() {
    this.setState(() => ({showStartStockTakeDialog: false}));
    this.loadData();
  }

  onHideStopStockTakeDialog() {
    this.setState({showStopStockTakeDialog: false});
    this.loadData();
  }

  onHideEditStockTakeDialog() {
    this.setState({showEditStockTakeDialog: false});
    this.loadData();
  }
  onHideReviewStockTakeDialog() {
    this.setState({showReviewStockTakeDialog: false});
    this.loadData();
  }

  render() {
    const {
      stockTake,
      loadingEvents,
      showEditStockTakeDialog,
      showReviewStockTakeDialog,
      events,
      loading,
      showStopStockTakeDialog,
      showStartStockTakeDialog,
    } = this.state;

    if (loading) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }

    if (!stockTake) {
      return <></>;
    }

    return (
      <>
        <TwoEntityComponent title={stockTake.name} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            <StockTakeDetail stockTake={stockTake} />
          </TwoEntityPanel>
          <TwoEntityPanel icon={faClipboardList}>
            <StockTakeItemList items={stockTake.document?.items ?? []} mode={'readonly'} stockTake={stockTake} />
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            {!loadingEvents ? <TwoTimeline items={events ?? []} /> : <ProgressSpinner />}
          </TwoEntityPanel>
        </TwoEntityComponent>
        {showStartStockTakeDialog && (
          <StartStockTakeDialog
            showDialog={showStartStockTakeDialog}
            stockTake={stockTake}
            onHide={this.onHideStartStockTakeDialog}
          />
        )}
        {showStopStockTakeDialog && (
          <StopStockTakeDialog
            showDialog={showStopStockTakeDialog}
            stockTakeId={stockTake.id}
            onHide={this.onHideStopStockTakeDialog}
          />
        )}
        {showEditStockTakeDialog && (
          <EditStockTakeDialog
            showDialog={showEditStockTakeDialog}
            onHide={this.onHideEditStockTakeDialog}
            stockTakeId={stockTake.id}
            mode={'edit'}
          />
        )}
        {showReviewStockTakeDialog && (
          <EditStockTakeDialog
            showDialog={showReviewStockTakeDialog}
            onHide={this.onHideReviewStockTakeDialog}
            stockTakeId={stockTake.id}
            mode={'review'}
          />
        )}
      </>
    );
  }
}

export default withRouter(StockTakePage);
