import React from 'react';
import {
  FactoryChainItem,
  FactoryOrder,
  FactoryOrderPatch,
  MapOf,
  Order,
  ProductionStageM2cAuShades,
  TleContentStageTransition,
} from 'two-core';
import {getCurrentUserId} from './UserUtil';
import {AppMenuItem, AppMenuItemTemplate, TwoAction} from 'two-app-ui';
import {
  faArrowCircleRight,
  faCalendarAlt,
  faCheck,
  faEnvelope,
  faPencil,
  faPrint,
  faRedo,
  faStickyNote,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import {faTimes} from '@fortawesome/pro-light-svg-icons';
import {MenuItemOptions} from 'primereact/menuitem';

/**
 * Get patch for factory_order to update production_stage and factory_chain.stage
 * @param factoryOrder
 * @param newStage
 * @returns FactoryOrderPatch
 */
export const getNewProductionStagesPatch = (
  factoryOrder: FactoryOrder,
  newStage?: ProductionStageM2cAuShades,
  newSecondaryFactoriesStage?: ProductionStageM2cAuShades,
  stageChangeReason?: string
): Pick<FactoryOrderPatch, 'factory_chain' | 'production_stage' | 'tles_success'> => {
  const factoryChain = factoryOrder.factory_chain;
  const productionStage = factoryOrder.production_stage;
  const factoryOrderPatch: FactoryOrderPatch = {};
  if (factoryChain) {
    const currentFactoryId = localStorage.getItem('current factory') ?? '';
    //set factory chain stages
    if (newStage) {
      const updatedFactoryChainItem = {...factoryChain[currentFactoryId]!, stage: newStage};
      factoryOrderPatch.factory_chain = {
        ...factoryChain,
        [currentFactoryId]: updatedFactoryChainItem,
      };
      if (newStage === 'On Hold') {
        factoryOrderPatch.factory_chain[currentFactoryId].last_stage = productionStage;
      }
    }
    if (newSecondaryFactoriesStage) {
      const newFactoryChain: MapOf<FactoryChainItem> = {};
      for (const [factoryId, chainItem] of Object.entries(factoryOrderPatch.factory_chain ?? factoryChain)) {
        if (factoryId === currentFactoryId) {
          newFactoryChain[factoryId] = {
            ...chainItem,
          };
        } else {
          newFactoryChain[factoryId] = {...chainItem, stage: newSecondaryFactoriesStage};
        }
      }
      factoryOrderPatch.factory_chain = newFactoryChain;
    }

    //set production_stage based on factory_chain stages
    const factoryChainPatchStages = Object.values(factoryOrderPatch.factory_chain!).map(chainItem => chainItem.stage);
    if (productionStage !== newStage) {
      if (newStage === 'Done') {
        //change production_stage on factory_order only if all factories are in stage 'Done'
        if (factoryChainPatchStages.every(chainStage => chainStage === 'Done')) {
          factoryOrderPatch.production_stage = 'Done';
        }
      } else if (newStage === 'Post Fix Return') {
        //change production_stage on factory_order only if all factories are in stage 'Done' or 'Post Fix Return'
        if (factoryChainPatchStages.every(chainStage => chainStage === 'Done' || chainStage === 'Post Fix Return')) {
          factoryOrderPatch.production_stage = 'Post Fix Return';
        }
      } else if (newStage === 'Between Factories') {
        //change production_stage on factory_order only if all factories are in stage 'Done', 'Post Fix Return' or 'Between Factories'
        if (
          factoryChainPatchStages.every(
            chainStage =>
              chainStage === 'Done' || chainStage === 'Post Fix Return' || chainStage === 'Between Factories'
          )
        ) {
          factoryOrderPatch.production_stage = 'Between Factories';
        }
      } else {
        //change production_stage to most advanced factory_chain.stage
        let mostAdvancedStage: ProductionStageM2cAuShades | undefined;
        if (factoryChainPatchStages.includes('In Production')) {
          mostAdvancedStage = 'In Production';
        } else if (factoryChainPatchStages.includes('Sent To Floor')) {
          mostAdvancedStage = 'Sent To Floor';
        } else if (factoryChainPatchStages.includes('Ready')) {
          mostAdvancedStage = 'Ready';
        } else if (factoryChainPatchStages.includes('Waiting 4 Material')) {
          mostAdvancedStage = 'Waiting 4 Material';
        } else if (factoryChainPatchStages.includes('Waiting For Return')) {
          mostAdvancedStage = 'Waiting For Return';
        }
        if (mostAdvancedStage && mostAdvancedStage !== productionStage) {
          factoryOrderPatch.production_stage = mostAdvancedStage;
        }
      }
    }
  } else {
    if (newStage === 'On Hold') {
      factoryOrderPatch.previos_production_stage = factoryOrder.production_stage;
    }
    factoryOrderPatch.production_stage = newStage;
  }

  //create tles
  const userId = getCurrentUserId();
  factoryOrderPatch.tles_success = [];
  if (factoryOrderPatch.factory_chain) {
    for (const [factoryId, chainItem] of Object.entries(factoryOrderPatch.factory_chain)) {
      const oldStage = factoryOrder.factory_chain![factoryId]!.stage;
      if (oldStage !== chainItem.stage) {
        factoryOrderPatch.tles_success.push({
          event_type: 'production_stage_transition',
          entity_id: factoryOrder.id!,
          entity_type: 'order',
          recorded_at: new Date(),
          recorded_by: userId,
          content: {
            old_stage: oldStage,
            new_stage: chainItem.stage,
            message: `Changed in ${chainItem.factory_name}.`,
            reason: stageChangeReason,
          } as TleContentStageTransition,
        });
      }
    }
    if (factoryOrderPatch.production_stage && factoryOrder.production_stage !== factoryOrderPatch.production_stage) {
      factoryOrderPatch.tles_success.push({
        event_type: 'production_stage_transition',
        entity_id: factoryOrder.id!,
        entity_type: 'order',
        recorded_at: new Date(),
        recorded_by: userId,
        content: {
          old_stage: factoryOrder.production_stage,
          new_stage: factoryOrderPatch.production_stage,
          message: 'Changed overall stage.',
          reason: stageChangeReason,
        } as TleContentStageTransition,
      });
    }
  } else if (
    factoryOrderPatch.production_stage &&
    factoryOrder.production_stage !== factoryOrderPatch.production_stage
  ) {
    factoryOrderPatch.tles_success.push({
      event_type: 'production_stage_transition',
      entity_id: factoryOrder.id!,
      entity_type: 'order',
      recorded_at: new Date(),
      recorded_by: userId,
      content: {
        old_stage: factoryOrder.production_stage,
        new_stage: factoryOrderPatch.production_stage,
        reason: stageChangeReason,
      } as TleContentStageTransition,
    });
  }
  return factoryOrderPatch;
};

export interface FactoryOrderMenuFunctions {
  onPrintCutSheets?: (prePowderOnly: boolean) => void;
  onShowPurchaseOrderDialog?: () => void;
  onShowShippingLabelsDialog?: () => void;
  onStageChangeWithSummaryReplaced?: (replacedSummary: string) => void;
  onStageChange?: (
    newStage: ProductionStageM2cAuShades,
    newSecondaryFactoriesStage?: ProductionStageM2cAuShades
  ) => void;
  onReceived?: () => void;
  onShowAndContinueOrderStockAvailabilityCheckDialog?: () => void;
  onStageChangeWithPostFixReturnSetting?: () => void;
  onProductionComplete?: () => void;
  onShowHoldDialog?: () => void;
  onShowOrderStockAvailabilityCheckDialog?: () => void;
  onShowRecordNoteDialog?: () => void;
  onShowRemoveFromPoDialog?: () => void;
  onSendPickUpEmail?: () => void;
  onShowEditDialog?: () => void;
  onShowConfirmReImportOrderDialog?: () => void;
  onCancel?: () => void;
  onShowEditEcdDialog?: () => void;
}
interface ItemData {
  label?: string;
  icon?: IconDefinition;
  action?: () => void;
  separator?: boolean;
  main?: boolean;
}

/**
 * Get button menu items for factory order
 * @param selectedOrder
 * @param functions
 */
export function getFactoryOrderButtonMenuItems(
  selectedOrder: Order,
  functions: FactoryOrderMenuFunctions
): TwoAction[] {
  const items = getFactoryOrdersItems([selectedOrder], functions);
  return items.map(item => {
    if (item.separator) {
      return {separator: true};
    }
    return {
      label: item.label,
      icon: item.icon,
      action: item.action,
      main: item.main,
    };
  });
}

/**
 * Get table menu items for factory orders
 * @param selectedOrders
 * @param functions
 */
export function getFactoryOrdersMenuItems(
  selectedOrders: Order[],
  functions: FactoryOrderMenuFunctions
): AppMenuItem[] {
  const items = getFactoryOrdersItems(selectedOrders, functions);
  return items.map(item => {
    if (item.separator) {
      return {separator: true};
    }
    return {
      label: item.label,
      faIcon: item.icon,
      command: item.action,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
    };
  });
}
function getFactoryOrdersItems(selectedOrders: Order[], functions: FactoryOrderMenuFunctions): ItemData[] {
  if (selectedOrders.length === 0) {
    return [] as ItemData[];
  }
  const currentFactoryId = localStorage.getItem('current factory');
  if (!currentFactoryId) {
    console.error('Current factory is not set');
    return [] as ItemData[];
  }

  const withProductChainOnly = selectedOrders.every(o => o.factory_order?.factory_chain);
  const withoutProductChainOnly = selectedOrders.every(o => !o.factory_order?.factory_chain);
  if (!withProductChainOnly && !withoutProductChainOnly) {
    return [] as ItemData[];
  }

  const hasComponentType = selectedOrders.some(o => o.type === 'Component');
  const allOrdersFinalFactory = selectedOrders.every(o => o.factory_order?.factory_id === currentFactoryId);
  const allOrdersRepair = selectedOrders.every(o => o.type === 'Repair');
  const allOrderNonRepair = selectedOrders.every(o => o.type !== 'Repair');
  const allSecondaryFactoriesFinished = selectedOrders.every(o => {
    const factoryChain = o.factory_order?.factory_chain;
    if (!factoryChain) {
      return false;
    }
    return !Object.entries(factoryChain).some(([factoryId, chainItem]) => {
      return factoryId !== o.factory_order?.factory_id && chainItem.stage !== 'Between Factories';
    });
  });
  const allSecondaryFactoriesReceived = selectedOrders.every(o => {
    const factoryChain = o.factory_order?.factory_chain;
    if (!factoryChain) {
      return false;
    }
    return !Object.entries(factoryChain).some(([factoryId, chainItem]) => {
      return (
        factoryId !== o.factory_order?.factory_id && chainItem.stage !== 'Done' && chainItem.stage !== 'Post Fix Return'
      );
    });
  });
  const currentFactorySelectedStages = Array.from(
    new Set(
      selectedOrders.map(o =>
        withProductChainOnly
          ? o.factory_order!.factory_chain![currentFactoryId]!.stage
          : o.factory_order!.production_stage
      )
    ).values()
  ) as ProductionStageM2cAuShades[];
  const items: ItemData[] = [];
  //edit
  if (functions.onShowEditDialog && selectedOrders.length === 1 && allOrdersFinalFactory) {
    items.push({
      label: 'Edit',
      icon: faPencil,
      action: functions.onShowEditDialog,
      main: true,
    });
    items.push({separator: true});
  }

  //print
  if (
    functions.onPrintCutSheets &&
    currentFactorySelectedStages.every(stage => ['Alu Needs Cutting', 'Alu Cutting'].includes(stage))
  ) {
    items.push({
      label: 'Alu Cut Sheets',
      icon: faPrint,
      action: () => functions.onPrintCutSheets!(false),
    });
  }
  if (
    functions.onShowPurchaseOrderDialog &&
    currentFactorySelectedStages.every(stage => ['Alu Ordered', 'Alu Required'].includes(stage)) &&
    selectedOrders.length === 1 &&
    !hasComponentType
  ) {
    items.push({
      label: 'Alu PO',
      icon: faPrint,
      action: functions.onShowPurchaseOrderDialog,
    });
  }
  if (functions.onPrintCutSheets) {
    items.push({
      label: 'Cut Sheets',
      icon: faPrint,
      action: () => functions.onPrintCutSheets!(false),
    });
  }
  if (
    functions.onShowPurchaseOrderDialog &&
    currentFactorySelectedStages.every(stage => ['Powder Ordered', 'Powder Required'].includes(stage)) &&
    selectedOrders.length === 1 &&
    !hasComponentType
  ) {
    items.push({
      label: 'Powder PO',
      icon: faPrint,
      action: functions.onShowPurchaseOrderDialog,
    });
  }
  if (functions.onShowShippingLabelsDialog) {
    items.push({
      label: 'Shipping Labels',
      icon: faPrint,
      action: functions.onShowShippingLabelsDialog,
    });
  }
  if (items.length && !items[items.length - 1].separator) {
    items.push({separator: true});
  }
  // stage transitions
  if (!currentFactorySelectedStages.includes('On Hold')) {
    if (
      functions.onStageChangeWithSummaryReplaced &&
      currentFactorySelectedStages.every(stage => stage === 'Alu Cutting') &&
      !hasComponentType
    ) {
      items.push({
        label: 'Alu Cut',
        icon: faArrowCircleRight,
        action: () => functions.onStageChangeWithSummaryReplaced!('alu'),
      });
    }
    if (functions.onStageChange) {
      if (currentFactorySelectedStages.every(stage => stage === 'Alu Required') && !hasComponentType) {
        items.push({
          label: 'Alu Ordered',
          icon: faArrowCircleRight,
          action: () => functions.onStageChange!('Alu Ordered'),
        });
      }
      if (currentFactorySelectedStages.every(stage => stage === 'Alu Ordered') && !hasComponentType) {
        items.push({
          label: 'Alu Delivered',
          icon: faArrowCircleRight,
          action: () => functions.onStageChange!('Alu Needs Cutting'),
        });
      }
      if (currentFactorySelectedStages.every(stage => stage === 'Alu Needs Cutting') && !hasComponentType) {
        items.push({
          label: 'Alu Cut Requested',
          icon: faArrowCircleRight,
          action: () => functions.onStageChange!('Alu Cutting'),
        });
      }
      if (currentFactorySelectedStages.every(stage => stage === 'Powder Ordered') && !hasComponentType) {
        items.push({
          label: 'Coated and Delivered',
          icon: faArrowCircleRight,
          action: () => functions.onStageChangeWithSummaryReplaced!('powder'),
        });
      }
    }
    if (
      functions.onReceived &&
      currentFactorySelectedStages.every(stage => stage !== 'Cancelled' && stage !== 'Done') &&
      withProductChainOnly &&
      allSecondaryFactoriesFinished
    ) {
      items.push({
        label: 'Received',
        icon: faArrowCircleRight,
        action: functions.onReceived,
      });
    }
    if (
      functions.onShowAndContinueOrderStockAvailabilityCheckDialog &&
      currentFactorySelectedStages.every(stage => stage === 'Waiting For Return')
    ) {
      items.push({
        label: 'Delivered',
        icon: faArrowCircleRight,
        action: functions.onShowAndContinueOrderStockAvailabilityCheckDialog,
      });
    }
    if (functions.onStageChange) {
      if (allOrdersRepair && currentFactorySelectedStages.every(stage => stage === 'New')) {
        items.push({
          label: 'Waiting For Return',
          icon: faArrowCircleRight,
          action: () => functions.onStageChange!('Waiting For Return'),
        });
      }
      if (currentFactorySelectedStages.every(stage => stage === 'Post Fix Return') && allOrdersFinalFactory) {
        items.push({
          label: 'Delivered',
          icon: faArrowCircleRight,
          action: () => {
            functions.onStageChange!('Done', 'Done');
          },
        });
      }
      if (currentFactorySelectedStages.every(stage => stage === 'Sent To Floor')) {
        items.push({
          label: 'In Production',
          icon: faArrowCircleRight,
          action: () => {
            functions.onStageChange!('In Production');
          },
        });
      }
      if (currentFactorySelectedStages.every(stage => stage === 'Ready') && !hasComponentType) {
        items.push({
          label: 'Needs Alu',
          icon: faArrowCircleRight,
          action: () => {
            functions.onStageChange!('Alu Required');
          },
        });
        items.push({
          label: 'Needs Powder',
          icon: faArrowCircleRight,
          action: () => {
            functions.onStageChange!('Powder Required');
          },
        });
      }
    }
    if (
      functions.onStageChangeWithPostFixReturnSetting &&
      (currentFactorySelectedStages.every(stage => stage === 'Waiting For Return') ||
        (currentFactorySelectedStages.every(stage => stage === 'New') && allOrdersRepair)) &&
      !hasComponentType
    ) {
      items.push({
        label: 'Post Fix Return',
        icon: faArrowCircleRight,
        action: functions.onStageChangeWithPostFixReturnSetting,
      });
    }
    if (
      functions.onStageChange &&
      currentFactorySelectedStages.every(stage => stage === 'Powder Required') &&
      !hasComponentType
    ) {
      items.push({
        label: 'Powder Ordered',
        icon: faArrowCircleRight,
        action: () => functions.onStageChange!('Powder Ordered'),
      });
    }
    if (
      functions.onShowAndContinueOrderStockAvailabilityCheckDialog &&
      currentFactorySelectedStages.every(stage => stage === 'New') &&
      allOrderNonRepair
    ) {
      items.push({
        label: 'Printed',
        icon: faArrowCircleRight,
        action: functions.onShowAndContinueOrderStockAvailabilityCheckDialog,
      });
    }
    if (functions.onStageChange && currentFactorySelectedStages.every(stage => stage === 'Waiting 4 Material')) {
      items.push({
        label: 'Material Available',
        icon: faArrowCircleRight,
        action: () => functions.onStageChange!('Ready'),
      });
    }
    if (
      functions.onProductionComplete &&
      (currentFactorySelectedStages.every(stage => stage === 'Sent To Floor') ||
        currentFactorySelectedStages.every(stage => stage === 'In Production')) &&
      (!withProductChainOnly || !allOrdersFinalFactory || allSecondaryFactoriesReceived)
    ) {
      items.push({
        label: 'Production Complete',
        icon: faArrowCircleRight,
        action: functions.onProductionComplete,
      });
    }
    if (functions.onStageChange && currentFactorySelectedStages.every(stage => stage === 'Ready')) {
      items.push({
        label: 'Send To Floor',
        icon: faArrowCircleRight,
        action: () => functions.onStageChange!('Sent To Floor'),
      });
    }
    if (
      functions.onShowHoldDialog &&
      currentFactorySelectedStages.every(stage => !['Done', 'Cancelled'].includes(stage))
    ) {
      items.push({
        label: 'On Hold',
        icon: faArrowCircleRight,
        action: functions.onShowHoldDialog,
      });
    }
  }
  if (
    functions.onStageChange &&
    selectedOrders.length === 1 &&
    currentFactorySelectedStages.every(stage => stage === 'On Hold')
  ) {
    const lastStage = (
      withProductChainOnly
        ? selectedOrders[0].factory_order?.factory_chain?.[currentFactoryId].last_stage
        : selectedOrders[0].factory_order?.previos_production_stage
    ) as ProductionStageM2cAuShades;
    if (lastStage) {
      items.push({
        label: `Return to ${lastStage}`,
        icon: faArrowCircleRight,
        action: () => functions.onStageChange!(lastStage),
      });
    }
  }
  if (items.length && !items[items.length - 1].separator) {
    items.push({separator: true});
  }

  //other items
  if (
    functions.onShowOrderStockAvailabilityCheckDialog &&
    currentFactorySelectedStages.every(stage => stage === 'New')
  ) {
    items.push({
      label: 'Check Stock',
      icon: faCheck,
      action: functions.onShowOrderStockAvailabilityCheckDialog,
    });
  }
  if (functions.onShowRecordNoteDialog) {
    items.push({
      label: 'Record Note',
      icon: faStickyNote,
      action: functions.onShowRecordNoteDialog,
    });
  }
  if (
    functions.onShowEditEcdDialog &&
    currentFactorySelectedStages.every(stage => !['Done', 'Cancelled'].includes(stage))
  ) {
    items.push({
      label: 'Change ECD',
      icon: faCalendarAlt,
      action: functions.onShowEditEcdDialog,
    });
  }
  if (allOrdersFinalFactory) {
    if (functions.onShowRemoveFromPoDialog) {
      items.push({
        label: 'Remove from PO',
        icon: faTimes,
        action: functions.onShowRemoveFromPoDialog,
      });
    }
    if (functions.onSendPickUpEmail && currentFactorySelectedStages.every(stage => stage === 'Waiting For Return')) {
      items.push({
        label: 'Send Pick-up Email',
        icon: faEnvelope,
        action: functions.onSendPickUpEmail,
      });
    }
    if (functions.onShowConfirmReImportOrderDialog) {
      items.push({
        label: 'Re-Import from FF2',
        icon: faRedo,
        action: functions.onShowConfirmReImportOrderDialog,
      });
    }
    if (functions.onCancel) {
      items.push({
        label: 'Cancel',
        icon: faArrowCircleRight,
        action: functions.onCancel,
      });
    }
  }

  //remove last item if it is separator
  if (items.length && items[items.length - 1].separator) {
    items.pop();
  }
  return items;
}
