import {Calendar} from 'primereact/calendar';
import {InputSwitch} from 'primereact/inputswitch';
import {Column} from 'primereact/column';
import React from 'react';
import {MessageService, AppContext, TwoDialog, TwoToast, TwoDataTable} from 'two-app-ui';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import {
  PurchaseOrder,
  PurchaseOrderItem,
  QueryParameter,
  PurchaseOrderDelivery,
  PurchaseOrderDeliveryItem,
  PurchaseOrderAggregate,
  PurchaseOrderItemAggregate,
} from 'two-core';
import {messages} from '../../config/messages';
import formats from '../../config/formats';
import InventoryService from '../../services/InventoryService';
import {InputNumber} from 'primereact/inputnumber';

interface PoItemTableRowPatch {
  quantity?: number;
  isFinal?: boolean;
}

interface Props {
  purchaseOrderId: string;
  showDialog: boolean;
  onHide: () => void;
}

interface State {
  loading: boolean;
  purchaseOrder?: PurchaseOrder;
  purchaseOrderItems: PurchaseOrderItem[];
  completeDelivery: boolean;
  poItemRowPatchesMap: Map<number, PoItemTableRowPatch>; // purchaseOrderItemId -> PoItemTableRowPatch
  deliveredAt: Date;
}

class PurchaseOrderRecordDeliveryDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  purchaseOrdersService?: PurchaseOrdersService;
  inventoryService?: InventoryService;
  twoToast?: TwoToast;
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      completeDelivery: true,
      purchaseOrderItems: [],
      poItemRowPatchesMap: new Map<number, PoItemTableRowPatch>(),
      deliveredAt: new Date(),
    };

    this.onSave = this.onSave.bind(this);
    this.onHide = this.onHide.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onShow = this.onShow.bind(this);
    this.nowInBody = this.nowInBody.bind(this);
    this.onQuantityChange = this.onQuantityChange.bind(this);
  }

  componentDidMount() {
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.inventoryService = this.context.inventoryService;
    this.twoToast = this.context.twoToast;
  }

  async loadData() {
    const {purchaseOrderId} = this.props;
    this.setState({loading: true});
    const purchaseOrder = await this.loadPurchaseOrder(purchaseOrderId);
    const purchaseOrderItems = await this.loadPurchaseOrderItems(purchaseOrderId);
    this.setState({
      loading: false,
      purchaseOrder: purchaseOrder,
      purchaseOrderItems: purchaseOrderItems,
      deliveredAt: new Date(),
    });
  }

  async loadPurchaseOrder(id: string) {
    try {
      const filters: string[] = [JSON.stringify({field: 'id', value: id})];
      const aggregate: PurchaseOrderAggregate[] = ['supplier'];
      const params: QueryParameter = {
        filters: filters,
        aggregate: aggregate,
      };
      const result = await this.purchaseOrdersService!.getPurchaseOrders(params);
      return ((result?.records ?? []) as PurchaseOrder[])[0];
    } catch (error) {
      console.error('Failed to load purchase order', error);
      this.twoToast?.showError('Failed to load purchase order');
      return undefined;
    }
  }

  async loadPurchaseOrderItems(id: string) {
    try {
      const aggregate: PurchaseOrderItemAggregate[] = ['supply_item', 'inventory_item'];
      const orderBys = [JSON.stringify({field: 'inventory_item.name'})];
      const params: QueryParameter = {
        aggregate: aggregate,
        orderBys: orderBys,
      };
      const result = await this.purchaseOrdersService!.getPurchaseOrderItems(id, params);
      return (result?.records ?? []) as PurchaseOrderItem[];
    } catch (error) {
      console.error('Failed to load purchase order items', error);
      this.twoToast?.showError('Failed to load purchase order items');
      return [];
    }
  }

  async onSave() {
    this.setState({loading: true});
    const {purchaseOrder, completeDelivery, poItemRowPatchesMap, purchaseOrderItems, deliveredAt} = this.state;

    if (!purchaseOrder) {
      this.twoToast?.showError('Ups, something went wrong. Please, refresh the page.');
      this.setState({loading: false});
      return;
    }

    const poDeliveryItemPatches: PurchaseOrderDeliveryItem[] = [];

    for (const poItem of purchaseOrderItems) {
      let quantity = 0;
      let isFinal = false;
      const poItemRowPatch = poItemRowPatchesMap.get(poItem.id!);
      if (completeDelivery || poItemRowPatch?.quantity === undefined) {
        quantity = poItem.quantity - (poItem.delivered_qty ?? 0);
        isFinal = true;
      } else {
        quantity = poItemRowPatch?.quantity ?? 0;
        isFinal = poItemRowPatch?.isFinal ?? false;
      }
      if (quantity > 0) {
        poDeliveryItemPatches.push({
          purchase_order_item_id: poItem.id!,
          inventory_item_id: poItem.inventory_item_id,
          supply_item_id: poItem.supply_item_id,
          qty: quantity,
          is_final: isFinal,
        });
      }
    }

    const poDeliveryPatch: PurchaseOrderDelivery = {
      purchase_order_id: purchaseOrder.id!,
      content: poDeliveryItemPatches,
      received_at: deliveredAt,
    };
    const createdPoDelivery = await this.purchaseOrdersService?.recordPurchaseOrderDelivery(
      purchaseOrder.id!,
      poDeliveryPatch
    );

    if (!createdPoDelivery) {
      console.error('Failed to record delivery for PO.');
      this.twoToast?.showError(
        'Sorry, something went wrong saving your delivery. Please, refresh the page and try again.'
      );
      this.setState({loading: false});
    }
    this.twoToast?.showSuccess(`Purchase Order ${purchaseOrder?.name} delivery recorded successfully.`);
    MessageService.sendMessage(messages.purchaseOrderUpdated);
    this.onHide();
  }

  onHide() {
    this.setState({
      loading: false,
      purchaseOrder: undefined,
      purchaseOrderItems: [],
      completeDelivery: true,
      poItemRowPatchesMap: new Map<number, PoItemTableRowPatch>(),
    });
    this.props.onHide();
  }

  onDeliveredAtChange(value: Date) {
    this.setState(() => ({deliveredAt: value}));
  }

  onShow() {
    this.loadData();
  }

  onQuantityChange(purchaseOrderItem: PurchaseOrderItem, value: number) {
    this.setState(state => {
      const poItemRowPatchesMap = new Map<number, PoItemTableRowPatch>(state.poItemRowPatchesMap);
      const poItemRowPatch: PoItemTableRowPatch = poItemRowPatchesMap.get(purchaseOrderItem.id!) ?? {};
      poItemRowPatch.quantity = value;
      poItemRowPatchesMap.set(purchaseOrderItem.id!, poItemRowPatch);
      return {poItemRowPatchesMap};
    });
  }

  onIsFinalChange(purchaseOrderItem: PurchaseOrderItem, value: boolean) {
    this.setState(state => {
      const poItemRowPatchesMap = new Map<number, PoItemTableRowPatch>(state.poItemRowPatchesMap);
      const poItemRowPatch: PoItemTableRowPatch = poItemRowPatchesMap.get(purchaseOrderItem.id!) ?? {};
      poItemRowPatch.isFinal = value;
      poItemRowPatchesMap.set(purchaseOrderItem.id!, poItemRowPatch);
      return {poItemRowPatchesMap};
    });
  }

  nowInBody(purchaseOrderItem: PurchaseOrderItem) {
    const {poItemRowPatchesMap, completeDelivery} = this.state;
    const poItemRowPatch = poItemRowPatchesMap.get(purchaseOrderItem.id!);
    if (completeDelivery) {
      if (purchaseOrderItem.delivered) {
        return 0;
      }
      return Math.max(purchaseOrderItem.quantity - (purchaseOrderItem.delivered_qty ?? 0), 0);
    }
    const max = Math.max((purchaseOrderItem.quantity ?? 0) * 2 - (purchaseOrderItem.delivered_qty ?? 0), 0);
    const value = poItemRowPatch?.quantity ?? purchaseOrderItem.quantity - (purchaseOrderItem.delivered_qty ?? 0);
    return (
      <InputNumber
        inputClassName="w-100"
        min={0}
        max={max}
        value={value}
        onValueChange={e => this.onQuantityChange(purchaseOrderItem, e.value ?? 0)}
        showButtons
      />
    );
  }
  isFinalBody = (rowData: PurchaseOrderItem) => {
    const {poItemRowPatchesMap} = this.state;
    const poItemRowPatch = poItemRowPatchesMap.get(rowData.id!);
    if (!poItemRowPatch) {
      return <></>;
    }
    const remainingQty = rowData.quantity - (rowData.delivered_qty ?? 0) - (poItemRowPatch.quantity ?? 0);
    if (remainingQty <= 0) {
      return <></>;
    }
    return (
      <InputSwitch
        checked={poItemRowPatch.isFinal}
        onChange={e => {
          this.onIsFinalChange(rowData, e.value);
        }}
      />
    );
  };

  render() {
    const {showDialog} = this.props;
    const {purchaseOrder, completeDelivery, purchaseOrderItems, loading, deliveredAt} = this.state;

    let dialogBody;
    if (!purchaseOrder) {
      dialogBody = <></>;
    } else {
      dialogBody = (
        <>
          <div className="p-grid p-fluid w-100 p-ai-center">
            <label className="p-col-1">vendor</label>
            <span className="p-col-2">{purchaseOrder.supplier?.company_name}</span>

            <label className="p-col-1">stage</label>
            <span className="p-col-2">{purchaseOrder.stage}</span>
            <label htmlFor="delivered_at" className="p-col-1">
              delivered at
            </label>
            <div className="p-col-2">
              <Calendar
                value={deliveredAt}
                dateFormat={formats.calendarInputDate}
                onChange={e => {
                  const date = e.value as Date;
                  this.onDeliveredAtChange(date);
                }}
              />
            </div>
            <label className="p-col-2">complete delivery</label>
            <div className="p-col-1">
              <InputSwitch
                checked={this.state.completeDelivery}
                onChange={e => {
                  this.setState({
                    completeDelivery: e.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="w-100">
            <TwoDataTable
              value={purchaseOrderItems}
              selectedItems={[]}
              activeFilters={[]}
              showPaging={false}
              hideFilter
            >
              <Column field="detail" header="Item" className="col-min-xl" />
              <Column field="package_size" header="Package Size" className="col-min-l col-max-xxl" />
              <Column field="quantity" header="Order Qty" className="col-m" />
              <Column field="order_unit" header="Order Unit" className="col-m" />
              <Column field="delivered_qty" header="Already Delivered" className="col-m" />
              <Column header="Now In" body={this.nowInBody} className="col-m" />
              {!completeDelivery && <Column header="is Final?" body={this.isFinalBody} className="col-s" />}
            </TwoDataTable>
          </div>
        </>
      );
    }

    return (
      <TwoDialog
        header={`Record Delivery of ${purchaseOrder?.name ?? ''}`}
        showDialog={showDialog}
        style={{width: '70vw'}}
        breakpoints={{'1920px': '90vw'}}
        onHide={this.onHide}
        onSave={this.onSave}
        loading={loading}
        onShow={this.onShow}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}

export default PurchaseOrderRecordDeliveryDialog;
