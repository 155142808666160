import React from 'react';
import StockTakeList from './StockTakeList';

export const StockTakesPage = () => {
  return (
    <div id="stock_takes_page" className="page-container">
      <StockTakeList />
    </div>
  );
};
