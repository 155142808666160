import React from 'react';
import ReportFooter from './ReportFooter';

interface ReportItem {
  lineCount: number;
  element: JSX.Element;
}

interface Page {
  items: ReportItem[];
  number: number;
}

class Report {
  pages: Page[];
  header: JSX.Element | undefined;
  footer: ReportFooter | undefined;
  currentLineCount: number;
  currentItemCount: number;
  currentPageCount: number;
  orientation: string;
  orderData: any;

  constructor() {
    this.header = undefined;
    this.footer = undefined;
    this.pages = [];
    this.currentLineCount = 0;
    this.currentItemCount = 0;
    this.currentPageCount = 1;
    this.orientation = 'portrait';
    const page: Page = {
      items: [],
      number: 1,
    };
    this.pages[0] = page;
    this.orderData = {};
  }

  setOrientation(orientation: string) {
    this.orientation = orientation;
  }

  addHeader(size: number, header: JSX.Element) {
    this.currentLineCount += size;
    //getComputedStyle(header);
    this.header = header;
  }

  addFooter(size: number, footer: ReportFooter) {
    this.currentLineCount += size;
    this.footer = footer;
  }

  addItem(data: any, lineCount: number, item: JSX.Element) {
    this.orderData = data;
    if (this.newPageRequired(lineCount)) {
      this.currentPageCount++;
      this.currentLineCount = 0;
      this.currentItemCount = 0;
      const page: Page = {
        items: [],
        number: this.currentPageCount,
      };
      this.pages.push(page);
    }

    this.pages[this.currentPageCount - 1].items.push({
      lineCount: lineCount,
      element: item,
    });
    this.currentLineCount += lineCount;
    this.currentItemCount++;
  }

  /**
   * Method decides whether the incoming item with specified line count requires a new page to be
   * added to the document or not. Current size + new item size > maximum item space.
   * Note: values are in mm.
   * @param newLineCount
   */
  newPageRequired(newLineCount: number): boolean {
    const marginTop = 16;
    const headerHeight = 16;
    const lineHeight = 8;
    const itemGap = 9;
    const summaryHeight = 15;
    const footerHeight = 8;
    const marginBottom = 8;
    let pageSize = 310;

    if (this.orderData.station && this.orderData.station === 'saw') {
      pageSize = 305;
    }
    if (this.orderData.station && this.orderData.station === 'fabric') {
      pageSize = 295;
    }
    if (this.orderData.station && this.orderData.station === 'assembly') {
      pageSize = 300;
    }
    if (this.orderData.station && this.orderData.station === 'pack') {
      pageSize = 280;
    }
    if (this.orderData.station && this.orderData.station === 'qc') {
      pageSize = 275;
    }

    if (this.orderData.station && this.orderData.station === 'saw' && this.orderData.category === 'prepowder') {
      pageSize = 295;
    }

    if (this.orderData.station && this.orderData.station === 'saw' && this.orderData.category === 'tube') {
      pageSize = 340;
    }

    if (this.orderData.station && this.orderData.station === 'fabric' && this.orderData.category === 'skin') {
      pageSize = 360;
    }

    return (
      lineHeight * (newLineCount + this.currentLineCount) + (this.currentItemCount + 1) * itemGap >
      pageSize - marginTop - headerHeight - summaryHeight - footerHeight - marginBottom
    );
  }

  getReport(): JSX.Element {
    return (
      <React.Fragment>
        {this.pages.map(page => {
          if (this.footer) {
            this.footer.setPageNumber(`${page.number}/${this.pages.length}`);
          }
          return (
            <table key="report-table" className={'cutting-sheet-page ' + this.orientation}>
              {this.header ? (
                <tr className="header">
                  <td>{this.header}</td>
                </tr>
              ) : (
                ''
              )}
              <tr className={'cut-sheet-body'}>
                <td>
                  {page.items.map(item => {
                    return item.element;
                  })}
                </td>
              </tr>
              {this.footer ? (
                <tr>
                  <td>
                    <table className="footer">{this.footer.getFooter()}</table>
                  </td>
                </tr>
              ) : (
                ''
              )}
            </table>
          );
        })}
      </React.Fragment>
    );
  }
}

export default Report;
