import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  TwoAction,
  TwoEntityComponent,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
  TwoToast,
} from 'two-app-ui';
import {InventoryItem, Oos, PurchaseOrder, QueryParameter, TimeLineEvent} from 'two-core';
import TleService from '../../services/TleService';
import {Subscription} from 'rxjs';
import {MessageService} from 'two-app-ui';
import {messages} from '../../config/messages';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarAlt,
  faPencil,
  faCalculatorAlt,
  faFileInvoiceDollar,
  faTrashAlt,
  faCalendarExclamation,
  faCarBuilding,
  faShelves,
} from '@fortawesome/pro-regular-svg-icons';
import {Toast} from 'primereact/toast';
import InventoryService from '../../services/InventoryService';
import InventoryItemDetail from './InventoryItemDetail';
import InventoryItemSuppliers from './InventoryItemSuppliers';
import InventoryItemPos from './InventoryItemPos';
import InventoryItemEdit from './InventoryItemEdit';
import OosExistsDialog from '../Inventory/OosExistsDialog';
import OosDialog from '../Oos/OosDialog';
import OosService from '../../services/OosService';
import InventoryItemStockDialog from './InventoryItemStockDialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import '../Inventory/InventoryItem.scss';
import PurchaseOrderAddDialog from '../PurchaseOrders/PurchaseOrderAddDialog';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import InventoryItemPositions from './InventoryItemPositions';

library.add(
  faCalendarAlt,
  faPencil,
  faCalculatorAlt,
  faFileInvoiceDollar,
  faTrashAlt,
  faCalendarExclamation,
  faCarBuilding,
  faShelves
);

interface RouteProps {
  id: string;
}

interface State {
  loadingInventoryItem: boolean;
  loadingSecondaryView: boolean;
  inventoryItem: InventoryItem | undefined;
  events: TimeLineEvent[];
  items: TwoTimelineItem[];
  showEditDialog: boolean;
  showNewOosDialog: boolean;
  showEditOosDialog: boolean;
  showOosExistsDialog: boolean;
  showPoAddDialog: boolean;
  editOos: Oos | undefined;
  inventoryItemOos: Oos[];
  showUpdateStockDialog: boolean;
  incomingQty: number;
}

class InventoryItemComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  inventoryService: InventoryService | null = null;
  tleService: TleService | null = null;
  oosService: OosService | null = null;
  purchaseOrdersService?: PurchaseOrdersService;
  twoToast?: TwoToast;

  messageSendSubscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingInventoryItem: false,
      loadingSecondaryView: false,
      inventoryItem: undefined,
      events: [],
      items: [],
      showEditDialog: false,
      showNewOosDialog: false,
      showEditOosDialog: false,
      showOosExistsDialog: false,
      showPoAddDialog: false,
      editOos: undefined,
      inventoryItemOos: [],
      showUpdateStockDialog: false,
      incomingQty: 0,
    };

    this.toast = React.createRef();

    this.showNewOosDialogAction = this.showNewOosDialogAction.bind(this);
    this.showEditOosDialogAction = this.showEditOosDialogAction.bind(this);
    this.closeInventoryItemEditDialog = this.closeInventoryItemEditDialog.bind(this);
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    this.inventoryService = this.context.inventoryService;
    this.tleService = this.context.tleService;
    this.oosService = this.context.oosService;
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.twoToast = this.context.twoToast;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.oosUpdated) {
        this.loadInventoryItem(id);
      }
      if (message === messages.purchaseOrderUpdated) {
        this.loadInventoryItem(id);
      }
      if (message === messages.inventoryItemUpdated) {
        this.loadInventoryItem(id);
        this.loadEvents(id);
      }
    });

    this.loadInventoryItem(id);
    this.loadEvents(id);
    this.loadIncomingQty(id);
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.messageSendSubscription.unsubscribe();
  }

  loadInventoryItem(id: string) {
    this.setState({loadingInventoryItem: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.inventoryService
      ?.getInventoryItems(params)
      .then(data => {
        const inventoryItem = (data.records as InventoryItem[])[0];
        this.setState({
          inventoryItem: inventoryItem,
          loadingInventoryItem: false,
        });
      })
      .catch(error => {
        this.setState({loadingInventoryItem: false});
        this.toast.current?.show({
          contentClassName: '',
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, records load failed, please try again.',
          life: 3000,
        });
        console.error(error);
      });
  }

  loadEvents(id: string) {
    this.setState({loadingSecondaryView: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'inventory_item',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    this.tleService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];

        const items = events.map(event => {
          const item: TwoTimelineItem = {event: event};
          return item;
        });

        this.setState({
          events: events,
          loadingSecondaryView: false,
          items: items,
        });
      })
      .catch(() => {
        this.toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Sorry, order events load failed, please try again.',
          life: 3000,
          contentClassName: '',
        });
        this.setState({loadingSecondaryView: false});
      });
  }

  async loadIncomingQty(invItemId: string) {
    try {
      const params = {
        filters: [
          JSON.stringify({
            field: 'purchase_order_item.inventory_item_id',
            value: invItemId,
          }),
          JSON.stringify({
            field: 'stage',
            value: ['Ordered', 'Eta Confirmed', 'Delayed'],
            condition: 'in',
          }),
        ],
        aggregate: ['items'],
      } as QueryParameter;
      const result = await this.purchaseOrdersService?.getPurchaseOrders(params);
      const pos = result?.records as PurchaseOrder[] | undefined;
      if (pos?.length) {
        const incomingQty = pos.reduce((qty, po) => {
          return (
            qty +
            (po.items ?? []).reduce((acc, poItem) => {
              return acc + (poItem.qty_in_uom ?? 0);
            }, 0)
          );
        }, 0);
        this.setState({incomingQty});
      } else {
        this.setState({incomingQty: 0});
      }
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Failed to load incoming quantity');
      this.setState({incomingQty: 0});
    }
  }

  async deleteInventoryItem() {
    const inventoryItem = this.state.inventoryItem;
    if (inventoryItem) {
      this.inventoryService
        ?.deleteInventoryItem(inventoryItem.id ?? '')
        .then(() => {
          this.toast.current?.show({
            contentClassName: '',
            severity: 'success',
            summary: 'Success',
            detail: `${inventoryItem.name} deleted successfully.`,
            life: 2000,
          });

          this.props.history.push('/inventory');
        })
        .catch(() => {
          this.toast.current?.show({
            contentClassName: '',
            severity: 'error',
            summary: 'Error',
            detail: `Sorry, ${inventoryItem.name} delete failed, please try again.`,
            life: 2000,
          });
        });
    } else {
      this.toast.current?.show({
        contentClassName: '',
        severity: 'error',
        summary: 'Error',
        detail: 'Sorry, inventory item delete failed, please try again.',
        life: 2000,
      });
    }
  }

  setOos() {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'factory_id',
        value: localStorage.getItem('current factory') ?? '',
      })
    );
    filters.push(
      JSON.stringify({
        field: 'inventory_item_id',
        value: this.state.inventoryItem?.id,
      })
    );
    filters.push(
      JSON.stringify({
        field: 'stage',
        value: ['Available'],
        condition: 'notIn',
      })
    );

    const params: QueryParameter = {
      filters,
      aggregate: true,
    };
    this.oosService?.getOos(params).then(data => {
      const oosData = (data.records as Oos[]) ?? [];
      if (oosData.length > 0) {
        this.setState({
          showOosExistsDialog: true,
          inventoryItemOos: oosData,
        });
      } else {
        this.showNewOosDialogAction();
      }
    });
  }

  showNewOosDialogAction() {
    if (this.state.inventoryItem) {
      this.setState({
        showOosExistsDialog: false,
        showNewOosDialog: true,
        editOos: {
          factory_id: localStorage.getItem('current factory') as string,
          inventory_item_id: this.state.inventoryItem.id as string,
          inventory_item: this.state.inventoryItem,
          stage: 'Eta Confirmed',
          updated_at: new Date(),
        },
      });
    }
  }

  showEditOosDialogAction() {
    const oosOfInventoryItem = this.state.inventoryItemOos;
    this.setState({
      showOosExistsDialog: false,
      showEditOosDialog: true,
      editOos: oosOfInventoryItem[0],
    });
  }

  processShowUpdateStockDialog() {
    this.setState({
      showUpdateStockDialog: true,
    });
  }

  closeInventoryItemEditDialog() {
    this.setState({showEditDialog: false});
  }

  getActions(): TwoAction[] {
    const editAction = {
      icon: faPencil,
      label: 'Edit',
      main: true,
      action: () => {
        this.setState({showEditDialog: true});
      },
    };

    const createPoAction = {
      label: 'Create PO',
      icon: faFileInvoiceDollar,
      action: () => {
        this.setState({showPoAddDialog: true});
      },
    };

    const deleteAction = {
      label: 'Delete',
      icon: faTrashAlt,
      action: () => {
        this.deleteInventoryItem();
      },
    };

    const setOosAction = {
      label: 'Set OOS',
      icon: faCalendarExclamation,
      action: () => {
        this.setOos();
      },
    };

    const updateStockAction = {
      label: 'Update Stock',
      icon: faCalculatorAlt,
      action: () => {
        this.processShowUpdateStockDialog();
      },
    };

    return [editAction, createPoAction, deleteAction, setOosAction, updateStockAction];
  }

  render() {
    const {inventoryItem, items, incomingQty} = this.state;

    return inventoryItem ? (
      <>
        <TwoEntityComponent title={inventoryItem.name} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loadingInventoryItem ? (
              <InventoryItemDetail
                inventoryItem={inventoryItem}
                history={this.props.history}
                incomingQty={incomingQty}
              />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="Vendors" icon={faCarBuilding} tooltip="Vendors">
            {!this.state.loadingSecondaryView ? (
              <InventoryItemSuppliers inventoryItem={inventoryItem} toast={this.toast} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            {!this.state.loadingSecondaryView ? (
              <TwoTimeline key={inventoryItem.id} items={items} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="POs" icon={faFileInvoiceDollar} tooltip="POs">
            {!this.state.loadingSecondaryView ? (
              <InventoryItemPos inventoryItem={inventoryItem} history={this.props.history} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="Positions" icon={faShelves} tooltip="Positions">
            {!this.state.loadingSecondaryView ? (
              <InventoryItemPositions inventoryItem={inventoryItem} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
        </TwoEntityComponent>

        <InventoryItemEdit
          inventoryItem={inventoryItem}
          showDialog={this.state.showEditDialog}
          onHide={this.closeInventoryItemEditDialog}
          toast={this.toast}
          history={this.props.history}
        />
        <PurchaseOrderAddDialog
          toast={this.toast}
          showPurchaseOrderDialog={this.state.showPoAddDialog}
          closeDialog={() => this.setState({showPoAddDialog: false})}
          inventoryItems={[inventoryItem]}
        />
        <OosExistsDialog
          showDialog={this.state.showOosExistsDialog}
          onHide={() => this.setState({showOosExistsDialog: false})}
          onYes={this.showEditOosDialogAction}
          toast={this.toast}
        />
        <OosDialog
          showDialog={this.state.showEditOosDialog}
          oos={this.state.editOos}
          toast={this.toast}
          onHide={() => {
            this.setState({
              showEditOosDialog: false,
              editOos: undefined,
              inventoryItemOos: [],
            });
          }}
          isEdit={true}
        />
        <OosDialog
          showDialog={this.state.showNewOosDialog}
          toast={this.toast}
          oos={this.state.editOos}
          onHide={() => {
            this.setState({
              showNewOosDialog: false,
            });
          }}
          isNew={true}
        />
        <InventoryItemStockDialog
          showDialog={this.state.showUpdateStockDialog}
          toast={this.toast}
          inventoryItem={inventoryItem}
          onHide={() => {
            this.setState({
              showUpdateStockDialog: false,
            });
          }}
        />

        <Toast ref={this.toast} />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(InventoryItemComponent);
