import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, Bom, QueryParameter} from 'two-core';

class BomService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.boms as string;
  }

  async getBoms(params: QueryParameter): Promise<Bom[]> {
    return this.get(this.endpoint, params)
      .then(data => {
        return Promise.resolve((data as ApiListResponse).records as Bom[]);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updateBom(id: number, recordPatch: Partial<Bom>): Promise<Bom> {
    return this.patch(`${this.endpoint}/${id}`, recordPatch)
      .then(data => {
        return Promise.resolve(data as Bom);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default BomService;
