import React from 'react';
import {Button} from 'primereact/button';
import {AppContext, TwoDialog} from 'two-app-ui';
import {Toast} from 'primereact/toast';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  onYes: () => void;
  toast: React.RefObject<Toast>;
}

class OosExistsDialog extends React.Component<Props, {}> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="No" className={'p-mr-2 p-button-text'} onClick={this.props.onHide} />
        <Button label="Yes" onClick={this.props.onYes} />
      </div>
    );

    const dialogBody = (
      <div className="p-d-flex w-100">
        <span>Please note, that there already is an OOS entry for this particular item. Do you want to see it?</span>
      </div>
    );
    return (
      <TwoDialog
        headerTitle={'OOS already exists'}
        showDialog={this.props.showDialog}
        width={50}
        onHide={this.props.onHide}
        loading={false}
        footer={footer}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default OosExistsDialog;
