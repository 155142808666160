import React from 'react';
import {InputTextarea} from 'primereact/inputtextarea';
import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FactoryAlarm} from 'two-core';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import {InputSwitch, InputSwitchChangeParams} from 'primereact/inputswitch';
import {faArrowUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons';
import {Link} from 'react-router-dom';

interface AlarmProps {
  alarm: FactoryAlarm;
  alarmReferencedData: string;
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleCheckBoxChange: (e: InputSwitchChangeParams) => void;
  handleDateChange: (e: CalendarChangeParams) => void;
}

const EditAlarm = ({
  alarm,
  alarmReferencedData,
  handleInputChange,
  handleCheckBoxChange,
  handleDateChange,
}: AlarmProps) => (
  <>
    <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0">
      <label htmlFor="done" className="p-col-1">
        created
      </label>
      <div className="p-col-3 p-p-0">
        <span>{alarm.created_at ? DateTime.fromISO(alarm.created_at?.toString()).toFormat(formats.dateTime) : ''}</span>
      </div>
      <label htmlFor="done" className="p-col-1">
        silenced until
      </label>
      <div className="p-col-3 p-p-0">
        <Calendar
          value={alarm.silence_until ? new Date(alarm.silence_until) : undefined}
          dateFormat={formats.calendarInputDate}
          name="silence_until"
          className="w-100"
          onChange={e => handleDateChange(e)}
        />
      </div>
      <label htmlFor="done" className="p-col-1">
        referes to
      </label>
      <div className="p-col-3 p-p-0">
        <Link to={`/order/${alarm.reference_id}`} target="_blank">
          <span>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> {alarmReferencedData}
          </span>
        </Link>
      </div>
    </div>

    <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0">
      <label htmlFor="detail" className="p-col-1">
        detail
      </label>
      <div className="p-col-11 p-p-0">
        <InputTextarea
          className="w-100"
          autoResize={true}
          id="detail"
          name="detail"
          value={alarm.detail}
          rows={5}
          cols={85}
          onChange={e => handleInputChange(e)}
        />
      </div>
    </div>

    <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pb-0">
      <label htmlFor="showDone" className="p-col-1">
        done
      </label>
      <div className="p-col-3 p-p-0">
        <InputSwitch
          name="showDone"
          className={'p-as-center'}
          checked={alarm.done}
          onChange={e => handleCheckBoxChange(e)}
        />
      </div>
    </div>
  </>
);

export default EditAlarm;
