import React from 'react';
import ReportFooter from '../Reports/ReportFooter';

class CutSheetFooter extends ReportFooter {
  getFooter(): JSX.Element {
    return (
      <React.Fragment>
        <table className="footer">
          <tr>
            <td className="label">date</td>
            <td className="text">.............................</td>
            <td className="label">initials</td>
            <td className="text">.............................</td>
            <td className="label">page</td>
            <td className="text">{this.pageNumber}</td>
          </tr>
        </table>
      </React.Fragment>
    );
  }
}

export default CutSheetFooter;
