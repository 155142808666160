import React, {useEffect, useRef, useState} from 'react';
import {FileUpload, FileUploadHandlerParam} from 'primereact/fileupload';
import {Image} from 'primereact/image';
import {Button} from 'primereact/button';
import {faCamera, faTrashCan} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Galleria} from 'primereact/galleria';

interface Props {
  fileUrls: string[];
  onFilesSelect: (files: File[]) => void;
  onFileDelete: (url: string) => void;
}

export const PhotosContent = ({fileUrls, onFilesSelect, onFileDelete}: Props) => {
  const fileUploadRef: React.RefObject<FileUpload> = useRef(null);
  const galleryRef: React.RefObject<Galleria> = useRef(null);
  const [galleryItemIndex, setGalleryItemIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (galleryItemIndex !== undefined) {
      galleryRef.current?.show();
    }
  }, [galleryItemIndex]);

  const uploadHandler = (e: FileUploadHandlerParam) => {
    onFilesSelect(e.files);
    fileUploadRef.current?.clear();
  };

  const galleryItemTemplate = (item: React.ReactElement): React.ReactNode => {
    return (
      <img
        src={item.props.src}
        style={{
          display: 'block',
          objectFit: 'contain',
          maxHeight: '85vh',
          maxWidth: '100%',
        }}
      />
    );
  };

  return (
    <>
      <Galleria
        ref={galleryRef}
        value={fileUrls.map((url, index) => (
          <Image
            key={index}
            src={url}
            width="100%"
            height="250px"
            imageStyle={{objectFit: 'contain'} as unknown as string}
          />
        ))}
        activeIndex={galleryItemIndex}
        onItemChange={e => setGalleryItemIndex(e.index)}
        circular
        showItemNavigators
        fullScreen
        showThumbnails={false}
        item={galleryItemTemplate}
        onHide={() => {
          setGalleryItemIndex(undefined);
        }}
      />
      <div className="p-grid">
        {!!fileUrls.length &&
          fileUrls.map((url, index) => (
            <div className="p-col-4" key={index} style={{position: 'relative'}}>
              <img
                src={url}
                width="100%"
                height="250px"
                style={{objectFit: 'contain'}}
                onClick={() => setGalleryItemIndex(index)}
              />
              <Button
                style={{position: 'absolute', bottom: 0, right: 0}}
                className="p-button-lg"
                icon={<FontAwesomeIcon icon={faTrashCan} size="xl" />}
                onClick={() => onFileDelete(url)}
              />
            </div>
          ))}
      </div>

      <div className="p-d-flex p-jc-center p-mt-2">
        <FileUpload
          className="photos-upload-button"
          ref={fileUploadRef}
          mode="basic"
          multiple
          accept="image/*"
          auto
          customUpload
          uploadHandler={uploadHandler}
          chooseOptions={{
            icon: <FontAwesomeIcon className="p-mx-auto" icon={faCamera} size="2xl" />,
            className: 'w-100 p-py-1',
            iconOnly: true,
          }}
          style={{width: '200px'}}
        />
      </div>
    </>
  );
};
