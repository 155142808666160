import React from 'react';
import {AppContext, TwoDialog, TwoToast} from 'two-app-ui';
import StockTakesService from '../../services/StockTakesService';
import {QueryParameter, StockTake} from 'two-core';
import {Button} from 'primereact/button';
import {confirmDialog} from 'primereact/confirmdialog';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  stockTakeId: number;
}

interface State {
  loading?: boolean;
  saving?: boolean;
  stockTake?: StockTake;
}

export default class StopStockTakeDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  stockTakesService?: StockTakesService;

  twoToast?: TwoToast;
  constructor(props: Props) {
    super(props);
    this.state = {};

    this.onHide = this.onHide.bind(this);
    this.onStop = this.onStop.bind(this);
    this.loadData = this.loadData.bind(this);
    this.loadStockTake = this.loadStockTake.bind(this);
    this.onShow = this.onShow.bind(this);
  }

  componentDidMount() {
    this.stockTakesService = this.context.stockTakesService;
    this.twoToast = this.context.twoToast;
  }

  async loadData() {
    const {stockTakeId} = this.props;
    this.setState({loading: true});
    const stockTake = await this.loadStockTake(stockTakeId);
    this.setState({loading: false, stockTake});
  }
  async loadStockTake(stockTakeId: number) {
    try {
      const queryParams: QueryParameter = {
        filters: [JSON.stringify({field: 'id', value: stockTakeId})],
        aggregate: ['document'],
      };
      const response = await this.stockTakesService!.getStockTakes(queryParams);
      const record = ((response?.records ?? []) as StockTake[])[0];
      if (!record) {
        this.twoToast?.showError('Error loading stock take');
        return undefined;
      }
      return record;
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error loading stock take');
      return undefined;
    }
  }

  onHide() {
    this.setState({
      saving: false,
    });
    this.props.onHide();
  }

  onShow() {
    this.loadData();
  }

  onStop() {
    const {stockTake} = this.state;
    if (!stockTake) {
      return;
    }
    const uncountedItems = stockTake.document?.items?.filter(item =>
      item.counts?.some(count => count.counted_qty === undefined)
    );
    if (uncountedItems?.length) {
      const message = `Sorry, there are ${uncountedItems.length} inventory items in this stock take that have not been counted yet. Are you sure you want to stop the counting?`;
      confirmDialog({
        message: message,
        header: 'Stop Stock Take',
        accept: () => {
          this.saveStockTake(stockTake.id, {stage: 'Review'});
        },
      });
    } else {
      this.saveStockTake(stockTake.id, {stage: 'Review'});
    }
  }

  async saveStockTake(stockTakeId: number, stockTakePatch: Partial<StockTake>) {
    this.setState({saving: true});
    try {
      await this.stockTakesService?.updateStockTake(stockTakeId, stockTakePatch);
      this.twoToast?.showSuccess('Stock take started');
      this.onHide();
    } catch (e) {
      console.error(e);
      this.twoToast?.showError('Error starting stock take');
      this.setState({saving: false});
    }
  }

  render() {
    const {showDialog} = this.props;
    const {saving} = this.state;

    const factoryKey = localStorage.getItem('current factory key') ?? '';
    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={this.onHide} disabled={saving} />
        <Button label="OK, Stop Counting" onClick={this.onStop} loading={saving} />
      </div>
    );
    return (
      <>
        <TwoDialog
          onHide={this.onHide}
          onShow={this.onShow}
          header="Stop Stock Take"
          showDialog={showDialog}
          style={{width: '75vw'}}
          breakpoints={{'768px': '80vw', '576px': '90vw'}}
          draggable={false}
          footer={footer}
        >
          <div>
            This action will finish the counting at factory {factoryKey} and will prepare the stock take document for
            review.
          </div>
        </TwoDialog>
      </>
    );
  }
}
