import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';

interface Props {
  data: any;
}

//interface State {}

class SSPack extends CutSheetTemplate {
  report: Report;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      2,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">ss std v2</td>
              <td className="text">
                <b>PACKING</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text" colSpan={3}>
                {this.props.data.reference}
              </td>
              <td className="label">shipping to</td>
              <td className="text">{this.props.data.ship_to}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      this.props.data.pack.items.map((item: any) => {
        let currentLineCount = 6;
        let arms;
        let cover;
        let channels;
        let ffChannels;
        let wire;
        let components;
        let controls;
        let extras, note;
        let repair1, repair2;

        if (item.content.repair_respo) {
          repair1 = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">rework</td>
              <td className="text">{item.content.repair_whole_item ? 'YES' : 'NO'}</td>
              <td className="label">responsibility</td>
              <td className="text" colSpan={4}>
                {this.capitaliseFirst(item.content.repair_respo)}
              </td>
            </tr>
          );
          repair2 = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">repair detail</td>
              <td className="text" colSpan={5}>
                {item.content.repair_details}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += 2;
        } else {
          repair1 = repair2 = '';
        }

        if (item.content.box_type) {
          cover = (
            <tr>
              <td />
              <td className="label">box</td>
              <td className="text">{this.capitaliseFirst(item.content.box_type)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.box_colour)}</td>
              <td className="label">length</td>
              <td className="number">{item.content.box_length}</td>
              <td className="sign-off">packed</td>
            </tr>
          );
          currentLineCount++;
        } else {
          cover = '';
        }

        if (item.content.ch_l_length || item.content.urail_l_length) {
          channels = (
            <React.Fragment>
              <tr>
                <td />
                <td className="label">ch left</td>
                <td className="text">
                  {this.capitaliseFirst(item.content.ch_l_colour) +
                    (item.content.urail_l_length ? ' Face Fit U Rail' : ' Outer Rail')}
                </td>
                <td className="label">outer</td>
                <td className="text">
                  {item.content.urail_l_length ? item.content.urail_l_length : item.content.ch_l_length}
                </td>
                <td className="label">inner</td>
                <td className="number">{item.content.ch_in_l_length ?? 'n/a'}</td>
                <td className="sign-off">packed</td>
              </tr>
              <tr>
                <td />
                <td className="label">ch right</td>
                <td className="text">
                  {this.capitaliseFirst(item.content.ch_r_colour) +
                    (item.content.urail_l_length ? ' Face Fit U Rail' : ' Outer Rail')}
                </td>
                <td className="label">outer</td>
                <td className="text">
                  {item.content.urail_r_length ? item.content.urail_r_length : item.content.ch_r_length}
                </td>
                <td className="label">inner</td>
                <td className="number">{item.content.ch_in_r_length ?? 'n/a'}</td>
                <td className="sign-off">packed</td>
              </tr>
            </React.Fragment>
          );
          currentLineCount += 2;
        } else {
          channels = '';
        }

        if (item.content.ff_l_length) {
          ffChannels = (
            <React.Fragment>
              <tr>
                <td />
                <td className="label">ch ff left</td>
                <td className="text">{this.capitaliseFirst(item.content.ch_l_colour) + ' L Face Fit'}</td>
                <td />
                <td />
                <td className="label">length</td>
                <td className="number">{item.content.ff_l_length}</td>
                <td className="sign-off">packed</td>
              </tr>
              <tr>
                <td />
                <td className="label">ch ff right</td>
                <td className="text">{this.capitaliseFirst(item.content.ch_r_colour) + ' L Face Fit'}</td>
                <td />
                <td />
                <td className="label">length</td>
                <td className="number">{item.content.ff_r_length}</td>
                <td className="sign-off">packed</td>
              </tr>
            </React.Fragment>
          );
          currentLineCount += 2;
        } else {
          ffChannels = '';
        }

        if (item.content.wire_type) {
          wire = (
            <tr>
              <td />
              <td className="label">wire</td>
              <td className="text">{this.capitaliseFirst(item.content.wire_type)}</td>
              <td className="label">left</td>
              <td className="text">{item.content.wire_l_length}</td>
              <td className="label">right</td>
              <td className="number">{item.content.wire_r_length}</td>
              <td className="sign-off">packed</td>
            </tr>
          );
          currentLineCount++;
        } else {
          wire = '';
        }

        if (item.content.arm_length) {
          arms = (
            <tr>
              <td />
              <td className="label">arms</td>
              <td className="text">Left & Right</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(item.content.arm_colour)}</td>
              <td className="label">length</td>
              <td className="number">{item.content.arm_length}</td>
              <td className="sign-off">check</td>
            </tr>
          );
        }

        if (item.content.components && item.content.components.length > 0) {
          components = item.content.components.map((component: any) => {
            currentLineCount++;
            return (
              <tr>
                <td>{item.order_item_index}</td>
                <td className="label">component</td>
                <td className="text" colSpan={3}>
                  {this.capitaliseFirst(component.label)}
                </td>
                <td className="label">qty</td>
                <td className="number">{component.qty}</td>
                <td className="sign-off">packed</td>
              </tr>
            );
          });
        } else {
          components = '';
        }

        if (item.content.controls && item.content.controls.length > 0) {
          controls = item.content.controls.map((control: any) => {
            currentLineCount++;
            const oldControl = typeof control === 'string';
            return (
              <tr>
                <td>{item.order_item_index}</td>
                <td className="label">control</td>
                <td className="text" colSpan={3}>
                  {this.capitaliseFirst(oldControl ? control : control.label)}
                </td>
                <td className="label">qty</td>
                <td className="number">{oldControl ? 1 : control.qty}</td>
                <td className="sign-off">packed</td>
              </tr>
            );
          });
        } else {
          controls = '';
        }

        if (item.content.extras && item.content.extras.length > 0) {
          extras = item.content.extras.map((extra: any) => {
            currentLineCount++;
            return (
              <tr>
                <td>{item.order_item_index}</td>
                <td className="label">extra</td>
                <td className="text" colSpan={3}>{`${this.capitaliseFirst(extra.designation)}${
                  extra.colour ? ' ' + this.capitaliseFirst(extra.colour) : ''
                }${extra.length ? ' @ ' + extra.length + 'mm' : ''}`}</td>
                <td className="label">qty</td>
                <td className="number">{extra.qty}</td>
                <td className="sign-off">packed</td>
              </tr>
            );
          });
        } else {
          extras = '';
        }

        if (item.content.notes) {
          note = (
            <tr>
              <td />
              <td className="label">note</td>
              <td className="text" colSpan={5}>
                {`${item.content.notes}`.replace(/\r?\n|\r/g, ' ')}
              </td>
            </tr>
          );
          currentLineCount += Math.ceil(`${item.content.notes}`.length / 90);
        } else {
          note = '';
        }

        this.report.addItem(
          item,
          currentLineCount,
          <React.Fragment>
            <table className="mt-10 odd-zebra three-cols content">
              {repair1}
              {repair2}
              <tr>
                <td>{item.order_item_index}</td>
                <td className="label">product</td>
                <td className="text">{item.content.product}</td>
                <td className="label">fabric</td>
                <td className="text">{this.capitaliseFabric(item.content.fabric)}</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(item.content.fab_colour)}</td>
                <td className="sign-off">packed</td>
              </tr>
              <tr>
                <td />
                <td className="label">box/open</td>
                <td className="text">
                  {item.content.box_type ? this.capitaliseFirst(`Box ${item.content.box_type}`) : 'Open'}
                </td>
                <td className="label">b2b width</td>
                <td className="number">{item.content.width}</td>
                <td className="label">drop</td>
                <td className="number">{item.content.drop}</td>
                <td className="sign-off">check</td>
              </tr>
              <tr>
                <td />
                <td className="label">tube</td>
                <td className="text">{this.capitaliseFirst(item.content?.tube_type)}</td>
                <td className="label">ctrl side</td>
                <td className="text">{item.content?.control_side?.toUpperCase()}</td>
                <td className="label">mount</td>
                <td>{this.capitaliseFirst(item.content?.mount)}</td>
                <td className="sign-off">done</td>
              </tr>
              <tr>
                <td />
                <td className="label">ctrl type</td>
                <td className="text">{this.capitaliseFirst(item.content.control_type)}</td>
                <td className="label">insert left</td>
                <td className="text">{this.capitaliseFirst(item.content.insert_l)}</td>
                <td className="label">insert right</td>
                <td className="text">{this.capitaliseFirst(item.content.insert_r)}</td>
                <td className="sign-off">done</td>
              </tr>
              {cover}
              <tr>
                <td />
                <td className="label">weight bar</td>
                <td className="text">{this.capitaliseFirst(item.content.wb_type)}</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(item.content.wb_colour)}</td>
                <td className="label">length</td>
                <td className="number">{item.content.wb_length}</td>
                <td className="sign-off">check</td>
              </tr>
              <tr>
                <td />
                <td className="label">skirt</td>
                <td className="text">{this.capitaliseFirst(item.content.skirt) ?? 'n/a'}</td>
                <td className="label">weight</td>
                <td className="text">{item.content.steel_weight}</td>
                <td className="label">caps</td>
                <td className="text">{this.capitaliseFirst(item.content.caps)}</td>
                <td className="sign-off">check</td>
              </tr>
              {arms}
              {channels}
              {ffChannels}
              {wire}
              {components}
              {controls}
              {extras}
              {note}
            </table>
          </React.Fragment>
        );
      });
    }

    {
      /* CHECK SUM */
    }
    this.report.addItem(
      this.props.data,
      1,
      <React.Fragment>
        <table className="summary">
          <td className="label">total</td>
          <td className="text">{this.props.data.pack.items.length}</td>
        </table>
      </React.Fragment>
    );

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="shadesol-standard-assembly-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default SSPack;
