import React from 'react';
import {TwoDataTable} from 'two-app-ui';
import {InventoryItem} from 'two-core';
import {Column} from 'primereact/column';
import {toInputUppercase} from '../../Inventory/Constants/Utils';
import {InputText} from 'primereact/inputtext';
import {inventoryItemCategories} from '../../Inventory/Constants/constants';
import {MultiSelect} from 'primereact/multiselect';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {Card} from 'primereact/card';

export interface AddStockTakeInventoryItemListFilterProps {
  name?: string;
  colour?: string;
  category?: string[];
}
interface Props {
  droppableId: string;
  items: InventoryItem[];
  totalItems?: number;
  patrolElement?: any;
  pageSizeIdentifier: string;
  filters?: AddStockTakeInventoryItemListFilterProps;
  onFilterChange?: (filters: Partial<AddStockTakeInventoryItemListFilterProps>) => void;
  loading?: boolean;
}

export const AddStockTakeInventoryItemList = ({
  items,
  droppableId,
  totalItems,
  patrolElement,
  pageSizeIdentifier,
  onFilterChange,
  filters,
  loading,
}: Props) => {
  const nameFilter = (
    <InputText
      value={filters?.name}
      className="form-filter"
      onChange={e => onFilterChange?.({name: e.target.value})}
      onInput={toInputUppercase}
    />
  );
  const colourFilter = (
    <InputText
      value={filters?.colour}
      className="form-filter"
      onChange={e => onFilterChange?.({colour: e.target.value})}
      onInput={toInputUppercase}
    />
  );
  const categoryFilter = (
    <MultiSelect
      optionLabel="label"
      optionValue="value"
      options={inventoryItemCategories}
      value={filters?.category}
      className="form-filter"
      onChange={e => onFilterChange?.({category: e.value})}
    />
  );

  const renderClone = (provided: any, snapshot: any, rubric: any) => {
    const invItemId = rubric.draggableId.split('_')[0];
    const item = items.find(item => item.id === invItemId);
    return (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <Card style={{width: '300px'}}>{item?.name ?? invItemId}</Card>
      </div>
    );
  };

  const itemBodyDraggableTemplate = (rowData: InventoryItem, key: string) => {
    const value = `${rowData[key as keyof InventoryItem]}`;

    if (patrolElement && rowData.id === 'patrol') {
      return patrolElement;
    }

    return (
      <React.Fragment>
        <Draggable
          key={`${rowData.id}_${key}`}
          draggableId={`${rowData.id}_${key}`}
          index={0}
          disableInteractiveElementBlocking={true}
        >
          {provided => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{transform: 'none'}}
              >
                {value}
              </div>
            );
          }}
        </Draggable>
      </React.Fragment>
    );
  };

  return (
    <Droppable droppableId={droppableId ?? ''} renderClone={renderClone}>
      {provided => (
        <div ref={provided.innerRef}>
          <TwoDataTable
            pageSizeIdentifier={pageSizeIdentifier}
            selectedItems={[]}
            activeFilters={[]}
            value={items}
            hideFilter={!onFilterChange}
            showPaging={false}
            totalRecords={totalItems}
            loading={loading}
          >
            <Column
              header="Name"
              field="name"
              body={rowData => itemBodyDraggableTemplate(rowData, 'name')}
              filter
              filterElement={nameFilter}
              // sortable={!hideHeaderActions}
              showFilterMenu={false}
              className="col-min-xxl"
            />
            <Column
              header="Colour"
              field="colour"
              body={rowData => itemBodyDraggableTemplate(rowData, 'colour')}
              filter
              filterElement={colourFilter}
              // sortable={!hideHeaderActions}
              showFilterMenu={false}
              className="col-min-l"
            />
            <Column
              header="Category"
              field="category"
              body={rowData => itemBodyDraggableTemplate(rowData, 'category')}
              filter
              filterElement={categoryFilter}
              // sortable={!hideHeaderActions}
              showFilterMenu={false}
              className="col-l"
            />
          </TwoDataTable>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
