import React from 'react';
import {AppContext, TwoDataTable} from 'two-app-ui';
import {InventoryItem, MapOf, QueryParameter, Supplier, SupplyItem, SupplyItemAggregate} from 'two-core';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {supplyItemReorderUnit} from '../Order/Constants/constants';
import SupplyItemsService from '../../services/SupplyItemsService';
import {InputText} from 'primereact/inputtext';
import values from '../../config/values';
import {DropdownChangeParams} from 'primereact/dropdown';
import {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import {toInputUppercase} from '../Inventory/Constants/Utils';
import {MultiSelect} from 'primereact/multiselect';
import {InputNumber} from 'primereact/inputnumber';
import {Card} from 'primereact/card';

interface Props {
  id?: string;
  editableTable?: boolean;
  paginator?: boolean;
  quantityHidden?: boolean;
  filtersHidden?: boolean;
  droppableId?: string;
  supplier?: Supplier;
  assignedSupplyItems?: SupplyItem[];
  excludedSupplyItemsIds?: number[];
  updateSupplyItem?: (supplyItem: SupplyItem) => void;
  editableAssignedValuesMap?: MapOf<{
    unit_price: number;
    quantity: number;
  }>;
  setEditableAssignedValuesMap?: (
    editableAssignedValuesMap: MapOf<{
      unit_price: number;
      quantity: number;
    }>
  ) => void;
  customEmptyMessage?: string;
}

interface State {
  loading: boolean;
  factoryId: string;
  supplyItems: SupplyItem[];
  selectedSupplyItems: SupplyItem[];
  totalItems: number;
  filters: {
    inventoryItemName: string;
    sku: string;
    colour: string;
    supplyItemName: string;
    supplyItemSku: string;
    supplyItemPackageSizeLabel: string;
    supplyItemReorderUnit: string;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  pagination: {pageSize: number; offset: number};
}

//todo Temporary fix. This should be reworked -> the draggableId must be a simple string(according to documentation) not a json with data.
export interface DraggableIdData {
  id: string;
  data: any;
}

class SupplyItemListComponent extends React.Component<Props, State> {
  static contextType = AppContext;
  typingTimer: NodeJS.Timeout | undefined = undefined;
  supplyItemsService: SupplyItemsService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      factoryId: localStorage.getItem('current factory') ?? '',
      supplyItems: [],
      selectedSupplyItems: [],
      totalItems: 0,
      filters: {
        inventoryItemName: '',
        sku: '',
        colour: '',
        supplyItemName: '',
        supplyItemSku: '',
        supplyItemPackageSizeLabel: '',
        supplyItemReorderUnit: '',
      },
      sortBy: {field: 'id', order: 1},
      pagination: {pageSize: 10, offset: 0},
    };

    this.onSort = this.onSort.bind(this);
    this.itemBodyDraggableTemplate = this.itemBodyDraggableTemplate.bind(this);
  }

  componentDidMount() {
    this.supplyItemsService = this.context.supplyItemsService;
    if (this.props.assignedSupplyItems === undefined) {
      this.loadSupplyItems();
    }
  }

  loadSupplyItems(): Promise<void> | undefined {
    const {supplier} = this.props;
    const {sortBy, pagination} = this.state;
    this.setState({loading: true});
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'inventory_item.factory_id',
        value: this.state.factoryId,
      })
    );
    if (supplier) {
      filters.push(
        JSON.stringify({
          field: 'supplier_id',
          value: supplier.id!,
        })
      );
    }
    if (this.state.filters.inventoryItemName) {
      filters.push(
        JSON.stringify({
          field: 'inventory_item.name',
          value: this.state.filters.inventoryItemName,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.sku) {
      filters.push(
        JSON.stringify({
          field: 'sku',
          value: this.state.filters.sku,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.colour) {
      filters.push(
        JSON.stringify({
          field: 'inventory_item.colour',
          value: this.state.filters.colour,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.supplyItemName) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: this.state.filters.supplyItemName,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.supplyItemSku) {
      filters.push(
        JSON.stringify({
          field: 'sku',
          value: this.state.filters.supplyItemSku,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.supplyItemPackageSizeLabel) {
      filters.push(
        JSON.stringify({
          field: 'package_size_label',
          value: this.state.filters.supplyItemPackageSizeLabel,
          condition: 'like',
        })
      );
    }
    if (this.state.filters.supplyItemReorderUnit) {
      filters.push(
        JSON.stringify({
          field: 'reorder_unit',
          value: this.state.filters.supplyItemReorderUnit,
          condition: 'in',
        })
      );
    }
    const orderBys = [
      JSON.stringify({
        field: sortBy?.field,
        direction: sortBy?.order === 1 ? 'ASC' : 'DESC',
      }),
    ];
    const aggregate: SupplyItemAggregate[] = ['inventory_items'];
    const params: QueryParameter = {
      filters: filters,
      aggregate: aggregate,
      orderBys: orderBys,
      offset: pagination.offset,
      page_size: pagination.pageSize,
    };
    return this.supplyItemsService?.getSupplyItems(params).then(data => {
      const supplyItems = (data.records as SupplyItem[]) ?? [];
      this.setState({
        supplyItems: supplyItems,
        totalItems: data?.total_records ?? 0,
        loading: false,
      });
    });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadSupplyItems();
  }

  itemBodyDraggableTemplate(rowData: SupplyItem, key: string) {
    let value: string;
    const parsedKey = key.split('.');
    if (parsedKey[0] === 'inventory_item') {
      value = `${rowData['inventory_item']![parsedKey[1] as keyof InventoryItem]}`;
    } else {
      value = `${rowData[parsedKey[0] as keyof SupplyItem]}`;
    }
    if (this.props.droppableId) {
      const data = JSON.stringify({
        id: `${this.props.droppableId}_${key}_${rowData.id}`,
        data: rowData,
      });
      return (
        <React.Fragment>
          <Draggable key={rowData.id} draggableId={data} index={0} disableInteractiveElementBlocking={true}>
            {provided => (
              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                {value}
              </div>
            )}
          </Draggable>
        </React.Fragment>
      );
    } else {
      return value;
    }
  }

  filterSupplyItems(supplyItems: SupplyItem[], excludedSupplyItemsIds: number[]): SupplyItem[] {
    return supplyItems.filter(supplyItem => !excludedSupplyItemsIds.includes(supplyItem.id!));
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | DateColumnFilterChangeEvent) {
    const value = e.target.value;
    const name = e.target.name;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      () => this.loadSupplyItems()
    );
  }

  async onSort(e: DataTableSortParams) {
    this.setState({sortBy: {field: e.sortField, order: e.sortOrder}}, () => this.loadSupplyItems());
  }

  onEditableValuesChange(supplyItem: SupplyItem, key: 'unit_price' | 'quantity', value: number) {
    const {editableAssignedValuesMap, setEditableAssignedValuesMap} = this.props;
    setEditableAssignedValuesMap!({
      ...editableAssignedValuesMap,
      [supplyItem.id!]: {
        ...editableAssignedValuesMap![supplyItem.id!],
        [key]: value,
      },
    });
  }

  bodyEdit(supplyItem: SupplyItem, key: 'unit_price' | 'quantity') {
    const {editableTable, editableAssignedValuesMap} = this.props;
    if (editableTable) {
      return (
        <InputNumber
          inputClassName="w-100"
          mode="decimal"
          minFractionDigits={0}
          maxFractionDigits={4}
          min={0}
          name={key}
          value={editableAssignedValuesMap![supplyItem.id!][key]}
          onChange={e => this.onEditableValuesChange(supplyItem, key, e.value ?? 0)}
        />
      );
    }
    if (key === 'unit_price') {
      return supplyItem[key];
    }
    return '';
  }

  render() {
    const {customEmptyMessage} = this.props;
    const {supplyItems, totalItems, pagination, loading} = this.state;
    const {assignedSupplyItems, excludedSupplyItemsIds, editableTable, paginator, quantityHidden, filtersHidden} =
      this.props;

    const inventoryItemNameFilter = (
      <InputText
        name="inventoryItemName"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );
    const itemSkuFilter = (
      <InputText
        name="sku"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );
    const colourFilter = (
      <InputText
        name="colour"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );
    const supplyItemNameFilter = (
      <InputText
        name="supplyItemName"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );
    const supplyItemSkuFilter = (
      <InputText
        name="supplyItemSku"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
        onInput={toInputUppercase}
      />
    );
    const supplyItemPackageSizeLabelFilter = (
      <InputText
        name="supplyItemPackageSizeLabel"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const supplyItemReorderUnitFilter = (
      <MultiSelect
        value={this.state.filters.supplyItemReorderUnit}
        options={supplyItemReorderUnit}
        name="supplyItemReorderUnit"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const tableValue = assignedSupplyItems ?? this.filterSupplyItems(supplyItems, excludedSupplyItemsIds ?? []);
    const table = (
      <TwoDataTable
        pageSizeIdentifier={'supply_item_container'}
        value={tableValue}
        selectedItems={[]}
        activeFilters={[]}
        editMode={editableTable ? 'cell' : 'row'}
        className="editable-cells-table"
        totalRecords={totalItems}
        rows={pagination.pageSize}
        first={pagination.offset}
        showPaging={paginator}
        onPage={e => this.onPageChange(e as DataTablePageParams)}
        onSort={this.onSort}
        sortField={this.state.sortBy?.field}
        sortOrder={this.state.sortBy?.order}
        loading={loading}
        customEmptyMessage={customEmptyMessage}
      >
        <Column
          className={'col-min-l'}
          key="inventory_item.name"
          field="inventory_item.name"
          header="Name"
          sortable={!filtersHidden}
          filter={!filtersHidden}
          filterElement={inventoryItemNameFilter}
          showFilterMenu={false}
          body={item => this.itemBodyDraggableTemplate(item, 'inventory_item.name')}
        />
        <Column
          className={'col-l'}
          key="colour"
          field="inventory_item.colour"
          header="Colour"
          sortable={!filtersHidden}
          filter={!filtersHidden}
          filterElement={colourFilter}
          showFilterMenu={false}
          body={item => this.itemBodyDraggableTemplate(item, 'inventory_item.colour')}
        />
        <Column
          className={'col-min-xxl'}
          key="name"
          field="name"
          header="Supply Item Name"
          sortable={!filtersHidden}
          filter={!filtersHidden}
          filterElement={supplyItemNameFilter}
          showFilterMenu={false}
          body={item => this.itemBodyDraggableTemplate(item, 'name')}
        />
        <Column
          className={'col-xl'}
          key="sku"
          field="sku"
          header="Supply SKU"
          sortable={!filtersHidden}
          filter={!filtersHidden}
          filterElement={supplyItemSkuFilter}
          showFilterMenu={false}
          body={item => this.itemBodyDraggableTemplate(item, 'sku')}
        />
        <Column
          className={'col-l'}
          key="package_size_label"
          field="package_size_label"
          header="Package"
          sortable={!filtersHidden}
          filter={!filtersHidden}
          filterElement={supplyItemPackageSizeLabelFilter}
          showFilterMenu={false}
          body={item => this.itemBodyDraggableTemplate(item, 'package_size_label')}
        />
        <Column
          className={'col-l'}
          key="reorder_unit"
          field="reorder_unit"
          header="Reorder Unit"
          sortable={!filtersHidden}
          filter={!filtersHidden}
          filterElement={supplyItemReorderUnitFilter}
          showFilterMenu={false}
          body={item => this.itemBodyDraggableTemplate(item, 'reorder_unit')}
        />
        <Column
          className={'col-m'}
          key="unit_price"
          field="unit_price"
          header="Unit Price"
          // body={item => this.bodyEdit(item, 'unit_price')}
        />
        <Column className={'col-m'} key="order_minimum" field="order_minimum" header="Order Min" />
        <Column
          className={'col-m'}
          key="quantity"
          field="quantity"
          header="Quantity"
          hidden={quantityHidden}
          body={item => this.bodyEdit(item, 'quantity')}
        />
      </TwoDataTable>
    );
    return (
      <div id={'supply_item_list_page_container' + this.props.id ?? ''} className="page-container">
        {this.props.droppableId ? (
          <Droppable
            droppableId={this.props.droppableId ?? ''}
            renderClone={(provided, snapshot, rubric) => {
              const draggableIdData = JSON.parse(rubric.draggableId) as DraggableIdData;
              const supplyItem = draggableIdData.data as SupplyItem;
              return (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  <Card style={{width: '300px'}}>{supplyItem.name}</Card>
                </div>
              );
            }}
          >
            {provided => (
              <div ref={provided.innerRef}>
                {table}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ) : (
          table
        )}
      </div>
    );
  }
}

export default SupplyItemListComponent;
