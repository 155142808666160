const shippingLabelV2 = ` CT~~CD,~CC^~CT~
^XA
^CI27
^XZ
^XA
^FT16,58^A0N,28,28^FH\^CI28^FDcustomer^FS^CI27
^FT60,266^A0N,28,28^FH\^CI28^FDorder^FS^CI27
^FT11,154^A0N,28,28^FH\^CI28^FDreference^FS^CI27
^FT68,594^A0N,28,28^FH\^CI28^FDitem^FS^CI27
^FT29,362^A0N,28,28^FH\^CI28^FDaddress^FS^CI27
^FT66,776^A0N,28,28^FH\^CI28^FDpckg^FS^CI27
^FT542,776^A0N,28,28^FH\^CI28^FDweight^FS^CI27
^FT756,776^A0N,28,28^FB27,1,7,C^FH\^CI28^FDkg^FS^CI27
^FT232,776^A0N,28,28^FB16,1,7,C^FH\^CI28^FDX^FS^CI27
^FT358,782^A0N,34,33^FB19,1,9,C^FH\^CI28^FDX^FS^CI27
^FT647,266^A0N,28,28^FH\^CI28^FDof^FS^CI27
^FO16,733^GB775,0,4^FS
^FO16,206^GB775,0,4^FS
^FO16,302^GB775,0,4^FS
^FO502,208^GB0,96,4^FS
^FO16,541^GB775,0,4^FS
^FT28,650^A0N,28,28^FH\^CI28^FDlocation^FS^CI27
^FT52,706^A0N,28,28^FH\^CI28^FDW x D^FS^CI27
^FT79,498^A0N,28,28^FH\^CI28^FDcity^FS^CI27
^FT128,73^A0N,68,68^FH\^CI28^FD{customer}^FS^CI27
^FT128,270^A0N,39,41^FH\^CI28^FD{order_code}^FS^CI27
^FT128,165^A0N,56,56^FH\^CI28^FD{reference}^FS^CI27
^FT128,369^A0N,45,46^FH\^CI28^FD{address_1}^FS^CI27
^FT526,283^A0N,73,74^FB105,1,19,R^FH\^CI28^FD{index}^FS^CI27
^FT679,285^A0N,79,79^FB114,1,20,C^FH\^CI28^FD{count}^FS^CI27
^FT130,781^A0N,39,48^FB92,1,10,C^FH\^CI28^FD{width}^FS^CI27
^FT256,781^A0N,39,51^FB96,1,10,C^FH\^CI28^FD{height}^FS^CI27
^FT384,781^A0N,39,51^FB96,1,10,C^FH\^CI28^FD{depth}^FS^CI27
^FT631,781^A0N,39,41^FB109,1,10,C^FH\^CI28^FD{weight}^FS^CI27
^FT128,433^A0N,45,46^FH\^CI28^FD{address_2}^FS^CI27
^FT708,505^A0N,45,46^FB75,1,12,R^FH\^CI28^FD{state}^FS^CI27
^FT128,654^A0N,39,38^FH\^CI28^FD{location}^FS^CI27
^FT128,710^A0N,39,38^FH\^CI28^FD{more_detail}^FS^CI27
^FT128,598^A0N,39,38^FH\^CI28^FD{ord_item}^FS^CI27
^FT128,507^A0N,51,51^FH\^CI28^FD{suburb}^FS^CI27
^FT623,502^A0N,39,38^FH\^CI28^FD{postcode}^FS^CI27
^FT128,198^A0N,39,38^FH\^CI28^FD{reference_2}^FS^CI27
^PQ1,,,Y
^XZ`;

export default shippingLabelV2;
