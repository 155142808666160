import React from 'react';
import {Column} from 'primereact/column';
import {TwoDataTable} from 'two-app-ui';
import {InventoryItem, MapOf, WarehousePosition} from 'two-core';

interface Props {
  inventoryItem: InventoryItem;
}

interface State {
  positions: WarehousePosition[];
}

class InventoryItemPos extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      positions: [],
    };
  }

  componentDidMount() {
    this.sortPositions();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.inventoryItem !== this.props.inventoryItem) {
      this.sortPositions();
    }
  }

  sortPositions() {
    const positions: MapOf<WarehousePosition> = this.props.inventoryItem.positions ?? {};
    const sortedPositions = Object.values(positions).sort((a, b) => {
      if (a.section < b.section) {
        return -1;
      }
      if (a.section > b.section) {
        return 1;
      }
      if (+a.rack < +b.rack) {
        return -1;
      }
      if (+a.rack > +b.rack) {
        return 1;
      }
      if (+a.shelve < +b.shelve) {
        return -1;
      }
      if (+a.shelve > +b.shelve) {
        return 1;
      }
      if (+a.bay < +b.bay) {
        return -1;
      }
      if (+a.bay > +b.bay) {
        return 1;
      }
      return 0;
    });
    this.setState({positions: sortedPositions});
  }

  render() {
    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="po_list_page" className="page-container">
          <TwoDataTable
            style={{height: '100%'}}
            pageSizeIdentifier={'positions_list_page'}
            heightToScroll={undefined}
            customEmptyMessage="No positions set."
            selectedItems={[]}
            loading={false}
            activeFilters={{}}
            value={this.state.positions ?? []}
            showPaging={false}
          >
            <Column header="Section" field="section" className="col-min-l" />
            <Column header="Rack" field="rack" className="col-l" />
            <Column header="Shelve" field="shelve" className="col-l" />
            <Column header="Bay" field="bay" className="col-l" />
          </TwoDataTable>
        </div>
      </div>
    );
  }
}

export default InventoryItemPos;
