import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';
import {FloorTaskContentSaw, MapOf} from 'two-core';

interface Props {
  data: any;
}

//interface State {}

class CVSaw_v2 extends CutSheetTemplate {
  report: Report;
  contentPrio: MapOf<number>;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
    this.contentPrio = {
      repair: 1,
      note: 2,
      tube: 3,
      rail: 4,
      deco_cover: 5,
      channel: 6,
      head_mount: 7,
    };
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      2,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">cv std v3</td>
              <td className="text">
                <b>SAW</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text" colSpan={3}>
                {this.props.data.reference}
              </td>
              <td className="label">shipping to</td>
              <td className="text">{this.props.data.ship_to}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      /* ITEMS */
    }
    {
      this.props.data.saw.items.sort((firstTask: any, secondTask: any) => {
        const weight1 =
          firstTask.order_item_index * 100 +
          this.contentPrio[firstTask.category] * 10 +
          (firstTask.content?.blindIndex ?? 0);
        const weight2 =
          secondTask.order_item_index * 100 +
          this.contentPrio[secondTask.category] * 10 +
          (secondTask.content.blindIndex ?? 0);
        return weight1 - weight2;
      });

      let currentItemIndex = this.props.data.saw.items[0].order_item_index;
      let itemRows: any[] = [];
      let repair1: any, repair2: any, repair3: any;
      repair1 = repair2 = repair3 = '';
      let isFirstRow = true;
      let currentLineCount = 1;

      this.props.data.saw.items.map((item: any) => {
        let indexColumn = <td className="no-top-border" />;
        let row;
        let rowClass = 'inside-row';

        if (item.order_item_index !== currentItemIndex) {
          this.report.addItem(
            item,
            currentLineCount,
            <React.Fragment>
              <table className="mt-10 odd-zebra three-cols content">
                {repair1}
                {repair2}
                {repair3}
                {itemRows}
              </table>
            </React.Fragment>
          );
          currentItemIndex = item.order_item_index;
          currentLineCount = 0;
          itemRows = [];
          isFirstRow = true;
        }

        if (isFirstRow) {
          isFirstRow = false;
          indexColumn = <td className="index">{item.order_item_index}</td>;
          rowClass = 'new-item';
        }

        if (item.category === 'repair') {
          repair1 = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">rework</td>
              <td className="text">{item.content.repair_whole_item ? 'YES' : 'NO'}</td>
              <td className="label">type</td>
              <td className="text">{this.capitaliseFirst(item.content.repair_type ?? 'Whole Item Rework')}</td>
              <td className="label">responsibility</td>
              <td className="text" colSpan={2}>
                {this.capitaliseFirst(item.content.repair_responsibility)}
              </td>
            </tr>
          );
          if (item.content.repair_type === 'cutdown (standard)') {
            repair2 = (
              <tr>
                <td />
                <td className="label">new height</td>
                <td className="number">{item.content.new_height}</td>
                <td className="label">new width</td>
                <td className="number" colSpan={3}>
                  {item.content.new_width}
                </td>
                <td className="sign-off">check</td>
              </tr>
            );
            currentLineCount += 1;
          }
          repair3 = (
            <tr>
              <td />
              <td className="label">repair</td>
              <td className="text" colSpan={5}>
                {item.content.repair_details}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += 2;
        }

        if (item.category === 'tube') {
          const data = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">tube {data.blindIndex}</td>
              <td className="text">{this.capitaliseFirst(data.extrusion)}</td>
              <td className="label">colour</td>
              <td className="text">MILL</td>
              <td className="label">length</td>
              <td className="number">{data.length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        }

        if (item.category === 'rail') {
          const data = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">base rail {data.blindIndex}</td>
              <td className="text">{this.capitaliseFirst(data.extrusion)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(data.colour)}</td>
              <td className="label">length</td>
              <td className="number">{data.length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        }

        if (item.category === 'deco_cover') {
          const data: FloorTaskContentSaw = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">cover</td>
              <td className="text">{this.capitaliseFirst(data.extrusion)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(data.colour)}</td>
              <td className="label">length</td>
              <td className="number">{data.length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        }

        if (item.category === 'channel') {
          const data: FloorTaskContentSaw = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">channel</td>
              <td className="text">{this.capitaliseFirst(data.extrusion)}</td>
              <td className="label">colour</td>
              <td className="number">{data.colour}</td>
              <td className="label">length</td>
              <td className="number">{data.length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        }

        if (item.category === 'head_mount') {
          const data: FloorTaskContentSaw = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label no-top-border">mount rail</td>
              <td className="text">{this.capitaliseFirst(data.extrusion)}</td>
              <td className="label">colour</td>
              <td className="number">{data.colour}</td>
              <td className="label">length</td>
              <td className="number">{data.length}</td>
              <td className="sign-off">cut</td>
            </tr>
          );
          currentLineCount++;
        }

        if (item.category === 'note') {
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">note</td>
              <td className="text" colSpan={5}>
                {`${item.content}`.replace(/\r?\n|\r/g, ' ')}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += Math.ceil(`${item.content}`.length / 90);
        }

        itemRows.push(row);
      });
      this.report.addItem(
        this.props.data,
        currentLineCount,
        <React.Fragment>
          <table className="mt-10 odd-zebra three-cols content">
            {repair1}
            {repair2}
            {repair3}
            {itemRows}
          </table>
        </React.Fragment>
      );
    }

    {
      /* CHECK SUM */
    }
    this.report.addItem(
      this.props.data,
      1,
      <React.Fragment>
        <table className="summary">
          <td className="label">total</td>
          <td className="text">{this.props.data.saw.items.length}</td>
        </table>
      </React.Fragment>
    );

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="colorvue-standard-saw-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default CVSaw_v2;
