import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from 'primereact/progressspinner';
import React from 'react';
import {AppContext, TwoToast} from 'two-app-ui';
import ShipmentsService from '../../services/ShipmentsService';
import NewLabelDialog from './NewLabelDialog';
import shippingLabel from '../../templates/ShippingLabelTemplate';
import shippingLabelV2 from '../../templates/ShippingLabelV2Template';
import {confirmDialog} from 'primereact/confirmdialog';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faPrint} from '@fortawesome/pro-regular-svg-icons';
import ShipmentItemsTable from './ShipmentItemsTable';
import './Shipment.scss';
import {Address, Order, ShipmentItem} from 'two-core';
import FactoriesService from '../../services/FactoriesService';
import {colourvueExtLabels, curtainsExtLabels, shadesolExtLabels} from '../../config/factoryConstants';

library.add(faPrint);

interface Props {
  hideCancelButton?: boolean;
  orders: Order[];
  onHide: () => void;
}

interface Printable {
  order: Order;
  items: ShipmentItem[];
}

interface State {
  loading: boolean;
  saving: boolean;
  savingAndPrinting: boolean;
  savingAndPrintingSelected: boolean;
  labelsMap: Map<string, ShipmentItem[]>;
  deletedLabels: ShipmentItem[];
  selectedLabels: ShipmentItem[];
  printerIp: string;
  newLabel?: ShipmentItem;
  newLabelExtOptions: string[];
}

class ShipmentComponent extends React.Component<Props, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;

  shipmentsService?: ShipmentsService;
  factoriesService?: FactoriesService;
  twoToast?: TwoToast;
  newLabelId = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      saving: false,
      savingAndPrinting: false,
      savingAndPrintingSelected: false,
      labelsMap: new Map<string, ShipmentItem[]>(),
      deletedLabels: [],
      selectedLabels: [],
      printerIp: '',
      newLabelExtOptions: [],
    };

    this.toast = React.createRef();

    // this.setDataAndPrint = this.setDataAndPrint.bind(this);
    this.onHide = this.onHide.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.onNewLabelDialogShow = this.onNewLabelDialogShow.bind(this);
    this.onSelectedItemsChange = this.onSelectedItemsChange.bind(this);
    this.onSelectedLabelsDelete = this.onSelectedLabelsDelete.bind(this);
    this.onLabelUpdate = this.onLabelUpdate.bind(this);
    this.onSelectedLabelsCopy = this.onSelectedLabelsCopy.bind(this);
    this.onNewLabelDialogHide = this.onNewLabelDialogHide.bind(this);
    this.onNewLabelUpdate = this.onNewLabelUpdate.bind(this);
    this.onNewLabelSave = this.onNewLabelSave.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSaveAndPrintSelected = this.onSaveAndPrintSelected.bind(this);
    this.onSaveAndPrint = this.onSaveAndPrint.bind(this);
    this.loadData();
  }

  componentDidMount() {
    this.shipmentsService = this.context.shipmentsService;
    this.factoriesService = this.context.factoriesService;
    this.twoToast = this.context.twoToast;

    this.loadData();
  }

  async loadData() {
    const currentFactoryId = localStorage.getItem('current factory');
    if (!currentFactoryId) {
      console.error('No factory selected');
      return;
    }
    this.setState({loading: true});
    const printerIp = await this.loadShippingPrinterIp(currentFactoryId);
    if (!printerIp) {
      console.error('No printer ip found');
      return;
    }
    const labelsMap = await this.loadLabels(this.props.orders);
    if (labelsMap?.size === 0) {
      console.error('No labels found');
      this.setState({loading: false});
      return;
    }
    this.setState({labelsMap: labelsMap as Map<string, ShipmentItem[]>, loading: false, printerIp});
  }

  async loadLabels(orders: Order[]) {
    const orderIds = orders.map(order => {
      return order.id!;
    });

    return this.shipmentsService
      ?.getShippingLabelsByOrders(orderIds)
      .then(response => {
        const records = (response.records ?? []) as ShipmentItem[];
        const labelsMap = new Map<string, ShipmentItem[]>();
        for (const record of records) {
          const orderId = record.order_id;
          if (!labelsMap.has(orderId)) {
            labelsMap.set(orderId, []);
          }
          labelsMap.get(orderId)!.push(record);
        }
        for (const labels of Array.from(labelsMap.values())) {
          labels.sort((a, b) => a.index - b.index);
        }
        return labelsMap;
      })
      .catch(() => {
        this.twoToast?.showError('Sorry there was an error loading labels. Please try again.');
        return undefined;
      });
  }

  groupBy = (items: any[], key: string) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    );

  async onNewLabelDialogShow(orderId: string) {
    const newLabel: ShipmentItem = {
      order_id: orderId,
      content: '',
      stage: '',
      location: '',
      index: 0,
      count: 0,
      dimensions: {
        width: 0,
        height: 0,
        depth: 0,
        weight: 0,
      },
    };
    let newFormat = true;
    const orderLabels = this.state.labelsMap.get(orderId);
    if (orderLabels && orderLabels.length > 0) {
      newFormat = !!orderLabels[0].content_detail && orderLabels[0].content_detail !== undefined;
    }
    if (newFormat) {
      newLabel.content_detail = {
        location: '',
        more_detail: '',
        order_item: '',
      };
    }

    const options: string[] = [];
    const order = this.props.orders.find(o => o.id === orderId);
    if (order && order.factory_order) {
      if (order.factory_order.product_line === 'Colourvue') {
        options.push(...colourvueExtLabels);
      } else if (order?.factory_order?.product_line === 'Shadesol') {
        options.push(...shadesolExtLabels);
      } else if (order?.factory_order?.product_line === 'Curtains') {
        options.push(...curtainsExtLabels);
      }
    }

    this.setState({newLabel: newLabel, newLabelExtOptions: options, selectedLabels: []});
  }

  onSelectedLabelsCopy() {
    const {selectedLabels, labelsMap} = this.state;
    if (selectedLabels.length === 0) {
      return;
    }
    for (const selectedLabel of selectedLabels) {
      const labels = labelsMap.get(selectedLabel.order_id) ?? [];
      const newLabel: ShipmentItem = {
        ...selectedLabel,
        dimensions: {...selectedLabel.dimensions},
        id: --this.newLabelId,
      };
      labels.push(newLabel);
      for (const [index, label] of Array.from(labels.entries())) {
        label.index = index + 1;
        label.count = labels.length;
      }
      labelsMap.set(selectedLabel.order_id, labels);
    }
    this.setState({labelsMap, selectedLabels: []});
  }

  onSelectedLabelsDelete() {
    const {selectedLabels, labelsMap, deletedLabels} = this.state;
    if (selectedLabels.length > 0) {
      confirmDialog({
        message: `Are you sure you want to delete ${selectedLabels.length} label(s)?`,
        icon: 'pi pi-exclamation-triangle',
        header: 'Delete Labels',
        accept: () => {
          const newLabelsMap = new Map<string, ShipmentItem[]>();
          Array.from(labelsMap.entries()).map(([key, labels]) => {
            const newLabels = labels.filter(label => !selectedLabels.includes(label));
            for (const [index, label] of Array.from(newLabels.entries())) {
              label.index = index + 1;
              label.count = newLabels.length;
            }
            newLabelsMap.set(key, newLabels);
          });

          const newDeletedLabels = [...deletedLabels];
          for (const selectedLabel of selectedLabels) {
            if (selectedLabel.id! > 0) {
              // only add to deleted labels if it's not a new label
              newDeletedLabels.push(selectedLabel);
            }
          }
          this.setState({labelsMap: newLabelsMap, selectedLabels: [], deletedLabels: newDeletedLabels});
        },
      });
    }
  }

  onNewLabelUpdate(labelPatch: Partial<ShipmentItem>) {
    const {newLabel} = this.state;
    this.setState({newLabel: {...newLabel, ...labelPatch} as ShipmentItem});
  }

  onNewLabelSave() {
    const {newLabel, labelsMap} = this.state;
    if (newLabel) {
      const labels = labelsMap.get(newLabel.order_id) ?? [];
      labels.push(newLabel);
      for (const [index, label] of Array.from(labels.entries())) {
        label.index = index + 1;
        label.count = labels.length;
      }
      labelsMap.set(newLabel.order_id, labels);
      this.setState({labelsMap, newLabel: undefined});
    }
  }
  async onSaveAndPrint() {
    const {orders} = this.props;
    const {labelsMap, deletedLabels} = this.state;
    if (!this.validate(labelsMap)) {
      return;
    }
    this.setState({savingAndPrinting: true});
    if (!(await this.saveChanges(labelsMap, deletedLabels))) {
      return;
    }
    const updatedLabelsMap = await this.loadLabels(orders);
    if (!updatedLabelsMap) {
      return;
    }
    this.setState({labelsMap: updatedLabelsMap});
    const printableData: Printable[] = [];
    for (const [orderId, labels] of Array.from(updatedLabelsMap.entries())) {
      const order = orders.find(o => o.id === orderId);
      if (!order) {
        return;
      }
      printableData.push({
        order: order,
        items: labels,
      });
    }
    await this.print(printableData);
    this.setState({savingAndPrinting: false});
    this.onHide();
  }
  async onSaveAndPrintSelected() {
    const {orders} = this.props;
    const {labelsMap, deletedLabels, selectedLabels} = this.state;
    if (!this.validate(labelsMap)) {
      return;
    }
    this.setState({savingAndPrintingSelected: true});
    if (!(await this.saveChanges(labelsMap, deletedLabels))) {
      return;
    }
    const updatedLabelsMap = await this.loadLabels(orders);
    if (!updatedLabelsMap) {
      return;
    }
    this.setState({labelsMap: updatedLabelsMap});
    const printableData: Printable[] = [];
    for (const [orderId, labels] of Array.from(updatedLabelsMap.entries())) {
      const order = orders.find(o => o.id === orderId);
      if (!order) {
        return;
      }
      const orderSelectedLabels = selectedLabels.filter(selectedLabel => selectedLabel.order_id === orderId);
      const labelsToPrint = labels.filter(label =>
        orderSelectedLabels.find(selectedLabel => {
          if (selectedLabel.id! > 0) {
            // existing label
            return selectedLabel.id === label.id;
          }
          // new label
          return selectedLabel.index === label.index;
        })
      );
      if (labelsToPrint.length === 0) {
        continue;
      }
      printableData.push({
        order: order,
        items: labelsToPrint,
      });
    }
    await this.print(printableData);
    this.setState({savingAndPrintingSelected: false});
    this.onHide();
  }

  async sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), ms));
  }

  async print(printableData: Printable[]) {
    //const zpl = "^XA^ILR:shippingLabel_v1.GRF^FS^FN1^FDAcme Printing^FS^XZ";

    const printerIp = this.state.printerIp;
    if (printerIp === '') {
      throw new Error('no factory printer ip');
    }

    const url = 'http://' + printerIp + '/pstprnt';

    for (const labelSet of printableData) {
      const formattedAddress1 = (labelSet.order.shipping_address as Address).name
        ? (labelSet.order.shipping_address as Address).name
        : labelSet.order.owner_company?.trading_as ?? labelSet.order.owner_company?.name;

      const formattedAddress2 = (labelSet.order.shipping_address as Address)?.street ?? '';

      for (const label of labelSet.items) {
        const data = {
          customer: labelSet.order.owner_company?.account_number?.substring(0, 20),
          order_code: labelSet.order.id?.substring(0, 19),
          reference: labelSet.order.reference.substring(0, 24),
          content: label.content,
          address_1: formattedAddress1?.substring(0, 30),
          address_2: formattedAddress2.substring(0, 30),
          state: (labelSet.order.shipping_address as Address)?.state,
          index: label.index,
          count: label.count,
          width: label.dimensions?.width,
          height: label.dimensions?.height,
          depth: label.dimensions?.depth,
          weight: label.dimensions?.weight,
          location: label.content_detail?.location?.substring(0, 37),
          more_detail: label.content_detail?.more_detail?.substring(0, 37),
          ord_item: label.content_detail?.order_item?.substring(0, 37),
          suburb: (labelSet.order.shipping_address as Address)?.suburb?.substring(0, 20),
          postcode: (labelSet.order.shipping_address as Address)?.postCode?.substring(0, 4),
          reference_2: labelSet.order.reference.substring(24, 37),
        };

        const shippingLabelTemplate = label.content_detail ? shippingLabelV2 : shippingLabel;

        const pritableLabel = shippingLabelTemplate.replace(/{(\w*)}/g, (m, key: keyof typeof data) => {
          return data[key]?.toString() ?? '';
        });
        console.log(pritableLabel);

        await this.sleep(200);
        window.postMessage(
          {
            type: 'zebra_print_label',
            zpl: pritableLabel,
            url: url,
          },
          '*'
        );
        this.twoToast?.showInfo('Label(s) sent to print.');
      }
    }
  }

  async onSave() {
    const {labelsMap, deletedLabels} = this.state;
    if (!this.validate(labelsMap)) {
      return;
    }
    this.setState({saving: true});
    await this.saveChanges(labelsMap, deletedLabels);
    this.setState({saving: false});
    this.onHide();
  }

  validate(labelsMap: Map<string, ShipmentItem[]>) {
    for (const labels of Array.from(labelsMap.values())) {
      for (const label of labels) {
        const dimensions = label.dimensions;
        if (
          !label.content ||
          !label.ext_label ||
          (!dimensions.height && dimensions.height !== 0) ||
          (!dimensions.width && dimensions.width !== 0) ||
          (!dimensions.depth && dimensions.depth !== 0) ||
          (!dimensions.weight && dimensions.weight !== 0)
        ) {
          this.twoToast?.showError('Fields can not be empty');
          return false;
        }
      }
    }
    return true;
  }

  async saveChanges(labelsMap: Map<string, ShipmentItem[]>, deletedLabels: ShipmentItem[]): Promise<boolean> {
    const promises = [];
    for (const deletedLabel of deletedLabels) {
      promises.push(this.shipmentsService?.deleteItem(deletedLabel.order_id, deletedLabel.id!));
    }
    for (const [orderId, labels] of Array.from(labelsMap.entries())) {
      promises.push(this.shipmentsService?.saveItems(orderId, labels));
    }

    return Promise.all(promises)
      .then(() => {
        this.twoToast?.showSuccess('Changes saved successfully');
        return true;
      })
      .catch(() => {
        this.twoToast?.showError('Error saving labels');
        return false;
      });
  }

  async loadShippingPrinterIp(currentFactoryId: string) {
    return this.factoriesService
      ?.getFactory(currentFactoryId)
      .then(data => {
        return data.settings?.printers?.shipping_label_printer_ip ?? '';
      })
      .catch(error => this.twoToast?.showError('Shipping Label Printer IP not found'));
  }
  async loadProductionPrinterIp(currentFactoryId: string) {
    return this.factoriesService
      ?.getFactory(currentFactoryId)
      .then(data => {
        return data.settings?.printers?.production_label_printer_ip ?? '';
      })
      .catch(error => this.twoToast?.showError('Production Label Printer IP not found'));
  }

  onHide() {
    this.setState({
      selectedLabels: [],
      newLabel: undefined,
      saving: false,
      savingAndPrinting: false,
      savingAndPrintingSelected: false,
      labelsMap: new Map<string, ShipmentItem[]>(),
      deletedLabels: [],
      loading: false,
      printerIp: '',
    });
    this.props.onHide();
  }

  onSelectedItemsChange(selectedLabels: ShipmentItem[]) {
    this.setState(() => ({selectedLabels}));
  }

  onNewLabelDialogHide() {
    this.setState({newLabel: undefined});
  }

  onLabelUpdate(orderId: string, labelId: number, labelPatch: Partial<ShipmentItem>) {
    const {labelsMap} = this.state;
    const labels = labelsMap.get(orderId) ?? [];
    const newLabels = labels.map(label => {
      if (label.id === labelId) {
        return {...label, ...labelPatch};
      }
      return label;
    });
    labelsMap.set(orderId, newLabels);
    this.setState({labelsMap});
  }

  renderFooter(
    labels: ShipmentItem[],
    selectedLabels: ShipmentItem[],
    saving: boolean,
    savingAndPrinting: boolean,
    savingAndPrintingSelected: boolean
  ) {
    return (
      <div className={'p-d-flex p-mt-4 p-justify-end'}>
        {!this.props.hideCancelButton && (
          <Button
            label="Cancel"
            className={'p-mr-2 p-button-text'}
            onClick={this.onHide}
            disabled={saving || savingAndPrinting}
          />
        )}
        {labels?.length > 0 && (
          <>
            <Button
              label="Save"
              className={'p-mr-2'}
              onClick={() => this.onSave()}
              loading={saving}
              disabled={savingAndPrinting}
            />
            {selectedLabels.length > 0 && (
              <Button
                label={'Save & Print Selected'}
                className={'p-mr-2'}
                onClick={() => {
                  this.onSaveAndPrintSelected();
                }}
                disabled={saving}
                loading={savingAndPrintingSelected}
              />
            )}
            <Button
              label={labels?.length > 1 ? 'Save & Print All' : 'Save & Print'}
              onClick={() => {
                this.onSaveAndPrint();
              }}
              disabled={saving}
              loading={savingAndPrinting}
            />
          </>
        )}
      </div>
    );
  }

  render() {
    const {orders} = this.props;
    const {loading, saving, savingAndPrinting, savingAndPrintingSelected, labelsMap, selectedLabels, newLabel} =
      this.state;
    if (loading) {
      return (
        <div className="p-d-flex p-ai-center w-100" style={{minHeight: '20vh'}}>
          <ProgressSpinner />
        </div>
      );
    }
    return (
      <>
        <div className="page-container">
          <div className="shipment-label-detai w-100">
            {orders.map((order: Order, orderIndex: number) => {
              const orderLabels = labelsMap.get(order.id!) ?? [];
              return (
                <ShipmentItemsTable
                  key={orderIndex}
                  labels={orderLabels}
                  order={order}
                  selectedLabels={selectedLabels}
                  onLabelAdd={() => this.onNewLabelDialogShow(order.id!)}
                  onSelectedLabelsCopy={this.onSelectedLabelsCopy}
                  onSelectedLabelsDelete={this.onSelectedLabelsDelete}
                  onSelectedItemsChange={this.onSelectedItemsChange}
                  onLabelUpdate={this.onLabelUpdate}
                  readonly={loading || saving || savingAndPrinting || savingAndPrintingSelected}
                />
              );
            })}
          </div>
          {this.renderFooter(
            Array.from(labelsMap.values()).flat(),
            selectedLabels,
            saving,
            savingAndPrinting,
            savingAndPrintingSelected
          )}
        </div>

        {!!newLabel && (
          <NewLabelDialog
            label={newLabel}
            onNewLabelUpdate={this.onNewLabelUpdate}
            onSave={this.onNewLabelSave}
            onHide={this.onNewLabelDialogHide}
            externalLabelOptions={this.state.newLabelExtOptions}
          />
        )}

        <Toast ref={this.toast} />
      </>
    );
  }
}

export default ShipmentComponent;
