import {ApiListResponse, ApiResponse, CreateStockTakeRequest, QueryParameter, StockTake} from 'two-core';
import config from '../config/config';
import {ApiService, AuthService} from 'two-app-ui';

export default class StockTakesService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.stockTakes!;
  }

  async getStockTakes(params: QueryParameter) {
    const currentFactoryId = localStorage.getItem('current factory') ?? '';
    if (!currentFactoryId) {
      return Promise.reject('No factory selected');
    }
    const filters = params.filters ?? [];
    filters.push(JSON.stringify({field: 'factory_id', value: currentFactoryId}));
    return this.get(this.endpoint, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async createStockTake(data: CreateStockTakeRequest) {
    return this.post(this.endpoint, data)
      .then(data => {
        return Promise.resolve(data as StockTake);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async cloneStockTake(data: CreateStockTakeRequest, originalId: number) {
    return this.post(`${this.endpoint}/${originalId}/clone`, data)
      .then(data => {
        return Promise.resolve(data as StockTake);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async updateStockTake(stockTakeId: number, data: Partial<StockTake>) {
    return this.patch(`${this.endpoint}/${stockTakeId}`, data)
      .then(data => {
        return Promise.resolve(data as StockTake);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}
