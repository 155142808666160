import React from 'react';
import {AppContext, TwoDialog, TwoToast} from 'two-app-ui';
import formats from '../../config/formats';
import {Calendar} from 'primereact/calendar';
import {
  Bom,
  InventoryItem,
  PurchaseOrder,
  PurchaseOrderAggregate,
  PurchaseOrderItem,
  PurchaseOrderItemAggregate,
  QueryParameter,
  TimeLineEvent,
  TleContentCreate,
} from 'two-core';
import {InvalidInventoryItem} from './PurchaseOrderItems';
import {getCurrentUserId} from '../../utils/UserUtil';
import {PurchaseOrderEditConfirmationDialog} from './PurchaseOrderEditConfirmationDialog';
import {Button} from 'primereact/button';
import InventoryService from '../../services/InventoryService';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import BomService from '../../services/BomService';

interface Props {
  purchaseOrderId: string;
  showDialog: boolean;
  onHide: () => void;
}

interface State {
  loading: boolean;
  saving: boolean;
  purchaseOrder?: PurchaseOrder;
  poItems?: PurchaseOrderItem[];
  invalidItems: InvalidInventoryItem[];
  uncoveredBoms: Bom[];
  showConfirmationDialog?: boolean;
  purchaseOrderPatch: Partial<PurchaseOrder>;
}

export class PurchaseOrderOrderedDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  inventoryService?: InventoryService;
  purchaseOrdersService?: PurchaseOrdersService;
  bomService?: BomService;
  twoToast?: TwoToast;
  constructor(props: Props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onSave = this.onSave.bind(this);
    this.savePurchaseOrder = this.savePurchaseOrder.bind(this);
    this.createPurchaseOrder = this.createPurchaseOrder.bind(this);
    this.updateBoms = this.updateBoms.bind(this);

    this.state = {loading: false, saving: false, uncoveredBoms: [], invalidItems: [], purchaseOrderPatch: {}};
  }

  componentDidMount() {
    this.inventoryService = this.context.inventoryService;
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.bomService = this.context.bomService;
    this.twoToast = this.context.twoToast;
  }

  async loadData() {
    const {purchaseOrderId} = this.props;
    this.setState({loading: true});
    const filters: string[] = [JSON.stringify({field: 'id', value: purchaseOrderId})];
    const aggregate: PurchaseOrderAggregate[] = ['related_boms'];
    const poParams: QueryParameter = {
      filters: filters,
      aggregate: aggregate,
    };
    const purchaseOrder = await this.loadPurchaseOrder(poParams);
    const poItems = await this.loadPurchaseOrderItems(purchaseOrderId);
    if (!poItems.length) {
      this.twoToast?.showError('No items found in purchase order');
      this.onHide();
      return;
    }
    this.setState({loading: false, purchaseOrder, poItems});
  }

  async loadPurchaseOrder(params: QueryParameter) {
    try {
      const result = await this.purchaseOrdersService!.getPurchaseOrders(params);
      return ((result?.records ?? []) as PurchaseOrder[])[0];
    } catch (error) {
      console.error('Failed to load purchase order', error);
      this.twoToast?.showError('Failed to load purchase order');
      return undefined;
    }
  }

  async loadPurchaseOrderItems(id: string) {
    try {
      const aggregate: PurchaseOrderItemAggregate[] = ['inventory_item'];
      const params: QueryParameter = {aggregate: aggregate};
      const result = await this.purchaseOrdersService!.getPurchaseOrderItems(id, params);
      return (result?.records ?? []) as PurchaseOrderItem[];
    } catch (error) {
      console.error('Failed to load purchase order items', error);
      this.twoToast?.showError('Failed to load purchase order items');
      return [];
    }
  }

  async loadInventoryItem(id: string) {
    try {
      return await this.inventoryService!.getInventoryItem(id);
    } catch (error) {
      console.error('Failed to load inventory item', error);
      this.twoToast?.showError('Failed to load inventory item');
      return undefined;
    }
  }

  onHide() {
    this.setState({
      loading: false,
      saving: false,
      purchaseOrder: undefined,
      poItems: undefined,
      invalidItems: [],
      uncoveredBoms: [],
      showConfirmationDialog: false,
    });
    this.props.onHide();
  }

  onShow() {
    this.loadData();
  }

  async onSaveClick() {
    const {purchaseOrder, poItems} = this.state;
    if (!purchaseOrder || !poItems?.length) {
      return;
    }
    this.setState({saving: true});
    const {invalidItems, uncoveredBoms} = await this.getUncoveredBoms(purchaseOrder.related_boms ?? [], poItems);
    if (invalidItems.length) {
      this.setState({invalidItems, uncoveredBoms, showConfirmationDialog: true});
      return;
    }
    this.onSave(false);
  }

  async onSave(reassignBoms: boolean) {
    const {purchaseOrder, purchaseOrderPatch, uncoveredBoms} = this.state;
    //update purchase order
    const updatedPo = await this.savePurchaseOrder(purchaseOrder!.id!, {...purchaseOrderPatch, stage: 'Ordered'});
    if (!updatedPo) {
      return;
    }
    this.twoToast?.showSuccess('Purchase order updated successfully');
    if (!reassignBoms) {
      this.onHide();
      return;
    }
    //get or create other po in stage "Draft" with same supplier
    const filters = [
      JSON.stringify({field: 'supplier_id', value: updatedPo.supplier_id}),
      JSON.stringify({field: 'stage', value: 'Draft'}),
    ];
    const params: QueryParameter = {filters, page_size: 1};
    let otherDraftPo = await this.loadPurchaseOrder(params);
    if (!otherDraftPo) {
      const newPo: Partial<PurchaseOrder> = {
        supplier_id: purchaseOrder!.supplier_id,
        factory_id: purchaseOrder!.factory_id,
        stage: 'Draft',
        tles_success: [
          {
            event_type: 'create',
            entity_type: 'purchase_order',
            recorded_at: new Date(),
            recorded_by: getCurrentUserId(),
            entity_id: '<REPLACE_WITH_ENTITY_ID>',
            content: {
              message: 'Purchase Order has been created manually.',
            } as TleContentCreate,
          } as TimeLineEvent,
        ],
      };
      otherDraftPo = await this.createPurchaseOrder(newPo);
      if (!otherDraftPo) {
        this.twoToast?.showError('Failed to create new purchase order');
        return;
      }
    }

    const updatedBoms = await this.updateBoms(uncoveredBoms, otherDraftPo.id!);
    if (updatedBoms?.length) {
      this.twoToast?.showSuccess(`Boms re-assigned to po ${otherDraftPo.name}`);
    }

    this.onHide();
  }

  async savePurchaseOrder(poId: string, purchaseOrderPatch: Partial<PurchaseOrder>) {
    try {
      return await this.purchaseOrdersService!.updatePurchaseOrder(poId, purchaseOrderPatch);
    } catch (e) {
      console.error('Failed to update purchase order', e);
      this.twoToast?.showError('Failed to update purchase order');
      return undefined;
    }
  }

  async createPurchaseOrder(newPo: Partial<PurchaseOrder>) {
    try {
      return await this.purchaseOrdersService!.createPurchaseOrder(newPo);
    } catch (e) {
      console.error('Failed to update purchase order', e);
      this.context.twoToast?.showError('Failed to update purchase order');
      return undefined;
    }
  }

  async updateBoms(uncoveredBoms: Bom[], poId: string) {
    try {
      const bomResults: Promise<Bom | undefined>[] = [];
      for (const uncoveredBom of uncoveredBoms) {
        const bomPatch = {ordered_on_po_id: poId};
        bomResults.push(this.bomService!.updateBom(uncoveredBom.id!, bomPatch));
      }
      return await Promise.all(bomResults);
    } catch (e) {
      console.error('Failed to update boms', e);
      this.twoToast?.showError('Failed to update boms');
      return undefined;
    }
  }

  async getUncoveredBoms(relatedBoms: Bom[], poItems: PurchaseOrderItem[]) {
    const invalidItems: InvalidInventoryItem[] = [];
    const invItemRemainedQtyInUom = new Map<string, number>();
    const invItemsMap = new Map<string, InventoryItem>();
    const uncoveredBoms: Bom[] = [];

    for (const poItem of poItems ?? []) {
      const key = poItem.inventory_item_id;

      const qtyInUom = poItem.qty_in_uom ?? 0;
      const current = invItemRemainedQtyInUom.get(key) ?? 0;
      invItemRemainedQtyInUom.set(key, current + qtyInUom);

      if (!invItemsMap.has(key)) {
        invItemsMap.set(key, poItem.inventory_item!);
      }
    }

    // search for uncovered boms
    for (const relatedBom of relatedBoms) {
      const key = relatedBom.inventory_item_id;
      const requiredQty = relatedBom.quantity ?? 0;
      const remainingOrderedQty = invItemRemainedQtyInUom.get(key) ?? 0;
      if (requiredQty > remainingOrderedQty) {
        let invItem = invItemsMap.get(key);
        if (!invItem) {
          invItem = await this.loadInventoryItem(key);
          invItemsMap.set(key, invItem!);
        }
        invalidItems.push({
          inventoryItem: invItem ?? ({id: key, name: 'Unknown'} as InventoryItem),
          reason: `BoM in order ${relatedBom.order_id} not covered (req: ${Math.round(requiredQty * 100) / 100} > remaining ordered qty: ${Math.round(remainingOrderedQty * 100) / 100})`,
        });
        uncoveredBoms.push(relatedBom);
      } else {
        invItemRemainedQtyInUom.set(key, remainingOrderedQty - requiredQty);
      }
    }

    return {invalidItems, uncoveredBoms};
  }

  render() {
    const {showDialog} = this.props;
    const {loading, purchaseOrder, purchaseOrderPatch, showConfirmationDialog, invalidItems, saving} = this.state;

    const sentAtValue =
      purchaseOrderPatch?.sent_at ?? (purchaseOrder?.sent_at ? new Date(purchaseOrder.sent_at) : undefined);
    const etaValue = purchaseOrderPatch?.eta ?? (purchaseOrder?.eta ? new Date(purchaseOrder.eta) : undefined);

    const footer = (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={() => this.onHide()} disabled={saving} />
        <Button label="Save" className={'p-mr-2'} onClick={() => this.onSaveClick()} loading={saving} />
      </div>
    );

    return (
      <TwoDialog
        header={`Order the Purchase Order ${purchaseOrder?.name ?? ''}`}
        loading={loading}
        onHide={this.onHide}
        onShow={this.onShow}
        showDialog={showDialog}
        style={{width: '50vw'}}
        breakpoints={{'768px': '75vw', '576px': '90vw'}}
        draggable={false}
        footer={footer}
      >
        <div className="p-grid p-fluid w-100 p-ai-center">
          <label className="p-col-2">sent at</label>
          <Calendar
            name="sent_at"
            className="p-col-4"
            value={sentAtValue}
            dateFormat={formats.calendarInputDate}
            onChange={e =>
              this.setState(state => ({purchaseOrderPatch: {...state.purchaseOrderPatch, sent_at: e.value as Date}}))
            }
            disabled={saving}
          />
          <label className="p-col-2">eta</label>
          <Calendar
            name="eta"
            className={'p-col-4'}
            value={etaValue}
            dateFormat={formats.calendarInputDate}
            onChange={e =>
              this.setState(state => ({purchaseOrderPatch: {...state.purchaseOrderPatch, eta: e.value as Date}}))
            }
            disabled={saving}
          />
        </div>
        <PurchaseOrderEditConfirmationDialog
          showDialog={showConfirmationDialog ?? false}
          onContinueAnyway={() => this.onSave(false)}
          onReAssignBoms={() => this.onSave(true)}
          onHide={() => this.onHide()}
          invalidItems={invalidItems ?? []}
        />
      </TwoDialog>
    );
  }
}
