import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';
import {
  FactoryFloorTask,
  FloorTaskContentQC,
  FloorTaskContentQCBlindDepre,
  FloorTaskContentQCDepre,
  MapOf,
} from 'two-core';

interface Props {
  data: any;
}

//interface State {}

class CVQualityCheck_v2 extends CutSheetTemplate {
  report: Report;
  contentPrio: MapOf<number>;
  executableTasks = 0;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
    this.contentPrio = {
      repair: 1,
      note: 2,
      general: 3,
      blind: 4,
    };
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      2,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">cv std v1</td>
              <td className="text">
                <b>QC</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text" colSpan={3}>
                {this.props.data.reference}
              </td>
              <td className="label">shipping to</td>
              <td className="text">{this.props.data.ship_to}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      /* ITEMS */
    }
    {
      this.props.data.qc.items.sort((firstTask: any, secondTask: any) => {
        const weight1 =
          firstTask.order_item_index * 100 +
          this.contentPrio[firstTask.category] * 10 +
          (firstTask.content?.blindIndex ?? 0);
        const weight2 =
          secondTask.order_item_index * 100 +
          this.contentPrio[secondTask.category] * 10 +
          (secondTask.content.blindIndex ?? 0);
        return weight1 - weight2;
      });

      this.executableTasks = 0;

      const blindsOnItem: Map<number, number> = new Map<number, number>();
      this.props.data.qc.items.map((task: FactoryFloorTask) => {
        if (task.category === 'blind') {
          this.executableTasks++;
          const currentBlindCount = blindsOnItem.get(task.order_item_index) ?? 0;
          blindsOnItem.set(task.order_item_index, currentBlindCount + 1);
        }
      });

      let currentItemIndex = this.props.data.qc.items[0].order_item_index;
      let itemRows: any[] = [];
      let repair1: any, repair2: any, repair3: any;
      repair1 = repair2 = repair3 = '';
      let isFirstRow = true;
      let currentLineCount = 1;

      this.props.data.qc.items.map((item: any) => {
        let indexColumn = <td className="no-top-border" />;
        let row;
        let rowClass = 'inside-row';

        if (item.order_item_index !== currentItemIndex) {
          this.report.addItem(
            item,
            currentLineCount,
            <React.Fragment>
              <table className="mt-10 odd-zebra three-cols content">
                {repair1}
                {repair2}
                {repair3}
                {itemRows}
              </table>
            </React.Fragment>
          );
          currentItemIndex = item.order_item_index;
          currentLineCount = 0;
          itemRows = [];
          isFirstRow = true;
        }

        if (isFirstRow) {
          isFirstRow = false;
          indexColumn = <td className="index">{item.order_item_index}</td>;
          rowClass = 'new-item';
        }

        if (item.category === 'repair') {
          repair1 = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">rework</td>
              <td className="text">{item.content.repair_whole_item ? 'YES' : 'NO'}</td>
              <td className="label">type</td>
              <td className="text">{this.capitaliseFirst(item.content.repair_type ?? 'Whole Item Rework')}</td>
              <td className="label">responsibility</td>
              <td className="text" colSpan={2}>
                {this.capitaliseFirst(item.content.repair_responsibility)}
              </td>
            </tr>
          );
          if (item.content.repair_type === 'cutdown (standard)') {
            repair2 = (
              <tr>
                <td />
                <td className="label">new height</td>
                <td className="number">{item.content.new_height}</td>
                <td className="label">new width</td>
                <td className="number" colSpan={3}>
                  {item.content.new_width}
                </td>
                <td className="sign-off">check</td>
              </tr>
            );
            currentLineCount += 1;
          }
          repair3 = (
            <tr>
              <td />
              <td className="label">repair detail</td>
              <td className="text" colSpan={5}>
                {item.content.repair_details}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += 2;
        }

        if (item.category === 'general') {
          const data: FloorTaskContentQCDepre = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">product</td>
              <td className="text">{this.capitaliseFirst(data.product)}</td>
              <td className="label">config</td>
              <td className="text">{data.blindConfiguration.toUpperCase()}</td>
              <td className="label">dual</td>
              <td className="text">{data.isDual ? 'Yes' : 'No'}</td>
              <td className="sign-off">check</td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount++;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">mount</td>
              <td className="text">{this.capitaliseFirst(data.mount)}</td>
              <td className="label">channels</td>
              <td className="text">{data.channelColour ? this.capitaliseFirst(data.channelColour) : 'N/A'}</td>
              <td className="label">ch length</td>
              <td className="text" colSpan={2}>
                {data.channelLength ?? 'N/A'}
              </td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount++;
          // if (data.unevenWidths) {
          //   row = (
          //     <tr className={rowClass}>
          //       {indexColumn}
          //       <td className="label">uneven</td>
          //       <td className="text">Uneven Blind Widths</td>
          //       <td className="label">sub widths</td>
          //       <td className="number" colSpan={4}>
          //         {data.subWidths.join(', ')}
          //       </td>
          //     </tr>
          //   );
          //   itemRows.push(row);
          //   currentLineCount++;
          // }
          // if (data.decoCover) {
          //   row = (
          //     <tr className={rowClass}>
          //       {indexColumn}
          //       <td className="label">cover</td>
          //       <td className="text">{this.capitaliseFirst(data.decoCover)}</td>
          //       <td className="label">colour</td>
          //       <td className="text">
          //         {this.capitaliseFirst(
          //           data.decoCoverFabric
          //             ? `${data.decoCoverFabric} ${data.decoCoverFabricColour}`
          //             : data.decoCoverColour
          //         )}
          //       </td>
          //       <td className="label">length</td>
          //       <td className="number" colSpan={2}>
          //         {data.decoCoverLength}
          //       </td>
          //     </tr>
          //   );
          //   itemRows.push(row);
          //   currentLineCount++;
          // }
          //
          // if (!data.isDual) {
          //   row = (
          //     <tr className={rowClass}>
          //       {indexColumn}
          //       <td className="label">fabric</td>
          //       <td className="text">{this.capitaliseFirst(data.fabric)}</td>
          //       <td className="label">colour</td>
          //       <td className="number">
          //         {this.capitaliseFirst(data.fabricColour)}
          //       </td>
          //       <td className="label">direction</td>
          //       <td className="text" colSpan={2}>
          //         {this.capitaliseFirst(data.direction)}
          //       </td>
          //     </tr>
          //   );
          //   itemRows.push(row);
          //   currentLineCount++;
          // } else {
          //   row = (
          //     <tr className={rowClass}>
          //       {indexColumn}
          //       <td className="label">top fab</td>
          //       <td className="text">{this.capitaliseFirst(data.topFabric)}</td>
          //       <td className="label">colour</td>
          //       <td className="number">
          //         {this.capitaliseFirst(data.topFabricColour)}
          //       </td>
          //       <td className="label">direction</td>
          //       <td className="text" colSpan={2}>
          //         {this.capitaliseFirst(data.topDirection)}
          //       </td>
          //     </tr>
          //   );
          //   itemRows.push(row);
          //   currentLineCount++;
          //   row = (
          //     <tr className={rowClass}>
          //       {indexColumn}
          //       <td className="label">bottom fab</td>
          //       <td className="text">
          //         {this.capitaliseFirst(data.bottomFabric)}
          //       </td>
          //       <td className="label">colour</td>
          //       <td className="number">
          //         {this.capitaliseFirst(data.bottomFabricColour)}
          //       </td>
          //       <td className="label">direction</td>
          //       <td className="text" colSpan={2}>
          //         {this.capitaliseFirst(data.bottomDirection)}
          //       </td>
          //     </tr>
          //   );
          //   itemRows.push(row);
          //   currentLineCount++;
          // }
          // const detailColumns: any[] = [];
          // if (data.controlType === 'chain') {
          //   detailColumns.push(<td className="label">colour</td>);
          //   detailColumns.push(
          //     <td className="text">{this.capitaliseFirst(data.chainType)}</td>
          //   );
          //   detailColumns.push(<td className="label">length</td>);
          //   detailColumns.push(<td className="number">{data.chainLength}</td>);
          // } else {
          //   detailColumns.push(<td className="label">motor</td>);
          //   detailColumns.push(
          //     <td className="text">{this.capitaliseFirst(data.motor)}</td>
          //   );
          //   detailColumns.push(<td className="label">colour</td>);
          //   detailColumns.push(
          //     <td className="number">{data.componentColour}</td>
          //   );
          // }
          // row = (
          //   <tr className={rowClass}>
          //     {indexColumn}
          //     <td className="label">control</td>
          //     <td className="text">{this.capitaliseFirst(data.controlType)}</td>
          //     <td className="label">
          //       {data.controlType === 'chain' ? 'chain' : 'motor'}
          //     </td>
          //     <td className="text" colSpan={4}>
          //       {this.capitaliseFirst(
          //         data.controlType === 'chain'
          //           ? `${data.chainType}, ${data.chainLength}`
          //           : data.motor
          //       )}
          //     </td>
          //   </tr>
          // );
          // itemRows.push(row);
          // currentLineCount++;
          // row = (
          //   <tr className={rowClass}>
          //     {indexColumn}
          //     <td className="label">base rail</td>
          //     <td className="text">{this.capitaliseFirst(data.bottomRail)}</td>
          //     <td className="label">colour</td>
          //     <td className="text">
          //       {this.capitaliseFirst(data.bottomRailColour)}
          //     </td>
          //     <td className="label">comp col</td>
          //     <td className="text" colSpan={2}>
          //       {this.capitaliseFirst(data.componentColour)}
          //     </td>
          //   </tr>
          // );
          // itemRows.push(row);
          // currentLineCount++;
          // if (data.unevenWidths || data.channelColour) {
          //   row = (
          //     <tr className={rowClass}>
          //       {indexColumn}
          //       <td className="label">sub-widths</td>
          //       <td className="text">
          //         {data.subWidths && data.subWidths.length > 0
          //           ? data.subWidths.join(', ')
          //           : 'N/A'}
          //       </td>
          //       <td className="label">channels</td>
          //       <td className="text">
          //         {data.channelColour
          //           ? this.capitaliseFirst(data.channelColour)
          //           : 'N/A'}
          //       </td>
          //       <td className="label">ch length</td>
          //       <td className="text" colSpan={2}>
          //         {data.channelLength ?? 'N/A'}
          //       </td>
          //     </tr>
          //   );
          //   itemRows.push(row);
          //   currentLineCount++;
          // }
          if (data.requiresBracketCoverCaps || data.isWireguide || data.headMountColour) {
            row = (
              <tr className={rowClass}>
                {indexColumn}
                <td className="label">head mount</td>
                <td className="text">{data.headMountColour ? this.capitaliseFirst(data.headMountColour) : 'N/A'}</td>
                <td className="label">wire guide</td>
                <td className="text">{data.isWireguide ? 'Yes' : 'No'}</td>
                <td className="label">cover caps</td>
                <td className="text" colSpan={2}>
                  {data.requiresBracketCoverCaps ? 'Yes' : 'No'}
                </td>
              </tr>
            );
            itemRows.push(row);
            currentLineCount++;
          }
        }

        if (item.category === 'note') {
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">note</td>
              <td className="text" colSpan={5}>
                {`${item.content}`.replace(/\r?\n|\r/g, ' ')}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount += Math.ceil(`${item.content}`.length / 90);
        }

        if (item.category === 'blind') {
          const data: FloorTaskContentQCBlindDepre = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="text" colSpan={6}>
                blind ({data.blindIndex}/{blindsOnItem.get(item.order_item_index) ?? 0})
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount++;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">b2b width</td>
              <td className="number">{data.width}</td>
              <td className="label">drop</td>
              <td className="number">{data.drop}</td>
              <td className="label">ctrl side</td>
              <td className="text" colSpan={2}>
                {data.controlSide}
              </td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount++;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">fabric</td>
              <td className="text">{this.capitaliseFirst(data.fabric)}</td>
              <td className="label">colour</td>
              <td className="text">{this.capitaliseFirst(data.fabricColour)}</td>
              <td className="label">direction</td>
              <td className="text" colSpan={2}>
                {this.capitaliseFirst(data.direction)}
              </td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount++;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">base rail</td>
              <td className="text">{this.capitaliseFirst(data.bottomRail)}</td>
              <td className="label">colour</td>
              <td className="text">{data.bottomRailColour ?? ''}</td>
              <td className="label">caps</td>
              <td className="text" colSpan={2}>
                {data.isWireGuide ? 'Wire Guide' : 'Standard'}
              </td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount++;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">control</td>
              <td className="text">
                {this.capitaliseFirst(
                  !data.isControl
                    ? 'dependent'
                    : data.chainType
                    ? `chain ${data.chainType} ${data.chainLength}`
                    : 'motor'
                )}
              </td>
              <td className="label">ins L</td>
              <td className="number">{this.capitaliseFirst(data.insertLeft)}</td>
              <td className="label">ins R</td>
              <td className="text" colSpan={2}>
                {this.capitaliseFirst(data.insertRight)}
              </td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount++;
        }
      });

      this.report.addItem(
        this.props.data,
        currentLineCount,
        <React.Fragment>
          <table className="mt-10 odd-zebra three-cols content">
            {repair1}
            {repair2}
            {repair3}
            {itemRows}
          </table>
        </React.Fragment>
      );
    }

    {
      /* CHECK SUM */
    }
    this.report.addItem(
      this.props.data,
      1,
      <React.Fragment>
        <table className="summary">
          <td className="label">total</td>
          <td className="text">{this.executableTasks}</td>
        </table>
      </React.Fragment>
    );

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="colorvue-standard-assembly-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default CVQualityCheck_v2;
