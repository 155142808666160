import React from 'react';
import {TwoDialog} from 'two-app-ui';
import {InvalidInventoryItem} from './PurchaseOrderItems';
import {Button} from 'primereact/button';

interface Props {
  invalidItems: InvalidInventoryItem[];
  showDialog: boolean;
  onHide: () => void;
  onContinueAnyway: () => void;
  onReAssignBoms?: () => void;
}

export const PurchaseOrderEditConfirmationDialog = ({
  showDialog,
  onHide,
  onContinueAnyway,
  onReAssignBoms,
  invalidItems,
}: Props) => {
  const footer = (
    <div className={'p-d-flex p-my-4 p-justify-end'}>
      <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={() => onHide()} />
      {onReAssignBoms && (
        <Button
          label={'Re-assign not covered BOMs to another PO'}
          className={'p-mr-2'}
          onClick={() => onReAssignBoms()}
        />
      )}
      <Button label={'Continue Anyway'} className={'p-mr-2'} onClick={() => onContinueAnyway()} />
    </div>
  );

  return (
    <>
      <TwoDialog
        header="Not All Requirements Met"
        onHide={onHide}
        showDialog={showDialog}
        style={{width: '50vw'}}
        breakpoints={{'768px': '80vw', '576px': '90vw'}}
        draggable={false}
        footer={footer}
      >
        <div className="p-d-flex p-flex-column p-mx-3 w-100">
          <div className="p-mb-3">The following items may not be completely covered by the purchase order:</div>
          <div className="p-mx-3">
            <ul>
              {invalidItems.map(invalidItem => (
                <li key={invalidItem.inventoryItem.id} className="p-mb-2">
                  {`${invalidItem.inventoryItem.name}   => ${invalidItem.reason}.`}
                </li>
              ))}
            </ul>
          </div>
          <div className="p-mt-3 p-mb-1 p-mx-3">Are you sure you want to continue?</div>
        </div>
      </TwoDialog>
    </>
  );
};
