import React from 'react';
import {Order, PurchaseOrder, QueryParameter, TwoAggregate} from 'two-core';
import {Tooltip} from 'primereact/tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faIndustryWindows} from '@fortawesome/pro-regular-svg-icons';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import {AppContext, TwoToast} from 'two-app-ui';
import {OrderStagePurchaseOrdersTooltipContent} from './OrderStagePurchaseOrdersTooltipContent';
import {TooltipEventParams, TooltipPositionType} from 'primereact/tooltip/tooltipoptions';

interface Props {
  order: Order;
  currentFactoryId: string;
}

interface State {
  loadingPurchaseOrders?: boolean;
  purchaseOrders?: PurchaseOrder[];
  stageTooltipPosition: TooltipPositionType;
}

//As for the refs, right now, we only need to see the POs in stage Waiting for Material. Hence, apply a delayed pop-up (2 second) for the stage if it is Waiting 4 Material and the pop-up should show the same content as does a PO reference component, but for all POs the order in the row is waiting for = related to.  Listed next to each other.
export class OrderStageComponent extends React.Component<Props, State> {
  //add context
  static contextType = AppContext;
  purchaseOrdersService?: PurchaseOrdersService;
  twoToast?: TwoToast;

  constructor(props: Props) {
    super(props);
    this.state = {
      stageTooltipPosition: 'right',
    };

    this.onPurchaseOrdersTooltipShow = this.onPurchaseOrdersTooltipShow.bind(this);
    this.loadPurchaseOrders = this.loadPurchaseOrders.bind(this);
    this.onBeforeShow = this.onBeforeShow.bind(this);
  }

  componentDidMount() {
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.twoToast = this.context.twoToast;
  }

  async loadPurchaseOrders(orderId: string) {
    try {
      const filters = [JSON.stringify({field: 'bom.order_id', value: orderId})];
      const aggregate = ['supplier', 'related_order_ids'] as unknown as TwoAggregate[];
      const params: QueryParameter = {filters, aggregate};
      const result = await this.purchaseOrdersService?.getPurchaseOrders(params);
      if (!result?.records) {
        return undefined;
      }
      return result.records as PurchaseOrder[];
    } catch (e) {
      this.twoToast?.showError('Failed to load purchase orders');
      console.error(e);
      return undefined;
    }
  }

  async onPurchaseOrdersTooltipShow() {
    const {order} = this.props;
    if (this.state.purchaseOrders !== undefined) {
      return;
    }
    this.setState({loadingPurchaseOrders: true});
    const purchaseOrders = await this.loadPurchaseOrders(order.id!);
    this.setState({purchaseOrders: purchaseOrders, loadingPurchaseOrders: false});
  }

  getStageClass(stage?: string) {
    return `stage-badge stage-${stage?.toLowerCase().replaceAll(' ', '-')}`;
  }

  onBeforeShow(e: TooltipEventParams) {
    const {stageTooltipPosition} = this.state;
    const mouseEvent = e.originalEvent as unknown as MouseEvent;
    const x = mouseEvent.x;
    const width = window.innerWidth;
    const widthPercentage = Math.round((x / width) * 100);
    if (widthPercentage > 50 && stageTooltipPosition === 'right') {
      this.setState(() => ({stageTooltipPosition: 'left'}));
    } else if (widthPercentage <= 50 && stageTooltipPosition === 'left') {
      this.setState(() => ({stageTooltipPosition: 'right'}));
    }
  }

  render() {
    const {order, currentFactoryId} = this.props;
    const {loadingPurchaseOrders, purchaseOrders, stageTooltipPosition} = this.state;
    const productionStage = order.factory_order?.production_stage;
    const factoryChain = order.factory_order?.factory_chain;
    const stageElementId = order.id + '_stage_label';

    let currentFactoryStage: string;
    let stageChainTooltip: JSX.Element | undefined;
    let stageChainIcon: JSX.Element | undefined;
    let purchaseOrdersTooltip: JSX.Element | undefined;

    if (factoryChain && Object.keys(factoryChain).length) {
      currentFactoryStage = factoryChain[currentFactoryId]?.stage;
      const productionChainElementId = order.id + '_chain_stage_label';
      const factoryChainNodes = [];
      for (const [factoryId, chainItem] of Object.entries(factoryChain)) {
        factoryChainNodes.push(
          <div className="p-mb-1" key={factoryId}>
            {chainItem.factory_name}: <span className={this.getStageClass(chainItem.stage)}>{chainItem.stage}</span>
          </div>
        );
      }
      stageChainIcon = <FontAwesomeIcon className="p-ml-1" icon={faIndustryWindows} id={productionChainElementId} />;
      stageChainTooltip = (
        <Tooltip target={`#${productionChainElementId}`} showDelay={500}>
          {factoryChainNodes}
        </Tooltip>
      );
    } else {
      currentFactoryStage = productionStage!;
    }

    if (currentFactoryStage === 'Waiting 4 Material') {
      purchaseOrdersTooltip = (
        <Tooltip
          target={`#${stageElementId}`}
          key={`tooltip_order_stage_${order.id}_${stageTooltipPosition}`}
          onBeforeShow={this.onBeforeShow}
          onShow={this.onPurchaseOrdersTooltipShow}
          showDelay={500}
          mouseTrack
          position={stageTooltipPosition}
        >
          <OrderStagePurchaseOrdersTooltipContent loading={loadingPurchaseOrders} purchaseOrders={purchaseOrders} />
        </Tooltip>
      );
    }

    return (
      <>
        <span className={this.getStageClass(currentFactoryStage)}>
          <span id={stageElementId}>{currentFactoryStage}</span>
          {stageChainIcon}
        </span>
        {stageChainTooltip}
        {purchaseOrdersTooltip}
      </>
    );
  }
}
