import {CutSheetReport, PrePowderItem, SawExtra} from 'two-core';
import {ProductionLabelData} from '../ProductionLabelData';
import {ProductionLabelMaker} from '../ProductionLabelMaker';

export function mapToShadesolLabel(shadesolCutSheet: CutSheetReport): ProductionLabelData[] {
  const result: ProductionLabelData[] = [];
  const labelMaker = new ProductionLabelMaker(
    '???',
    '???',
    shadesolCutSheet.production_bay?.toString() ?? '?',
    shadesolCutSheet.order_id,
    shadesolCutSheet.reference,
    '???',
    `ITEM #${0}`
  );

  // ----- PrePowder
  let orderedItems = shadesolCutSheet.prepowder.items.sort((a, b) => a.order_item_index - b.order_item_index) as any[];
  labelMaker.station = 'PRE-POWDER';
  for (const item of orderedItems) {
    labelMaker.product = item.content.product ?? item.product;
    labelMaker.index = `BLIND #${item.order_item_index}`;
    if (item.content.wb_type) {
      result.push(
        labelMaker.makeLabelTwoLines(
          item.content.wb_type,
          item.content.wb_colour,
          '',
          item.content.wb_length,
          item.id ?? 0,
          'WEIGHT BAR'
        )
      );
    }
    if (item.content.box_back_type) {
      result.push(
        labelMaker.makeLabelTwoLines(
          item.content.box_back_type,
          item.content.box_back_colour,
          '',
          item.content.box_back_length,
          item.id ?? 0,
          'BOX BACK'
        )
      );
    }
    if (item.content.box_front_type) {
      result.push(
        labelMaker.makeLabelTwoLines(
          item.content.box_front_type,
          item.content.box_front_colour,
          '',
          item.content.box_front_length,
          item.id ?? 0,
          'BOX FRONT'
        )
      );
    }
    if (item.content.box_top_type) {
      result.push(
        labelMaker.makeLabelTwoLines(
          item.content.box_top_type,
          item.content.box_top_colour,
          '',
          item.content.box_top_length,
          item.id ?? 0,
          'BOX TOP'
        )
      );
    }
    if (item.content.urail_left_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'U RAIL LEFT',
          item.content.ch_left_colour,
          '',
          item.content.urail_left_length,
          item.id ?? 0,
          'U RAIL'
        )
      );
    }
    if (item.content.ch_out_left_length) {
      result.push(
        labelMaker.makeLabelTwoLines('CH OUTER LEFT', item.content.ch_left_colour, '', 'CHANNEL OUTER', item.id ?? 0)
      );
    }
    if (item.content.ch_ff_left_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'CH FACE LEFT',
          item.content.ch_left_colour,
          '',
          item.content.ch_ff_left_length,
          item.id ?? 0,
          'CHANNEL FACE FIT'
        )
      );
    }
    if (item.content.ch_in_left_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'CH INNER LEFT',
          item.content.ch_left_colour,
          '',
          item.content.ch_in_left_length,
          item.id ?? 0,
          'CHANNEL INNER'
        )
      );
    }
    if (item.content.urail_right_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'U RAIL RIGHT',
          item.content.ch_right_colour,
          '',
          item.content.urail_right_length,
          item.id ?? 0,
          'U RAIL'
        )
      );
    }
    if (item.content.ch_out_right_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'CH OUTER RIGHT',
          item.content.ch_right_colour,
          '',
          item.content.ch_out_right_length,
          item.id ?? 0,
          'CHANNEL OUTER'
        )
      );
    }
    if (item.content.ch_ff_right_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'CH FACE RIGHT',
          item.content.ch_right_colour,
          '',
          item.content.ch_ff_right_length,
          item.id ?? 0,
          'CHANNEL FACE FIT'
        )
      );
    }
    if (item.content.ch_in_right_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'CH INNER RIGHT',
          item.content.ch_right_colour,
          '',
          item.content.ch_in_right_length,
          item.id ?? 0,
          'CHANNEL INNER'
        )
      );
    }
    if (item.content.arm_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'ARM LENGTH',
          item.content.arm_colour,
          '',
          item.content.arm_length,
          item.id ?? 0,
          'ARM'
        )
      );
    }
    if (item.content.extras) {
      for (const extra of item.content.extras as PrePowderItem[]) {
        result.push(
          labelMaker.makeLabelTwoLines(extra.material, extra.colour ?? '', '', `${extra.length}`, item.id ?? 0, 'EXTRA')
        );
      }
    }
  }

  // ----- SAW
  orderedItems = shadesolCutSheet.saw.items.sort((a, b) => a.order_item_index - b.order_item_index) as any[];
  labelMaker.station = 'SAW';
  for (const item of orderedItems) {
    labelMaker.product = item.content.product ?? item.product;
    labelMaker.index = `BLIND #${item.order_item_index}`;
    if (item.content.tube_type) {
      result.push(labelMaker.makeLabelOneLine(item.content.tube_type, item.content.tube_length, item.id ?? 0, 'TUBE'));
    }
    if (item.content.wb_type) {
      result.push(
        labelMaker.makeLabelTwoLines(
          item.content.wb_type,
          item.content.wb_colour,
          '',
          item.content.wb_length,
          item.id ?? 0,
          'WEIGHT BAR'
        )
      );
    }
    if (item.content.steel_count) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'STEEL WEIGHT',
          `COUNT: ${item.content.steel_count}`,
          '',
          `LENGTH: ${item.content.steel_length}`,
          item.id ?? 0,
          'STEEL'
        )
      );
    }
    if (item.content.box_back_type) {
      result.push(
        labelMaker.makeLabelTwoLines(
          item.content.box_back_type,
          item.content.box_back_colour,
          '',
          item.content.box_back_length,
          item.id ?? 0,
          'BOX BACK'
        )
      );
    }
    if (item.content.box_front_type) {
      result.push(
        labelMaker.makeLabelTwoLines(
          item.content.box_front_type,
          item.content.box_front_colour,
          '',
          item.content.box_front_length,
          item.id ?? 0,
          'BOX FRONT'
        )
      );
    }
    if (item.content.box_top_type) {
      result.push(
        labelMaker.makeLabelTwoLines(
          item.content.box_top_type,
          item.content.box_top_colour,
          '',
          item.content.box_top_length,
          item.id ?? 0,
          'BOX TOP'
        )
      );
    }
    if (item.content.urail_left_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'U RAIL LEFT',
          item.content.ch_left_colour,
          '',
          item.content.urail_left_length,
          item.id ?? 0,
          'U RAIL'
        )
      );
    }
    if (item.content.ch_out_left_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'CH OUTER LEFT',
          item.content.ch_left_colour,
          '',
          item.content.ch_out_left_length,
          item.id ?? 0,
          'CHANNEL OUTER'
        )
      );
    }
    if (item.content.ch_ff_left_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'CH FACE LEFT',
          item.content.ch_left_colour,
          '',
          item.content.ch_ff_left_length,
          item.id ?? 0,
          'CHANNEL FACE FIT'
        )
      );
    }
    if (item.content.ch_in_left_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'CH INNER LEFT',
          item.content.ch_left_colour,
          '',
          item.content.ch_in_left_length,
          item.id ?? 0,
          'CHANNEL INNER'
        )
      );
    }
    if (item.content.urail_right_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'U RAIL RIGHT',
          item.content.ch_right_colour,
          '',
          item.content.urail_right_length,
          item.id ?? 0,
          'U RAIL'
        )
      );
    }
    if (item.content.ch_out_right_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'CH OUTER RIGHT',
          item.content.ch_right_colour,
          '',
          item.content.ch_out_right_length,
          item.id ?? 0,
          'CHANNEL OUTER'
        )
      );
    }
    if (item.content.ch_ff_right_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'CH FACE RIGHT',
          item.content.ch_right_colour,
          '',
          item.content.ch_ff_right_length,
          item.id ?? 0,
          'CHANNEL FACE FIT'
        )
      );
    }
    if (item.content.ch_in_right_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'CH INNER RIGHT',
          item.content.ch_right_colour,
          '',
          item.content.ch_in_right_length,
          item.id ?? 0,
          'CHANNEL INNER'
        )
      );
    }
    if (item.content.arm_length) {
      result.push(
        labelMaker.makeLabelTwoLines(
          'ARM LENGTH',
          item.content.arm_colour,
          '',
          item.content.arm_length,
          item.id ?? 0,
          'ARM'
        )
      );
    }
    if (item.content.extras) {
      for (const extra of item.content.extras as SawExtra[]) {
        result.push(
          labelMaker.makeLabelTwoLines(extra.material, extra.colour, '', `${extra.length}`, item.id ?? 0, 'EXTRA')
        );
      }
    }
  }

  // ----- FABRIC
  orderedItems = shadesolCutSheet.fabric.items.sort((a, b) => a.order_item_index - b.order_item_index) as any[];
  labelMaker.station = 'FABRIC';
  for (const item of orderedItems) {
    labelMaker.product = item.content.product ?? item.product;
    labelMaker.index = `BLIND #${item.order_item_index}`;
    if (item.content.fabric) {
      result.push(
        labelMaker.makeLabelTwoLines(
          item.content.fabric,
          item.content.colour,
          `WIDTH:  ${item.content.cloth_width}`,
          `DROP:  ${item.content.cloth_drop}`,
          item.id ?? 0,
          'FABRIC'
        )
      );
    }
  }

  result.push(labelMaker.makePhotoLabel());

  return result;
}
