import React from 'react';
import Report from '../../Reports/Report';
import CutSheetFooter from '../CutSheetFooter';
import {CutSheetTemplate} from '../CutSheetTemplate';
import {FactoryFloorTask, FloorTaskContentPack, FloorTaskContentPackBlindDepre, MapOf} from 'two-core';

interface Props {
  data: any;
}

//interface State {}

class CVPack_v2 extends CutSheetTemplate {
  report: Report;
  contentPrio: MapOf<number>;
  executableTasks = 0;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.report = new Report();
    this.contentPrio = {
      repair: 1,
      note: 2,
      general: 3,
      blind: 4,
    };
  }

  render() {
    this.report = new Report();
    this.report.addHeader(
      2,
      <React.Fragment>
        <div className="d-flex justify-content-between mb-2mm mt-20mm">
          {/* Meta data */}
          <table className="header">
            <tr>
              <td className="label">order code</td>
              <td className="text">
                {this.props.data.order_id}
                {this.props.data.type === 'Repair' ? ' REPAIR' : ''}
              </td>
              <td className="label">dealer</td>
              <td className="text">{this.props.data.dealership_name}</td>
              <td className="label">bay</td>
              <td className="text">{this.props.data?.production_bay}</td>
              <td className="label">cv std v3</td>
              <td className="text">
                <b>PACKING</b>
              </td>
            </tr>
            <tr>
              <td className="label">reference</td>
              <td className="text" colSpan={3}>
                {this.props.data.reference}
              </td>
              <td className="label">shipping to</td>
              <td className="text">{this.props.data.ship_to}</td>
              <td className="label">ecd</td>
              <td className="text">
                <b>{this.props.data.ecd}</b>
              </td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );

    {
      /* ITEMS */
    }
    {
      this.props.data.pack.items.sort((firstTask: any, secondTask: any) => {
        const weight1 =
          firstTask.order_item_index * 100 +
          this.contentPrio[firstTask.category] * 10 +
          (firstTask.content?.blindIndex ?? 0);
        const weight2 =
          secondTask.order_item_index * 100 +
          this.contentPrio[secondTask.category] * 10 +
          (secondTask.content.blindIndex ?? 0);
        return weight1 - weight2;
      });

      this.executableTasks = 0;

      const blindsOnItem: Map<number, number> = new Map<number, number>();
      this.props.data.qc.items.map((task: FactoryFloorTask) => {
        if (task.category === 'blind') {
          this.executableTasks++;
          const currentBlindCount = blindsOnItem.get(task.order_item_index) ?? 0;
          blindsOnItem.set(task.order_item_index, currentBlindCount + 1);
        }
      });

      let currentItemIndex = this.props.data.pack.items[0].order_item_index;
      let itemRows: any[] = [];
      let repair1: any, repair2: any, repair3: any;
      repair1 = repair2 = repair3 = '';
      let isFirstRow = true;
      let currentLineCount = 1;

      this.props.data.pack.items.map((item: any) => {
        let indexColumn = <td className="no-top-border" />;
        let row;
        let rowClass = 'inside-row';

        if (item.order_item_index !== currentItemIndex) {
          this.report.addItem(
            item,
            currentLineCount,
            <React.Fragment>
              <table className="mt-10 odd-zebra three-cols content">
                {repair1}
                {repair2}
                {repair3}
                {itemRows}
              </table>
            </React.Fragment>
          );
          currentItemIndex = item.order_item_index;
          currentLineCount = 0;
          itemRows = [];
          isFirstRow = true;
        }

        if (isFirstRow) {
          isFirstRow = false;
          indexColumn = <td className="index">{item.order_item_index}</td>;
          rowClass = 'new-item';
        }

        if (item.category === 'repair') {
          repair1 = (
            <tr>
              <td>{item.order_item_index}</td>
              <td className="label">rework</td>
              <td className="text">{item.content.repair_whole_item ? 'YES' : 'NO'}</td>
              <td className="label">type</td>
              <td className="text">{this.capitaliseFirst(item.content.repair_type ?? 'Whole Item Rework')}</td>
              <td className="label">responsibility</td>
              <td className="text" colSpan={2}>
                {this.capitaliseFirst(item.content.repair_responsibility)}
              </td>
            </tr>
          );
          if (item.content.repair_type === 'cutdown (standard)') {
            repair2 = (
              <tr>
                <td />
                <td className="label">new height</td>
                <td className="number">{item.content.new_height}</td>
                <td className="label">new width</td>
                <td className="number" colSpan={3}>
                  {item.content.new_width}
                </td>
                <td className="sign-off">check</td>
              </tr>
            );
            currentLineCount += 1;
          }
          repair3 = (
            <tr>
              <td />
              <td className="label">repair</td>
              <td className="text" colSpan={5}>
                {item.content.repair_details}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          currentLineCount += 2;
        }

        if (item.category === 'general') {
          const data = item.content;
          const twoPack: string[] = [];
          twoPack.push(
            `${blindsOnItem.get(item.order_item_index) ?? 0} Blind${
              blindsOnItem.get(item.order_item_index) ?? 0 > 1 ? 's' : ''
            }`
          );
          if (data.decoCover) {
            twoPack.push('Deco Cover');
          }
          if (data.channelColour) {
            twoPack.push('Channels');
          }
          if (data.components.length > 0) {
            twoPack.push(`${data.components.length} Component${data.components.length > 1 ? '(s)' : ''}`);
          }
          if (data.wireQty) {
            twoPack.push('Wires & Stuff');
          }
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">product</td>
              <td className="text">{this.capitaliseFirst(data.product)}</td>
              <td className="label">2 pack</td>
              <td className="text" colSpan={3}>
                {twoPack.join(', ')}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount++;
          if (data.decoCover) {
            const colourColumns: any[] = [];
            if (!data.decoCoverFabric) {
              colourColumns.push(<td className="label">colour</td>);
              colourColumns.push(
                <td className="text" colSpan={3}>
                  {this.capitaliseFirst(data.decoCoverColour)}
                </td>
              );
            } else {
              colourColumns.push(<td className="label">farbic</td>);
              colourColumns.push(<td className="text">{this.capitaliseFirst(data.decoCoverFabric)}</td>);
              colourColumns.push(<td className="label">fab col</td>);
              colourColumns.push(<td className="text">{this.capitaliseFirst(data.decoCoverFabricColour)}</td>);
            }
            row = (
              <tr className={rowClass}>
                {indexColumn}
                <td className="label">deco cover</td>
                <td className="text">{this.capitaliseFirst(data.decoCover)}</td>

                {colourColumns}
                <td className="sign-off">pack</td>
              </tr>
            );
            itemRows.push(row);
            currentLineCount++;
          }
          if (data.channelColour) {
            row = (
              <tr className={rowClass}>
                {indexColumn}
                <td className="label">channels</td>
                <td className="text">2</td>
                <td className="label">colour</td>
                <td className="text">{this.capitaliseFirst(data.channelColour)}</td>
                <td className="label">length</td>
                <td className="number">{data.channelLength}</td>
                <td className="sign-off">pack</td>
              </tr>
            );
            itemRows.push(row);
            currentLineCount++;
          }
          if (data.wireQty) {
            row = (
              <tr className={rowClass}>
                {indexColumn}
                <td className="label">wires</td>
                <td className="number">2</td>
                <td className="label">type</td>
                <td className="text">{data.wireType}</td>
                <td className="label">length</td>
                <td className="number">{data.wireLength}</td>
                <td className="sign-off">pack</td>
              </tr>
            );
            itemRows.push(row);
            currentLineCount++;
          }
          for (const component of data.components) {
            const colourColumns: any[] = [];
            if (!component.colour) {
              colourColumns.push(
                <td className="text" colSpan={3}>
                  {component.unit}
                </td>
              );
            } else {
              colourColumns.push(<td className="text">{component.unit}</td>);
              colourColumns.push(<td className="label">colour</td>);
              colourColumns.push(<td className="text">{component.colour}</td>);
            }
            row = (
              <tr className={rowClass}>
                {indexColumn}
                <td className="label">component</td>
                {colourColumns}
                <td className="label">qty</td>
                <td className="number">{component.qty}</td>
                <td className="sign-off">pack</td>
              </tr>
            );
            itemRows.push(row);
            currentLineCount++;
          }
        }

        if (item.category === 'note') {
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">note</td>
              <td className="text" colSpan={5}>
                {`${item.content}`.replace(/\r?\n|\r/g, ' ')}
              </td>
              <td className="sign-off">check</td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount += Math.ceil(`${item.content}`.length / 90);
        }

        if (item.category === 'blind') {
          const data: FloorTaskContentPackBlindDepre = item.content;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="text" colSpan={7}>
                blind ({data.blindIndex}/{blindsOnItem.get(item.order_item_index) ?? 0})
              </td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount++;
          row = (
            <tr className={rowClass}>
              {indexColumn}
              <td className="label">W x D</td>
              <td className="number">
                {data.blindLength ?? 0} x {data.drop ?? 0}
              </td>
              <td className="label">fabric</td>
              <td className="number">{data.fabric}</td>
              <td className="label">colour</td>
              <td className="text">{data.fabricColour}</td>
              <td className="sign-off">pack</td>
            </tr>
          );
          itemRows.push(row);
          currentLineCount++;
          if (data.brackets && data.brackets.length > 0)
            for (const bracket of data.brackets) {
              row = (
                <tr className={rowClass}>
                  {indexColumn}
                  <td className="label">bracket</td>
                  <td className="text">{bracket.unit}</td>
                  <td className="label">colour</td>
                  <td className="text">{bracket.colour}</td>
                  <td className="label">qty</td>
                  <td className="number">{bracket.qty}</td>
                  <td className="sign-off">pack</td>
                </tr>
              );
              itemRows.push(row);
              currentLineCount++;
            }
          if (data.components) {
            for (const component of data.components) {
              const colourColumns: any[] = [];
              const unitColspan = '';
              if (!component.colour) {
                colourColumns.push(
                  <td className="text" colSpan={3}>
                    {component.unit}
                  </td>
                );
              } else {
                colourColumns.push(<td className="text">{component.unit}</td>);
                colourColumns.push(<td className="label">colour</td>);
                colourColumns.push(<td className="text">{component.colour}</td>);
              }
              row = (
                <tr className={rowClass}>
                  {indexColumn}
                  <td className="label">component</td>
                  {colourColumns}
                  <td className="label">qty</td>
                  <td className="number">{component.qty}</td>
                  <td className="sign-off">pack</td>
                </tr>
              );
              itemRows.push(row);
              currentLineCount++;
            }
          }
        }
      });
      this.report.addItem(
        this.props.data,
        currentLineCount,
        <React.Fragment>
          <table className="mt-10 odd-zebra three-cols content">
            {repair1}
            {repair2}
            {repair3}
            {itemRows}
          </table>
        </React.Fragment>
      );
    }

    {
      /* CHECK SUM */
    }
    this.report.addItem(
      this.props.data,
      1,
      <React.Fragment>
        <table className="summary">
          <td className="label">total</td>
          <td className="index">{this.executableTasks}</td>
        </table>
      </React.Fragment>
    );

    {
      /* FOOTER */
    }
    this.report.addFooter(1, new CutSheetFooter());

    return (
      <div className="cut-sheet">
        <div className="colorvue-standard-pack-v1">{this.report.getReport()}</div>
      </div>
    );
  }
}

export default CVPack_v2;
