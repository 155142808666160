import React from 'react';
import {History} from 'history';
import {Link} from 'react-router-dom';
import {Card} from 'primereact/card';
import {AppContext, AlarmReferenceComponent} from 'two-app-ui';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {InventoryItem, Order, FactoryAlarm, Supplier, Address} from 'two-core';
import PoReferenceComponent from '../Reference/PoReferenceComponent';
import OosReferenceComponent from '../Reference/OosReferenceComponent';
import {formats} from '../../config/formats';
import {OrderStageComponent} from './OrderStageComponent';

interface Props {
  order: Order;
  alarms: FactoryAlarm[];
  showAlarm: (alarm: FactoryAlarm) => Promise<void>;
  history: History;
}

interface State {
  poSuppliers: Supplier[];
  oosInventoryItems: InventoryItem[];
}

class OrderDetail extends React.Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      poSuppliers: [],
      oosInventoryItems: [],
    };

    this.priorityTemplate = this.priorityTemplate.bind(this);
  }

  priorityTemplate(priority: number) {
    let priorityText = '';
    switch (priority) {
      case 1:
        priorityText = '!';
        break;
      case 2:
        priorityText = '!!';
        break;
      case 3:
        priorityText = '!!!';
        break;
    }
    return <span className={`stage-badge prio-${priority}`}>{priorityText}</span>;
  }

  render() {
    const order = this.props.order;
    const currentFactoryId = localStorage.getItem('current factory') ?? '';

    return (
      <Card className={'p-d-flex order-detail-item'}>
        {/**Reference */}
        <div className="p-d-flex">
          <label htmlFor="reference" className="p-col-12 p-md-3">
            reference
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order.reference ?? ''}</span>
          </div>
        </div>

        {/**Product line */}
        <div className="p-d-flex">
          <label htmlFor="product_line" className="p-col-12 p-md-3">
            product line
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order.factory_order?.product_line ?? ''}</span>
          </div>
        </div>

        {/** Production stage */}
        <div className="p-d-flex">
          <label htmlFor="production_stage" className="p-col-12 p-md-3">
            stage
          </label>
          <div className={'p-col-12 p-md-9'}>
            <OrderStageComponent order={order} currentFactoryId={currentFactoryId} />
          </div>
        </div>

        {/** Priority */}
        <div className="p-d-flex">
          <label htmlFor="priority" className="p-col-12 p-md-3">
            priority
          </label>
          <div className="p-col-12 p-md-9">
            <span>{this.priorityTemplate(order.priority)}</span>
          </div>
        </div>

        {/** Ecd */}
        <div className="p-d-flex">
          <label htmlFor="ecd" className="p-col-12 p-md-3">
            ecd
          </label>
          <div className="p-col-12 p-md-9">
            <span>
              {order.factory_order?.ecd
                ? DateTime.fromISO(order.factory_order?.ecd.toString()).toFormat(formats.date)
                : ''}
            </span>
          </div>
        </div>

        {/** Type */}
        <div className="p-d-flex">
          <label htmlFor="type" className="p-col-12 p-md-3">
            type
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order.type}</span>
          </div>
        </div>

        {/** Summary */}
        <div className="p-d-flex">
          <label htmlFor="summary" className="p-col-12 p-md-3">
            summary
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order.factory_order?.summary ?? ''}</span>
          </div>
        </div>

        {/** Size */}
        <div className="p-d-flex p-mb-4">
          <label htmlFor="size" className="p-col-12 p-md-3">
            size
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order.factory_order?.size}</span>
          </div>
        </div>

        {order.submitted_by || order.submitted_at ? (
          <div className="p-d-flex">
            <label htmlFor="submitted" className="p-col-12 p-md-3">
              submitted
            </label>
            <div className="p-col-12 p-md-9">
              <span>
                {`${order.submitted_by ?? ''} ${
                  order.submitted_at ? DateTime.fromISO(order.submitted_at.toString()).toFormat(formats.date) : ''
                }`}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {order.approved_by || order.approved_at ? (
          <div className="p-d-flex">
            <label htmlFor="approved" className="p-col-12 p-md-3">
              approved
            </label>
            <div className="p-col-12 p-md-9">
              <span>
                {`${order.approved_by ?? ''} ${
                  order.approved_at ? DateTime.fromISO(order.approved_at.toString()).toFormat(formats.date) : ''
                }`}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {order.factory_order?.scheduled_for ? (
          <div className="p-d-flex">
            <label htmlFor="scheduled_for" className="p-col-12 p-md-3">
              sent to floor
            </label>
            <div className="p-col-12 p-md-9">
              <span>{DateTime.fromJSDate(order.factory_order?.scheduled_for).toLocaleString()}</span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {order.factory_order?.qc_by ? (
          <div className="p-d-flex">
            <label htmlFor="checked_by" className="p-col-12 p-md-3">
              QC
            </label>
            <div className="p-col-12 p-md-9">
              <span>{order.factory_order?.qc_by}</span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {order.factory_order?.completed_at ? (
          <div className="p-d-flex">
            <label htmlFor="completed_on" className="p-col-12 p-md-3">
              completed
            </label>
            <div className="p-col-12 p-md-9">
              <span>{DateTime.fromISO(order.factory_order.completed_at.toString()).toLocaleString()}</span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {order.owner_company ? (
          <div className="p-d-flex">
            <label htmlFor="owner" className="p-col-12 p-md-3">
              owner
            </label>
            <div className="p-col-12 p-md-9">
              <span>{order.owner_company?.name ?? ''}</span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {order.shipping_address ? (
          <div className="p-d-flex">
            <label htmlFor="shipping_address" className="p-col-12 p-md-3">
              shipping to
            </label>
            <div className="p-col-12 p-md-12 p-lg-9">
              <span>
                {(order.shipping_address as Address)?.street}
                {(order.shipping_address as Address)?.state}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {order.home_folder ? (
          <div className="p-d-flex">
            <label htmlFor="home_folder" className="p-col-12 p-md-3">
              folder
            </label>
            <div className="p-col-12 p-md-9 p-d-flex p-flex-wrap">
              <Link target="_blank" className="p-mr-3" to="">
                <FontAwesomeIcon icon={['fal', 'external-link']} className={'p-mr-1'} />
                {order.home_folder}
              </Link>
            </div>
          </div>
        ) : (
          <></>
        )}

        {this.props.alarms && this.props.alarms.length > 0 ? (
          <div className="p-d-flex">
            <label htmlFor="alarms" className="p-col-12 p-md-3">
              alarms
            </label>
            <div className="p-col-12 p-md-9">
              <div className="p-d-flex p-flex-row p-flex-wrap">
                <AlarmReferenceComponent
                  identifier={order.id ?? ''}
                  factoryAlarms={this.props.alarms ?? []}
                  handleAlarmReferenceClick={(alarm: FactoryAlarm) => this.props.showAlarm(alarm)}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {order.purchase_orders && order.purchase_orders.length > 0 && order.purchase_orders[0] !== null ? (
          <div className="p-d-flex">
            <label htmlFor="pos" className="p-col-12 p-md-3">
              pos
            </label>
            <div className="p-col-12 p-md-9">
              <div className="p-d-flex p-flex-row p-flex-wrap">
                <PoReferenceComponent
                  identifier={order.id ?? ''}
                  purchaseOrders={order.purchase_orders ?? []}
                  suppliers={this.state.poSuppliers}
                  handleChangePoSupplier={suppliers => this.setState({poSuppliers: suppliers})}
                  handlePoReferenceClick={po => {
                    this.props.history.push(`/purchase-order/${po.id}`);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {order.out_of_stocks && order.out_of_stocks.length > 0 && order.out_of_stocks[0] !== null ? (
          <div className="p-d-flex">
            <label htmlFor="oos" className="p-col-12 p-md-3">
              oos
            </label>
            <div className="p-col-12 p-md-9">
              <div className="p-d-flex p-flex-row p-flex-wrap">
                <OosReferenceComponent
                  identifier={order.id ?? ''}
                  oosRecords={order.out_of_stocks}
                  oosInventoryItems={this.state.oosInventoryItems}
                  handleChangeOosInventoryItems={inventoryItems => this.setState({oosInventoryItems: inventoryItems})}
                  handleOosReferenceClick={oos => {
                    this.props.history.push(`/oos-item/${oos.id}`);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Card>
    );
  }
}

export default OrderDetail;
