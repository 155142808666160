import React from 'react';
import {Column} from 'primereact/column';
import {DataTablePageParams} from 'primereact/datatable';
import {AppContext, MessageService, TwoDataTable, TwoToast} from 'two-app-ui';
import {InventoryItem, PurchaseOrder, QueryParameter} from 'two-core';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import {History} from 'history';

interface Props {
  inventoryItem: InventoryItem;
  history: History;
}

interface State {
  loading: boolean;
  items: PurchaseOrder[];
  total_items: number;
  pagination: {
    pageSize: number;
    offset: number;
  };
}

class InventoryItemPos extends React.Component<Props, State> {
  static contextType = AppContext;
  twoToast?: TwoToast;
  purchaseOrdersService: PurchaseOrdersService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      items: [],
      total_items: 0,
      pagination: {
        pageSize: 10,
        offset: 0,
      },
    };

    this.idBodyTemplate = this.idBodyTemplate.bind(this);
  }

  componentDidMount() {
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.twoToast = this.context.twoToast;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.purchaseOrderUpdated) {
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.messageSendSubscription.unsubscribe();
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'inventory_item.id',
        value: [this.props.inventoryItem.id ?? ''],
        condition: 'in',
      })
    );

    const sortBy = JSON.stringify({
      field: 'id',
      direction: 'ASC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      orderBys: [sortBy],
      aggregate: true,
      filters: filters,
    };

    this.purchaseOrdersService
      ?.getPurchaseOrders(params)
      .then(data => {
        const pos: PurchaseOrder[] = (data?.records as PurchaseOrder[]) ?? [];
        this.setState({
          items: pos,
          total_items: pos?.length ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({loading: false});
        this.twoToast?.showError('Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  etaTemplate(purchaseOrder: PurchaseOrder) {
    return purchaseOrder.eta ? DateTime.fromISO(purchaseOrder.eta.toString()).toFormat(formats.date) : '';
  }

  idBodyTemplate(purchaseOrder: PurchaseOrder): JSX.Element {
    return (
      <a
        className="po-link"
        onClick={() => {
          this.props.history.push(`/purchase-order/${purchaseOrder.id}`);
        }}
      >
        {purchaseOrder.id ?? ''}
      </a>
    );
  }

  qtyTemplate(purchaseOrder: PurchaseOrder) {
    const purchaserOrderItems = purchaseOrder.items?.filter(i => i !== null) ?? [];
    const quantity = purchaserOrderItems.reduce((a, b) => a + (b.quantity ?? 0), 0);

    return quantity;
  }

  render() {
    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="po_list_page" className="page-container">
          <TwoDataTable
            style={{height: '100%'}}
            pageSizeIdentifier={'po_list_page'}
            heightToScroll={undefined}
            customEmptyMessage="No active purchase orders found."
            selectedItems={[]}
            handleChangeSelectedItems={() => {}}
            loading={this.state.loading}
            rows={this.state.pagination.pageSize}
            first={this.state.pagination.offset}
            activeFilters={{}}
            value={this.state.items}
            totalRecords={this.state.total_items}
            onPage={e => this.onPageChange(e as DataTablePageParams)}
          >
            <Column header="Id" field="id" body={this.idBodyTemplate} style={{width: '170px'}} />
            <Column header="Supplier" field="supplier.company_name" style={{width: '170px'}} />
            <Column header="Stage" field="stage" style={{width: '170px'}} />
            <Column header="Eta" field="eta" body={this.etaTemplate} style={{width: '170px'}} />
            <Column header="QTY" field="qty" body={this.qtyTemplate} style={{width: '120px'}} />
          </TwoDataTable>
        </div>
      </div>
    );
  }
}

export default InventoryItemPos;
