import React, {useContext} from 'react';
import {Toolbar} from 'primereact/toolbar';
import {Dropdown} from 'primereact/dropdown';
import {SelectButton} from 'primereact/selectbutton';
import {Calendar} from 'primereact/calendar';
import {ProgressSpinner} from 'primereact/progressspinner';
import {DropdownOption, OrderType, ProductionStageM2cAuShades} from 'two-core';
import {AppContext} from 'two-app-ui';
import {DateTime, Settings} from 'luxon';
import {SchedulerMode} from './Scheduler';
import {MultiSelect} from 'primereact/multiselect';
import {stages} from '../Order/Constants/constants';
import formats from '../../config/formats';

export interface SchedulerFilterProps {
  productLine: 'Colourvue' | 'Shadesol' | 'Curtains';
  types?: OrderType[];
  ecdFrom?: DateTime;
  ecdTo?: DateTime;
  completedAt?: DateTime;
  completedAtFrom?: DateTime;
  completedAtTo?: DateTime;
  stages?: ProductionStageM2cAuShades[];
}
interface Props {
  loading: boolean;
  filter: SchedulerFilterProps;
  onFilterChange: (filter: Partial<SchedulerFilterProps>) => void;
  pauseTypingTimer: () => void;
  resumeTypingTimer: () => void;
  factoryOrdersSizeSum: number;
  mode: SchedulerMode;
}
export function SchedulerFilter({
  loading,
  filter,
  onFilterChange,
  resumeTypingTimer,
  pauseTypingTimer,
  factoryOrdersSizeSum,
  mode,
}: Props) {
  const context = useContext(AppContext);

  const typeOptions = ['Standard', 'Component', 'Repair'];
  const productLineOptions = ['Colourvue', 'Shadesol', 'Curtains'];
  const ecdFromValue = filter.ecdFrom?.setZone('system', {keepLocalTime: true}).toJSDate();
  const ecdToValue = filter.ecdTo?.setZone('system', {keepLocalTime: true}).toJSDate();
  const ecdValue = [ecdFromValue, ecdToValue].filter(d => d) as Date[];
  const completedAtValue = filter.completedAt?.setZone(Settings.defaultLocale, {keepLocalTime: true}).toJSDate();
  const onProductLineChange = (e: {value: 'Colourvue' | 'Shadesol' | 'Curtains'}) => {
    onFilterChange({productLine: e.value});
  };

  const onTypeChange = (e: {value: OrderType[]}) => {
    if (e.value.length) {
      onFilterChange({types: e.value});
    }
  };

  const onEcdValueChange = (e: {value?: Date | Date[]}) => {
    if (!e.value) {
      onFilterChange({ecdFrom: undefined, ecdTo: undefined});
      return;
    }

    const [startJsDate, endJsDate] = e.value as Date[];

    let ecdFrom = undefined;
    let ecdTo = undefined;
    if (startJsDate) {
      ecdFrom = DateTime.fromJSDate(startJsDate).startOf('day').setZone('utc', {keepLocalTime: true});
    }
    if (endJsDate) {
      ecdTo = DateTime.fromJSDate(endJsDate).endOf('day').setZone('utc', {keepLocalTime: true});
    }
    onFilterChange({ecdFrom, ecdTo});
  };
  const onCompletedAtValueChange = (e: {value?: Date | Date[]}) => {
    if (!e.value) {
      onFilterChange({completedAtFrom: undefined, completedAtTo: undefined});
      return;
    }
    const luxonDate = DateTime.fromJSDate(e.value as Date).setZone(Settings.defaultLocale, {keepLocalTime: true});
    const luxonStartMonthDate = DateTime.fromJSDate(e.value as Date)
      .startOf('month')
      .setZone('utc', {keepLocalTime: true});

    const luxonEndMonthDate = DateTime.fromJSDate(e.value as Date)
      .endOf('month')
      .setZone('utc', {keepLocalTime: true});

    onFilterChange({completedAtFrom: luxonStartMonthDate, completedAtTo: luxonEndMonthDate, completedAt: luxonDate});
  };

  const onStageChange = (e: {value: ProductionStageM2cAuShades[]}) => {
    onFilterChange({stages: e.value});
  };

  const selectedStageItemTemplate = (value: string) => {
    if (value) {
      return (
        <span className={`p-mr-1 stage-badge stage-badge-filter stage-${value.toLowerCase().replaceAll(' ', '-')}`}>
          {value}
        </span>
      );
    }

    return <div className="p-multiselect-label-empty">empty</div>;
  };

  const stageItemTemplate = (option: DropdownOption) => {
    return (
      <span className={`stage-badge stage-${(option.value as string).toLowerCase().replaceAll(' ', '-')}`}>
        {option.label}
      </span>
    );
  };

  const onEcdHide = () => {
    if (filter.ecdFrom && !filter.ecdTo) {
      const ecdTo = filter.ecdFrom.endOf('day');
      onFilterChange({ecdTo: ecdTo});
    }
    resumeTypingTimer();
  };

  const leftContent = (
    <>
      <label className="p-mr-2">Line</label>
      <Dropdown
        className="p-mr-3"
        disabled={loading}
        options={productLineOptions}
        value={filter.productLine}
        onChange={onProductLineChange}
        onShow={pauseTypingTimer}
        onHide={resumeTypingTimer}
      />
      <label className="p-mr-2">Type</label>
      <SelectButton
        className="p-mr-3"
        options={typeOptions}
        disabled={loading}
        value={filter.types}
        onChange={onTypeChange}
        multiple
      />
      {mode === 'scheduler' && (
        <>
          <label className="p-mr-2">Stage</label>
          <MultiSelect
            className="p-mr-3"
            selectedItemTemplate={selectedStageItemTemplate}
            itemTemplate={stageItemTemplate}
            value={filter.stages}
            options={stages}
            onChange={onStageChange}
            showClear
          />
          <label className="p-mr-2">Time Frame</label>
          <Calendar
            selectionMode="range"
            value={ecdValue}
            disabled={loading}
            onChange={onEcdValueChange}
            dateFormat={formats.calendarInputDate}
            showButtonBar
            onShow={pauseTypingTimer}
            onHide={onEcdHide}
            readOnlyInput
          />
        </>
      )}
      {mode === 'completed' && (
        <>
          <label className="p-mr-2">Month</label>
          <Calendar
            disabled={loading}
            value={completedAtValue}
            onSelect={onCompletedAtValueChange}
            dateFormat={'M/y'}
            onShow={pauseTypingTimer}
            onHide={resumeTypingTimer}
            view={'month'}
          />
        </>
      )}
    </>
  );

  const rightContent = (
    <>
      <label className="p-mr-2">Total</label>
      <span>
        {loading ? (
          <ProgressSpinner style={{width: '1em', height: '1em'}} strokeWidth="8" />
        ) : (
          <span className="p-text-bold" style={{fontSize: '1.2em'}}>
            {factoryOrdersSizeSum}
          </span>
        )}
      </span>
    </>
  );

  return <Toolbar className="p-reset p-py-2 p-px-3" left={leftContent} right={rightContent} />;
}
