import React from 'react';
import {InputText} from 'primereact/inputtext';
import {User} from 'two-core';

interface UserProps {
  user: User;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const UserComponent = ({user, handleInputChange}: UserProps) => (
  <div id="user-detail">
    {/** uuid */}
    <div className="p-field p-grid">
      <label htmlFor="id" className="p-col-12 p-mb-3 p-md-3 p-mb-md-2">
        UUID
      </label>
      <div className="p-col-12 p-md-9">
        <label className="p-col-12 p-mb-3 p-md-3 p-mb-md-2">{user.id ? user.id : <>New User</>}</label>
      </div>
    </div>

    {/** username */}
    <div className="p-field p-grid">
      <label htmlFor="username" className="p-col-12 p-mb-3 p-md-3 p-mb-md-2">
        Username
      </label>
      <div className="p-col-12 p-md-9">
        <InputText
          id="username"
          name="username"
          type="text"
          value={user.username}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
        />
      </div>
    </div>

    {/** email */}
    <div className="p-field p-grid">
      <label htmlFor="email" className="p-col-12 p-mb-3 p-md-3 p-mb-md-2">
        Email
      </label>
      <div className="p-col-12 p-md-9">
        <InputText
          id="email"
          name="email"
          type="text"
          value={user.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
        />
      </div>
    </div>

    {/** role
    <div className="p-field p-grid">
      <label htmlFor="role" className="p-col-12 p-mb-3 p-md-3 p-mb-md-2">
        Role
      </label>
      <div className="p-col-12 p-md-9">
        <InputText
          id="role"
          name="role"
          type="text"
          value={user.role}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(e)
          }
        />
      </div>
    </div> */}

    {/** factory
    <div className="p-field p-grid">
      <label htmlFor="factory" className="p-col-12 p-mb-3 p-md-3 p-mb-md-2">
        Factory
      </label>
      <div className="p-col-12 p-md-9">
        <Dropdown
          value={user.factory_id}
          options={factories}
          name="factory"
          className="form-filter"
          onChange={(e: any) => handleSelectChange(e)}
          showClear
        />
      </div>*
    </div>*/}
  </div>
);

export default UserComponent;
