import React from 'react';
import {Button} from 'primereact/button';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import '../../scss/CustomTable.scss';
import {Order, ProductionStageM2cAuShades, PurchaseOrder, Supplier} from 'two-core';
import InventoryService from '../../services/InventoryService';
import BomService from '../../services/BomService';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';
import PurchaseOrdersService from '../../services/PurchaseOrdersService';
import {Toast} from 'primereact/toast';
import SuppliersService from '../../services/SuppliersService';
import OrdersService from '../../services/OrdersService';
import FactoryOrdersService from '../../services/FactoryOrdersService';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import PoReferenceComponent from '../Reference/PoReferenceComponent';
import {getNewProductionStagesPatch} from '../../utils/FactoryOrderUtil';

interface Props {
  toast: React.RefObject<Toast>;
  orders: Order[];
}

interface State {
  loadingDialog: boolean;
  isMissingOrderStock: boolean;
  showDialog: boolean;
  oosOrders: string[] | null;
  poSuppliers: Supplier[];
  canContinueToReady: boolean;
}

export interface PosPurchases {
  purchaseOrder: PurchaseOrder;
  newPurchases: PurchaseOrder[];
}

class OrderStockDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  inventoryService: InventoryService | null = null;
  purchaseOrdersService: PurchaseOrdersService | null = null;
  bomService: BomService | null = null;
  suppliersService: SuppliersService | null = null;
  toastService: ToastService | null = null;
  ordersService: OrdersService | null = null;
  subscription: Subscription = new Subscription();
  factoryOrdersService: FactoryOrdersService | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      loadingDialog: false,
      isMissingOrderStock: false,
      showDialog: false,
      oosOrders: null,
      poSuppliers: [],
      canContinueToReady: false,
    };

    this.onCloseDialog = this.onCloseDialog.bind(this);
    this.showDialog = this.showDialog.bind(this);
    this.setChangePoSuppliers = this.setChangePoSuppliers.bind(this);
    this.saveAndClose = this.saveAndClose.bind(this);
  }

  componentDidMount() {
    this.inventoryService = this.context.inventoryService;
    this.bomService = this.context.bomService;
    this.purchaseOrdersService = this.context.purchaseOrdersService;
    this.suppliersService = this.context.suppliersService;
    this.toastService = this.context.toastService;
    this.ordersService = this.context.ordersService;
    this.factoryOrdersService = this.context.factoryOrderService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.orderStockCheck) {
        this.showDialog(false);
      } else if (message === messages.orderStockCheckAndContinue) {
        this.showDialog(true);
      }
    });
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  /**
   * Opens the dialog
   */
  async showDialog(canContinue: boolean) {
    this.setState({
      showDialog: true,
      loadingDialog: true,
    });
    const unprocessableOrders = await this.checkUnprocessableOrders();

    this.setState({
      loadingDialog: false,
      isMissingOrderStock: unprocessableOrders ? true : false,
      oosOrders: unprocessableOrders,
      canContinueToReady: canContinue,
    });
  }

  async saveAndClose() {
    this.setState({loadingDialog: true});

    this.updateOrdersStages().then(() => {
      MessageService.sendMessage(messages.ordersUpdated);
      this.onCloseDialog();
    });
  }

  /**
   * Check the orders array and returns those that contain at least one PO
   * that has not been delivered => delivered_at = null
   */
  async checkUnprocessableOrders(): Promise<string[] | null> {
    const orders = this.props.orders.filter(o => {
      let canBeProcessed = true;
      if (o.purchase_orders && o.purchase_orders.length > 0) {
        for (const po of o.purchase_orders) {
          if (!po.delivered_at) {
            canBeProcessed = false;
          }
        }
      }
      return !canBeProcessed;
    });
    const ordersIds = orders.map(o => {
      return o.id!;
    });

    if (ordersIds && ordersIds.length) {
      return ordersIds;
    } else {
      return null;
    }
  }

  async updateOrdersStages() {
    console.log(this.props.orders);
    const promises = this.props.orders.map(order => {
      let newStage: ProductionStageM2cAuShades = 'Ready';
      if (this.state.oosOrders?.find(oos => oos === order.id)) {
        newStage = 'Waiting 4 Material';
      }
      const factoryOrderPatch = getNewProductionStagesPatch(order.factory_order!, newStage);
      return this.factoryOrdersService?.updateFactoryOrder(order.id ?? '', factoryOrderPatch);
    });

    return Promise.all(promises).catch(error => {
      console.error(error);
    });
  }

  setChangePoSuppliers(suppliers: Supplier[]) {
    this.setState({poSuppliers: suppliers});
  }

  /**
   * Deactivates and hides the dialog
   */
  onCloseDialog() {
    this.setState({showDialog: false, loadingDialog: false});
  }

  poReferenceTemplate = (rowData: Order) => {
    return (
      <PoReferenceComponent
        key={'PO-' + rowData.id}
        identifier={rowData.id ?? ''}
        purchaseOrders={rowData.purchase_orders ?? []}
        suppliers={this.state.poSuppliers}
        handleChangePoSupplier={suppliers => this.setChangePoSuppliers(suppliers)}
      />
    );
  };

  refsBodyTemplate = (rowData: Order) => {
    return <div className="p-d-flex p-flex-row p-flex-wrap">{this.poReferenceTemplate(rowData)}</div>;
  };

  render() {
    const ordersTitle = this.props.orders.length > 1 ? 'orders' : 'order';

    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        {!this.state.loadingDialog &&
          (this.state.canContinueToReady ? (
            <>
              <Button
                label="Cancel"
                className={'p-mr-2 p-button-text'}
                onClick={() => {
                  this.onCloseDialog();
                }}
              />
              <Button
                label="Continue"
                className={'p-mr-2'}
                onClick={() => {
                  this.saveAndClose();
                }}
                autoFocus
              />
            </>
          ) : (
            <Button
              label="OK"
              className={'p-mr-2'}
              onClick={() => {
                this.onCloseDialog();
              }}
              autoFocus
            />
          ))}
      </div>
    );
    const dialogBody =
      !this.state.loadingDialog &&
      (this.state.isMissingOrderStock ? (
        <>
          <span>{`Following ${ordersTitle} need to wait for material.`}</span>
          <DataTable
            value={this.props.orders}
            style={{
              width: '100%',
            }}
          >
            <Column field="id" header="Order Code"></Column>
            <Column field="reference" header="Reference"></Column>
            <Column header="Purchase Orders" body={this.refsBodyTemplate}></Column>
          </DataTable>
        </>
      ) : (
        <span>{`All stock levels are sufficient to complete the selected ${ordersTitle}.`}</span>
      ));

    return (
      <TwoDialog
        headerTitle={'Stock Check'}
        showDialog={this.state.showDialog}
        width={this.state.isMissingOrderStock ? 50 : 70}
        onHide={() => {
          this.onCloseDialog();
        }}
        footer={footer}
        loading={this.state.loadingDialog}
      >
        {dialogBody}
      </TwoDialog>
    );
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }
}

export default OrderStockDialog;
