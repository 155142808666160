import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {OtherMenuOptions, PrintMenuOptions, StageMenuOptions} from '../Order/Constants/constants';
import OrderListComponent from '../Orders/OrderListComponent';

class ComponentOrders extends React.Component<RouteComponentProps> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <OrderListComponent
        showFilterBox={true}
        orderStages={[
          'New',
          'Ready',
          'Waiting For Return',
          'Sent To Floor',
          'In Production',
          'Between Factories',
          'Post Fix Return',
          'Done',
          'On Hold',
        ]}
        orderTypes={['Component']}
        printMenuOptions={[PrintMenuOptions.CutSheets, PrintMenuOptions.ShippingLabels]}
        stageMenuOptions={[
          StageMenuOptions.Delivered,
          StageMenuOptions.InProduction,
          StageMenuOptions.Printed,
          StageMenuOptions.ProductionCompleted,
          StageMenuOptions.SendToFloor,
        ]}
        otherMenuOptions={Object.values(OtherMenuOptions)}
        history={this.props.history}
      />
    );
  }
}

export default withRouter(ComponentOrders);
