import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, CutSheetReport} from 'two-core';

class DocumentsService extends ApiService {
  cuttingSheetEndpoint: string;
  shippingLabelEndpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.cuttingSheetEndpoint = config().endpoints.cuttingSheets as string;
    this.shippingLabelEndpoint = config().endpoints.shippingLabels as string;
  }

  async getCuttingSheets(orders: string[]): Promise<CutSheetReport[]> {
    return this.get(this.cuttingSheetEndpoint, {orders: orders.join(',')})
      .then(data => {
        console.log(data);
        const response = data as unknown as {
          content: CutSheetReport[];
        };
        return Promise.resolve(response.content);
      })
      .catch(e => {
        console.log(e);
        return Promise.reject();
      });
  }

  async getShippingLabels(orderId: string): Promise<ApiListResponse> {
    return this.get(this.shippingLabelEndpoint, {order_id: orderId})
      .then(data => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default DocumentsService;
