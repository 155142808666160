import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import {DataTablePageParams, DataTable} from 'primereact/datatable';
import {Dialog} from 'primereact/dialog';
import {Paginator} from 'primereact/paginator';
import {Toast} from 'primereact/toast';
import React from 'react';
import {NavLink} from 'react-router-dom';
import UserComponent from './UserComponent';
import update from 'immutability-helper';
import {ToastService, UsersService, AppContext} from 'two-app-ui';
import {Factory, QueryParameter, User} from 'two-core';

interface State {
  loading: boolean;
  users: User[];
  totalUsers: number;
  selectedUsers: [];
  pagination: {
    pageSize: number;
    offset: number;
  };
  showNewUserDialog: boolean;
  factories: Factory[];
  currentUser: User;
}

class UserListComponent extends React.Component<{}, State> {
  static contextType = AppContext;
  toast: React.RefObject<Toast>;
  usersService: UsersService | null;
  toastService: ToastService | null = null;

  constructor() {
    super({});
    this.state = {
      loading: false,
      users: [],
      totalUsers: 0,
      selectedUsers: [],
      pagination: {
        pageSize: 10,
        offset: 0,
      },
      showNewUserDialog: false,
      factories: [],
      currentUser: {
        username: '',
      },
    };
    this.toast = React.createRef();
    this.usersService = null;

    this.saveUser = this.saveUser.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.usersService = this.context.usersService;
    this.toastService = this.context.toastService;
    this.loadData();
  }

  loadData() {
    this.setState({loading: true});
    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: [],
    };

    this.usersService
      ?.getUsers(params)
      .then(data => {
        this.setState({
          users: (data?.records as User[]) ?? [],
          totalUsers: data?.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  showDialog(uuid: string) {
    if (uuid === 'new') {
      this.setState({
        currentUser: {username: ''},
      });
    } else {
      this.usersService?.getUser(uuid).then((user: User) => {
        console.log(user);
        this.setState({currentUser: user});
      });
    }
    this.setState({showNewUserDialog: true});
  }

  saveUser() {
    if (this.state.currentUser.id !== undefined) {
      this.usersService
        ?.saveUser(this.state.currentUser.id, this.state.currentUser)
        .then(() => {
          this.toastService?.showSuccess(this.toast, 'User updated successfully.');
          this.loadData();
          this.setState({showNewUserDialog: false});
        })
        .catch(error => {
          this.toastService?.showError(this.toast, 'Sorry, User update failed, please try again.');
          console.error('error: ' + error);
        });
    } else {
      /*
      this.usersService
        ?.createUser(this.state.currentUser)
        .then(() => {
          this.toastService?.showSuccess(
            this.toast,
            'User created successfully.'
          );
          this.loadData();
          this.setState({showNewUserDialog: false});
        })
        .catch(error => {
          this.toastService?.showError(
            this.toast,
            'Sorry, User create failed, please try again.'
          );
          console.error('error: ' + error);
        });
        */
    }
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newState = update(this.state, {
      currentUser: {
        $merge: {
          [e.target.name]: e.target.value,
        },
      },
    });
    this.setState(newState);
  }

  renderFooter() {
    return (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button
          label="cancel"
          className={'p-mr-2 p-button-text'}
          onClick={() => {
            this.setState({showNewUserDialog: false});
          }}
        />
        <Button
          label="save"
          className={'p-mr-2'}
          onClick={() => {
            this.saveUser();
          }}
          autoFocus
        />
      </div>
    );
  }

  render() {
    const usernameTemplate = (rowData: User) => {
      return (
        <React.Fragment>
          <NavLink to="#" onClick={() => this.showDialog(rowData.id ?? '')}>
            {rowData.username}
          </NavLink>
        </React.Fragment>
      );
    };

    return (
      <div>
        <div className="top-bar p-justify-between">
          <div className="button-set">
            <span className="p-buttonset">
              <Button label="add new" icon="pi pi-plus" onClick={() => this.showDialog('new')} />
              <Button label="disable" icon="pi pi-calendar" />
              <Button label="delete" icon="pi pi-clock" />
            </span>
          </div>
          <div className="top-paginator">
            <Paginator
              totalRecords={this.state.totalUsers}
              first={this.state.pagination.offset}
              rows={this.state.pagination.pageSize}
              onPageChange={e => this.onPageChange(e)}
            ></Paginator>
          </div>
        </div>
        <div className="table-container">
          <DataTable
            value={this.state.users}
            rows={this.state.pagination.pageSize}
            dataKey="id"
            emptyMessage="Nothing to see here, move along"
            loading={this.state.loading}
            selection={this.state.selectedUsers}
            onSelectionChange={e => this.setState({selectedUsers: e.value})}
          >
            <Column selectionMode="multiple" headerStyle={{width: '3rem'}}></Column>

            <Column header="Username" field="id" body={usernameTemplate} />
            <Column header="Name" field="name" />
            <Column header="email" field="email" />
            <Column header="role" field="role" />
          </DataTable>
        </div>

        <Dialog
          header={''}
          footer={this.renderFooter()}
          visible={this.state.showNewUserDialog}
          style={{width: '50%'}}
          modal
          //className="p-fluid"
          onHide={() => {
            this.setState({showNewUserDialog: false});
          }}
        >
          <UserComponent user={this.state.currentUser} handleInputChange={this.handleInputChange} />
        </Dialog>
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default UserListComponent;
