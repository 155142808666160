import React from 'react';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Calendar} from 'primereact/calendar';
import './Oos.scss';
import OosService from '../../services/OosService';
import {AppContext, ToastService, MessageService, TwoDialog} from 'two-app-ui';
import {Oos, OosPatch} from 'two-core';
import formats from '../../config/formats';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  oosRecords: Oos[];
  toast: React.RefObject<Toast>;
}

interface State {
  oosRecords: Oos[];
}

class SetEtaDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  oosService: OosService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      oosRecords: [],
    };

    this.setOosRecords = this.setOosRecords.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.oosService = this.context.oosService;
    this.toastService = this.context.toastService;
  }

  setOosRecords() {
    this.setState({
      oosRecords: this.props.oosRecords,
    });
  }

  save() {
    const oosItems = this.state.oosRecords;
    const promises = oosItems.map(oos => {
      const oosPatch: OosPatch = {
        stage: 'Eta Confirmed',
        eta: oos.eta,
      };
      return this.oosService?.updateOos(oos.id?.toString() ?? '', oosPatch).then(() => {
        MessageService.sendMessage(messages.oosUpdated);
      });
    });

    Promise.all(promises)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Eta changed');
        this.props.onHide();
      })
      .catch(error => {
        console.error('error: ' + error);
        this.toastService?.showError(this.props.toast, 'Eta was not changed');
      });
  }

  render() {
    const dialogBody = (
      <ScrollPanel style={{height: '200px', width: '100%'}}>
        {this.state.oosRecords.map(oos => {
          return (
            <div className="p-d-flex p-ai-center p-col-12 p-pr-0 p-pl-0 p-pt-0" key={oos.id}>
              <label htmlFor="name" className="p-col-1">
                OOS
              </label>
              <label htmlFor="name" className="p-col-7">
                {oos.inventory_item?.name ?? ''} : {oos.inventory_item?.sku ?? ''} : {oos.inventory_item?.colour ?? ''}
              </label>
              <div className="p-col-4 p-p-0">
                <span className="p-fluid">
                  <Calendar
                    value={oos.eta ? new Date(oos.eta) : undefined}
                    dateFormat={formats.calendarInputDate}
                    onChange={e => {
                      const eta = e.value as Date;
                      this.setState({
                        oosRecords: this.state.oosRecords.map(stateOos => {
                          return stateOos.id === oos.id
                            ? {
                                ...oos,
                                eta: eta,
                              }
                            : stateOos;
                        }),
                      });
                    }}
                  />
                </span>
              </div>
            </div>
          );
        })}
      </ScrollPanel>
    );
    return (
      <TwoDialog
        headerTitle={'Set ETA'}
        showDialog={this.props.showDialog}
        width={50}
        onHide={this.props.onHide}
        onShow={this.setOosRecords}
        onSave={this.save}
        loading={false}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default SetEtaDialog;
