import React from 'react';
import {Toast} from 'primereact/toast';
import FactoriesService from '../../services/FactoriesService';
import {AppContext, MessageService, TwoMessage, ToastService} from 'two-app-ui';
import {Subscription} from 'rxjs';
import {Factory, FactorySetting} from 'two-core';
import {messages} from '../../config/messages';
import './LeadTimesComponent.scss';
import {Fieldset} from 'primereact/fieldset';
import {ProgressSpinner} from 'primereact/progressspinner';
import {InputNumber} from 'primereact/inputnumber';
import {Divider} from 'primereact/divider';
import {Button} from 'primereact/button';

interface State {
  loading: boolean;
  factory: Factory | undefined;
}

class LeadTimesComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  factoriesService: FactoriesService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();
  toast: React.RefObject<Toast>;

  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
      factory: undefined,
    };

    this.save = this.save.bind(this);
    this.reset = this.reset.bind(this);
    this.updateFactory = this.updateFactory.bind(this);
    this.loadFactory = this.loadFactory.bind(this);
    this.initSettings = this.initSettings.bind(this);

    this.toast = React.createRef();
  }

  async componentDidMount() {
    this.factoriesService = this.context.factoriesService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      const castedMessage = message as TwoMessage;
      if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
        await localStorage.setItem('current factory', castedMessage.value as string);
        this.loadFactory();
      }
    });

    this.loadFactory();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  loadFactory() {
    this.setState({loading: true});
    const currentFactoryId = localStorage.getItem('current factory') ?? '';

    return this.factoriesService
      ?.getFactory(currentFactoryId)
      .then(data => {
        const factory = data;
        const settings = factory.settings;

        if (!settings.product_lines.colourvue) {
          this.initSettings('colourvue', settings.product_lines.colourvue);
        }
        if (!settings.product_lines.shadesol) {
          this.initSettings('shadesol', settings.product_lines.shadesol);
        }
        if (!settings.product_lines.curtains) {
          this.initSettings('curtains', settings.product_lines.curtains);
        }

        const updatedFactory = {
          ...factory,
          settings: settings,
        };
        this.setState({loading: false, factory: updatedFactory});
      })
      .catch(() => {
        this.setState({loading: false});
      });
  }

  save() {
    const factory = this.state.factory;
    if (factory) {
      this.updateFactory(factory);
    } else {
      this.toastService?.showError(this.toast, 'Sorry, factory update failed, please try again.');
    }
  }

  reset() {
    this.loadFactory();
  }

  initSettings(productLine: string, factoryProdLineObject: any) {
    switch (productLine) {
      case 'colourvue':
        factoryProdLineObject = {
          lead_time: {
            standard: 0,
            extra: 0,
            repair: 0,
            component: 0,
          },
          daily_capacity: 0,
        };
        break;
      case 'shadesol':
        factoryProdLineObject = {
          lead_time: {
            standard: 0,
            extra: 0,
            repair: 0,
            component: 0,
            extreme: 0,
            extreme_extra: 0,
          },
          daily_capacity: 0,
        };
        break;
      case 'curtains':
        factoryProdLineObject = {
          lead_time: {
            standard: 0,
            motorised: 0,
            repair: 0,
            component: 0,
            custom: 0,
          },
          daily_capacity: 0,
        };
        break;
    }
  }

  updateFactory(factory: Factory) {
    this.setState({loading: true});

    return this.factoriesService
      ?.updateFactory(factory?.id ?? '', factory)
      .then(data => {
        const factory = data;
        this.toastService?.showSuccess(this.toast, 'Factory updated successfully.');
        this.setState({loading: false, factory: factory});
        MessageService.sendMessage(messages.factoryUpdated);
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, factory update failed, please try again.');
        this.setState({loading: false});
      });
  }

  render() {
    const {factory, loading} = this.state;
    const factorySettings = factory?.settings;

    return (
      <div id="lead_times_component" className="page-container">
        <div className="p-grid p-mt-2 p-flex-column">
          <Fieldset legend="Colourvue">
            <div className="setting_part">
              {loading && (
                <>
                  <div className="overlay">
                    <ProgressSpinner />
                  </div>
                </>
              )}
              <div className="p-d-flex p-ai-center p-col-12">
                <label htmlFor="standard" className="p-col-1">
                  standard
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.colourvue?.lead_time.standard}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.colourvue!.lead_time.standard = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="extra" className="p-col-1">
                  standard + deco cover
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.colourvue?.lead_time.extra}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.colourvue!.lead_time.extra = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="repair" className="p-col-1">
                  repair
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.colourvue?.lead_time.repair}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.colourvue!.lead_time.repair = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="component" className="p-col-1">
                  component
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.colourvue?.lead_time.component}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.colourvue!.lead_time.component = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
              </div>
              <Divider />

              <div className="p-d-flex p-ai-center p-col-12">
                <label htmlFor="daily_capacity" className="p-col-1">
                  daily capacity
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.colourvue?.daily_capacity}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.colourvue!.daily_capacity = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Fieldset>
          <Fieldset legend="Shadesol" className="p-mt-2">
            <div className="setting_part">
              {loading && (
                <>
                  <div className="overlay">
                    <ProgressSpinner />
                  </div>
                </>
              )}
              <div className="p-d-flex p-ai-center p-col-12">
                <label htmlFor="standard" className="p-col-1">
                  standard
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.shadesol?.lead_time.standard}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.shadesol!.lead_time.standard = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="extra" className="p-col-1">
                  standard + powder
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.shadesol?.lead_time.extra}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.shadesol!.lead_time.extra = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="repair" className="p-col-1">
                  repair
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.shadesol?.lead_time.repair}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.shadesol!.lead_time.repair = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="component" className="p-col-1">
                  component
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.shadesol?.lead_time.component}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.shadesol!.lead_time.component = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-ai-center p-col-12">
                <label htmlFor="standard" className="p-col-1">
                  extreme
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.shadesol?.lead_time.extreme}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.shadesol!.lead_time.extreme = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="extra" className="p-col-1">
                  extreme + powder
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.shadesol?.lead_time.extreme_extra}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.shadesol!.lead_time.extreme_extra = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
              </div>
              <Divider />

              <div className="p-d-flex p-ai-center p-col-12">
                <label htmlFor="daily_capacity" className="p-col-1">
                  daily capacity
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.shadesol?.daily_capacity}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.shadesol!.daily_capacity = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Fieldset>
          <Fieldset legend="Curtains" className="p-mt-2">
            <div className="setting_part">
              {loading && (
                <>
                  <div className="overlay">
                    <ProgressSpinner />
                  </div>
                </>
              )}
              <div className="p-d-flex p-ai-center p-col-12">
                <label htmlFor="standard" className="p-col-1">
                  standard
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.curtains?.lead_time.standard}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.curtains!.lead_time.standard = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="custom fabric" className="p-col-1">
                  custom fabric
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.curtains?.lead_time.custom}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.curtains!.lead_time.custom = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="repair" className="p-col-1">
                  repair
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.curtains?.lead_time.repair}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.curtains!.lead_time.repair = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="component" className="p-col-1">
                  component
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.curtains?.lead_time.component}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.curtains!.lead_time.component = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
              </div>
              <Divider />

              <div className="p-d-flex p-ai-center p-col-12">
                <label htmlFor="daily_capacity" className="p-col-1">
                  daily capacity
                </label>
                <div className="p-col-2">
                  <span className="p-fluid">
                    <InputNumber
                      value={factorySettings?.product_lines?.curtains?.daily_capacity}
                      onChange={e => {
                        const value = e.value;
                        const newSettings: FactorySetting = {...this.state.factory!.settings};
                        newSettings.product_lines.curtains!.daily_capacity = value as number;
                        this.setState({factory: {...this.state.factory!, settings: newSettings}});
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Fieldset>
        </div>
        <div className={'p-d-flex p-my-4 p-justify-end'}>
          <Button
            label="reset"
            className={'p-mr-2 p-button-text'}
            onClick={() => {
              this.reset();
            }}
          />
          <Button
            label="save"
            onClick={() => {
              this.save();
            }}
            autoFocus
          />
        </div>
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default LeadTimesComponent;
